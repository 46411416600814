import React from 'react'
import { Dialog } from '@headlessui/react'
import CrowconButton from 'components/miscComponents/CrowconButton'
import { CloseIcon } from 'assets/icons'
import { useLocation } from 'react-router-dom'
import { withErrorBoundary } from 'react-error-boundary'
import ErrorPage from 'pages/common/ErrorPage'
import ExportCSV from './ExportCSV'
import ExportPDF from './ExportPDF'
import { ExportColumnType, ExportWidgetType } from './DataExportInterfaces'

interface ExportDataProps<T> {
  data: T[]
  IsOpen: boolean
  reportColumns: ExportColumnType[]
  filterSummary: string
  reportTitle: string
  handleCancel: () => void
  widgetForReport?: ExportWidgetType[]
}

function ExportData<T>(props: ExportDataProps<T>) {
  const { IsOpen, handleCancel, data, reportColumns, widgetForReport, filterSummary, reportTitle } =
    props
  const location = useLocation()

  const columnOrder = localStorage.getItem('columnOrder')
  const isColumOrder = columnOrder && JSON.parse(columnOrder)[location.pathname]

  const columnVisibility = localStorage.getItem('columnVisibility')
  const isColumVisible = columnVisibility && JSON.parse(columnVisibility)[location.pathname]

  const sortedColumns = isColumOrder || Object.keys(data?.[0] || {})
  const sortedReportColumns = reportColumns
    .sort((a, b) => {
      const indexA = sortedColumns.indexOf(a.key)
      const indexB = sortedColumns.indexOf(b.key)
      return indexA - indexB
    })
    .filter((column) => isColumVisible?.[column.key] !== false)

  return (
    <Dialog open={IsOpen} onClose={() => handleCancel()} className='relative z-50 rounded-2xl'>
      <div className='fixed inset-0 bg-c-dark-blue-1/50' aria-hidden='true' />
      <div className='fixed inset-0 w-screen overflow-y-auto'>
        <div className='flex min-h-full items-center justify-center p-4'>
          <Dialog.Panel className='mx-auto rounded-2xl bg-white'>
            <div className='h-full  w-80 text-c-dark-blue-1 font-bold text-3xs font-poppins flex flex-col gap-4 py-6 px-7'>
              <div className='flex flex-row w-full'>
                <div className='w-full text-base text-c-dark-blue-3 pt-2'>Export Data</div>
                <div className='w-12'>
                  <CloseIcon
                    toggleClick={() => handleCancel()}
                    className='bg-c-dark-blue-1 cursor-pointer rounded-full w-10 h-10 p-2.5 hover:bg-c-dark-blue-2  active:bg-c-dark-blue-3'
                  />
                </div>
              </div>
              <div className='flex flex-col gap-3 pt-2'>
                <ExportCSV data={data} reportColumns={sortedReportColumns} fileName={reportTitle} />

                <ExportPDF
                  data={data}
                  reportColumns={sortedReportColumns}
                  widgetForReport={widgetForReport}
                  reportTitle={reportTitle}
                  filterSummary={filterSummary}
                />
                <CrowconButton
                  useDefaultTextColour={false}
                  additionalClassName='text-base text-c-dark-blue-1 mt-5 font-bold  bg-c-light-blue-2 hover:text-white h-52  w-full rounded-4xl'
                  buttonChildren={<span>Cancel</span>}
                  buttonAction={() => handleCancel()}
                />
              </div>
            </div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  )
}

ExportData.defaultProps = {
  widgetForReport: [],
}
export default withErrorBoundary(ExportData, {
  FallbackComponent: ErrorPage,
})
