import { QueryClient, useQuery } from '@tanstack/react-query'
import { useMsal } from '@azure/msal-react'

import ConnectAPIClient from 'services/ConnectAPIClient'
import { DeviceCalDueTypes, PortableTypes } from 'utils/CommonEnums'
import { selectAllOption } from 'forms/FormUtils'
import { iBusinessUnitDetails } from 'components/commonComponents/BusinessUnitDataUtils'
import { getAccessToken, getAccessTokenFromPCA, getAccessTokenProps } from 'services/MSALService'
import { iAPIBusinessUnitParams } from 'pages/devices-2/data/DevicePageData'
import { allBusinessUnitOption } from 'data/BusinessUnitsListHookWrapper'
import { allCustomerOption, getOrFetchCustomerList } from 'data/CustomerListHook'
import { AccountInfo, IPublicClientApplication, InteractionStatus } from '@azure/msal-browser'
import { LoaderFunction, createSearchParams } from 'react-router-dom'
import { getOrFetchUserInfo } from 'data/UserInfoHook'
import { IsUserGlobalScope } from 'utils/UserDataUtils'
import { getOrFetchBusinessUnitsByCustomer } from 'data/BusinessUnitsListByCustomerHook'
import { getOrFetchBusinessUnitsForUser } from 'data/BusinessUnitsListByUserHook'
import { iDataLoaderParams } from 'data/Interfaces'
import { getBUId } from 'data/DeviceListHook'
import { AxiosError } from 'axios'
import { parseApiErrorOrNetworkError } from 'services/APIErrorParser'

function getTopLevelBusinessUnitId(businessUnitsList: iBusinessUnitDetails[]) {
  const topLevelBusinessUnit = businessUnitsList.find(
    (businessUnit) => businessUnit.parentId === null,
  )
  if (!topLevelBusinessUnit) return ''
  return topLevelBusinessUnit?.id
}

export const fleetHealthDataQueryKey = (businessUnitId?: string, customerId?: string) => [
  'fleetHealthSummary',
  'businessUnit',
  businessUnitId,
]

export const fleetInsightsDataQueryKey = (businessUnitId?: string, customerId?: string) => [
  'fleetInsightsData',
  'businessUnit',
  businessUnitId,
]

export const fleetHealthDataURL = (businessUnitId?: string, customerId?: string) => {
  if (
    businessUnitId &&
    businessUnitId.toLowerCase() !== selectAllOption.value?.toString().toLowerCase()
  ) {
    return `portables/api/device/fleetsummary?ExpandBusinessUnit=true&BusinessUnitId=${businessUnitId}`
  }
  return `portables/api/device/fleetsummary`
}

export const fleetInsightsDataURL = (businessUnitId?: string, customerId?: string) => {
  if (
    businessUnitId &&
    businessUnitId.toLowerCase() !== selectAllOption.value?.toString().toLowerCase()
  ) {
    return `devicelogs/api/EventLogs/fleetinsights?ExpandBusinessUnit=true&BusinessUnitId=${businessUnitId}`
  }
  return `devicelogs/api/EventLogs/fleetinsights`
}

interface iDashboardDataLoaderParams {
  queryClient: QueryClient
  msalInstance: IPublicClientApplication
  msalAccounts: AccountInfo[]
  msalInProgress: InteractionStatus
}

export interface iDevicesOverdueData {
  totalOverdue: number
  gasmanOverDue: number
  t3OverDue: number
  gasProOverDue: number
  t4OverDue: number
}

export interface iFleetHealthSummary {
  totalDevices: number
  totalActiveDevices: number
  totalInActiveDevices: number
  fleetAssignment: {
    deviceAssignments: number
    deviceUnAssignments: number
  }
  upcomingCalibrations: {
    thirtyDaysDue: number
    sixtyDaysDue: number
    ninetyDaysDue: number
  }
  devicesOverDue: iDevicesOverdueData
}

export interface iFleetInsightsSummary {
  totalDevices: number
  totalActiveDevices: number
  totalInActiveDevices: number
  devicesSynced: number
  devicesNotSynced: number
  devicesSwitchedON: number
  devicesNotSwitchedON: number
  devicesWithAlarms: number
  alarms: {
    month: string
    alarm1: number
    alarm2: number
  }[]
  faults: {
    month: string
    faults: number
  }[]
  switchOns: {
    month: string
    switchOns: number
  }[]
}

interface iFetchDashboardDataParams {
  token: string
  businessUnitId?: string
  customerId?: string
}

interface iGetOrFetchDashboardDataProps {
  queryClient: QueryClient
  token: string
  businessUnitId?: string
  customerId?: string
}

interface iDashboardDataAPIParams extends iAPIBusinessUnitParams, getAccessTokenProps {}

export async function FetchFleetHealthData(
  params: iFetchDashboardDataParams,
): Promise<iFleetHealthSummary> {
  try {
    const { token, businessUnitId, customerId } = params
    const url = fleetHealthDataURL(businessUnitId, customerId)
    const resp = await ConnectAPIClient.get<iFleetHealthSummary>(url, token)
    const response = resp.data
    return response
  } catch (error) {
    console.error(error)
    throw parseApiErrorOrNetworkError(error)
  }
}

export async function FetchFleetInsightsData(
  params: iFetchDashboardDataParams,
): Promise<iFleetInsightsSummary> {
  const { token, businessUnitId, customerId } = params
  const url = fleetInsightsDataURL(businessUnitId, customerId)
  const resp = await ConnectAPIClient.get<iFleetInsightsSummary>(url, token)
  const response = resp.data
  return response
}

export async function getFleetHealthData(fleethHealthAPIParams: iDashboardDataAPIParams) {
  try {
    const { msalContext, redirectPageURL, businessUnitId, customerId } = fleethHealthAPIParams
    const token = await getAccessToken({ msalContext, redirectPageURL })
    if (!token) return null
    const fleetHealthData = await FetchFleetHealthData({ token, businessUnitId, customerId })
    return fleetHealthData
  } catch (error) {
    throw parseApiErrorOrNetworkError(error)
  }
}

export async function getFleetInsightsData(fleetInsightsAPIParams: iDashboardDataAPIParams) {
  const { msalContext, redirectPageURL, businessUnitId, customerId } = fleetInsightsAPIParams
  const token = await getAccessToken({ msalContext, redirectPageURL })
  if (!token) return null
  const fleetInsightsData = await FetchFleetInsightsData({ token, businessUnitId, customerId })
  return fleetInsightsData
}

export function useFleetHealthData(
  redirectPageURL: string,
  businessUnitId: string,
  customerId?: string,
  businessUnitsList?: iBusinessUnitDetails[] | undefined,
  enabled?: boolean,
) {
  const msalContext = useMsal()
  const { accounts, inProgress, instance } = msalContext
  const isQueryEnabled = enabled && accounts.length > 0 && inProgress === InteractionStatus.None

  const buID = getBUId(businessUnitId, customerId || '', businessUnitsList)

  return useQuery(
    fleetHealthDataQueryKey(buID, customerId),
    () =>
      getFleetHealthData({
        msalContext,
        redirectPageURL,
        businessUnitId: buID,
        customerId,
      }),
    {
      enabled: isQueryEnabled,
      onError: (error: AxiosError) => {
        console.log(error)
      },
    },
  )
}

export function useFleetInsightsData(
  redirectPageURL: string,
  businessUnitId: string,
  customerId?: string,
  businessUnitsList?: iBusinessUnitDetails[] | undefined,
  enabled?: boolean,
) {
  const msalContext = useMsal()
  const { accounts, inProgress, instance } = msalContext
  const isQueryEnabled = enabled && accounts.length > 0 && inProgress === InteractionStatus.None

  const buID = getBUId(businessUnitId, customerId || '', businessUnitsList)

  return useQuery(
    fleetInsightsDataQueryKey(buID, customerId),
    () =>
      getFleetInsightsData({
        msalContext,
        redirectPageURL,
        businessUnitId: buID,
        customerId,
      }),
    {
      enabled: isQueryEnabled,
      onError: (error: AxiosError) => {
        console.log(error)
      },
    },
  )
}

export async function getOrFetchFleetHealthData(props: iGetOrFetchDashboardDataProps) {
  const { queryClient, token, businessUnitId, customerId } = props

  const fleetHealthDataCached = queryClient.getQueryData<iFleetHealthSummary>(
    fleetHealthDataQueryKey(businessUnitId, customerId),
  )

  if (fleetHealthDataCached) {
    return fleetHealthDataCached
  }

  const fleetHealthDataFetched = await queryClient.fetchQuery(
    fleetHealthDataQueryKey(businessUnitId, customerId),
    async () =>
      FetchFleetHealthData({
        token,
        businessUnitId,
        customerId,
      }),
  )

  return fleetHealthDataFetched
}

export async function getOrFetchFleetInsightsData(props: iGetOrFetchDashboardDataProps) {
  const { queryClient, token, businessUnitId, customerId } = props

  const fleetInsightsDataCached = queryClient.getQueryData<iFleetInsightsSummary>(
    fleetInsightsDataQueryKey(businessUnitId, customerId),
  )

  if (fleetInsightsDataCached) {
    return fleetInsightsDataCached
  }

  const fleetInsightsDataFetched = await queryClient.fetchQuery(
    fleetInsightsDataQueryKey(businessUnitId, customerId),
    async () =>
      FetchFleetInsightsData({
        token,
        businessUnitId,
        customerId,
      }),
  )

  return fleetInsightsDataFetched
}

export const DashboardDataLoader =
  (props: iDataLoaderParams): LoaderFunction =>
  async ({ request, params }) => {
    const { queryClient, msalInstance, msalAccounts, msalInProgress } = props
    if (!msalInstance) throw new Error('No MSAL Context')
    if (!queryClient) throw new Error('No query client')

    try {
      if (msalInProgress !== InteractionStatus.None) {
        return {}
      }
      if (msalAccounts.length === 0) {
        return {}
      }
      const token = await getAccessTokenFromPCA({ msalInstance })

      const userInfo = await getOrFetchUserInfo({ queryClient, token })

      const isUserGlobal = IsUserGlobalScope(userInfo)

      const searchParams = createSearchParams(new URLSearchParams(request.url.split('?')[1]))

      const customerId = isUserGlobal
        ? searchParams.get('c') ?? (allCustomerOption.id as string)
        : ''
      const businessUnitId = searchParams.get('mainbu') ?? allBusinessUnitOption.id

      const customerList = !isUserGlobal ? [] : await getOrFetchCustomerList({ queryClient, token })

      const businessUnitsList =
        isUserGlobal && customerId !== '' && customerId !== allCustomerOption.id
          ? await getOrFetchBusinessUnitsByCustomer({ queryClient, token, customerId })
          : await getOrFetchBusinessUnitsForUser({ queryClient, token })

      let buId = ''
      if (
        businessUnitId === allBusinessUnitOption.id &&
        customerId !== '' &&
        customerId !== allCustomerOption.id &&
        businessUnitsList
      ) {
        buId = getTopLevelBusinessUnitId(businessUnitsList)
      } else {
        buId = businessUnitId
      }

      const fleetHealthData = await getOrFetchFleetHealthData({
        queryClient,
        token,
        businessUnitId: buId,
        customerId,
      })

      const fleetInsightsData = await getOrFetchFleetInsightsData({
        queryClient,
        token,
        businessUnitId: buId,
        customerId,
      })

      return {
        fleetHealthData,
        fleetInsightsData,
        businessUnitsList,
        customerList,
      }
    } catch (error) {
      console.error(error)
      throw error
    }
  }
