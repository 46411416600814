// Import the React library
import React from 'react'

// Define the CalenderWeek component
export default function CalenderWeek() {
  // Render the component
  return (
    // Create a grid layout with 7 columns, one for each day of the week
    <div className='grid grid-cols-7 gap-px border-y border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 flex-none'>
      {/* Each child div represents a day of the week */}
      {/* Monday */}
      <div className='bg-white py-2'>
        M<span className='sr-only sm:not-sr-only'>on</span>
      </div>
      {/* Tuesday */}
      <div className='bg-white py-2'>
        T<span className='sr-only sm:not-sr-only'>ue</span>
      </div>
      {/* Wednesday */}
      <div className='bg-white py-2'>
        W<span className='sr-only sm:not-sr-only'>ed</span>
      </div>
      {/* Thursday */}
      <div className='bg-white py-2'>
        T<span className='sr-only sm:not-sr-only'>hu</span>
      </div>
      {/* Friday */}
      <div className='bg-white py-2'>
        F<span className='sr-only sm:not-sr-only'>ri</span>
      </div>
      {/* Saturday */}
      <div className='bg-white py-2'>
        S<span className='sr-only sm:not-sr-only'>at</span>
      </div>
      {/* Sunday */}
      <div className='bg-white py-2'>
        S<span className='sr-only sm:not-sr-only'>un</span>
      </div>
    </div>
  )
}
