import { Transition, Dialog } from '@headlessui/react'
import { CloseIcon } from 'assets/icons'
import TextInputControl from 'components/formComponents/TextInputControl'
import React, { Fragment } from 'react'
import {
  Controller,
  DeepPartial,
  FieldValues,
  Path,
  RegisterOptions,
  SubmitHandler,
  useForm,
} from 'react-hook-form'
import { useLocation } from 'react-router-dom'

type CustomRegisterOptions<T> = RegisterOptions<T & FieldValues> | undefined

type OrganizationCrudModalProps<T> = {
  isOpen: boolean
  closeModal: () => void
  type: string
  formConfig: {
    name: string
    label: string
    placeHolder: string
    rules: CustomRegisterOptions<T> | undefined
    defaultValue: string
    value: string
  }[]
  onSubmit: (data: T) => void
  onError: (data: unknown) => void
}

export default function OrganizationCrudModal<T extends FieldValues>({
  isOpen,
  closeModal,
  type,
  formConfig,
  onSubmit: onFormSubmit,
  onError: onFormError,
}: OrganizationCrudModalProps<T>) {
  // useLocation is a hook provided by react-router-dom that returns the current location object.
  // We're destructuring 'pathname' from the location object.
  const { pathname } = useLocation()

  // Determine the heading based on the last segment of the pathname.
  // If the last segment is 'organisation-structure', set the heading to 'Organization'.
  // Otherwise, set the heading to 'Business Unit'.
  const heading =
    pathname.split('/').reverse()[0] === 'organisation-structure' ? 'Organization' : 'Business Unit'
  // useForm is a hook provided by react-hook-form that is used to handle form state and validation.
  // It returns an object with various form methods and properties.
  // In this case, we're destructuring 'handleSubmit' and 'control' from the returned object.
  const {
    handleSubmit,
    control,
    formState: { isValid, isSubmitting, isDirty, isSubmitted },
  } = useForm<T>({
    // defaultValues is an option that sets the default values for the form fields.
    // If the modal type is 'edit', it's set to an object created by reducing 'formConfig' to a new object with properties corresponding to the form fields and values corresponding to the default values.
    // If the modal type is not 'edit', it's set to null.
    // The resulting value is cast to DeepPartial<T> to indicate that every property of T is optional.
    defaultValues: (type === 'edit'
      ? formConfig.reduce(
          (acc, crr) => ({
            ...acc,
            [crr.name]: crr.defaultValue,
          }),
          {},
        )
      : null) as DeepPartial<T>,
    // criteriaMode is an option that determines when the validation errors for each field are shown.
    // 'all' means that all validation errors for each field are shown.
    criteriaMode: 'all',
    // shouldFocusError is an option that determines whether the first field with an error should be focused when the form is submitted and validation errors occur.
    // true means that the first field with an error will be focused.
    shouldFocusError: true,
    // mode is an option that determines when validation is triggered.
    // 'onTouched' means that validation is triggered when a field is touched.
    mode: 'onTouched',
  })

  // Define an onSubmit function that will be called when the form is submitted.
  // This function takes an object 'formValue' of generic type T and calls the 'onFormSubmit' function with 'formValue' as an argument.
  const onSubmit: SubmitHandler<T> = (formValue) => onFormSubmit(formValue)

  // Define an onError function that will be called when there is an error in the form.
  // This function takes an 'errors' object of unknown type and calls the 'onFormError' function with 'errors' as an argument.
  const onError = (errors: unknown) => onFormError(errors)

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog className='relative z-50 rounded-2xl' onClose={() => closeModal()}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-black/30' />
        </Transition.Child>

        <form
          onSubmit={handleSubmit(onSubmit, onError)}
          className='fixed inset-0 w-screen overflow-y-auto'
        >
          <div className='flex min-h-full items-center justify-center p-4'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <Dialog.Panel className='w-full max-w-md transform overflow-hidden mx-auto rounded-2xl  p-4 bg-white  transition-all'>
                <Dialog.Title>
                  <div className='flex flex-row justify-between items-center'>
                    <p className='text-lg font-medium leading-6 text-gray-900 mb-3'>
                      {type === 'edit' && `Edit ${heading}`}
                      {type === 'add' && `Add ${heading}`}
                    </p>
                    <CloseIcon
                      toggleClick={() => closeModal()}
                      className='bg-c-dark-blue-1 rounded-full w-10 h-10 p-2.5 hover:bg-c-dark-blue-2  active:bg-c-dark-blue-3'
                    />
                  </div>
                </Dialog.Title>
                {formConfig.map((inputField) => (
                  <div className='flex flex-col w-full mb-3' key={inputField.name}>
                    <div className='flex flex-col gap-0.5 items-start w-full'>
                      <label
                        htmlFor='name'
                        className='pl-[14px] text-xs font-bold leading-5 text-c-dark-blue-1 '
                      >
                        {inputField.label}
                      </label>
                      <Controller
                        name={inputField.name as Path<T>}
                        control={control}
                        rules={inputField.rules}
                        render={({ field }) => (
                          <TextInputControl
                            className='w-full h-[42px]'
                            id={inputField.name}
                            elementProps={{
                              defaultValue: inputField.defaultValue,
                              placeholder: `${inputField.placeHolder}`,
                              onChange: field.onChange,
                              onBlur: field.onBlur,
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                ))}
                <div className='mt-4 flex gap-3'>
                  <button
                    type='submit'
                    disabled={!isValid || !isDirty || isSubmitting || isSubmitted}
                    className='text-base font-extrabold active:bg-c-dark-blue-3  hover:bg-c-dark-blue-2 disabled:bg-c-light-blue-3 disabled:text-c-dark-blue-1 mt-2 bg-c-dark-blue-1 text-white h-12 w-full rounded-3xl'
                  >
                    {type === 'edit' && 'Update'}
                    {type === 'add' && 'Create'}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </form>
      </Dialog>
    </Transition>
  )
}
