import { useMsal } from '@azure/msal-react'
import { useQueryClient, useMutation } from '@tanstack/react-query'
import { EditIcon, AddIcon } from 'assets/icons'
import { AxiosError } from 'axios'
import { useGenericEventHandler } from 'data/GenericEventHandler'
import React, { useState } from 'react'
import { CustomNodeElementProps } from 'react-d3-tree'
import { SubmitHandler } from 'react-hook-form'
import Show from 'components/atom/Show'
import OrganizationCrudMenu from '../components/OrganizationCrudMenu'
import OrganizationCrudModal from '../components/OrganizationCrudModal'
import {
  iOrganizationTreeData,
  iOrganizationTreePayloadParams,
} from '../utils/OrganizationInterface'
import {
  BusinessUnitTreeQueryKey,
  addNodeToOrganizationTreeList,
  organizationTreeQueryKey,
  updateNodeInOrganizationTreeList,
} from '../data/OrganizationPageData'

/**
 * Renders a node in the hierarchical organization tree.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {CustomNodeElementProps} props.nodeDatum - The node data.
 * @returns {JSX.Element} The rendered node component.
 */
export default function OrganizationTreeNode({ nodeDatum }: CustomNodeElementProps): JSX.Element {
  // useGenericEventHandler is a custom hook that provides a generic event handler function.
  // genericEventHandler is a function that can be used to handle various types of events.
  const { genericEventHandler } = useGenericEventHandler()

  // useQueryClient is a hook provided by react-query that returns the query client instance.
  // The query client is used to interact with and control the behavior of react-query.
  const queryClient = useQueryClient()

  // useMsal is a hook provided by the Microsoft Authentication Library (MSAL) for React.
  // It provides access to the MSAL instance and various authentication functions.
  const msalContext = useMsal()

  // Initialize a state variable 'isOpen' with useState hook. This state will hold an object with two properties:
  // 'open': a boolean indicating whether a modal is open or not,
  // 'type': a string indicating the type of the modal.
  const [isOpen, setIsOpen] = useState<{
    open: boolean
    type: string
  }>({ open: false, type: '' })

  // nodeDatum is cast to iOrganizationTreeData type and assigned to 'data'.
  const data = nodeDatum as unknown as iOrganizationTreeData

  // closeModal is a function that sets 'isOpen' state to its initial state, effectively closing the modal.
  const closeModal = () => {
    setIsOpen({
      open: false,
      type: '',
    })
  }

  // openModal is a function that takes a string parameter 'type' and sets 'isOpen' state to open the modal of the given type.
  function openModal(type: string) {
    setIsOpen({
      open: true,
      type,
    })
  }

  // useMutation is a hook provided by react-query that is used to handle mutations (create, update, delete).
  // updateNodeInOrganizationTreeList is a function that updates a node in the organization Tree list.
  // The hook returns a mutate function (updateNode) and an object with information about the mutation's state.
  const updateNode = useMutation(updateNodeInOrganizationTreeList, {
    // onSuccess is a callback function that is called when the mutation is successful.
    onSuccess: () => {
      // Invalidate queries with the query key 'organizationTreeQueryKey(data?.customerId ?? '')'
      // This will cause react-query to refetch the data for these queries.
      queryClient.invalidateQueries({
        queryKey: organizationTreeQueryKey(data?.customerId ?? ''),
      })
      queryClient.invalidateQueries({
        queryKey: BusinessUnitTreeQueryKey(data?.customerId ?? ''),
      })
      // Close the modal
      closeModal()
    },
    // onError is a callback function that is called when the mutation encounters an error.
    onError: (error) => {
      // Call the generic event handler with an error object
      genericEventHandler({
        onlyTrack: false, // Whether to only track the event, not display it
        // The error message
        message:
          (
            (error as unknown as AxiosError).response?.data as {
              detail: string
            }
          )?.detail || 'Error in updating node',
        error, // The error object
        type: 'Error', // The type of the event
        severity: 'error', // The severity of the event
        extraData: {
          // Extra data to include with the event
          component: 'OrganizationTreeCrudModal', // The component where the event occurred
          action: 'Error in updating node', // The action that was being performed when the event occurred
        },
      })
    },
  })

  // addNodeToOrganizationTreeList is a function that adds a node to the organization Tree list.
  // The hook returns a mutate function (createNode) and an object with information about the mutation's state.
  const createNode = useMutation(addNodeToOrganizationTreeList, {
    // onSuccess is a callback function that is called when the mutation is successful.
    onSuccess: () => {
      // Invalidate queries with the query key 'organizationTreeQueryKey(data?.customerId ?? '')'
      // This will cause react-query to refetch the data for these queries.
      queryClient.invalidateQueries({
        queryKey: organizationTreeQueryKey(data?.customerId ?? ''),
      })
      // Close the modal
      closeModal()
    },
    // onError is a callback function that is called when the mutation encounters an error.
    onError: (error) => {
      // Call the generic event handler with an error object
      genericEventHandler({
        onlyTrack: false, // Whether to only track the event, not display it
        // The error message
        message:
          (
            (error as unknown as AxiosError).response?.data as {
              detail: string
            }
          )?.detail || 'Error in creating node',
        error, // The error object
        type: 'Error', // The type of the event
        severity: 'error', // The severity of the event
        extraData: {
          // Extra data to include with the event
          component: 'OrganizationTreeCrudModal', // The component where the event occurred
          action: 'Error in creating node', // The action that was being performed when the event occurred
        },
      })
    },
  })

  // Define an onSubmit function that will be called when the form is submitted.
  // This function takes an object 'formValue' with properties 'name' and 'description'.
  const onSubmit: SubmitHandler<{
    name: string
    description?: string
  }> = (formValue) => {
    // Check if the modal type is 'edit'
    if (isOpen.type === 'edit') {
      // If it is, call the 'updateNode' mutate function provided by the useMutation hook.
      // This function takes an object with the MSAL context and a payload containing the form values and the node id.
      updateNode.mutate({
        msalContext,
        payload: {
          description: formValue?.description || '',
          bUlevelId: data.id,
          name: formValue.name,
        },
      } as iOrganizationTreePayloadParams)
    } else {
      // If the modal type is not 'edit', call the 'createNode' mutate function provided by the useMutation hook.
      // This function takes an object with the MSAL context, the customer id, and a payload containing the form values.
      createNode.mutate({
        msalContext,
        customerId: data.customerId,
        payload: {
          description: formValue.description || '',
          name: formValue.name,
        },
      } as iOrganizationTreePayloadParams)
    }
  }

  // Define an onError function that will be called when there is an error in the form.
  // This function takes an 'errors' object and logs it to the console.
  const onError = (errors: unknown) => {
    console.log(errors)
  }
  return (
    <g className='group/item '>
      <circle r={10} className='fill-c-light-blue-1 stroke-c-blue' strokeWidth={2} />
      <foreignObject className='w-[100%] md:w-[50%] h-[10%]  relative '>
        <div className='ml-5 bg-c-blue  text-white px-4 w-max py-2 flex items-center rounded-full '>
          <div className=''>
            <h3 className='text-sm text-bold '>{nodeDatum.name}</h3>
            <h3 className='text-3xs '>{nodeDatum?.attributes?.buLevelName}</h3>
          </div>

          <OrganizationCrudMenu
            menuItemConfig={[
              {
                title: 'Edit',
                type: 'button',
                className: 'group flex items-center rounded-lg  data-[focus]:bg-white/10',
                onClick: () => openModal('edit'),
                children: (
                  <EditIcon
                    className='w-4 h-4 stroke-white fill-transparent'
                    stroke='white'
                    fill='transparent'
                  />
                ),
              },

              {
                title: 'Add',
                type: 'button',
                className: `${
                  nodeDatum?.children?.length === 0 ? 'flex' : 'hidden'
                } group items-center rounded-lg  data-[focus]:bg-white/10`,
                onClick: () => openModal('add'),
                children: <AddIcon className='h-5 w-5  fill-c-white' fill='white' />,
              },
            ]}
          >
            <div className=''>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 10 24'
                strokeWidth={1.5}
                stroke='currentColor'
                className='w-4 h-4'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z'
                />
              </svg>
            </div>
          </OrganizationCrudMenu>
        </div>
      </foreignObject>

      <Show>
        <Show.When isTrue={isOpen.open}>
          <OrganizationCrudModal
            isOpen={isOpen.open}
            closeModal={closeModal}
            type={isOpen.type}
            formConfig={[
              {
                name: 'name',
                label: 'Name',
                placeHolder: 'Enter Name',
                rules: { required: 'Name is required' },
                defaultValue: isOpen.type === 'edit' ? data.name : '',
                value: '',
              },
              {
                name: 'description',
                label: 'Description',
                placeHolder: 'Enter Description',
                rules: undefined,
                defaultValue: isOpen.type === 'edit' ? data.description : '',
                value: '',
              },
            ]}
            onSubmit={onSubmit}
            onError={onError}
          />
        </Show.When>
      </Show>
    </g>
  )
}
