import { InteractionStatus } from '@azure/msal-browser'
import { LoaderFunction } from 'react-router-dom'
import { getAccessToken, getAccessTokenFromPCA, getAccessTokenProps } from 'services/MSALService'
import { getOrFetchLocaleData } from 'data/UserLocaleHook'
import ConnectAPIClient from 'services/ConnectAPIClient'
import { iDataLoaderParams } from 'data/Interfaces'

// interface UserSettingsPageDataLoaderParams {
//   queryClient: QueryClient
//   msalInstance: IPublicClientApplication
// }

export interface iUpdateUserSettingsDTO {
  id: string
  firstName: string
  lastName: string
  timeZone: string
  localeId: string
}

interface iUpdateUserSettingProps {
  userToUpdate: iUpdateUserSettingsDTO
  accessTokenProps: getAccessTokenProps
}

const updateUserSettingsURL = () => `users/user/preference`

export const UserSettingsPageDataLoader =
  (props: iDataLoaderParams): LoaderFunction =>
  async () => {
    const { queryClient, msalInstance, msalAccounts, msalInProgress } = props
    if (!msalInstance) throw new Error('No MSAL Context')
    if (!queryClient) throw new Error('No query client')

    try {
      if (msalInProgress !== InteractionStatus.None) {
        return {}
      }
      if (msalAccounts.length === 0) {
        return {}
      }
      const token = await getAccessTokenFromPCA({ msalInstance })
      const localesData = await getOrFetchLocaleData({
        queryClient,
        token,
      })
      return {
        userSettingsPageData: localesData,
      }
    } catch (error) {
      console.error(error)
      throw error
    }
  }

export async function updateUserSettings(updateUserSettingsProps: iUpdateUserSettingProps) {
  const { userToUpdate, accessTokenProps } = updateUserSettingsProps
  try {
    const apiURL = updateUserSettingsURL()
    const token = await getAccessToken(accessTokenProps)
    const resp = await ConnectAPIClient.put<iUpdateUserSettingsDTO>(
      apiURL,
      token,
      undefined,
      userToUpdate,
    )
    const response = resp.data
    return userToUpdate
  } catch (error) {
    console.error(error)
    throw error
  }
}
