// Import necessary modules and components
import React, { useContext } from 'react'
import { flexRender } from '@tanstack/react-table'
import clsx from 'clsx'
import Show from 'components/atom/Show'
import { DropDownIcon } from 'assets/icons'
import DragIcon from 'assets/icons/DragIcon'
import { TableContext, TableContextType } from '../../TableContext'

// Define the TableHeader component
export default function TableHeader<T>({
  index, // The index of the header in the header group
  groupHeaderIndex, // The index of the header group
  drag,
}: {
  index: number
  groupHeaderIndex: number
  drag: (ev: React.DragEvent<HTMLElement>) => void
}) {
  const { table } = useContext<TableContextType<T>>(TableContext)

  const headerArray = table.getHeaderGroups()?.[groupHeaderIndex].headers
  const header = headerArray[index]

  const { meta } = header.column.columnDef
  const shouldDraggable = header.column.columnDef.meta?.shouldDraggable !== false
  const shouldSortable = header.column.columnDef.meta?.sortable !== false
  const shouldResizable = header?.column?.columnDef?.meta?.resizable !== false
  const isSorted = Boolean(header.column.getIsSorted())
  const isAsc = header.column.getIsSorted() === 'asc'

  let timer: NodeJS.Timeout
  return (
    <th
      id={header?.column?.id} // this id is used to identify the column where it should be drag
      title={
        shouldDraggable
          ? 'Drag to reorder columns\nClick to sort\nDouble click to resize'
          : undefined
      }
      style={
        !shouldResizable
          ? {}
          : {
              width: header.getSize(),
              maxWidth: 50,
            }
      }
      colSpan={header.colSpan}
      className={clsx(
        {
          'pl-5': index === 0,
          'pr-2': index === headerArray.length - 1,
        },
        'bg-c-light-blue-2 ',
        'draggable relative pr-5 h-full  cursor-pointer', // draggable class is used to select the div is sortable or not
        meta?.headerClassName,
        meta?.fixedColumn && 'sticky left-0 l:relative bg-c-white ',
      )}
    >
      <div
        onDoubleClick={() => {
          // Clear the timer set in the click event handler
          // This prevents the sorting handler from being called if the user double-clicks
          clearTimeout(timer)
          // Reset the size of the column
          // This is typically used to reset the width of the column to its default value
          header.column.resetSize()
        }}
        onClick={
          // Check if the column can be sorted
          header.column.getCanSort()
            ? (e) => {
                // If this is the first click of a double click
                if (e.detail === 1) {
                  // Set a timer to call the sorting handler after 200ms
                  // This delay allows us to distinguish between single and double clicks
                  // If the user double-clicks, the timer will be cleared in the double click event handler before the sorting handler is called
                  timer = setTimeout(() => {
                    header.column.getToggleSortingHandler?.()?.(e)
                  }, 200)
                }
              }
            : undefined // If the column cannot be sorted, don't set a click event handler
        }
        tabIndex={0}
        role='button'
        onKeyDown={() => {}}
        id={`draggable-${header.column.id}`} // this is is used for grab the column from the dom element where the swap logic can implemented
        draggable={shouldDraggable} // this identify the column is draggable or not
        onDragStart={drag}
        className={clsx({
          'group/item flex flex-row items-center h-12  w-full justify-between ':
            !meta?.headerClassName,
          [meta?.headerClassName as string]: meta?.headerClassName,
        })}
      >
        {!header.isPlaceholder && (
          <div className={clsx('drop-shadow  hover:cursor-pointer mr-3 ')}>
            <Show>
              <Show.When isTrue={isSorted}>
                <div className='bg-c-dark-blue-1 h-[20px] w-[20px]  flex justify-center items-center rounded-full'>
                  <DropDownIcon
                    height={16}
                    width={16}
                    className={clsx(
                      {
                        'transform rotate-180': isAsc,
                      },

                      'transition-all duration-150 ease-in stroke-c-white fill-transparent',
                    )}
                  />
                </div>
              </Show.When>
              <Show.Otherwise>
                <Show>
                  <Show.When isTrue={shouldSortable}>
                    <div className=' flex flex-col h-[20px] w-[20px] pb-2  bg-c-white group-hover/item:bg-c-blue  justify-center items-center rounded-full'>
                      <DropDownIcon
                        height={16}
                        width={16}
                        className='rotate-180 translate-y-1/2 group-hover/item:stroke-white stroke-c-light-gray-1 fill-transparent'
                      />
                      <DropDownIcon
                        height={16}
                        width={16}
                        className='group-hover/item:stroke-white stroke-c-light-gray-1 fill-transparent'
                      />
                    </div>
                  </Show.When>
                </Show>
              </Show.Otherwise>
            </Show>
          </div>
        )}
        {!header.isPlaceholder && (
          <div className={clsx('w-full text-left text-3xs font-semibold leading-4 text-ellipsis')}>
            {flexRender(header.column.columnDef.header, header.getContext())}
          </div>
        )}
        <Show>
          <Show.When isTrue={shouldDraggable}>
            <DragIcon
              className={clsx('invisible group-hover/item:visible fill-c-blue ml-2')}
              height={16}
              width={16}
            />
          </Show.When>
        </Show>
      </div>
      <Show>
        <Show.When isTrue={shouldResizable}>
          <button
            {...{
              onDoubleClick: () => header.column.resetSize(),
              onMouseDown: header.getResizeHandler(),
              onTouchStart: header.getResizeHandler(),
              className: clsx(
                'absolute top-1/2 -translate-y-1/2  right-0 h-full  resizer w-[20px]',
                'flex bg-c-light-blue-2 items-center justify-center ',
              ),
            }}
            type='button'
            title='Resize Column'
          >
            <div className='w-[2px] flex h-8  bg-c-white rounded-full ' />
          </button>
        </Show.When>
      </Show>
    </th>
  )
}
