// Importing necessary libraries and components
import Tooltip from 'components/atom/Tooltip'
import { iDevice } from 'data/DeviceListHook'
import React, { memo, useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import { IFilterValues } from 'forms/FormModelInterface'
import { useSearchParams } from 'react-router-dom'
import { CalenderChildrenType, CalenderDateType } from 'components/modules/Calender/CalenderType'
import Calender from 'components/modules/Calender/Calender'
import { Transition } from '@headlessui/react'
import { DateRangeSelectorTypes } from 'utils/CommonEnums'
import { returnStaticDate } from 'components/modules/Calender/CalenderUtils'
import CalibrationsCalenderHeader from './CalibrationCalenderHeader'
import { CalibrationPageColumnIds } from '../../CalibrationsPageUtils'

/**
 * Type definition for the props of the CalibrationCalenderWidget component.
 *
 * @typedef {Object} CalibrationCalenderWidgetProps
 *
 * @property {iDevice[]} data - An array of devices. Each device is an object that adheres to the iDevice interface.
 *
 * @property {(filter: IFilterValues[]) => void} [onFilter] - Optional. A function that is called when the filter values change.
 * It receives an array of filter values (objects that adhere to the IFilterValues interface) as its argument.
 *
 * @property {() => void} [onReset] - Optional. A function that is called when the filter values need to be reset. It does not receive any arguments.
 */
type CalibrationCalenderWidgetProps = {
  data: iDevice[]
  onFilter?: (filter: IFilterValues[]) => void
  onReset?: () => void
}

// Defining a memoized component to display data
const DataDisplayWidget = memo(({ event, day }: CalenderChildrenType) => {
  // Converting the date to a format that can be compared with the current date
  const date: string[] = day.date.split('-').reverse()
  const isOverDue =
    new Date(Number(date?.[0]), Number(date[1]) - 1, Number(date[2])).getTime() <=
    new Date().getTime()

  // State for controlling the visibility of the tooltip
  const [show, setShow] = useState(false)
  let timer: NodeJS.Timeout

  // Setting a timer to show the tooltip after a delay
  useEffect(() => {
    timer = setTimeout(() => {
      setShow(true)
    }, 100)

    // Clearing the timer when the component unmounts
    return () => timer && clearTimeout(timer)
  }, [])

  // If there is an event, render the tooltip
  if (event) {
    return (
      // Transition component for animating the tooltip
      <Transition
        show={show}
        enter='transition-all scale-0 duration-150 delay-50 ease-in'
        enterFrom='opacity-0 scale-0'
        enterTo='opacity-100 scale-100'
        leave='transition-opacity duration-150 delay-50 ease-out'
        leaveFrom='opacity-100 scale-100'
        leaveTo='opacity-0 scale-0'
        className='absolute top-1/2 -translate-y-1/2 right-2 z-10'
      >
        <Tooltip
          tooltipText={
            <div className='z-50 w-full'>
              {isOverDue ? (
                <div>{`${event.length}`.padStart(2, '0')} devices is overdue</div>
              ) : (
                <div className='text-4xs font-medium'>
                  {`${event.length}`.padStart(2, '0')} devices will overdue on{' '}
                  <span className='font-bold'>{day.date}</span>
                </div>
              )}
            </div>
          }
          toolTipClass='tooltip text-4xs w-max px-2 m-auto -top-7 left-1/2 -translate-x-1/2  text-center '
        >
          <div
            className={clsx(
              {
                'bg-c-blue': !isOverDue,
                'bg-c-red-1': isOverDue,
              },
              ' text-white text-4xs  h-5 w-5 text-center py-[5px] rounded-full drop-shadow',
            )}
          >
            {event.length > 99 ? `99+` : `${event.length}`.padStart(2, '0')}
          </div>
        </Tooltip>
      </Transition>
    )
  }
  return null
})

// Setting the display name for the DataDisplayWidget component
DataDisplayWidget.displayName = 'DataDisplayWidget'

// Defining the CalibrationCalenderWidget component
export default function CalibrationCalenderWidget({
  data,
  onFilter,
  onReset,
}: CalibrationCalenderWidgetProps) {
  // State for storing the selected date
  const [selectedDate, setSelectedDate] = useState(new Date())

  // Ref for storing the clearFilter function
  const ref = useRef<{
    clearFilter: () => void
  }>(null)

  // Getting the search parameters from the URL
  const [searchParams] = useSearchParams()

  // Creating an events object from the data
  const events = data.reduce((acc: { [key: string]: iDevice[] }, curr: iDevice) => {
    // Check if the current device has a calibration due date
    if (curr.calibrationDueDate) {
      // If it does, add it to the accumulator object
      // The key is the date string and the value is an array of devices with that due date

      const date = new Date(curr.calibrationDueDate.split('T')?.[0])
      const key = returnStaticDate(date)
      return {
        ...acc,
        [key]: [
          // If there are already devices with this due date, include them in the array
          ...(acc[key] ?? []),
          // Add the current device to the array
          curr,
        ],
      }
    }
    // If the current device does not have a calibration due date, return the accumulator as is
    return acc
  }, {})

  // If the search parameters change and there are no parameters, clear the filter
  useEffect(() => {
    if (searchParams.toString().length === 0) {
      ref.current?.clearFilter?.()
    }
  }, [searchParams])

  // Function for filtering the calendar
  const onFilterCalender = (selectedDateArray: CalenderDateType[]) => {
    const filterValues: IFilterValues[] = [
      {
        columnId: CalibrationPageColumnIds.calDue,
        value:
          data.length > 0
            ? {
                label: DateRangeSelectorTypes.Custom,
                value:
                  data.length > 0
                    ? {
                        startDate: returnStaticDate(selectedDateArray[0] as Date, true),
                        endDate: returnStaticDate(
                          selectedDateArray[selectedDateArray.length - 1] as Date,
                          true,
                        ),
                      }
                    : '',
              }
            : '',
      },
    ]
    onFilter?.(filterValues)
  }

  // Rendering the Calender component
  return (
    <Calender
      ref={ref}
      shouldShowFilter
      onFilter={onFilterCalender}
      onReset={onReset}
      onChange={setSelectedDate}
      title={<CalibrationsCalenderHeader data={data} date={selectedDate} />}
      events={events}
      render={(props) => <DataDisplayWidget {...props} />}
    />
  )
}

// Setting default props for the CalibrationCalenderWidget component
CalibrationCalenderWidget.defaultProps = {
  onFilter: undefined,
  onReset: undefined,
}
