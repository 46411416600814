import { QueryClient, useQuery } from '@tanstack/react-query'
import { useMsal } from '@azure/msal-react'

import ConnectAPIClient from 'services/ConnectAPIClient'
import { DeviceCalDueTypes, PortableTypes } from 'utils/CommonEnums'
import { selectAllOption } from 'forms/FormUtils'
import { GetProductNameText } from 'utils/CommonUtils'
import { iBusinessUnitDetails } from 'components/commonComponents/BusinessUnitDataUtils'
import { getAccessToken, getAccessTokenProps } from 'services/MSALService'
import { iAPIBusinessUnitParams } from 'pages/devices-2/data/DevicePageData'
import { AxiosError } from 'axios'
import { allBusinessUnitOption } from './BusinessUnitsListHookWrapper'
import { allCustomerOption } from './CustomerListHook'
import { getDeviceCalDueStatus } from './GenericDataUtils'

function getTopLevelBusinessUnitId(businessUnitsList: iBusinessUnitDetails[]) {
  const topLevelBusinessUnit = businessUnitsList.find(
    (businessUnit) => businessUnit.parentId === null,
  )
  if (!topLevelBusinessUnit) return ''
  return topLevelBusinessUnit?.id
}

export const deviceListDataQueryKey = (customerId?: string, businessUnitId?: string) => [
  'devices',
  'list',
  'businessUnit',
  customerId,
  businessUnitId,
]

export const deviceListDataURL = (businessUnitId?: string, customerId?: string) => {
  if (
    businessUnitId &&
    businessUnitId.toLowerCase() !== selectAllOption.value?.toString().toLowerCase()
    // || (customerId === '' || customerId === allCustomerOption.id)
  ) {
    return `portables/api/device?ExpandBusinessUnit=true&BusinessUnitId=${businessUnitId}`
  }
  return `portables/api/device`
}

export interface iBusinessUnit {
  id: string
  name: string
}

export interface iDeviceUser {
  id: string
  deviceUserId: string
  fullName: string
  deviceUserName: string
  uniqueId: string
  assignedOn: string
}

export interface DeviceChannelInfoDto {
  channelNumber: number
  channelName: string
}

export interface iDevice {
  id: string
  serialNumber: string
  deviceType: PortableTypes | string // PortableTypes,
  customerId: string
  customerAssetNumber: string
  lastCalibratedDate?: string
  calibrationDueDate?: string
  businessUnit: iBusinessUnit
  deviceUser: iDeviceUser
  channels: Array<DeviceChannelInfoDto>
  status: string
  Note: string

  calDueStatus: DeviceCalDueTypes // Not populated by API
}

export interface iDeviceList {
  data: iDevice[]
  pageNumber?: number
  pageSize?: number
  nextPage?: number
  previousPage?: number
  totalRecords?: number
}

interface iFetchDeviceListParams {
  token: string
  businessUnitId?: string
  customerId?: string
}

interface iGetOrFetchDeviceListProps {
  queryClient: QueryClient
  token: string
  businessUnitId?: string
  customerId?: string
}

interface iDeviceListAPIParams extends iAPIBusinessUnitParams, getAccessTokenProps {}

export async function FetchDevicesList(params: iFetchDeviceListParams): Promise<iDeviceList> {
  try {
    const { token, businessUnitId, customerId } = params
    const url = deviceListDataURL(businessUnitId, customerId)
    const resp = await ConnectAPIClient.get<iDeviceList>(url, token)
    const response = resp.data

    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function getDeviceListData(deviceListAPIParams: iDeviceListAPIParams) {
  const { msalContext, redirectPageURL, businessUnitId, customerId } = deviceListAPIParams
  const token = await getAccessToken({ msalContext, redirectPageURL })
  const deviceListData = await FetchDevicesList({ token, businessUnitId })
  return deviceListData
}

export function getBUId(
  businessUnitId: string,
  customerId: string,
  businessUnitsList?: iBusinessUnitDetails[],
) {
  let buId = ''
  if (
    businessUnitId === allBusinessUnitOption.id &&
    customerId !== allCustomerOption.id &&
    businessUnitsList
  ) {
    buId = getTopLevelBusinessUnitId(businessUnitsList)
  } else {
    buId = businessUnitId
  }
  return buId
}

export function useDeviceList(
  redirectPageURL: string,
  businessUnitId: string,
  customerId?: string,
  businessUnitsList?: iBusinessUnitDetails[] | undefined,
  enabled?: boolean,
) {
  const msalContext = useMsal()

  // let buId = ''
  // if (
  //   businessUnitId === allBusinessUnitOption.id &&
  //   customerId !== allCustomerOption.id &&
  //   businessUnitsList
  // ) {
  //   buId = getTopLevelBusinessUnitId(businessUnitsList)
  // } else {
  //   buId = businessUnitId
  // }

  return useQuery(
    deviceListDataQueryKey(
      customerId,
      getBUId(businessUnitId, customerId || '', businessUnitsList),
    ),
    () =>
      getDeviceListData({
        msalContext,
        redirectPageURL,
        businessUnitId: getBUId(businessUnitId, customerId || '', businessUnitsList),
      }),
    {
      enabled,
      select: (resultData) => {
        // resultData.data.map((device) => {
        //   const modifiedDevice = device
        //   modifiedDevice.serialNumber = device.serialNumber.trim().replace(/\s/g, '');
        //   return modifiedDevice;
        // });
        if (resultData?.data?.length > 0) {
          resultData.data.sort(
            (a, b) =>
              (a?.serialNumber &&
                b?.serialNumber &&
                a?.serialNumber?.localeCompare(b?.serialNumber)) ||
              0,
          )
          const resultDataWithCalDueStatus = resultData.data.map((device) => {
            const calDueStatus = getDeviceCalDueStatus(device)
            return { ...device, calDueStatus }
          })

          const resultDataWithDeviceTypeTextModified = resultDataWithCalDueStatus.map((device) => {
            const deviceTypeText = GetProductNameText(device.deviceType as PortableTypes)
            if (deviceTypeText === '') {
              return device
            }
            return { ...device, deviceType: deviceTypeText }
          })

          return resultDataWithDeviceTypeTextModified
        }
        return null
      },
      onError: (error: AxiosError) => {
        console.log(error)
      },
    },
  )
}

export async function getOrFetchDevicesList(props: iGetOrFetchDeviceListProps) {
  const { queryClient, token, businessUnitId, customerId } = props

  const devicesListCached = queryClient.getQueryData<iDeviceList>(
    deviceListDataQueryKey(businessUnitId, customerId),
  )

  if (devicesListCached) {
    return devicesListCached.data
  }

  const devicesListFetched = await queryClient.fetchQuery(
    deviceListDataQueryKey(businessUnitId, customerId),
    async () =>
      FetchDevicesList({
        token,
        businessUnitId,
        customerId,
      }),
  )

  return devicesListFetched.data
}
