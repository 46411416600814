import ArrowLeft from 'assets/icons/ArrowLeft'
import ArrowRight from 'assets/icons/ArrowRight'
import CalenderIcon from 'assets/icons/CalenderIcon'
import clsx from 'clsx'
import React from 'react'
import { CloseIcon } from 'assets/icons'
import { CalenderHeaderProps } from './CalenderType'

export default function CalenderHeader({
  isCurrentMonth,
  goToCurrentMonth,
  goToPreviousMonth,
  goToNextMonth,
  selectedDate,
  selectedDay,
  dateFilter,
  shouldShowFilter,
  hasFilter,
  applyFilter,
  clearFilter,
  title,
}: CalenderHeaderProps) {
  return (
    <header className=' flex flex-row items-center justify-between bg-c-white   rounded-t-2xl  px-6 py-4 flex-none relative group/header'>
      <div className=''>
        <div className='flex gap-2 items-center'>
          <CalenderIcon className='w-4 h-4 fill-c-blue' />
          <button
            type='button'
            // Set the title based on whether it's the current month
            title={isCurrentMonth ? 'Current month' : 'Go to Current month'}
            // Set the onClick handler to go to the current month
            onClick={goToCurrentMonth}
            className='text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:relative md:block'
          >
            {/* Display the selected month */}
            {new Date(selectedDate).toLocaleDateString('en-US', {
              month: 'long',
            })}
            <time dateTime={selectedDay?.date} className='ml-2'>
              {/* Display the selected year */}
              {selectedDate.getFullYear()}
            </time>
          </button>
        </div>
        {/*  Display the title */}
        {title}
      </div>

      <div className='flex flex-col items-center'>
        <div className=' relative flex justify-between items-center   md:items-stretch'>
          {/* Button to navigate to the previous month */}
          <button
            onClick={goToPreviousMonth} // onClick handler to go to the previous month
            title='Previous Month' // Tooltip text
            type='button' // Button type
            className='flex h-9 w-12 items-center justify-center rounded-full  pr-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pr-0 md:hover:bg-gray-50' // Styling classes
          >
            <span className='sr-only'>Previous month</span>
            <ArrowLeft className='h-5 w-5' />
          </button>

          {/* Button to navigate to the next month */}
          <button
            type='button' // Button type
            title='Next Month' // Tooltip text
            className='flex h-9 w-12 items-center justify-center rounded-full pl-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pl-0 md:hover:bg-gray-50' // Styling classes
            onClick={goToNextMonth} // onClick handler to go to the next month
          >
            <span className='sr-only'>Next month</span>
            <ArrowRight className='h-5 w-5' />
          </button>
        </div>
        {/* // Check if the filter functionality should be shown */}
        {shouldShowFilter && (
          <div className='w-full flex flex-row gap-2'>
            <button
              // If a filter is applied, clicking the button clears the filter. Otherwise, it applies the filter.
              onClick={hasFilter ? clearFilter : applyFilter}
              // The title of the button changes based on whether a filter is applied
              title={hasFilter ? 'Reset Filter' : 'Apply filter'}
              // The button is disabled if no dates are selected for filtering
              disabled={dateFilter.length === 0}
              type='button'
              className={clsx(
                {
                  // The button's background color changes based on whether dates are selected for filtering
                  'bg-c-light-blue-4': dateFilter.length === 0,
                  'bg-c-dark-blue-1': dateFilter.length > 0,
                },
                'transition-all duration-200 delay-50 ease-in-out  rounded-xl text-white text-xs py-1 px-3  w-full ',
              )}
            >
              {/* The button's text changes based on whether a filter is applied */}
              {hasFilter ? 'Reset' : 'Apply'}
            </button>
            <button
              // Clicking this button clears the filter
              onClick={clearFilter}
              type='button'
              title='Clear Selection'
              className={clsx(
                {
                  // The button's position and scale change based on whether dates are selected for filtering and whether a filter is applied
                  'translate-x-0 scale-100 relative': dateFilter.length > 0 && !hasFilter,
                  '-translate-x-1/2 scale-0 absolute -z-50': dateFilter.length === 0 || hasFilter,
                },
                'bg-c-dark-blue-1 p-1 rounded-full transition-all duration-200 delay-100 transform ease-in-out ',
              )}
            >
              {/* The button contains a close icon */}
              <CloseIcon height={16} width={16} className='w-4 h-4 fill-c-dark-blue-1' />
            </button>
          </div>
        )}
      </div>
    </header>
  )
}
