import React, { memo, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import Calender from 'components/modules/Calender/Calender'
import { iDevice } from 'data/DeviceListHook'
import clsx from 'clsx'
import { CalenderChildrenType } from 'components/modules/Calender/CalenderType'
import Tooltip from 'components/atom/Tooltip'

/**
 * Type definition for the props of the ZoomedCalibrationCalendarWidget component.
 *
 * @typedef {Object} ZoomedCalibrationCalendarWidgetProps
 *
 * @property {boolean} open - A boolean indicating whether the ZoomedCalibrationCalendarWidget is open or not.
 *
 * @property {() => void} onClose - A function that is called when the ZoomedCalibrationCalendarWidget needs to be closed. It does not receive any arguments.
 *
 * @property {Date} [selectedDate] - Optional. A Date object representing the currently selected date.
 *
 * @property {iDevice[]} data - An array of devices. Each device is an object that adheres to the iDevice interface.
 */
type ZoomedCalibrationCalendarWidgetProps = {
  open: boolean
  onClose: () => void
  selectedDate?: Date
  data: iDevice[]
}

const device: {
  [key: string]: string
} = {
  Gasman: 'bg-c-green',
  T4: 'bg-c-blue',
  T3: 'bg-c-red-1',
  'Gas-Pro': 'bg-c-orange',
}

const ZoomedCalibrationDataWidget = memo(({ event }: CalenderChildrenType) => {
  // The 'devices' constant is an object that groups devices by their type.
  // It is created by reducing the 'event' array (which is cast to an array of iDevices).
  const devices = (event as iDevice[])?.reduce?.(
    // The reduce function takes an accumulator object and the current device as arguments.
    (acc: { [key: string]: iDevice[] }, curr: iDevice) => {
      // If the current device has a type and a calibration due date,
      if (curr.deviceType && curr.calibrationDueDate) {
        // return a new object that spreads the accumulator object and adds a new key-value pair.
        // The key is the device type and the value is an array of devices of that type.
        // If there are already devices of this type, they are included in the array.
        // The current device is also added to the array.
        // The array is then filtered to remove any null values.
        return {
          ...acc,
          [`${curr.deviceType}`]: [...(acc[`${curr.deviceType}`] ?? []), curr].filter((d) => d),
        }
      }
      // If the current device does not have a type or a calibration due date, return the accumulator as is.
      return acc
    },
    // The initial value of the accumulator is an empty object.
    {},
  )
  // Initialize the 'show' state variable to false
  const [show, setShow] = useState(false)

  // Declare a variable to hold a NodeJS.Timeout object
  let timer: NodeJS.Timeout

  // Use the useEffect hook to set a timeout that changes the 'show' state variable after 100 milliseconds
  // The timeout is cleared when the component unmounts
  useEffect(() => {
    timer = setTimeout(() => {
      setShow(true)
    }, 100)

    return () => timer && clearTimeout(timer)
  }, [])

  // If there is an event, render the component
  if (event) {
    return (
      <div className='min-h-[50px] h-full min-w-[100px] w-full'>
        <Transition
          show={show}
          enter='transition-opacity ease-in duration-150 delay-50'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='transition-opacity ease-in duration-200 delay-50'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
          className={clsx(
            {
              'grid-cols-2 grid-rows-2': Object.values(devices).length > 2,
              'grid-cols-1 grid-rows-2': Object.values(devices).length > 2,
            },
            'gap-2 absolute top-1/2 -translate-y-1/2 right-2 z-10 grid  ',
          )}
        >
          {Object.entries(devices).map(([key, value]) => (
            <Tooltip
              tooltipText={key}
              toolTipClass='tooltip text-4xs w-max px-2 m-auto -top-7 left-1/2 -translate-x-1/2  text-center'
              className={clsx(
                device[key] as string,
                ' text-white  rounded-full  p-1 select-none relative',
              )}
              key={key}
            >
              <div className='text-2xs font-bold leading-3  text-ellipsis self-center'>
                {value.length > 99 ? `99+` : `${value.length}`.padStart(2, '0')}
              </div>
            </Tooltip>
          ))}
        </Transition>
      </div>
    )
  }
  return <div className='min-h-[50px] h-full min-w-[100px] w-full ' />
})

ZoomedCalibrationDataWidget.displayName = 'ZoomedCalibrationDataWidget'

export default function ZoomedCalibrationCalendarWidget({
  data,
  open,
  onClose,
  selectedDate,
}: ZoomedCalibrationCalendarWidgetProps) {
  // The 'zoomCalDueDates' constant is an object that groups devices by their calibration due date.
  // It is created by reducing the 'data' array.
  const zoomCalDueDates = data.reduce(
    // The reduce function takes an accumulator object and the current device as arguments.
    (acc: { [key: string]: iDevice[] }, curr: iDevice) => {
      // If the current device has a calibration due date,
      if (curr.calibrationDueDate) {
        // return a new object that spreads the accumulator object and adds a new key-value pair.
        // The key is the calibration due date (converted to a locale date string) and the value is an array of devices with that calibration due date.
        // If there are already devices with this calibration due date, they are included in the array.
        // The current device is also added to the array.
        // The array is then filtered to remove any null values.
        return {
          ...acc,
          [`${new Date(curr.calibrationDueDate).toLocaleDateString()}`]: [
            ...(acc[`${new Date(curr.calibrationDueDate).toLocaleDateString()}`] ?? []),
            curr,
          ].filter((d) => d),
        }
      }
      // If the current device does not have a calibration due date, return the accumulator as is.
      return acc
    },
    // The initial value of the accumulator is an empty object.
    {},
  )

  return (
    <Transition appear show={open}>
      <Dialog open={open} as='div' className='relative z-50 rounded-2xl' onClose={onClose}>
        <div className='fixed inset-0 bg-black/30' aria-hidden='true' />
        <div className='fixed inset-0 w-screen overflow-y-auto'>
          <div className='flex min-h-full items-center justify-center '>
            <Transition.Child
              enter='transition-all ease-in duration-150 delay-50'
              enterFrom='opacity-0 transform-[scale(0%)]'
              enterTo='opacity-100 transform-[scale(100%)]'
              leave='transition-all ease-in duration-200 delay-50'
              leaveFrom='opacity-100 transform-[scale(100%)]'
              leaveTo='opacity-0 transform-[scale(0%)]'
            >
              <Dialog.Panel className=' rounded-2xl bg-white '>
                <Calender
                  title={
                    <div className='flex flex-row gap-2 mt-3'>
                      {['Gasman', 'T4', 'T3', 'Gas-Pro'].map((key) => (
                        <p
                          key={key}
                          className={clsx(
                            'text-2xs font-bold leading-3  text-ellipsis self-center text-c-white px-3 py-1 select-none rounded-full',
                            device[key] as string,
                          )}
                        >
                          {key}
                        </p>
                      ))}
                    </div>
                  }
                  date={selectedDate}
                  events={zoomCalDueDates}
                  render={(props) => <ZoomedCalibrationDataWidget {...props} />}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
ZoomedCalibrationCalendarWidget.defaultProps = {
  selectedDate: new Date(),
}
