import React, { useEffect } from 'react'
import { useForm, SubmitHandler, Controller, FieldErrors } from 'react-hook-form'
import { sub } from 'date-fns'
import { DateRange, IFilterValues, IselectOptionProps } from 'forms/FormModelInterface'
// eslint-disable-next-line import/no-extraneous-dependencies
import { DevTool } from '@hookform/devtools'

import { DateRangeSelectorTypes, SelectOptionTypes } from 'utils/CommonEnums'
import { ChevronRightIcon, CloseIcon } from 'assets/icons'
import { DatePickerControl, SelectControl, CreatableSelectControl } from 'components/formComponents'
import FilterFormTitle from 'components/formComponents/FilterFormTitle'

import { dateRangeOptions, selectAllOption } from 'forms/FormUtils'
import { withErrorBoundary } from 'react-error-boundary'
import ErrorPage from 'pages/common/ErrorPage'
import { DeviceBumpHistoryFilterFormType, columnIDs } from './BumpHistory'

interface BumpHistoryFilterFormProps {
  // Define any props that this component will receive
  selectedValues?: DeviceBumpHistoryFilterFormType
  bumpTestResults?: readonly IselectOptionProps[]
  deviceUserNames?: readonly IselectOptionProps[]
  eventDescriptions?: readonly IselectOptionProps[]

  // Define any functions that this component will call
  onFilterSubmit: (values: IFilterValues[]) => void
  onFilterReset: () => void
  onFilterFormClose: () => void
}

const defaultValues = (): DeviceBumpHistoryFilterFormType => {
  const dtTo = new Date()
  const dtFrom = sub(new Date(), { months: 6 })
  const defValues: DeviceBumpHistoryFilterFormType = {
    eventDateSelector: dateRangeOptions.find(
      (option) => option.value === DateRangeSelectorTypes.Custom,
    ) ?? {
      label: '',
      value: '',
    },
    eventDate: { startDate: dtFrom, endDate: dtTo },
    bumpTestResult: selectAllOption,
    eventDescription: {
      label: '',
      value: '',
    },
    deviceUserName: {
      label: '',
      value: '',
    },
    deviceUserUniqueID: {
      label: '',
      value: '',
    },
  }
  return defValues
}

function BumpHistoryFilterForm(props: BumpHistoryFilterFormProps): JSX.Element {
  const {
    selectedValues,
    bumpTestResults,
    deviceUserNames,
    eventDescriptions: eventDescriptionsData,
    onFilterSubmit,
    onFilterReset,
    onFilterFormClose,
  } = props

  const defaultFormValues = defaultValues()
  const { register, control, handleSubmit, setValue, reset, formState, watch } =
    useForm<DeviceBumpHistoryFilterFormType>({
      defaultValues: defaultFormValues,
      values: selectedValues,
    })

  const watchEventDateSelector = watch('eventDateSelector')

  const [showCustomEventDate, setShowCustomEventDate] = React.useState<boolean>(false)

  useEffect(() => {
    if (watchEventDateSelector?.value === DateRangeSelectorTypes.Custom) {
      setShowCustomEventDate(true)
    } else {
      setShowCustomEventDate(false)
    }
  }, [watchEventDateSelector])

  const { errors, isDirty, isValid, isSubmitting } = formState

  const onSubmit: SubmitHandler<DeviceBumpHistoryFilterFormType> = (data) => {
    const filterValues: IFilterValues[] = []

    filterValues.push({
      columnId: columnIDs.bumpTestDateTime,
      value: data.eventDate,
    })

    filterValues.push({
      columnId: columnIDs.assignedTo,
      value: data.deviceUserName?.value?.toString().trim() ?? '',
    })

    filterValues.push({
      columnId: columnIDs.bumpTestResult,
      value: data.bumpTestResult?.value?.toString().trim()
        ? data.bumpTestResult?.value?.toString().trim() === SelectOptionTypes.All
          ? ''
          : data.bumpTestResult?.value?.toString().trim()
        : '',
    })

    filterValues.push({
      columnId: columnIDs.eventDescription,
      value: data.eventDescription?.value?.toString().trim() ?? '',
    })

    onFilterSubmit(filterValues)
  }

  const onError = (formErrors: FieldErrors<DeviceBumpHistoryFilterFormType>) => {
    console.log(formErrors)
  }

  const onReset = () => {
    reset(defaultFormValues)
    onFilterReset()
  }

  return (
    <div className='filter-form-container'>
      <div className='filter-form-header'>
        <FilterFormTitle />

        <div
          title='Close Filter Form'
          role='button'
          tabIndex={0}
          onClick={() => onFilterFormClose()}
          onKeyDown={() => onFilterFormClose()}
          className='filter-form-close-button'
        >
          <CloseIcon toggleClick={() => onFilterFormClose()} className='w-5 h-5 ' />
        </div>
      </div>
      <form
        onSubmit={handleSubmit(onSubmit, onError)}
        onReset={onFilterReset}
        noValidate
        className=''
      >
        <div className='filter-form-fields-container'>
          {/* // Assigned Date */}
          <div className='flex flex-col gap-0.5 items-start self-stretch'>
            <label
              htmlFor='bumpEventDateSelector'
              className='pl-4 pr-3 text-xs font-bold leading-5 text-c-dark-blue-1 '
            >
              Date
            </label>

            <Controller
              name='eventDateSelector'
              control={control}
              render={({ field }) => (
                <SelectControl
                  className='w-full h-[42px]'
                  selectControlProps={{
                    options: dateRangeOptions,
                    defaultValue: field.value,
                    isMulti: false,

                    onChange: (selectedOption: IselectOptionProps) => {
                      const selectedValue = selectedOption?.value
                      field.onChange(selectedOption)
                      if (selectedValue !== DateRangeSelectorTypes.Custom) {
                        setValue('eventDate', selectedValue as DateRange, {
                          shouldValidate: true,
                        })
                      } else {
                        setValue('eventDate', {
                          startDate: selectedValues?.eventDate.startDate || new Date(),
                          endDate: selectedValues?.eventDate.endDate || new Date(),
                        })
                      }
                    },
                  }}
                />
              )}
            />
          </div>

          {showCustomEventDate && (
            <>
              <div className='flex flex-row self-stretch justify-between w-full items-end'>
                <div className='w-[110px]'>
                  <div className='flex flex-col gap-0.5 items-start '>
                    <label
                      htmlFor='assignedDateFrom'
                      className='pl-4  text-xs font-bold leading-5 text-c-dark-blue-1 '
                    >
                      From
                    </label>
                    <div className='self-stretch'>
                      <Controller
                        name='eventDate.startDate'
                        control={control}
                        render={({ field }) => (
                          <DatePickerControl
                            className=''
                            datePickerProps={{
                              selected: field.value,
                              onChange: (date: Date | null) => field.onChange(date as Date),
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>

                <div className='mx-0.5 w-4 h-4 my-3'>
                  <ChevronRightIcon className='self-center' />
                </div>

                <div className='w-[110px]'>
                  <div className='flex flex-col gap-0.5 items-start'>
                    <label
                      htmlFor='assignedDateFilterTo'
                      className='pl-4 text-xs font-bold leading-5 text-c-dark-blue-1 '
                    >
                      To
                    </label>
                    <div className='self-stretch'>
                      <Controller
                        name='eventDate.endDate'
                        control={control}
                        render={({ field }) => (
                          <DatePickerControl
                            className=''
                            datePickerProps={{
                              selected: field.value,
                              maxDate: new Date(),
                              onChange: (date: Date | null) => field.onChange(date as Date),
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {errors.eventDate && (
                <>
                  <p className='text-xs text-red-500'>{errors.eventDate?.startDate?.message}</p>
                  <p className='text-xs text-red-500'>{errors.eventDate?.endDate?.message}</p>
                </>
              )}
            </>
          )}

          <div className='flex flex-col gap-0.5 items-start self-stretch'>
            <label
              htmlFor='bumpTestResultFilter'
              className='pl-4 px-3 text-xs font-bold leading-5 text-c-dark-blue-1 '
            >
              Bump Test Result
            </label>
            <Controller
              name='bumpTestResult'
              control={control}
              render={({ field }) => (
                <SelectControl
                  className='w-full h-[42px]'
                  selectControlProps={{
                    options: bumpTestResults,
                    value: field.value,
                    defaultValue: field.value,
                    isMulti: false,
                    isSearchable: true,
                    openMenuOnClick: true,
                    placeholder: '',
                    isClearable: false,
                    isDropDownSelectable: true,
                    onChange: (selectedOption: IselectOptionProps) => {
                      field.onChange(selectedOption)
                    },
                  }}
                />
              )}
            />
            <p className='text-xs text-red-500'>{errors.deviceUserUniqueID?.message}</p>
          </div>

          <div className='flex flex-col gap-0.5 items-start self-stretch'>
            <label
              htmlFor='bumpEventLogDescFilter'
              className='pl-4 px-3 text-xs font-bold leading-5 text-c-dark-blue-1 '
            >
              Description
            </label>
            <Controller
              name='eventDescription'
              control={control}
              render={({ field }) => (
                <CreatableSelectControl
                  className='w-full h-[42px]'
                  selectControlProps={{
                    options: eventDescriptionsData,
                    value: field.value,
                    defaultValue: field.value,
                    isMulti: false,
                    isSearchable: true,
                    openMenuOnClick: true,
                    placeholder: '-',
                    isClearable: false,
                    onChange: (selectedOption: IselectOptionProps) => {
                      field.onChange(selectedOption)
                    },
                  }}
                />
              )}
            />
            <p className='text-xs text-red-500'>{errors.eventDescription?.message}</p>
          </div>

          <div className='flex flex-col gap-0.5 items-start self-stretch'>
            <label
              htmlFor='assignedToUserNamesFilter'
              className='pl-4 px-3 text-xs font-bold leading-5 text-c-dark-blue-1 '
            >
              Assigned To
            </label>
            <Controller
              name='deviceUserName'
              control={control}
              render={({ field }) => (
                <CreatableSelectControl
                  className='w-full h-[42px]'
                  selectControlProps={{
                    options: deviceUserNames,
                    value: field.value,
                    defaultValue: field.value,
                    isMulti: false,
                    isSearchable: true,
                    openMenuOnClick: true,
                    placeholder: '-',
                    isClearable: false,
                    onChange: (selectedOption: IselectOptionProps) => {
                      field.onChange(selectedOption)
                    },
                  }}
                />
              )}
            />
            <p className='text-xs text-red-500'>{errors.deviceUserName?.message}</p>
          </div>
        </div>
        {/* Buttons */}
        <div className='filter-form-footer'>
          <input
            className='filter-form-reset-button'
            type='reset'
            disabled={isSubmitting}
            onClick={() => {
              onReset()
            }}
          />
          <input
            className='filter-form-apply-button'
            type='submit'
            value='Apply'
            // disabled={!isDirty || !isValid || isSubmitting}
          />
        </div>
      </form>

      <DevTool control={control} />
    </div> // opening div
  )
}

export default withErrorBoundary(BumpHistoryFilterForm, {
  FallbackComponent: ErrorPage,
})
