/* eslint-disable import/no-duplicates */
import React from 'react'
import clsx from 'clsx'
import DatePicker from 'react-datepicker'
import CalenderIcon from 'assets/icons/CalenderIcon'

// TODO register locale does not work. Always shows enUS style format in the input.
// Needs more investiagion. For now we are using the dateFormat prop to set the format. (Matches figma)

// import enGB from 'date-fns/locale/en-GB'
// import es from 'date-fns/locale/es';
// import enUS from 'date-fns/locale/en-US';
// import enCA from 'date-fns/locale/en-CA';

// text-xs font-medium text-c-dark-blue-1  text-ellipsis border-[3px] h-[41px] border-c-light-blue-4 focus:border-c-blue hover:border-c-dark-blue-2 active:border-c-blue bg-c-white focus:bg-c-light-blue-1 hover:bg-c-white active:bg-c-blue
interface datePickerControlProps {
  className?: string
  datePickerProps?: any
}
//  w-full
function DatePickerControl(props: datePickerControlProps): JSX.Element {
  const { className, datePickerProps = {} } = props
  const { selected } = datePickerProps

  // registerLocale('enGB', enGB)
  // registerLocale('es', es)
  // registerLocale('enUS', enUS)
  // registerLocale('enCA', enCA)

  return (
    <DatePicker
      className=' h-[41px] w-full rounded-3xl text-xs font-bold select-none text-c-dark-blue-1  text-ellipsis border-[3px]  border-c-light-blue-4 placeholder-c-dark-blue-3 focus:border-c-blue hover:border-c-dark-blue-2 active:border-c-blue bg-c-white focus:bg-c-light-blue-1 hover:bg-c-white active:bg-c-blue'
      {...datePickerProps}
      showIcon
      // locale='enCA'
      dateFormat='dd MMM yy'
      // showYearDropdown
      // showMonthYearDropdown
      icon={<CalenderIcon className='fill-c-blue' width={18} height={17} />}
    />
  )
}

export default DatePickerControl

// customInput={
// <label className='relative h-11 w-full'>
//   <input
//     type='text'
//     className={clsx(
//       'rounded-3xl px-3 pl-7 outline-0 w-full border-4  border-c-light-blue-2 hover:border-c-dark-blue-1 focus:border-c-blue focus:bg-c-light-blue-2 active:border-c-blue active:bg-c-blue active:text-c-white',
//       className,
//     )}
//     value={selected ? selected?.toLocaleDateString() : 'Select'}
//   />
//   <span className='absolute inset-y-0 left-0 flex items-center pl-2'>
//     {/* <img className='w-4 h-4 mx-1 ' alt='' src={calender}/>  */}

//     <CalendarIcon />
//   </span>
// </label>
// }

// const CustomInput = forwardRef(({ value, onClick }, ref) => (

// <label className='relative h-11 w-full'>
//     <input
//       type='text'
//       className={clsx(
//         'rounded-3xl px-3 pl-7 outline-0 w-full border-4  border-c-light-blue-2 hover:border-c-dark-blue-1 focus:border-c-blue focus:bg-c-light-blue-2 active:border-c-blue active:bg-c-blue active:text-c-white',
//         className,
//       )}
//       value={selected ? selected?.toLocaleDateString() : 'Select'}
//     />
//     <span className='absolute inset-y-0 left-0 flex items-center pl-2'>
//       {/* <img className='w-4 h-4 mx-1 ' alt='' src={calender}/>  */}

//       <CalendarIcon />
//     </span>
//   </label>

//   // <button className="example-custom-input" onClick={onClick} ref={ref}>
//   //   {value}
//   // </button>
// ));

/*

 <svg xmlns='http://www.w3.org/2000/svg' width='2' height='2' viewBox='1 1 2 2'>
            <mask id='ipSApplication0'>
              <g fill='#0000FF' stroke='#FF0000' strokeLinejoin='round' strokeWidth='1'>
                <path strokeLinecap='round' d='M40.04 22v20h-32V22' />
                <path
                  fill='#00FF00'
                  d='M5.842 13.777C4.312 17.737 7.263 22 11.51 22c3.314 0 6.019-2.686 6.019-6a6 6 0 0 0 6 6h1.018a6 6 0 0 0 6-6c0 3.314 2.706 6 6.02 6c4.248 0 7.201-4.265 5.67-8.228L39.234 6H8.845l-3.003 7.777Z'
                />
              </g>
            </mask>
            <path fill='#FF0022' d='M0 0h48v48H0z' mask='url(#ipSApplication0)' />
          </svg> 
          */
