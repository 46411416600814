import React, { useContext, useEffect, useMemo, useState } from 'react'
import { UserAccountContext } from 'contexts/UserAccountContext'
import { IsUserGlobalScope } from 'utils/UserDataUtils'
import { useLocation, useSearchParams } from 'react-router-dom'
import { BusinessContext } from 'contexts/BusinessContext'
import ExportData from 'exportReports/ExportData'
import { useGenericEventHandler } from 'data/GenericEventHandler'
import { useCustomerMe } from 'data/CustomerListHook'
import withRoleChecking from 'hoc/withRoleChecking'
import { UserRoleTypes } from 'utils/CommonEnums'
import { useUsersList } from '../data/UsersListData'
import UsersPageTable from '../components/UsersPageTable'
import { User } from '../data/iUsersList'
import { UsersReportColumns } from '../export/UserReportFormat'
import { getFilteredUsers } from './UsersPageUtils'

function UsersListPage() {
  const userAccountInfo = useContext(UserAccountContext)
  const { state: userInfo } = userAccountInfo
  const isGlobalUser = IsUserGlobalScope(userInfo)
  const location = useLocation()
  const redirectPageURL = `${location.pathname}${location.search}`
  const [showFilterForm, setShowFilterForm] = useState(false)
  const [filteredUserList, setFilteredUserList] = useState<User[]>()
  const [showExportDialog, setShowExportDialog] = React.useState(false)
  const { genericEventHandler } = useGenericEventHandler()
  const { selectedCustomer, selectedBusinessUnit } = useContext(BusinessContext)
  const { data: myCustomerDetail } = useCustomerMe(
    location.pathname,
    isGlobalUser,
    Boolean(
      userInfo?.authority?.[0]?.businessUnitId &&
        !userInfo?.authority?.[0]?.businessUnitId?.startsWith?.('0000'),
    ),
  )

  useEffect(() => {
    document.title = 'Profile - Teams'
  }, [])

  useEffect(() => {
    function hideFilterFormHandler(e: KeyboardEvent) {
      if (e.key === 'Escape' && showFilterForm) {
        setShowFilterForm(false)
      }
    }
    window.addEventListener('keyup', hideFilterFormHandler)

    return () => {
      window.removeEventListener('keyup', hideFilterFormHandler)
    }
  }, [showFilterForm])

  const { data, isLoading, isError, error } = useUsersList(
    redirectPageURL,
    selectedBusinessUnit?.id === 'all' ? undefined : (selectedBusinessUnit?.id as string),
    true,
  )

  const userListData = useMemo(
    () =>
      data
        ? isGlobalUser
          ? getFilteredUsers(data, selectedCustomer?.id, selectedBusinessUnit?.id)
          : getFilteredUsers(data, myCustomerDetail?.id, selectedBusinessUnit?.id)
        : [],
    [data, isGlobalUser, myCustomerDetail?.id, selectedCustomer?.id, selectedBusinessUnit?.id],
  )

  useEffect(() => {
    if (isError) {
      genericEventHandler({
        onlyTrack: true,
        severity: 'error',
        error,
        message: error.message || 'Error in Safety List Data',
        extraData: {
          component: 'SafetyPage',
          action: 'useSafetyListData',
        },
      })
    }
  }, [error, isError])

  return (
    <div className='h-full pt-3'>
      <ExportData
        handleCancel={() => setShowExportDialog(false)}
        IsOpen={showExportDialog}
        data={filteredUserList || userListData}
        reportColumns={UsersReportColumns}
        widgetForReport={[]}
        reportTitle='Users'
        filterSummary=''
      />
      <div
        id='users-main'
        className='flex flex-col h-full w-full'
        aria-hidden='true'
        onClick={() => showFilterForm && setShowFilterForm(false)}
      >
        <UsersPageTable
          tableData={userListData}
          isGlobalUser={isGlobalUser}
          isLoading={isLoading}
          filterValues={[]}
          updateFilteredData={setFilteredUserList}
          onShowFilter={() => {}}
          onFilterFormSubmit={() => {}}
          onFilterFormReset={() => {}}
          setShowExportDialog={() => setShowExportDialog(true)}
        />
      </div>
    </div>
  )
}

export default withRoleChecking(
  UsersListPage,
  [UserRoleTypes.CustomerAdmin.split(' ').join(''), UserRoleTypes.Admin],
  true,
)
