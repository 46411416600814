import React from 'react'
import { ExportIcon } from 'assets/icons'
import Tooltip from 'components/atom/Tooltip'
import CrowconButton from 'components/miscComponents/CrowconButton'

export function ExportTableDataButton({
  setShowExportDialog,
}: {
  setShowExportDialog: (val: boolean) => void
}) {
  return (
    <div className=' relative'>
      <Tooltip
        tooltipText='Export'
        toolTipClass='tooltip -top-8 left-1/2  px-3 -translate-x-1/2 w-fit m-auto text-center w-auto'
      >
        <CrowconButton
          // additionalClassName='flex flex-row justify-around items-center rounded-full lg:rounded-4xl  p-[0.6rem] md:py-[1rem] md:px-[1.25rem] gap-4 '
          additionalClassName='p-[10px]  rounded-full '
          buttonAction={() => {
            setShowExportDialog(true)
            console.log('export clicked')
          }}
          buttonChildren={
            <>
              {/* <span className='font-bold text-base leading-5 hidden md:block sm:mr-3 lg:mr-0'>
            Export
          </span> */}
              {/* <span className=''> */}
              <ExportIcon height={18} width={18} />
              {/* </span> */}
            </>
          }
        />
      </Tooltip>
    </div>
  )
}
