import React, { useEffect, useMemo, useState, useContext, useCallback } from 'react'
import clsx from 'clsx'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { isAfter, isBefore, isEqual, fromUnixTime, sub } from 'date-fns'

import { useDeviceDetails } from 'pages/deviceDetails/rootPage/view/DeviceDetailsRoot'
import { ColumnDef } from '@tanstack/react-table'
import { FormattedDate, FormattedMessage, FormattedTime, useIntl } from 'react-intl'
import { DateRange, IFilterValues, IselectOptionProps } from 'forms/FormModelInterface'
import {
  DateRangeColumnFilterFn,
  DateRangeColumnFilterUnixTimeFn,
  dateRangeOptions,
  selectAllOption,
} from 'forms/FormUtils'
import { BumpTestStatusValues, ScreenWidths, returnDateWithoutTimeOffset } from 'utils/Constants'
import TsTable from 'components/table/TsTable'
import { DateRangeSelectorTypes, DeviceDetailPageTabs } from 'utils/CommonEnums'
import { iDeviceUser } from 'pages/devices/data/iDevicesList'
import {
  DeviceDetailContextActions,
  DeviceDetailsContext,
} from 'pages/deviceDetails/rootPage/view/DeviceDetailsContext'
import ExportData from 'exportReports/ExportData'

import Tooltip from 'components/atom/Tooltip'
import { withPageTracking } from 'utils/AppInsightConfig'
import { useGenericEventHandler } from 'data/GenericEventHandler'
import DeviceDetailsReportSelector from 'pages/deviceDetails/rootPage/view/DeviceDetailsReportSelector'
import { withErrorBoundary } from 'react-error-boundary'
import ErrorPage from 'pages/common/ErrorPage'
import { NavigateBackButton } from '../../../rootPage/view/NavigateBackButton'
import { iBumpHistory } from '../data/interface'
import BumpHistoryFilterForm from './BumpHistoryFilterForm'
import { prepareBumpHistoryFormData } from '../data/DeviceBumpDataUtils'
import { useBumpHistoryData } from '../data/BumpHistoryData'
import { BumpHistoryReportColumns } from '../export/BumpHistoryReportFormat'
import BumpHistoryFilterFormat from '../export/BumpHistoryFilterFormat'

export const columnIDs = {
  bumpTestDateTime: 'timeStamp',
  bumpTestResult: 'bumpStatus',
  eventDescription: 'eventDescription',
  assignedTo: 'assignedTo',
}

export type DeviceBumpHistoryFilterFormType = {
  eventDateSelector: IselectOptionProps
  eventDate: DateRange
  bumpTestResult: IselectOptionProps
  eventDescription: IselectOptionProps
  deviceUserName: IselectOptionProps
  deviceUserUniqueID: IselectOptionProps
}

const DeviceBumpHistoryFilterParamLabels = {
  bumpDateFrom: 'from',
  bumpDateTo: 'to',
  filterBumpDateFrom: 'fdatefrom',
  filterBumpDateTo: 'fdateto',
  bumpResult: 'res',
  bumpDescription: 'desc',
  bumpUser: 'user',
  bumpUserUniquieId: 'uniqueid',
}

function BumpHistory() {
  // Implement the component logic here

  const [is2xlAndAbove, setIs2xlAndAbove] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIs2xlAndAbove(window.innerWidth >= ScreenWidths.xxl)
    }

    handleResize() // Initial check

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const deviceDetailsContext = useContext(DeviceDetailsContext)
  const { state, dispatch } = deviceDetailsContext

  const location = useLocation()
  const redirectPageURL = `${location.pathname}${location.search}`

  const intl = useIntl()
  const [searchParams, setSearchParams] = useSearchParams()
  const [filterValues, setFilterValues] = React.useState<IFilterValues[]>([])

  /**
   * Get the bump date from and to from the URL
   * @param {string} from - The from date
   * @param {string} to - The to date
   * @param {string} filterBumpDateFrom - The filter from date
   * @param {string} filterBumpDateTo - The filter to date
   */

  const isToDate =
    searchParams.get('to') ?? searchParams.get(DeviceBumpHistoryFilterParamLabels.filterBumpDateTo)
  const isFromDate =
    searchParams.get('from') ??
    searchParams.get(DeviceBumpHistoryFilterParamLabels.filterBumpDateFrom)

  const filterBumpDateTo = returnDateWithoutTimeOffset(isToDate ?? new Date())
  const filterBumpDateFrom = returnDateWithoutTimeOffset(
    isFromDate ?? sub(new Date(filterBumpDateTo), { months: 6 }),
  )

  const bumpResultParam = searchParams.get('res') ?? ''
  const bumpDescriptionParam = searchParams.get('desc') ?? ''
  const bumpUserParam = searchParams.get('user') ?? ''
  const bumpUserUniquieIdParam = searchParams.get('uniqueid') ?? ''

  const params = useParams()
  const { deviceSerialNumber } = params as { deviceSerialNumber: string }
  document.title = `Bump History ${deviceSerialNumber}`

  const { deviceDetails } = useDeviceDetails()
  const { id: deviceId } = deviceDetails ?? { deviceId: '' }
  const [translatedBumpHistory, setTranslatedBumpHistory] = React.useState<iBumpHistory[]>([])
  const [showExportDialog, setShowExportDialog] = React.useState(false)

  const { genericEventHandler } = useGenericEventHandler()

  const {
    data: deviceBumpHistory,
    isLoading: isDeviceBumpHistoryLoading,
    error: deviceBumpHistoryError,
    isError: isDeviceBumpHistoryError,
  } = useBumpHistoryData(
    deviceId as string,
    filterBumpDateFrom.toISOString().split('T')[0],
    filterBumpDateTo.toISOString().split('T')[0],
    redirectPageURL,
    true,
  )

  useEffect(() => {
    if (isDeviceBumpHistoryError) {
      genericEventHandler({
        onlyTrack: true,
        severity: 'error',
        message: deviceBumpHistoryError?.message || 'Error getting bump history',
        error: deviceBumpHistoryError,
        extraData: {
          component: 'DeviceBumpHistory',
          action: 'get bump history',
        },
      })
    }
  }, [isDeviceBumpHistoryError, deviceBumpHistoryError])

  useEffect(() => {
    if (deviceBumpHistory && deviceBumpHistory.length > 0) {
      const historyTranslated = deviceBumpHistory.map((history) => {
        const { eventDescription } = history
        const translationKey = eventDescription as string
        if (!history.eventLogValues) {
          const translatedDescription = intl.formatMessage({ id: translationKey })
          return {
            ...history,
            eventDescription: translatedDescription,
          }
        }
        const translationValues: Record<string, string> = JSON.parse(history.eventLogValues)
        const translatedValue = intl.formatMessage({ id: translationKey }, translationValues)
        return {
          ...history,
          eventDescription: translatedValue,
        }
      })
      setTranslatedBumpHistory(historyTranslated)
    }
  }, [deviceBumpHistory])

  const [filterFormDescriptionValues, setFilterFormDescriptionValues] = React.useState<
    IselectOptionProps[]
  >([])

  const [filterFormDeviceUserValues, setFilterFormDeviceUserValues] = React.useState<
    IselectOptionProps[]
  >([])

  const [filterFormBumpStatusTypes, setFilterFormBumpStatusTypes] = React.useState<
    IselectOptionProps[]
  >([])

  useEffect(() => {
    if (translatedBumpHistory && translatedBumpHistory.length > 0) {
      const { bumpStatusTypes, eventDescriptions, eventDeviceUsers } =
        prepareBumpHistoryFormData(translatedBumpHistory)
      setFilterFormDescriptionValues(eventDescriptions)
      setFilterFormDeviceUserValues(eventDeviceUsers)
      setFilterFormBumpStatusTypes(bumpStatusTypes)
    }
  }, [translatedBumpHistory])

  const bumpHistoryColumns = useMemo<ColumnDef<iBumpHistory>[]>(
    () => [
      {
        id: columnIDs.bumpTestDateTime,
        header: 'Date & Time',
        accessorKey: 'timeStamp',
        cell: (info) => {
          const value: number = info.getValue() as number
          const date = fromUnixTime(value)
          return (
            <div className='font-poppins text-2xs leading-4 font-semibold text-c-dark-blue-1 '>
              <span className='mr-2'>
                <FormattedDate value={date} />
              </span>
              <span>
                <FormattedTime value={date} />
              </span>
            </div>
          )
        },
        meta: {},
        filterFn: DateRangeColumnFilterUnixTimeFn<iBumpHistory>,
      },
      {
        id: columnIDs.bumpTestResult,
        header: 'Bump result',
        accessorKey: 'bumpStatus',
        cell: (info) => {
          const value: string = info.getValue() as string
          const bumpResultValue = BumpTestStatusValues.find((status) => status.value === value)
          return (
            <Tooltip
              id={`bump-result-tooltip-${value}`}
              showOnlyWhenTextIsTruncate
              tooltipText={bumpResultValue?.label.toString() ?? ''}
              toolTipClass='tooltip'
              className='font-poppins text-2xs leading-4 font-normal text-c-dark-blue-1  text-ellipsis '
            >
              {bumpResultValue?.label ?? ''}
            </Tooltip>
          )
        },
        meta: { colClassName: '' },
        filterFn: 'includesString',
      },

      {
        id: columnIDs.eventDescription,
        header: 'Description',
        accessorKey: 'eventDescription',
        accessorFn: (row) => {
          const { eventDescription } = row
          return eventDescription
        },
        cell: (info) => {
          const value = info.getValue() as string
          return (
            <Tooltip
              id={`bump-description-tooltip-${value}`}
              showOnlyWhenTextIsTruncate
              tooltipText={value}
              toolTipClass='tooltip'
              className='font-poppins text-2xs leading-4 font-normal text-c-dark-blue-1  text-ellipsis '
            >
              {value}
            </Tooltip>
          )
        },
        meta: { colClassName: '' },

        filterFn: 'includesString',
      },

      {
        id: columnIDs.assignedTo,
        header: 'Assigned to',
        accessorKey: 'assignedTo',
        cell: (info) => {
          const userName = info.getValue() as string

          return (
            <Tooltip
              id={`bump-user-tooltip-${userName}`}
              showOnlyWhenTextIsTruncate
              tooltipText={userName}
              toolTipClass='tooltip'
              className='font-poppins text-2xs leading-4 font-normal text-c-dark-blue-1  text-ellipsis '
            >
              {userName}
            </Tooltip>
          )
        },
        meta: { colClassName: '' },
        filterFn: 'includesString',
      },
    ],
    [],
  )

  const resetFilterFormParams = () => {
    searchParams.delete(DeviceBumpHistoryFilterParamLabels.bumpDateFrom)
    searchParams.delete(DeviceBumpHistoryFilterParamLabels.bumpDateTo)
    searchParams.delete(DeviceBumpHistoryFilterParamLabels.filterBumpDateFrom)
    searchParams.delete(DeviceBumpHistoryFilterParamLabels.filterBumpDateTo)
    searchParams.delete(DeviceBumpHistoryFilterParamLabels.bumpResult)
    searchParams.delete(DeviceBumpHistoryFilterParamLabels.bumpDescription)
    searchParams.delete(DeviceBumpHistoryFilterParamLabels.bumpUser)
    searchParams.delete(DeviceBumpHistoryFilterParamLabels.bumpUserUniquieId)
    setSearchParams(searchParams)
  }

  const handleFilterFormSubmit = useCallback(
    (formData: IFilterValues[]) => {
      formData.forEach((filter) => {
        if (filter.columnId === columnIDs.assignedTo) {
          if (filter.value === '') {
            searchParams.delete(DeviceBumpHistoryFilterParamLabels.bumpUser)
          } else {
            searchParams.set(DeviceBumpHistoryFilterParamLabels.bumpUser, filter.value as string)
          }
        }

        if (filter.columnId === columnIDs.bumpTestResult) {
          if (filter.value === '') {
            searchParams.delete(DeviceBumpHistoryFilterParamLabels.bumpResult)
          } else {
            searchParams.set(DeviceBumpHistoryFilterParamLabels.bumpResult, filter.value as string)
          }
        }

        if (filter.columnId === columnIDs.eventDescription) {
          if (filter.value === '') {
            searchParams.delete(DeviceBumpHistoryFilterParamLabels.bumpDescription)
          } else {
            searchParams.set(
              DeviceBumpHistoryFilterParamLabels.bumpDescription,
              filter.value as string,
            )
          }
        }

        if (filter.columnId === columnIDs.bumpTestDateTime) {
          if (filter.value === '') {
            searchParams.delete(DeviceBumpHistoryFilterParamLabels.bumpDateFrom)
            searchParams.delete(DeviceBumpHistoryFilterParamLabels.bumpDateTo)
          } else {
            const dateRangeToBeFiltered = filter.value as DateRange
            const isFromBeforeOrSame =
              isBefore(new Date(dateRangeToBeFiltered.startDate), filterBumpDateFrom) ||
              isEqual(new Date(dateRangeToBeFiltered.startDate), filterBumpDateFrom)

            const isToAfterOrSame =
              isAfter(new Date(dateRangeToBeFiltered.endDate), filterBumpDateTo) ||
              isEqual(new Date(dateRangeToBeFiltered.endDate), filterBumpDateTo)

            const isDateRangeWider = isFromBeforeOrSame && isToAfterOrSame

            if (isDateRangeWider) {
              searchParams.set(
                DeviceBumpHistoryFilterParamLabels.bumpDateFrom,
                new Date(dateRangeToBeFiltered.startDate).toISOString().split('T')[0],
              )
              searchParams.set(
                DeviceBumpHistoryFilterParamLabels.bumpDateTo,
                new Date(dateRangeToBeFiltered.endDate).toISOString().split('T')[0],
              )
            }

            searchParams.set(
              DeviceBumpHistoryFilterParamLabels.filterBumpDateFrom,
              new Date(dateRangeToBeFiltered.startDate).toISOString().split('T')[0],
            )
            searchParams.set(
              DeviceBumpHistoryFilterParamLabels.filterBumpDateTo,
              new Date(dateRangeToBeFiltered.endDate).toISOString().split('T')[0],
            )
          }
        }
      })
      setSearchParams(searchParams)
    },
    [searchParams, setSearchParams, filterBumpDateFrom, filterBumpDateTo],
  )

  const filterTableValues = useMemo(
    () => [
      {
        columnId: columnIDs.bumpTestDateTime,
        value:
          isFromDate && isToDate
            ? { startDate: filterBumpDateFrom, endDate: filterBumpDateTo }
            : '',
      },
      {
        columnId: columnIDs.bumpTestResult,
        value: bumpResultParam ?? '',
      },
      {
        columnId: columnIDs.eventDescription,
        value: bumpDescriptionParam ?? '',
      },
      {
        columnId: columnIDs.assignedTo,
        value: bumpUserParam ?? '',
      },
    ],
    [
      isFromDate,
      isToDate,
      filterBumpDateFrom,
      filterBumpDateTo,
      bumpResultParam,
      bumpDescriptionParam,
      bumpUserParam,
    ],
  )

  const convertISelectOptionPropsToFormType = useMemo(() => {
    const bumpStatusFiltered =
      filterTableValues.find((filter) => filter.columnId === columnIDs.bumpTestResult)?.value ?? ''
    const bumpDescriptionFiltered =
      filterTableValues.find((filter) => filter.columnId === columnIDs.eventDescription)?.value ??
      ''
    const bumpUserFiltered =
      filterTableValues.find((filter) => filter.columnId === columnIDs.assignedTo)?.value ?? ''
    const dateRangeFiltered = (filterTableValues.find(
      (filter) => filter.columnId === columnIDs.bumpTestDateTime,
    )?.value || {
      startDate: filterBumpDateFrom,
      endDate: filterBumpDateTo,
    }) as DateRange

    const r: DeviceBumpHistoryFilterFormType = {
      bumpTestResult: {
        label: bumpStatusFiltered as string,
        value: bumpStatusFiltered as string,
      },
      eventDescription: {
        label: bumpDescriptionFiltered as string,
        value: bumpDescriptionFiltered as string,
      },
      deviceUserName: {
        label: bumpUserFiltered as string,
        value: bumpUserFiltered as string,
      },
      deviceUserUniqueID: {
        label: bumpUserUniquieIdParam as string,
        value: bumpUserUniquieIdParam as string,
      },
      eventDateSelector: dateRangeOptions.find(
        (option) => option.value === DateRangeSelectorTypes.Custom,
      ) ?? {
        label: '',
        value: '',
      },
      eventDate: {
        startDate: new Date(dateRangeFiltered?.startDate ?? ''),
        endDate: new Date(dateRangeFiltered?.endDate ?? ''),
      },
    }
    return r
  }, [filterTableValues, filterBumpDateFrom, filterBumpDateTo, bumpUserUniquieIdParam])

  const showFilter = () => {
    dispatch({
      type: DeviceDetailContextActions.ShowFilterForm,
      payload: {
        page: DeviceDetailPageTabs.BumpHistory,
        filterFormData: {
          onFilterSubmit: (formData: IFilterValues[]) => handleFilterFormSubmit(formData),
          onFilterReset: () => resetFilterFormParams(),
          // onFilterFormClose: () => setShowFilterForm(false),
          selectedValues: convertISelectOptionPropsToFormType,
          deviceUserNames: filterFormDeviceUserValues,
          eventDescriptions: filterFormDescriptionValues,
          bumpTestResults: filterFormBumpStatusTypes,
        },
      },
    })
  }

  const hideFilterForm = () => {
    dispatch({ type: DeviceDetailContextActions.HideFilterForm })
  }

  // useEffect(() => {
  //   if (is2xlAndAbove) {
  //     showFilter()
  //   } else {
  //     hideFilterForm()
  //   }
  // }, [is2xlAndAbove])

  // Return the JSX that will be rendered
  return (
    <>
      {/* {eventLogsTranslated && ( */}
      {/* <div className='flex'> */}
      <ExportData
        handleCancel={() => setShowExportDialog(false)}
        IsOpen={showExportDialog}
        data={translatedBumpHistory ?? []}
        reportColumns={BumpHistoryReportColumns}
        widgetForReport={[]}
        reportTitle='Bump history'
        filterSummary={BumpHistoryFilterFormat(
          filterTableValues,
          deviceDetails?.serialNumber || '',
          deviceDetails?.deviceType || '',
        )}
      />
      {/* <div className='flex-grow overflow-x-auto'> */}
      <TsTable<iBumpHistory>
        data={translatedBumpHistory ?? []}
        columns={bumpHistoryColumns}
        showGlobalActionButton={false}
        globalActionButton={<NavigateBackButton />}
        showGlobalFilter={false}
        getRowCanExpand={() => false}
        onPrint={() => {}}
        onExport={() => {}}
        onShowFilter={showFilter}
        dataIsLoading={isDeviceBumpHistoryLoading}
        resetFilter={() => resetFilterFormParams()}
        minDisplayRows={10}
        updateFilteredData={() => {}}
        renderFilterSummary={() => <> </>}
        filterValues={filterTableValues}
        renderTabs={() => <DeviceDetailsReportSelector />}
        defaultSortedColumKey={columnIDs.bumpTestDateTime}
        setShowExportDialog={(val) => setShowExportDialog(val)}
      />
      {/* </div> */}
      {/* <BumpHistoryFilterForm
          onFilterSubmit={filterBumpHistory}
          onFilterReset={() => {}}
          onFilterFormClose={() => {}}
          selectedValues={filterFormSelectedValues}
          deviceUserNames={filterFormDeviceUserValues}
          eventDescriptions={filterFormDescriptionValues}
          bumpTestResults={filterFormBumpStatusTypes}
        /> */}
      {/* </div> */}
    </>
  )
}

export default withErrorBoundary(withPageTracking(BumpHistory), {
  FallbackComponent: ErrorPage,
})
