import React, { useEffect, useState } from 'react'

import GenericWidget from 'components/viewComponents/GenericWidget'
import { iWidgetData } from 'components/viewComponents/interfaces'
import { DateRangeSelectorTypes, DeviceFaultTypes } from 'utils/CommonEnums'
import { DateRange, IFilterValues, IselectOptionProps } from 'forms/FormModelInterface'
import { calDueDateRangeOptions, dateRangeOptions } from 'forms/FormUtils'
import { iFaultData } from 'pages/service-faults/data/FaultsData'
import {
  faultTypesToCount,
  groupFaultByFaultType,
  iFaultTypeCount,
} from 'pages/service-faults/data/FaultsDataUtils'
import { FormattedMessage, useIntl } from 'react-intl'
import { DashboardDataSmall } from 'components/viewComponents'
import { withErrorBoundary } from 'react-error-boundary'
import ErrorPage from 'pages/common/ErrorPage'
import { ServiceFaultsColumnIds } from '../ServiceFaultsPageUtils'

function FaultsByFaultTypeWidget({
  faultsData,
  onFilter,
  faultTypeFilteredParam,
  faultTypeUntransaltedFilteredParam,
}: {
  faultsData: iFaultData[]
  onFilter: (filterRequestValues: IFilterValues[]) => void
  faultTypeFilteredParam: string
  faultTypeUntransaltedFilteredParam: string
}): JSX.Element {
  const intl = useIntl()
  const [widgetData, setWidgetData] = useState<iWidgetData[]>()

  // const translatedNonOtherFaultTypes = faultTypesToCount.map((faultType) =>
  //   faultType === DeviceFaultTypes.Other
  //     ? faultType
  //     : intl.formatMessage({
  //         id: `Eventlog.FaultTypes.${faultType}`,
  //       }),
  // )

  const onFilterData = (filterRequestValues: IFilterValues[]) => {
    const filterRequestValuesToSend: IFilterValues[] = []

    filterRequestValues.forEach((filterRequestValue) => {
      if (filterRequestValue.columnId === ServiceFaultsColumnIds.faultTypeUntranslated) {
        filterRequestValuesToSend.push({
          columnId: ServiceFaultsColumnIds.faultTypeUntranslated,
          value: filterRequestValue.value === '' ? '' : filterRequestValue.value,
        })
      } else if (filterRequestValue.columnId === ServiceFaultsColumnIds.faultType) {
        filterRequestValuesToSend.push({
          ...filterRequestValue,
          value:
            filterRequestValue.value === ''
              ? ''
              : intl.formatMessage({
                  id: `Eventlog.FaultTypes.${filterRequestValue.value}`,
                }),
        })
      }
    })

    onFilter(filterRequestValuesToSend)
  }

  useEffect(() => {
    if (faultsData) {
      const faultTypeCounts = groupFaultByFaultType(faultsData ?? [])

      const faultTypeWidgetData: iWidgetData[] = faultTypeCounts.map((faultTypeCount) => ({
        originalData: faultTypeCount.faultType,
        displayText: intl.formatMessage({
          id: `Eventlog.FaultTypes.${faultTypeCount.faultType}`,
        }),
        value: faultTypeCount.count,
        isFiltered:
          faultTypeCount.faultType === DeviceFaultTypes.Other
            ? faultTypeUntransaltedFilteredParam === faultTypeCount.faultType
            : faultTypeFilteredParam ===
              intl.formatMessage({
                id: `Eventlog.FaultTypes.${faultTypeCount.faultType}`,
              }),
        columnId:
          faultTypeCount.faultType === DeviceFaultTypes.Other
            ? ServiceFaultsColumnIds.faultTypeUntranslated
            : ServiceFaultsColumnIds.faultType,
      }))

      setWidgetData(faultTypeWidgetData)
    }
  }, [faultsData, faultTypeFilteredParam, faultTypeUntransaltedFilteredParam])

  return (
    <GenericWidget
      widgetProps={{
        title: 'Fault Type',
        widgetData,
        widgetLabelCount: 6,
        columnId: ServiceFaultsColumnIds.faultType,
        onFilter: (filterRequestValues: IFilterValues[]) => onFilterData(filterRequestValues),
      }}
    />
  )
}

export default withErrorBoundary(FaultsByFaultTypeWidget, {
  FallbackComponent: ErrorPage,
})
