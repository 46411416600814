import { QueryClient, useQuery } from '@tanstack/react-query'
import { useMsal } from '@azure/msal-react'
import { LoaderFunction, createSearchParams, redirect } from 'react-router-dom'

import { GasType, GasUnitOfMeasurement } from 'utils/CommonEnums'
import { getAccessToken, getAccessTokenProps } from 'services/MSALService'
import ConnectAPIClient from 'services/ConnectAPIClient'
import { selectAllOption } from 'forms/FormUtils'
import { AxiosError } from 'axios'
import {
  iGasValuesAPIResponse,
  iGasLogsData,
  iReportDataTabular,
  iGasLogsTabular,
  iSingleGasLogTabular,
} from './interface'

export const DEFAULT_PAGE_INDEX = 0
export const DEFAULT_PAGE_SIZE = 10
export const EXPORT_FILE_PAGE_SIZE = 50000

export interface iDevieGasLogsAPIParams {
  deviceId: string
  startDate: string
  endDate: string
}

export interface iDeviceGasLogsServerSideSearchParams {
  deviceId: string
  startDate: string
  endDate: string
  channelNumber?: number
  gasName?: string
  // gasValue?: number
  uom?: string
  pageNumber?: number
  pageSize?: number
}
interface iGetDataParams extends iDeviceGasLogsServerSideSearchParams, getAccessTokenProps {}
interface iFetchDataParams extends iDeviceGasLogsServerSideSearchParams {
  token: string
}

export const deviceGasLogsQueryKeyIdDates = (params: iDevieGasLogsAPIParams) => {
  const { deviceId, startDate, endDate } = params
  return ['device', 'gasLogs', 'id', deviceId, 'startDate', startDate, 'endDate', endDate]
}

const deviceGasLogsQueryKey = (params: iDeviceGasLogsServerSideSearchParams) => {
  const { deviceId, startDate, endDate, channelNumber, gasName, uom, pageNumber, pageSize } = params
  return [
    'device',
    'gasLogs',
    'id',
    deviceId,
    'startDate',
    startDate,
    'endDate',
    endDate,
    'channelNumber',
    channelNumber && channelNumber !== -1 ? channelNumber : '',
    'gasName',
    gasName,
    // 'gasValue',
    // gasValue && gasValue !== -1 ? gasValue : '',
    'uom',
    uom,
    'pageNumber',
    pageNumber,
    'pageSize',
    pageSize,
  ]
}

function deviceGasLogURL(
  deviecId: string,
  startDate: string | undefined,
  endDate: string | undefined,
  channelNumber: number | undefined,
  gasName: string | undefined,
  // gasValue: number | undefined,
  uom: string | undefined,
  pageNumber?: number,
  pageSize?: number,
) {
  let apiURL = `devicelogs/api/gaslogs/${deviecId}`
  const urlParams = new URLSearchParams()
  if (startDate) urlParams.append('FromDate', startDate)
  if (endDate) urlParams.append('ToDate', endDate)
  if (channelNumber && !Number.isNaN(channelNumber) && channelNumber !== -1) {
    urlParams.append('ChannelNumber', channelNumber.toString())
  }
  if (gasName && gasName !== selectAllOption.value) {
    urlParams.append('GasType', gasName)
  }
  // if (gasValue && gasValue !== -1) urlParams.append('GasValue', gasValue.toString())
  if (uom && uom !== selectAllOption.value) urlParams.append('UOM', uom)
  if (pageNumber && !Number.isNaN(pageNumber)) urlParams.append('PageNumber', pageNumber.toString())
  if (pageSize && !Number.isNaN(pageSize)) urlParams.append('PageSize', pageSize.toString())
  if (urlParams.toString()) apiURL += `?${urlParams.toString()}`
  // ?FromDate=${startDate}&ToDate=${endDate}&ChannelNumber=${channelNumber}&GasName=${gasName}&GasValue=${gasValue}&UOM=${uom}
  return apiURL
}

const convertGasLogsToTabular = (gasLogs: iGasValuesAPIResponse): iGasLogsTabular => {
  const gasLogsTabular: iGasLogsTabular = {
    data: [],
    pagination: {
      nextPage: gasLogs.nextPage,
      previousPage: gasLogs.previousPage,
      pageNumber: gasLogs.pageNumber,
      pageSize: gasLogs.pageSize,
      totalRecords: gasLogs.totalRecords,
    },
  }

  gasLogs?.data?.forEach((log) => {
    const { timeStamp, gasValues } = log
    const logTabular: iSingleGasLogTabular = {
      timeStamp,
      channelNumber: -1,
      gasName: GasType.Other,
      gasValue: -1,
      uom: GasUnitOfMeasurement.PPM,
    }

    gasValues.forEach((gasValue) => {
      const { channelNumber, name, value, uom } = gasValue

      const logTabularWithGasValues = {
        ...logTabular,
        channelNumber,
        gasName: name,
        gasValue: value,
        uom,
      }

      gasLogsTabular.data.push(logTabularWithGasValues)
    })
  })

  return gasLogsTabular
}

async function fetchDeviceGasLogs(deviceGasLogsParams: iFetchDataParams): Promise<iGasLogsTabular> {
  try {
    const {
      deviceId,
      startDate,
      endDate,
      channelNumber,
      gasName,
      uom,
      token,
      pageNumber,
      pageSize,
    } = deviceGasLogsParams

    const url = deviceGasLogURL(
      deviceId,
      startDate,
      endDate,
      channelNumber,
      gasName,
      uom,
      pageNumber,
      pageSize,
    )
    const resp = await ConnectAPIClient.get<iGasValuesAPIResponse>(url, token)

    const response = resp.data

    const gasLogsConvertedToTabular = convertGasLogsToTabular(response)

    return gasLogsConvertedToTabular
    // return {
    //   data: response.data,
    //   nextPage: response.nextPage,
    //   previousPage: response.previousPage,
    //   pageNumber: response.pageNumber,
    //   pageSize: response.pageSize,
    //   totalRecords: response.totalRecords,
    // }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function getDeviceGasLogData(getGasLogParams: iGetDataParams) {
  const {
    msalContext,
    redirectPageURL,
    deviceId,
    startDate,
    endDate,
    channelNumber,
    gasName,

    uom,
    pageNumber,
    pageSize,
  } = getGasLogParams
  const token = await getAccessToken({ msalContext, redirectPageURL })
  const gasLogsData = await fetchDeviceGasLogs({
    token,
    deviceId,
    startDate,
    endDate,
    channelNumber,
    gasName,

    uom,
    pageNumber,
    pageSize,
  })
  return gasLogsData
}

// export const DeviceGasLogsLoader =
//   (queryClient: QueryClient): LoaderFunction =>
//   async ({ request, params }) => {
//     try {
//       if (!params?.deviceSerialNumber) throw new Error('No device serial number')
//       const searchParams = createSearchParams(new URLSearchParams(request.url.split('?')[1]))

//       const endDate = searchParams.get('to')
//       const startDate = searchParams.get('from')

//       const devDetailsQuery = deviceDetailsQuery(params?.deviceSerialNumber)

//       let deviceDetails = queryClient.getQueryData(devDetailsQuery.queryKey) as iDeviceDetails

//       if (!deviceDetails || !deviceDetails.id) {
//         deviceDetails = (await queryClient.fetchQuery(
//           devDetailsQuery.queryKey,
//           devDetailsQuery.queryFn,
//         )) as iDeviceDetails
//       }

//       if (!deviceDetails?.id) throw new Error('No device Id')

//       const deviceGasLogsQueryAPIParams = {
//         deviceId: deviceDetails?.id ?? '',
//         startDate: startDate ?? '',
//         endDate: endDate ?? '',
//       }

//       // eslint-disable-next-line no-return-await
//       // return queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query))
//       const loaderData = queryClient.getQueryData(query.queryKey)
//       if (loaderData) return loaderData
//       try {
//         const fetchedData = await queryClient.fetchQuery(query.queryKey, query.queryFn, {
//           initialData: {},
//         })
//         // if (fetchedData?.length ===0) {
//         //   throw new Response("No data", { status: 404 });
//         // }
//         return fetchedData
//       } catch (error) {
//         console.error(error)
//         return []
//       }
//       // return queryClient.getQueryData(query.queryKey) ?? []
//     } catch (error) {
//       console.error(error)
//       throw error
//     }
//   }

export function useDeviceGasLogsData(
  redirectPageURL: string,
  deviceId: string,
  startDate: string,
  endDate: string,
  channelNumber?: number,
  gasName?: string,
  // gasValue?: number,
  uom?: string,
  pageNumber?: number,
  pageSize?: number,
  enabled?: boolean,
) {
  const msalContext = useMsal()
  const queryKey = deviceGasLogsQueryKey({
    deviceId,
    startDate: startDate ?? '',
    endDate: endDate ?? '',
    channelNumber: channelNumber === undefined || Number.isNaN(channelNumber) ? -1 : channelNumber,
    gasName: gasName ?? '',
    // gasValue: gasValue ?? -1,
    uom: uom ?? '',
    pageNumber: pageNumber ?? DEFAULT_PAGE_INDEX,
    pageSize: pageSize ?? DEFAULT_PAGE_SIZE,
  })

  return useQuery(
    queryKey,
    () =>
      getDeviceGasLogData({
        msalContext,
        redirectPageURL,

        deviceId,
        startDate: startDate ?? '',
        endDate: endDate ?? '',
        channelNumber,
        gasName,
        // gasValue,
        uom,
        pageNumber,
        pageSize,
      }),
    {
      enabled,
      onError: (error: AxiosError) => {
        console.log(error)
      },
    },
  )
}

// export const reportQuery = (deviceGasLogParams: iDevieGasLogsAPIParams) => ({
//   queryKey: deviceGasLogsQueryKeyIdDates(deviceGasLogParams),
//   queryFn: async () => {
//     try {
//       const deviceGasLogs = await getDeviceGasLogs(deviceGasLogParams)
//       const deviceGasLogsTabular: iReportDataTabular[] = []

//       return deviceGasLogs
//     } catch (error) {
//       console.error(error)
//       throw error
//     }
//   },
// })
