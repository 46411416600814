import React, { useEffect, useState, memo } from 'react'
import { useForm, SubmitHandler, Controller, FieldErrors } from 'react-hook-form'
import { DateRange, IFilterValues, IselectOptionProps } from 'forms/FormModelInterface'
// eslint-disable-next-line import/no-extraneous-dependencies
import { DevTool } from '@hookform/devtools'
import { sub } from 'date-fns'

import { DateRangeSelectorTypes, SelectOptionTypes } from 'utils/CommonEnums'
import { ChevronRightIcon, CloseIcon } from 'assets/icons'
import { DatePickerControl, SelectControl, CreatableSelectControl } from 'components/formComponents'
import FilterFormTitle from 'components/formComponents/FilterFormTitle'

import { dateRangeOptions, selectAllOption, selectBlankOption } from 'forms/FormUtils'
import { withErrorBoundary } from 'react-error-boundary'
import ErrorPage from 'pages/common/ErrorPage'
import { ServiceFaultsColumnIds, ServiceFaultsFilterFormType } from './ServiceFaultsPageUtils'

// faultType: IselectOptionProps
// deviceType: IselectOptionProps
// faultDescription: IselectOptionProps
// dateRangeSelector: IselectOptionProps
// faultDate: DateRange

interface ServiceFilterFormProps {
  selectedFilterValues?: ServiceFaultsFilterFormType
  serialNumberOptions: IselectOptionProps[]
  customerAssetNumberOptions: IselectOptionProps[]
  deviceUserNameOptions: IselectOptionProps[]
  deviceTypeOptions: IselectOptionProps[]
  businessUnitOptions: IselectOptionProps[]
  faultTypeOptions: IselectOptionProps[]
  faultDescriptionOptions: IselectOptionProps[]

  onFilterSubmit: (filterValues: IFilterValues[]) => void
  onFilterReset: () => void
  onFilterFormClose: () => void
}

const defaultFilterValues = () => {
  const dtTo = new Date()
  const dtFrom = sub(new Date(), { months: 6 })
  const defValues: ServiceFaultsFilterFormType = {
    deviceSerialNumber: selectBlankOption,
    customerAssetNumber: selectBlankOption,
    deviceUserName: selectBlankOption,
    deviceType: selectAllOption,
    businessUnit: selectAllOption,
    faultType: selectAllOption,
    faultDescription: selectBlankOption,
    faultDateRangeSelector: dateRangeOptions.find(
      (option) => option.value === DateRangeSelectorTypes.Custom,
    ) ?? {
      label: '',
      value: '',
    },
    faultDateRange: {
      startDate: dtFrom,
      endDate: dtTo,
    },
  }
  return defValues
}

function ServiceFaultsFilterForm(props: ServiceFilterFormProps): JSX.Element {
  const {
    selectedFilterValues,
    serialNumberOptions,
    customerAssetNumberOptions,
    deviceUserNameOptions,
    deviceTypeOptions,
    businessUnitOptions,
    faultTypeOptions,
    faultDescriptionOptions,
    onFilterSubmit,
    onFilterReset,
    onFilterFormClose,
  } = props
  // console.log('selectedFilterValues', selectedFilterValues.serialNumber)
  // console.log('defaultFilterValues', defaultFilterValues.serialNumber)
  const defaultFormValues = defaultFilterValues()

  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    getValues,
    formState: {
      errors,
      isDirty,
      isSubmitting,
      isValid,
      isValidating,
      touchedFields,
      isSubmitSuccessful,
      isSubmitted,
      dirtyFields,
    },
  } = useForm<ServiceFaultsFilterFormType>({
    defaultValues: defaultFormValues,
    values: selectedFilterValues,
  })

  const faultDateRangeSelector = watch('faultDateRangeSelector')
  const [showCustomFaultDate, setShowShowCustomFaultDate] = React.useState<boolean>(false)

  useEffect(() => {
    if (faultDateRangeSelector?.value === DateRangeSelectorTypes.Custom) {
      setShowShowCustomFaultDate(true)
    } else {
      setShowShowCustomFaultDate(false)
    }
  }, [faultDateRangeSelector])

  const onSubmit: SubmitHandler<ServiceFaultsFilterFormType> = (data) => {
    const filterValues: IFilterValues[] = []

    filterValues.push({
      columnId: ServiceFaultsColumnIds.deviceSerialNumber,
      value: data.deviceSerialNumber ? data.deviceSerialNumber.value : '',
    })
    filterValues.push({
      columnId: ServiceFaultsColumnIds.deviceUserName,
      value: data.deviceUserName ? data.deviceUserName.value : '',
    })
    filterValues.push({
      columnId: ServiceFaultsColumnIds.customerAssetNumber,
      value: data.customerAssetNumber ? data.customerAssetNumber.value : '',
    })

    filterValues.push({
      columnId: ServiceFaultsColumnIds.deviceType,
      value: data.deviceType.value === SelectOptionTypes.All ? '' : data.deviceType.value,
    })

    filterValues.push({
      columnId: ServiceFaultsColumnIds.faultType,
      value: data.faultType?.value?.toString().trim()
        ? data.faultType?.value?.toString().trim() === SelectOptionTypes.All
          ? ''
          : data.faultType?.value?.toString().trim()
        : '',
    })

    filterValues.push({
      columnId: ServiceFaultsColumnIds.faultDescription,
      value: data.faultDescription?.value?.toString().trim()
        ? data.faultDescription?.value?.toString().trim() === SelectOptionTypes.All
          ? ''
          : data.faultDescription?.value?.toString().trim()
        : '',
    })

    filterValues.push({
      columnId: ServiceFaultsColumnIds.businessUnit,
      value: data.businessUnit
        ? data.businessUnit.value === SelectOptionTypes.All
          ? ''
          : (data.businessUnit.label as string)
        : '',
    })

    filterValues.push({
      columnId: ServiceFaultsColumnIds.faultDateTime,
      value:
        data.faultDateRangeSelector.label === DateRangeSelectorTypes.All
          ? ''
          : {
              label: data.faultDateRangeSelector.label as string,
              value: data.faultDateRange,
            },
    })

    onFilterSubmit(filterValues)
  }

  const onError = (formErrors: FieldErrors<ServiceFaultsFilterFormType>) => {
    console.log(formErrors)
  }

  const onReset = () => {
    reset(defaultFilterValues)
    onFilterReset()
  }

  return (
    <div className='filter-form-container'>
      <div className='filter-form-header'>
        <FilterFormTitle />

        <div
          title='Close Filter Form'
          role='button'
          tabIndex={0}
          onClick={() => onFilterFormClose()}
          onKeyDown={() => onFilterFormClose()}
          className='filter-form-close-button'
        >
          <CloseIcon toggleClick={() => onFilterFormClose()} className='w-5 h-5 ' />
        </div>
      </div>

      <form
        onSubmit={handleSubmit(onSubmit, onError)}
        onReset={onFilterReset}
        noValidate
        className=''
      >
        <div className='filter-form-fields-container'>
          <div className='flex flex-col gap-0.5 items-start self-stretch'>
            <label
              htmlFor='faultsrFilter'
              className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1 '
            >
              Serial Number
            </label>
            <Controller
              name='deviceSerialNumber'
              control={control}
              render={({ field }) => (
                <CreatableSelectControl
                  className='w-full h-[41px]'
                  selectControlProps={{
                    options: serialNumberOptions,
                    value: field.value,
                    defaultValue: field.value,
                    isMulti: false,
                    isSearchable: true,
                    isDropDownSelectable: false,
                    openMenuOnClick: true,
                    placeholder: '-',
                    isClearable: true,
                    // maxMenuHeight: 130,
                    onChange: (selectedOption: IselectOptionProps) => {
                      field.onChange(selectedOption)
                    },
                  }}
                />
              )}
            />
            <p className='text-xs text-red-500'>{errors.deviceSerialNumber?.message}</p>
          </div>
          <div className='flex flex-col gap-0.5 items-start self-stretch'>
            <label
              htmlFor='faultsrFilter'
              className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1 '
            >
              Assets Number
            </label>
            <Controller
              name='customerAssetNumber'
              control={control}
              render={({ field }) => (
                <CreatableSelectControl
                  className='w-full h-[41px]'
                  selectControlProps={{
                    options: customerAssetNumberOptions,
                    value: field.value,
                    defaultValue: field.value,
                    isMulti: false,
                    isSearchable: true,
                    isDropDownSelectable: false,
                    openMenuOnClick: true,
                    placeholder: '-',
                    isClearable: true,
                    // maxMenuHeight: 130,
                    onChange: (selectedOption: IselectOptionProps) => {
                      field.onChange(selectedOption)
                    },
                  }}
                />
              )}
            />
            <p className='text-xs text-red-500'>{errors.customerAssetNumber?.message}</p>
          </div>
          <div className='flex flex-col gap-0.5 items-start self-stretch'>
            <label
              htmlFor='faultsrFilter'
              className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1 '
            >
              Assign To
            </label>
            <Controller
              name='deviceUserName'
              control={control}
              render={({ field }) => (
                <CreatableSelectControl
                  className='w-full h-[41px]'
                  selectControlProps={{
                    options: deviceUserNameOptions,
                    value: field.value,
                    defaultValue: field.value,
                    isMulti: false,
                    isSearchable: true,
                    isDropDownSelectable: false,
                    openMenuOnClick: true,
                    placeholder: '-',
                    isClearable: true,
                    // maxMenuHeight: 130,
                    onChange: (selectedOption: IselectOptionProps) => {
                      field.onChange(selectedOption)
                    },
                  }}
                />
              )}
            />
            <p className='text-xs text-red-500'>{errors.deviceUserName?.message}</p>
          </div>
          <div className='flex flex-col gap-0.5 items-start self-stretch w-full'>
            <label
              htmlFor='deviceTypeFilter'
              className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1 '
            >
              Device Type
            </label>

            <Controller
              name='deviceType'
              control={control}
              // rules={{ required: true }}
              render={({ field }) => (
                <SelectControl
                  className='w-full h-[42px]'
                  selectControlProps={{
                    options: deviceTypeOptions,
                    defaultValue: field.value,
                    isMulti: false,
                    isSearchable: true,
                    isDropDownSelectable: true,
                    openMenuOnClick: true,
                    placeholder: 'Select device type',
                    isClearable: false,
                    onChange: (selectedOption: IselectOptionProps) => {
                      field.onChange(selectedOption)
                    },
                  }}
                />
              )}
            />
          </div>
          <div className='flex flex-col gap-0.5 items-start self-stretch'>
            <label
              htmlFor='businessUnitFilter'
              className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1 '
            >
              Business Unit
            </label>
            <Controller
              name='businessUnit'
              control={control}
              render={({ field }) => (
                <SelectControl
                  className='w-full h-[42px]'
                  selectControlProps={{
                    options: businessUnitOptions,
                    value: field.value,
                    defaultValue: field.value,
                    isMulti: false,
                    isSearchable: true,
                    isDropDownSelectable: true,
                    openMenuOnClick: true,
                    placeholder: 'Select business unit',
                    isClearable: false,
                    onChange: (selectedOption: IselectOptionProps) => {
                      field.onChange(selectedOption)
                    },
                  }}
                />
              )}
            />
            <p className='text-xs text-red-500'>{errors.businessUnit?.message}</p>
          </div>
          <div className='flex flex-col gap-0.5 items-start self-stretch'>
            <label
              htmlFor='faultTypeFilter'
              className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1 '
            >
              Fault Type
            </label>
            <Controller
              name='faultType'
              control={control}
              render={({ field }) => (
                <SelectControl
                  className='w-full h-[42px]'
                  selectControlProps={{
                    options: faultTypeOptions,
                    value: field.value,
                    defaultValue: field.value,
                    isMulti: false,
                    isSearchable: true,
                    isDropDownSelectable: true,
                    openMenuOnClick: true,
                    placeholder: 'Select fault type',
                    isClearable: false,
                    onChange: (selectedOption: IselectOptionProps) => {
                      field.onChange(selectedOption)
                    },
                  }}
                />
              )}
            />
            <p className='text-xs text-red-500'>{errors.faultType?.message}</p>
          </div>
          <div className='flex flex-col gap-0.5 items-start self-stretch'>
            <label
              htmlFor='faultDescFilter'
              className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1 '
            >
              Description
            </label>
            <Controller
              name='faultDescription'
              control={control}
              render={({ field }) => (
                <CreatableSelectControl
                  className='w-full h-[41px]'
                  selectControlProps={{
                    options: faultDescriptionOptions,
                    value: field.value,
                    defaultValue: field.value,
                    isMulti: false,
                    isSearchable: true,
                    isDropDownSelectable: false,
                    openMenuOnClick: true,
                    placeholder: '-',
                    isClearable: true,
                    // maxMenuHeight: 130,
                    onChange: (selectedOption: IselectOptionProps) => {
                      field.onChange(selectedOption)
                    },
                  }}
                />
              )}
            />
            <p className='text-xs text-red-500'>{errors.faultDescription?.message}</p>
          </div>
          <div className='flex flex-col gap-0.5 items-start self-stretch'>
            <label
              htmlFor='faultDateRangeSelector'
              className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1 '
            >
              Date & Time
            </label>

            <Controller
              name='faultDateRangeSelector'
              control={control}
              render={({ field }) => (
                <SelectControl
                  className='w-full h-[42px]'
                  selectControlProps={{
                    options: dateRangeOptions,
                    defaultValue: field.value,
                    isMulti: false,
                    isSearchable: false,
                    isDropDownSelectable: true,
                    openMenuOnClick: true,
                    placeholder: 'Select date range',
                    isClearable: false,
                    // value: field.value,
                    onChange: (selectedOption: IselectOptionProps) => {
                      const selectedValue = selectedOption?.value
                      field.onChange(selectedOption)
                      if (selectedValue !== DateRangeSelectorTypes.Custom) {
                        setValue('faultDateRange', selectedValue as DateRange, {
                          shouldValidate: true,
                        })
                      } else {
                        setValue('faultDateRange', {
                          startDate: selectedFilterValues?.faultDateRange.startDate || new Date(),
                          endDate: selectedFilterValues?.faultDateRange.endDate || new Date(),
                        })
                      }
                    },
                  }}
                />
              )}
            />
          </div>
          {showCustomFaultDate && (
            <div className='flex flex-row justify-between  items-end '>
              <div className='w-[110px]'>
                <div className='flex flex-col gap-0.5 items-start '>
                  <div>
                    <label
                      htmlFor='faultDateFrom'
                      className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1 '
                    >
                      From
                    </label>
                  </div>
                  <div className=''>
                    <Controller
                      name='faultDateRange.startDate'
                      control={control}
                      render={({ field }) => (
                        <DatePickerControl
                          className=''
                          datePickerProps={{
                            selected: field.value,

                            onChange: (date: Date | null) => field.onChange(date as Date),
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className='mx-0.5 w-4 h-4 my-3'>
                <ChevronRightIcon className='' />
              </div>

              <div className='w-[110px]'>
                <div className='flex flex-col gap-0.5 items-start'>
                  <label
                    htmlFor='faultDateFilterTo'
                    className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1 '
                  >
                    To
                  </label>
                  <div className=''>
                    <Controller
                      name='faultDateRange.endDate'
                      control={control}
                      render={({ field }) => (
                        <DatePickerControl
                          className=''
                          datePickerProps={{
                            selected: field.value,
                            maxDate: new Date(),
                            onChange: (date: Date | null) => field.onChange(date as Date),
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {errors.faultDateRange && (
            <>
              <p className='text-xs text-red-500'>{errors.faultDateRange?.startDate?.message}</p>
              <p className='text-xs text-red-500'>{errors.faultDateRange?.endDate?.message}</p>
            </>
          )}
        </div>
        {/* Buttons */}
        <div className='filter-form-footer'>
          <input
            className='filter-form-reset-button'
            type='reset'
            disabled={isSubmitting}
            onClick={() => {
              onReset()
            }}
          />
          <input
            className='filter-form-apply-button'
            type='submit'
            value='Apply'
            // disabled={!isDirty || !isValid || isSubmitting}
          />
        </div>
      </form>

      <DevTool control={control} />
    </div> // opening div
  )
}

// export default ServiceFaultsFilterForm
export default withErrorBoundary(memo(ServiceFaultsFilterForm), {
  FallbackComponent: ErrorPage,
})
