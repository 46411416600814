import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react'
import Each from 'components/atom/Each'
import CalenderDate from './CalenderDate'
import CalenderHeader from './CalenderHeader'
import CalenderWeek from './CalenderWeek'
import { CalenderDateType, CalenderDayType, CalenderType } from './CalenderType'
import {
  classNames,
  dayDateToDate,
  debounce,
  deselectDateFromRange,
  generateDateRange,
  returnDaysInMonth,
  selectedDateIsInRange,
} from './CalenderUtils'

const Calender = forwardRef<unknown, CalenderType>(
  (
    {
      events,
      render,
      dayContainerClassName,
      title,
      onChange,
      date,
      shouldShowFilter,
      onFilter,
      onReset,
    },
    ref,
  ) => {
    // State for days in the month
    const [days, setDays] = useState(
      date
        ? returnDaysInMonth(date.getMonth(), date.getFullYear())
        : returnDaysInMonth(new Date().getMonth(), new Date().getFullYear()),
    )
    // State for filter status
    const [hasFilter, setHasFilter] = useState(false)
    // State for mouse down status
    const [isMouseDown, setIsMouseDown] = useState(false)
    // State for date filter
    const [dateFilter, setDateFilter] = useState<CalenderDateType[]>([])

    // Get the selected month
    const selectedMonth = days.find((day: CalenderDayType) => day.isCurrentMonth)
    const selectedDate = dayDateToDate(selectedMonth?.date as string)

    // Function to set event date
    const setEventDate = (month: number, year: number) => {
      onChange?.(new Date(year, month, 1))
      setDays(returnDaysInMonth(month, year))
    }

    // Function to apply filter
    const applyFilter = () => {
      setHasFilter(true)
      onFilter?.(dateFilter.map((d) => dayDateToDate(d as string)))
    }

    // Function to clear filter
    const clearFilter = useCallback(() => {
      setDateFilter([])
      setHasFilter(false)
      onReset?.()
    }, [onReset])

    // Function to go to current month
    const goToCurrentMonth = () => {
      setEventDate(new Date().getMonth(), new Date().getFullYear())
    }

    // Function to go to previous month
    const goToPreviousMonth = () => {
      setEventDate(selectedDate.getMonth() - 1, selectedDate.getFullYear())
    }

    // Function to go to next month
    const goToNextMonth = () => {
      setEventDate(selectedDate.getMonth() + 1, selectedDate.getFullYear())
    }

    // Check if the selected date is in the current month
    const isCurrentMonth =
      selectedDate.getMonth() === new Date().getMonth() &&
      selectedDate.getFullYear() === new Date().getFullYear()

    // Expose clearFilter function to parent component
    useImperativeHandle(
      ref,
      () => ({
        clearFilter,
      }),
      [clearFilter],
    )

    // Function to handle date selection
    const onDateSelect = (day: CalenderDayType) => {
      setHasFilter(false)
      setDateFilter((pre: CalenderDateType[]) => {
        const arrayOfDate = Array.from(new Set<CalenderDateType>([...pre, day.date])).sort((a, b) =>
          dayDateToDate(a as string).getTime() >= dayDateToDate(b as string).getTime() ? 1 : -1,
        )

        if (pre.length > 0) {
          const generatedDateRangeArray = generateDateRange(
            arrayOfDate[0],
            arrayOfDate[arrayOfDate.length - 1],
          )
          return deselectDateFromRange(day.date, generatedDateRangeArray)
        }
        return arrayOfDate
      })
    }
    // Add mouseup event listener
    useEffect(() => {
      const handleMouseUp = () => setIsMouseDown(false)
      window.addEventListener('mouseup', handleMouseUp)
      return () => window.removeEventListener('mouseup', handleMouseUp)
    }, [])

    // Debounce onDateSelect function
    const debouncedOnDateSelect = debounce(onDateSelect, 10)

    // Render Calender component
    return (
      <div className='flex h-full flex-col relative'>
        <CalenderHeader
          isCurrentMonth={isCurrentMonth} // Boolean indicating if the displayed month is the current month
          goToCurrentMonth={goToCurrentMonth} // Function to navigate to the current month
          goToPreviousMonth={goToPreviousMonth} // Function to navigate to the previous month
          goToNextMonth={goToNextMonth} // Function to navigate to the next month
          selectedDate={selectedDate} // The currently selected date
          selectedDay={selectedMonth as CalenderDayType} // The currently selected day, cast to the CalenderDayType
          dateFilter={dateFilter} // The current date filter
          shouldShowFilter={Boolean(shouldShowFilter)} // Boolean indicating if the filter should be shown
          hasFilter={hasFilter} // Boolean indicating if a filter is currently applied
          applyFilter={applyFilter} // Function to apply a filter
          clearFilter={clearFilter} // Function to clear the current filter
          title={title} // The title of the calendar
        />

        <div className=' flex flex-auto flex-col rounded-b-2xl'>
          <CalenderWeek />
          <div className='flex bg-gray-200 text-xs leading-6 text-gray-700 flex-auto rounded-b-2xl '>
            <div className=' w-full h-full grid grid-cols-7 grid-rows-6 gap-px relative  '>
              <Each
                mapper={days} // The array of days to map over
                render={(day: CalenderDayType, i: number) => {
                  // Function to render each day
                  // Get the event for the current day
                  const event = events?.[day.date]
                  // Check if the current day is selected or within the selected range
                  const isDateOrDateRangeSelected =
                    dateFilter.includes(day.date) ||
                    (dateFilter.length > 0 &&
                      selectedDateIsInRange(
                        dateFilter[0],
                        dateFilter[dateFilter.length - 1],
                        day.date,
                      ))
                  // Return the CalenderDate component
                  return (
                    <div
                      tabIndex={0}
                      role='button'
                      key={day.date} // Use the date as the key
                      onMouseDown={() => shouldShowFilter && setIsMouseDown(true)} // Set isMouseDown to true when the mouse button is pressed
                      onMouseOver={() => {
                        // If the filter should be shown and the mouse button is pressed, call the debounced onDateSelect function
                        if (shouldShowFilter && isMouseDown) {
                          debouncedOnDateSelect(day)
                        }
                      }}
                      onFocus={() => {
                        // If the filter should be shown and the mouse button is pressed, call the debounced onDateSelect function
                        if (shouldShowFilter && isMouseDown) {
                          debouncedOnDateSelect(day)
                        }
                      }}
                      onMouseUp={() => shouldShowFilter && setIsMouseDown(false)} // Set isMouseDown to false when the mouse button is released
                    >
                      <CalenderDate
                        day={day} // Pass the day object as a prop
                        onDateSelect={onDateSelect} // Pass the onDateSelect function as a prop
                        shouldShowFilter={Boolean(shouldShowFilter)} // Convert shouldShowFilter to a boolean and pass it as a prop
                        className={classNames(
                          // Generate the CSS classes
                          isDateOrDateRangeSelected
                            ? 'bg-c-light-blue-4' // If the date is selected, use the light blue background
                            : day.isCurrentMonth
                            ? 'bg-white hover:bg-gray-100' // If the date is in the current month, use the white background
                            : 'bg-gray-100 text-gray-500 hover:bg-gray-100', // Otherwise, use the gray background and text

                          i === 35 ? 'rounded-bl-2xl' : '', // If the index is 35, round the bottom left corner
                          i === 41 ? 'rounded-br-2xl' : '', // If the index is 41, round the bottom right corner
                          isMouseDown ? 'cursor-grabbing' : 'cursor-pointer', // Change the cursor based on whether the mouse button is pressed
                          'relative px-3 h-full py-2 w-full  group/day select-none ', // Add some general styles
                          dayContainerClassName ?? '', // Add any additional classes
                        )}
                      >
                        {/* <div
                          className={clsx(
                            {
                              'scale-100': isDateOrDateRangeSelected,
                              'scale-0': !isDateOrDateRangeSelected,
                            },
                            'opacity-20  bg-c-blue h-full w-full absolute top-0 right-0 transition-all duration-100 ease-in-out transform delay-75 ',
                          )}
                        /> */}
                        {/* Render the day and event */}
                        {render({ day, events, event })}
                      </CalenderDate>
                    </div>
                  )
                }}
              />
            </div>
          </div>
        </div>
      </div>
    )
  },
)

export default Calender

Calender.displayName = 'Calender'

Calender.defaultProps = {
  dayContainerClassName: '',
  onChange: () => {},
  date: undefined,
  title: <div />,
  shouldShowFilter: false,
  onFilter: () => {},
  onReset: () => {},
}
