import React, { useContext, useEffect, useState } from 'react'
import PageTitle from 'pages/common/PageTitle'
import { MailIcon } from 'assets/icons'
import { useHelpAndSupport } from 'data/HelpAndSupportDetailsHook'
import { UserAccountContext } from 'contexts/UserAccountContext'
import { useCustomerList } from 'data/CustomerListHook'
import { useLocation, useSearchParams } from 'react-router-dom'
import { IsUserGlobalScope } from 'utils/UserDataUtils'
import { useGenericEventHandler } from 'data/GenericEventHandler'
import NewTabIcon from 'assets/icons/NewTabIcon'
import { useThemeHook } from 'data/ThemeHook'
import clsx from 'clsx'
import Markdown from 'react-markdown'

function Help(): JSX.Element {
  const location = useLocation()
  const [loader, setLoader] = useState(true)
  const redirectPageURL = `${location.pathname}${location.search}`
  const { state: userInfo } = useContext(UserAccountContext)
  const isGlobalUser = IsUserGlobalScope(userInfo)

  const [text, setText] = useState<unknown>()

  useEffect(() => {
    setLoader(true)
    document.title = `Help`
    ;(async () => {
      const response = await fetch(process.env?.REACT_APP_HELP_URL as string)
      const data = await response.blob()
      setLoader(false)
      setText(await data.text())
    })()
  }, [])

  const [
    searchParams,
    // setSearchParams
  ] = useSearchParams()
  const { theme } = useThemeHook()
  const selectedCustomerParam: string = isGlobalUser
    ? searchParams.get('c') || ''
    : userInfo?.user.customerId
  const { genericEventHandler } = useGenericEventHandler()
  useEffect(() => {
    document.title = 'Help and Support'
  }, [])

  // const setCustomerParam = (customerId: string) => {
  //   if (isGlobalUser) {
  //     searchParams.set('c', customerId)
  //     setSearchParams(searchParams, { replace: true })
  //   }
  // }

  const {
    // data: customerList,
    // isLoading: isCustomerListLoading,
    isError: isCustomerListError,
    error: customerListError,
  } = useCustomerList(redirectPageURL, isGlobalUser, userInfo?.user?.id !== '' && isGlobalUser)

  // const uniqueCustomerList = customerList?.filter((item) => item !== allCustomerOption)

  // useEffect(() => {
  //   if (
  //     isGlobalUser &&
  //     selectedCustomerParam === '' &&
  //     uniqueCustomerList &&
  //     uniqueCustomerList?.length > 0
  //   )
  //     setCustomerParam(uniqueCustomerList[0].id as string)
  // }, [uniqueCustomerList])

  const {
    // data: helpAndSupportData,
    // isLoading: isHelpAndSupportDataLoading,
    isError: isHelpAndSupportDataError,
    error: helpAndSupportDataError,
  } = useHelpAndSupport(selectedCustomerParam, selectedCustomerParam !== '')

  useEffect(() => {
    if (isCustomerListError) {
      genericEventHandler({
        onlyTrack: true,
        severity: 'error',
        error: customerListError,
        message: customerListError?.message || 'Error while fetching customer list',
        extraData: {
          component: 'Help',
          action: 'fetching customer list',
        },
      })
    }
    if (isHelpAndSupportDataError) {
      genericEventHandler({
        onlyTrack: true,
        severity: 'error',
        error: helpAndSupportDataError,
        message: helpAndSupportDataError?.message || 'Error while fetching help and support data',
        extraData: {
          component: 'Help',
          action: 'fetching help and support data',
        },
      })
    }
  }, [isCustomerListError, customerListError, isHelpAndSupportDataError, helpAndSupportDataError])

  return (
    <div className='w-full h-full flex flex-col font-poppins relative'>
      <div className='px-4 py-4 w-full bg-c-light-blue-4 flex flex-row'>
        <div className='w-full'>
          <PageTitle title='Help' />
        </div>
        {isGlobalUser && (
          <div id='cust-selector' className='w-56 sm:w-80'>
            {isCustomerListError && (
              <p className='text-c-red-dark text-3xs px-5 py-0.5 font-semibold'>
                Error while fetching customer
              </p>
            )}
            {/* <SelectControlWithLabel
              isLoading={isCustomerListLoading}
              className='w-56 sm:w-80 h-12'
              selectControlProps={{
                labelName: 'Customer',
                options: uniqueCustomerList,
                getOptionLabel: (option: iCustomerList) => option.name,
                getOptionvalue: (option: iCustomerList) => option.id,
                value:
                  selectedCustomerParam !== ''
                    ? uniqueCustomerList?.find((c) => c.id === selectedCustomerParam)
                    : uniqueCustomerList?.[0],
                isMulti: false,
                isSearchable: false,
                onChange: (selectedOption: iCustomerList) => {
                  setCustomerParam(selectedOption.id as string)
                },
              }}
            /> */}
            <div className='float-right'>
              <p className='text-c-dark-blue-1 font-bold mb-1 text-sm '>Support links </p>
              <a
                target='_blank'
                href='mailto:mailto:TechnicalSupport@Crowcon.com'
                className='text-c-dark-blue-2 hover:text-underlined hover:text-c-blue hover:underline font-semibold text-xs mt-2'
                rel='noreferrer noopener'
              >
                <p className='flex items-center group/link-1'>
                  Technical Support{' '}
                  <MailIcon
                    width={18}
                    height={18}
                    className='group-hover/link-1:visible invisible'
                    stroke={theme.extend.colors['c-blue']}
                  />
                </p>
              </a>
              <a
                target='_blank'
                href='https://www.crowcon.com'
                className='text-c-dark-blue-2 hover:text-underlined hover:text-c-blue  hover:underline font-semibold text-xs mt-2'
                rel='noreferrer noopener'
              >
                <p className='flex items-center group/link-1'>
                  Crowcon website{' '}
                  <NewTabIcon
                    className='group-hover/link-1:visible invisible'
                    fill={theme.extend.colors['c-blue']}
                  />
                </p>
              </a>
            </div>
          </div>
        )}
      </div>
      {isHelpAndSupportDataError ? (
        <div>
          <p className='text-c-red-dark text-xs p-5 font-semibold'>
            Error while fetching support contacts..
          </p>
        </div>
      ) : (
        <div className='h-[calc(100vh-95px)]'>
          {/* <p className='px-4 pt-8 h-[71px] bg-c-light-blue-2 text-base font-bold'>
            Support Material Repository
          </p>
          <div className='px-4 pt-4 pb-[52px] h-auto text-[13px] font-semibold'>
            <p className='mb-7'>
              Click here to visit the Crowcon Connect Support Material Repository on the Crowcon
              website.
            </p>
            <a
              href={helpAndSupportData?.supportRepoLink}
              className='text-c-white bg-c-dark-blue-1 font-bold text-sm py-2.5 px-4 h-10 w-[185px] rounded-3xl  hover:bg-c-dark-blue-2  active:bg-c-dark-blue-3'
            >
              <button type='button'>
                <div className='flex flex-row gap-2  text-ellipsis'>
                  <p> Support Material </p> <LinkIcon />
                </div>
              </button>
            </a>
          </div>
          <p className='px-4 py-5 h-[71px] bg-c-light-blue-2 text-base font-bold pt-8'>
            Support Contacts
          </p>
          <div className='px-4 py-4 flex flex-col gap-6 h-auto'>
            <p className=' text-[13px] font-semibold h-auto pr-4'>
              If you have any further questions not answered in the provided support material, do
              not hesitate to get in touch with our support teams, as detailed below.
            </p>
            <div className='flex flex-col pt-1 md:flex-row gap-4 md:gap-12 md:pt-3 lg:gap-14'>
              <div className='h-auto flex flex-col gap-1'>
                <p className='text-[13px] font-normal mb-4'>
                  For Pre-sales support, ordering help, Order tracking, order management, spares
                  quotes.
                </p>
                <a
                  href={`mailto:${helpAndSupportData?.customerSupportEmail}`}
                  className='text-c-white bg-c-dark-blue-1 font-bold text-xs p-2.5 h-9 max-w-max rounded-3xl  hover:bg-c-dark-blue-2  active:bg-c-dark-blue-3'
                >
                  <div className='flex flex-row gap-1 max-w-max'>
                    <MailIcon className='-mt-0.5' />
                    <p className='w-auto'>
                      {helpAndSupportData?.customerSupportEmail.toLocaleLowerCase()}
                    </p>
                  </div>
                </a>
              </div>
              <div className='h-auto pt-3 md:pt-0 flex flex-col gap-3 md:gap-1 lg:pr-3'>
                <p className='text-[13px] font-normal mb-4'>
                  For Device Support, Portal queries, Calibration support, Comms kit & Software
                  support
                </p>
                <a
                  href={`mailto:${helpAndSupportData?.techSupportEmail}`}
                  className='text-c-white bg-c-dark-blue-1 font-bold text-xs p-2.5 h-9 w-64 rounded-3xl  hover:bg-c-dark-blue-2  active:bg-c-dark-blue-3'
                >
                  <div className='flex flex-row gap-1  text-ellipsis'>
                    <MailIcon className='-mt-0.5' />
                    <p>{helpAndSupportData?.techSupportEmail.toLocaleLowerCase()}</p>
                  </div>
                </a>
              </div>
            </div>
          </div> */}
          <div
            className={clsx(
              {
                'opacity-0 -z-50 hidden': !loader,
                'opacity-50 z-50 block': loader,
              },
              'absolute w-full h-full bg-slate-200 transition-opacity duration-300 ease-out',
            )}
          >
            <div className='animate-pulse absolute right-1/2 bottom-1/2  transform translate-x-1/2 translate-y-1/2 '>
              <div className='h-8 w-8 animate-spin rounded-full border-4 border-solid border-c-orange border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]' />
            </div>
          </div>

          {/* <iframe
            src='https://scribehow.com/page-embed/Crowcon_Connect_User_Guide__JxZdbzCdTp66kH50p8oODw'
            width='100%'
            className='h-full'
            title='Help'
            onLoad={() => setLoader(false)}
            allowFullScreen
          /> */}
          <div
            className={`p-20 ${
              loader ? 'h-screen' : ''
            }  bg-c-white mx-auto rounded-2xl shadow my-10 container`}
          >
            <Markdown
              components={{
                h1: ({ children }) => (
                  <h1 className='text-2xl border-b-1 border-c-light-blue-1 pb-5 text-c-blue font-bold my-10'>
                    {children}
                  </h1>
                ),
                h2: ({ children }) => <h2 className='text-xl font-bold'>{children}</h2>,
                h3: ({ children }) => <h3 className='text-lg font-bold'>{children}</h3>,
                h4: ({ children }) => <h4 className='text-base font-bold'>{children}</h4>,
                h5: ({ children }) => <h5 className='text-sm font-bold'>{children}</h5>,
                h6: ({ children }) => <h6 className='text-xs font-bold'>{children}</h6>,
                p: ({ children }) => <p className='text-base m-5'>{children}</p>,
                a: ({ children, ...props }) => (
                  <a className='text-blue-500 hover:underline' {...props}>
                    {children}
                  </a>
                ),
                ul: ({ children }) => <ul className='list-disc list-inside'>{children}</ul>,
                ol: ({ children }) => <ol className='list-decimal list-inside'>{children}</ol>,
                li: ({ children }) => <li className='text-base'>{children}</li>,
                blockquote: ({ children }) => (
                  <blockquote className='text-base'>{children}</blockquote>
                ),
                code: ({ children }) => <code className='text-base'>{children}</code>,
                pre: ({ children }) => <pre className='text-base'>{children}</pre>,
                table: ({ children }) => <table className='text-base'>{children}</table>,
                img: ({ ...props }) => (
                  <div className='flex justify-center items-center my-10'>
                    <img alt='' className=' drop-shadow-xl rounded-2xl self-center' {...props} />
                  </div>
                ),
                th: ({ children }) => <th className='text-base'>{children}</th>,
                tr: ({ children }) => <tr className='text-base'>{children}</tr>,
                td: ({ children }) => <td className='text-base'>{children}</td>,
              }}
            >
              {text as string}
            </Markdown>
          </div>
        </div>
      )}
    </div>
  )
}
export default Help
