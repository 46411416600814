import React, { useState, createContext, useEffect, useMemo } from 'react'

import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  RowData,
  Updater,
  VisibilityState,
  Header,
  Column,
} from '@tanstack/react-table'
import { useIntl } from 'react-intl'
import { DateRange, IFilterValues } from 'forms/FormModelInterface'
import { useLocation } from 'react-router-dom'
import Each from 'components/atom/Each'
import clsx from 'clsx'
import Show from 'components/atom/Show'
import TableNoDataFound from 'pages/common/TableNoDataFound'
import FilterSummary from './tsTableComponents/view/FilterSummary'
import TsTableHeader from './tsTableComponents/view/TsTableHeader'
import TsTableFooter from './tsTableComponents/view/TsTableFooter'
import { tsTableProps } from './tsTableProps'
import { TableContext, TableContextType } from './TableContext'
import TableHeader from './tsTableComponents/view/TableHeader'
import TableRow from './tsTableComponents/view/TableRow'
import DraggableWrapper, {
  DraggableWrapperChildrenProps,
} from './tsTableComponents/wrappers/DraggableWrapper'
import ScrollableWrapper from './tsTableComponents/wrappers/ScrollableWrapper'
import {
  ColumnVisibility,
  ExportTableDataButton,
  FilterTableDataButton,
} from './tsTableComponents/buttons'

declare module '@tanstack/table-core' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    filterComponent?: (props: unknown) => JSX.Element
    cellClassName?: string
    headerClassName?: string
    colClassName?: string
    fixedColumn?: boolean
    isCustomHeader?: boolean
    shouldDraggable?: boolean
    isLastColumn?: boolean
    forcedHidden?: boolean
    resizable?: boolean
    sortable?: boolean
    shouldGlobalFilter?: boolean
    customHeaderComponent?: (props: unknown) => JSX.Element
  }
}

// const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
//   // Rank the item
//   const itemRank = rankItem(row.getValue(columnId), value)

//   // Store the itemRank info
//   addMeta({
//     itemRank,
//   })

//   // Return if the item should be filtered in/out
//   return itemRank.passed
// }

export const ResetFilterFnContext = createContext<() => void>(() => {})
export const ApplyFilterFnContext = createContext<(filterValues: IFilterValues[]) => void>(() => {})

function TsTable<T>(props: tsTableProps<T>): JSX.Element {
  const {
    data,
    columns,
    showGlobalActionButton,
    showGlobalFilter,
    globalActionButton,
    getRowCanExpand,
    renderSubComponent,
    updateFilteredData,
    filterValues,
    onShowFilter,
    resetFilter,
    dataIsLoading = false,
    minDisplayRows = 10,
    manualPagination = false,
    manualPageIndex = 0,
    manualPageSize = 10,
    manualTotalRecordCount = 0,
    onManualPageChange,
    onManualPageSizeChange,
    setShowExportDialog,
    defaultSortedColumKey,
    columnVisibility: columnVisibilityProp,
    renderTabs = () => null,
    showColumnVisibilityPopover = true,
    showExportModal = true,
    showFilterForm = true,
  } = props
  // const [data, setData] = useState<T[]>(inputData)
  const location = useLocation()
  const intl = useIntl()
  const [globalFilter, setGlobalFilter] = useState('')
  const [columnOrder, setColumnOrder] = React.useState<string[]>(
    () =>
      JSON.parse(localStorage.getItem('columnOrder') as string)?.[location.pathname] ||
      columns.map((c) => c.id),
  )
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(
    JSON.parse(localStorage.getItem('columnVisibility') as string)?.[location.pathname] ||
      (columnVisibilityProp as VisibilityState),
  )

  const table = useReactTable({
    data,
    columns,
    getRowCanExpand,
    state: {
      globalFilter,
      columnVisibility,
      columnOrder,
    },
    initialState: {
      pagination: {
        pageSize: manualPagination ? manualPageSize : 10,
      },
    },
    getColumnCanGlobalFilter: (col: Column<T>) => {
      if (
        col.columnDef.meta?.shouldGlobalFilter === undefined ||
        col.columnDef.meta?.shouldGlobalFilter
      ) {
        return true
      }
      return false
    },
    columnResizeMode: 'onChange',
    enableColumnResizing: true,
    onColumnOrderChange: setColumnOrder,
    manualPagination,
    onColumnVisibilityChange: setColumnVisibility,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    getExpandedRowModel: getExpandedRowModel(),
    // onColumnVisibilityChange:setColumnVisibility,
    // onColumnFiltersChange: onColumnFilter,
    debugTable: false,
    debugHeaders: false,
    debugColumns: false,
  })
  const [showFilterSummary, setShowFilterSummary] = useState(false)
  const [filteredColValues, setFilteredColValues] = useState<IFilterValues[]>([])

  useEffect(() => {
    if (manualPagination) {
      if (filterValues && filterValues?.length > 0) {
        setShowFilterSummary(true)
      } else {
        setShowFilterSummary(false)
      }
      const colFilterValues: IFilterValues[] = []
      filterValues?.forEach((columnFilter) => {
        const colHeader =
          table.getColumn(columnFilter.columnId)?.columnDef.header?.toString() ??
          columnFilter.columnId
        const dtValue = columnFilter.value as DateRange
        if (dtValue?.startDate && dtValue?.endDate) {
          const stDate = intl.formatDate(dtValue.startDate as Date)
          const endDate = intl.formatDate(dtValue.endDate as Date)
          colFilterValues.push({
            columnId: colHeader,
            value: `${stDate} to ${endDate}`,
          })
        } else {
          colFilterValues.push({
            columnId: colHeader,
            value: columnFilter.value ? columnFilter.value.toString() : '',
          })
        }
      })
      setFilteredColValues(colFilterValues)
    } else if (table.getState().columnFilters?.length > 0) {
      setShowFilterSummary(true)
      const colFilterValues: IFilterValues[] = []
      table.getState().columnFilters?.forEach((columnFilter) => {
        const colHeader =
          table.getColumn(columnFilter.id)?.columnDef.header?.toString() ?? columnFilter.id
        const dtValue = columnFilter.value as DateRange
        if (dtValue?.startDate && dtValue?.endDate) {
          const stDate = intl.formatDate(dtValue.startDate as Date)
          const endDate = intl.formatDate(dtValue.endDate as Date)
          colFilterValues.push({
            columnId: colHeader,
            value: `${stDate} to ${endDate}`,
          })
        } else {
          colFilterValues.push({
            columnId: colHeader,
            value: columnFilter.value ? columnFilter.value.toString() : '',
          })
        }
      })
      setFilteredColValues(colFilterValues)
    } else {
      setShowFilterSummary(false)
    }
  }, [table.getState().columnFilters, filterValues, manualPagination])

  const filterTable = (inputFilterValues: IFilterValues[]) => {
    // Object.keys(filterValues)
    inputFilterValues.forEach((inputFilterValue) => {
      table.getColumn(inputFilterValue.columnId)?.setFilterValue(inputFilterValue?.value)
    })
    // Object.keys()
    // table.getColumn()?.setFilterValue("W222")
  }

  useEffect(() => {
    if (filterValues && !dataIsLoading && !manualPagination) {
      filterTable(filterValues)
    }
  }, [filterValues, dataIsLoading])

  useEffect(() => {
    const filteredRows = table.getFilteredRowModel().rows.map((row) => row.original as T)
    updateFilteredData(filteredRows)
    // setTableFilteredRows?.(filteredRows)
  }, [table.getFilteredRowModel().rows.length])

  useEffect(() => {
    const handleFilterTableEvent = (event: CustomEvent<IFilterValues[]>) => {
      filterTable(event.detail)
    }

    const handleResetTableEvent = () => {
      table.resetColumnFilters(true)
    }

    window.addEventListener('filterTable', handleFilterTableEvent as EventListener)
    window.addEventListener('resetTable', handleResetTableEvent as EventListener)

    return () => {
      window.removeEventListener('filterTable', handleFilterTableEvent as EventListener)
      window.removeEventListener('resetTable', handleResetTableEvent as EventListener)
    }
  }, [])

  const filterFns = React.useMemo(() => {
    // define resetFilters and applyFilters here
    const resetTableFilters = () => {
      table.resetColumnFilters(true)
    }

    const applyTableFilters = (filterValues: IFilterValues[]) => {
      // Object.keys(filterValues)
      filterValues.forEach((filterValue) => {
        table.getColumn(filterValue.columnId)?.setFilterValue(filterValue?.value)
      })

      // Object.keys()
      // table.getColumn()?.setFilterValue("W222")
    }

    return { resetTableFilters, applyTableFilters }
  }, [table]) // assuming table is a dependency

  // const colList :{id:string,checked:boolean,header:string}[]=[];
  // table.getAllLeafColumns().forEach((col)=>{
  //   colList.push({
  //     id:col.id,
  //     checked:true,
  //     header:col.columnDef.header?.toString()??'Controls'
  //   });
  // });

  // const AllColumnVisible=true;

  // Use the useEffect hook to set the default sorting of the table
  useEffect(() => {
    // If the table exists and a defaultSortedColumKey is provided
    if (table && defaultSortedColumKey) {
      // Set the sorting of the table to sort by the defaultSortedColumKey in ascending order
      table.setSorting([{ id: defaultSortedColumKey, desc: false }])
    }
    // The effect depends on defaultSortedColumKey and table, so it runs whenever either of these change
  }, [defaultSortedColumKey, table])

  // Use the useMemo hook to memoize the context value
  const contextValue = useMemo(
    () => ({
      // The context value is an object with the table, columnOrder, and columnVisibility
      table,
      columnOrder,
      columnVisibility,
    }),
    // The context value depends on table, columnOrder, and columnVisibility, so it's recalculated whenever any of these change
    [table, columnOrder, columnVisibility],
  )

  return (
    <TableContext.Provider value={contextValue as TableContextType<T>}>
      <div className='w-full flex flex-col'>
        <div className='flex-shrink  bg-c-light-blue-1  '>
          <TsTableHeader
            globalFilterValue={globalFilter}
            setGlobalFilter={(value: string) => setGlobalFilter(String(value))}
            showGlobalFilter={showGlobalFilter}
            showGlobalActionButton={showGlobalActionButton}
            globalActionButton={globalActionButton}
            pageSize={table.getState().pagination.pageSize}
            setPageSize={(e: Updater<number>) => table.setPageSize(e)}
            setPageIndex={(e: Updater<number>) => table.setPageIndex(e)}
            previousPage={() => table.previousPage()}
            nextPage={() => table.nextPage()}
            pageIndex={table.getState().pagination.pageIndex}
            pageCount={table.getPageCount()}
            canPreviousPage={table.getCanPreviousPage()}
            canNextPage={table.getCanNextPage()}
            totalRecords={table.getCoreRowModel().rows.length}
            onShowFilter={onShowFilter ?? onShowFilter}
            setShowExportDialog={(val) => setShowExportDialog(val)}
            ColumnVisibilityComponent={
              showColumnVisibilityPopover ? <ColumnVisibility /> : undefined
            }
            ExportTableDataComponent={
              table.getRowModel().rows.length !== 0 && showExportModal ? (
                <ExportTableDataButton setShowExportDialog={setShowExportDialog} />
              ) : undefined
            }
            FilterTableDataComponent={
              showFilterForm ? (
                <FilterTableDataButton toggleFilterFormVisibility={onShowFilter as () => void} />
              ) : undefined
            }
            // onFilterTable={applyTableFilters}
            // onResetFilters={resetTableFilters}
            // TableFilterComponent={tableFilterComponent}
          />
        </div>

        {showFilterSummary && (
          <div className='flex-shrink w-full'>
            <FilterSummary
              filterCount={table.getFilteredRowModel().rows.length}
              onClearFilters={() => {
                if (resetFilter) {
                  resetFilter()
                }
              }}
              filteredData={filteredColValues}
            />
          </div>
        )}

        {renderTabs && renderTabs()}
        <ScrollableWrapper containerId='table-wrapper'>
          <div className=' bg-c-white overflow-x-auto select-none items' id='table-wrapper'>
            <table
              className='border-collapse border-spacing-y-1 w-full overflow-x-auto '
              style={{
                minWidth:
                  !dataIsLoading && table.getRowModel().rows.length !== 0
                    ? table.getCenterTotalSize()
                    : 'auto',
              }}
            >
              <colgroup>
                <Each
                  mapper={table.getHeaderGroups()}
                  render={(headerGroup) => (
                    <Each
                      mapper={headerGroup.headers}
                      render={(header) => (
                        <col
                          key={header.id}
                          className={clsx(header.column.columnDef.meta?.colClassName)}
                        />
                      )}
                    />
                  )}
                />
              </colgroup>
              <thead id='table-thead' className='h-full'>
                <Show>
                  {/* Show the table only when there are rows */}
                  <Show.When isTrue={Boolean(table.getRowModel().rows.length !== 0)}>
                    {/* Iterate over each header group in the table */}
                    <Each
                      mapper={table.getHeaderGroups()}
                      render={(headerGroup, index) => (
                        // Wrap each header group in a DraggableWrapper to enable drag and drop
                        <DraggableWrapper
                          key={headerGroup.id}
                          onDragEnd={setColumnOrder} // Function to call when a drag ends
                          label={location.pathname} // Label to use for storing column order in local storage
                          headers={headerGroup.headers as Header<unknown, unknown>[]}
                        >
                          {/* Render a table row for each header group */}
                          {({ drop, allowDrop, drag }: DraggableWrapperChildrenProps) => (
                            <tr onDrop={drop} onDragOver={allowDrop}>
                              {/* Set up drop and drag over event handlers */}
                              {/* Iterate over each header in the header group */}
                              <Each
                                mapper={headerGroup.headers}
                                render={(header, i) => (
                                  //  Render a TableHeader component for each header
                                  <TableHeader
                                    drag={drag} // Function to call when a drag starts
                                    index={i}
                                    groupHeaderIndex={index}
                                    key={header.id}
                                  />
                                )}
                              />
                            </tr>
                          )}
                        </DraggableWrapper>
                      )}
                    />
                  </Show.When>
                </Show>
              </thead>
              <tbody className='w-full'>
                <Each
                  mapper={table.getRowModel().rows}
                  render={(row, index) => (
                    <>
                      <TableRow index={index} getRowCanExpand={getRowCanExpand} />
                      <Show>
                        <Show.When isTrue={row.getIsExpanded()}>
                          <tr>
                            <td colSpan={row.getVisibleCells().length}>
                              {renderSubComponent && renderSubComponent({ row })}
                            </td>
                          </tr>
                        </Show.When>
                      </Show>
                    </>
                  )}
                />

                <Show>
                  <Show.When isTrue={dataIsLoading}>
                    <tr className=''>
                      <td colSpan={table.getAllColumns().length} className='h-420'>
                        <div className='h-420 flex flex-row justify-center items-center'>
                          <div className='h-8 w-8 animate-spin rounded-full border-4 border-solid border-c-orange border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]' />
                        </div>
                      </td>
                    </tr>
                  </Show.When>
                </Show>

                <Show>
                  <Show.When isTrue={table.getRowModel().rows.length === 0 && !dataIsLoading}>
                    <tr className=''>
                      <td colSpan={table.getAllColumns().length} className=' '>
                        <div className='flex flex-row justify-center h-420'>
                          <TableNoDataFound />

                          {/* <img
                src={NoDataImage}
                height={400}
                alt='No Data'
                className='h-420 w-1/2 mx-auto'
              /> */}
                        </div>
                      </td>
                    </tr>
                  </Show.When>
                </Show>

                {table.getRowModel().rows.length < minDisplayRows &&
                  table.getRowModel().rows.length > 0 &&
                  Array.from(Array(minDisplayRows - table.getRowModel().rows.length).keys()).map(
                    (i) => (
                      <tr key={i} className=''>
                        <td colSpan={table.getAllColumns().length} className='h-12'>
                          <div className='h-12 w-full bg-c-white'>{}</div>
                        </td>
                      </tr>
                    ),
                  )}
              </tbody>
            </table>
          </div>
        </ScrollableWrapper>

        <div className='h-[72px]  bg-c-light-blue-1 '>
          <TsTableFooter
            pageSize={
              manualPagination
                ? manualPageSize
                : Math.min(
                    table.getState().pagination.pageSize,
                    table.getFilteredRowModel().rows.length,
                  )
              // table.getState().pagination.pageSize
            }
            setPageSize={(e) => {
              if (manualPagination) {
                onManualPageSizeChange?.(e)
              } else {
                table.setPageSize(e)
              }
            }}
            setPageIndex={(e) => {
              if (manualPagination) {
                onManualPageChange?.(e)
              } else {
                table.setPageIndex(e)
              }
            }}
            previousPage={() => {
              if (manualPagination) {
                if (manualPageIndex > 0) {
                  onManualPageChange?.(manualPageIndex - 1)
                }
              } else {
                table.previousPage()
              }
            }}
            nextPage={() => {
              if (manualPagination) {
                onManualPageChange?.(manualPageIndex + 1)
              } else {
                table.nextPage()
              }
            }}
            pageIndex={manualPagination ? manualPageIndex : table.getState().pagination.pageIndex}
            pageCount={
              manualPagination
                ? Math.ceil(manualTotalRecordCount / manualPageSize)
                : table.getPageCount()
            }
            canPreviousPage={manualPagination ? manualPageIndex > 0 : table.getCanPreviousPage()}
            canNextPage={
              manualPagination
                ? manualPageIndex < manualTotalRecordCount / manualPageSize
                : table.getCanNextPage()
            }
            totalRecords={
              manualPagination ? manualTotalRecordCount : table.getFilteredRowModel().rows.length
            }
            isTableDataLoading={dataIsLoading}
          />
        </div>
      </div>
    </TableContext.Provider>
  )
}

export default TsTable
