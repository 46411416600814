import React, { useContext, useEffect, useState } from 'react'
import { IselectOptionProps } from 'forms/FormModelInterface'
import { matchPath, useLocation, useNavigate, useParams } from 'react-router-dom'
import Tabs, { TabType } from 'components/modules/Tabs/Tabs'
import { DeviceDetailContextActions, DeviceDetailsContext } from './DeviceDetailsContext'

const DeviceDetailsReportOptions: IselectOptionProps[] = [
  {
    label: 'Event Logs',
    value: 'eventlog',
  },
  {
    label: 'Gas Levels',
    value: 'gaslog',
  },
  {
    label: 'Assignment History',
    value: 'assignment-history',
  },
  {
    label: 'Service',
    value: 'service',
  },
  {
    label: 'Sync History',
    value: 'sync-history',
  },
  {
    label: 'Near Miss',
    value: 'near-miss',
  },
  {
    label: 'Bump History',
    value: 'bump-history',
  },
]

function DeviceDetailsReportSelector() {
  const navigate = useNavigate()
  const params = useParams()
  const deviceDetailsContext = useContext(DeviceDetailsContext)
  const { dispatch } = deviceDetailsContext

  const [selectedRouteOption, setSelectedRouteOption] = useState<IselectOptionProps>()

  const OnChangeReportSelector = (selectedOption: TabType) => {
    if (selectedOption) {
      dispatch({
        type: DeviceDetailContextActions.HideFilterForm,
      })
      navigate(`/web/devices/${params?.deviceSerialNumber}/${selectedOption.value}`)
    }
  }

  const location = useLocation()

  useEffect(() => {
    if (location.pathname) {
      const currentSelectedOption = DeviceDetailsReportOptions.find(
        (option) =>
          matchPath(`/web/devices/:deviceSerialNumber/${option.value}`, location.pathname) !== null,
      )
      if (currentSelectedOption) {
        setSelectedRouteOption(currentSelectedOption)
      } else {
        setSelectedRouteOption(DeviceDetailsReportOptions[0])
      }
    }
  }, [location, DeviceDetailsReportOptions[0]])

  return (
    <Tabs
      onChange={OnChangeReportSelector}
      selectedTab={selectedRouteOption as TabType}
      tabs={DeviceDetailsReportOptions as TabType[]}
    />
  )
}

export default DeviceDetailsReportSelector
