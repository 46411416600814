/*
TODO:
Refactoring required:
1. Column width for description - need to add max-width. Max-width needs to be calculated
based on available width and other columns width (also considering side nav status etc)



*/

import React, { useEffect, useMemo } from 'react'
import { ColumnDef, VisibilityState } from '@tanstack/react-table'
import { fromUnixTime } from 'date-fns'
import { FormattedDate, FormattedTime } from 'react-intl'
import { IFilterValues, IselectOptionProps } from 'forms/FormModelInterface'
import { DateRangeColumnFilterUnixTimeFn, FaultTypeFilter } from 'forms/FormUtils'
import TsTable from 'components/table/TsTable'
import Tooltip from 'components/atom/Tooltip'
import { useLocation } from 'react-router-dom'
import { withErrorBoundary } from 'react-error-boundary'
import ErrorPage from 'pages/common/ErrorPage'
import { iFaultData } from '../data/FaultsData'
import { ServiceFaultsColumnIds } from './ServiceFaultsPageUtils'

interface serviceFaultsTableProps {
  tableData?: iFaultData[]
  isLoading: boolean
  filterValues: IFilterValues[]
  updateFilteredData: (data: iFaultData[]) => void
  serialNumberOptions: IselectOptionProps[]
  deviceTypeOptions: IselectOptionProps[]
  faultTypeOptions: IselectOptionProps[]
  faultDescriptionOptions: IselectOptionProps[]

  onShowFilter: () => void
  onFilterFormSubmit: (filterValues: IFilterValues[]) => void
  onFilterFormReset: () => void
  setShowExportDialog: (val: boolean) => void
}

function ServiceFaultstable(tableDataProps: serviceFaultsTableProps): JSX.Element {
  const {
    tableData,
    filterValues,
    updateFilteredData,
    onFilterFormReset,
    onShowFilter,
    isLoading = false,
    setShowExportDialog,
  } = tableDataProps

  const location = useLocation()
  const tableColumns = useMemo<ColumnDef<iFaultData>[]>(
    () => [
      {
        id: ServiceFaultsColumnIds.deviceSerialNumber,
        header: 'Serial #',
        accessorKey: 'deviceSerialNumber',
        cell: (info) => {
          // const businessUnitName = (info.getValue() as iBusinessUnit).name
          const deviceSerialNumber = info.getValue() as string
          return deviceSerialNumber ? (
            <Tooltip
              id={`service-faults-device-serial-number-${deviceSerialNumber}`}
              showOnlyWhenTextIsTruncate
              tooltipText={deviceSerialNumber}
              toolTipClass='tooltip '
              className='font-normal text-2xs leading-5  text-ellipsis '
            >
              {deviceSerialNumber}
            </Tooltip>
          ) : (
            <div className='font-normal text-2xs leading-5  text-ellipsis '>-</div>
          )
        },
        meta: {},
        filterFn: 'includesString',
      },

      {
        id: ServiceFaultsColumnIds.customerAssetNumber,
        header: 'Asset #',
        accessorKey: 'customerAssetNumber',
        cell: (info) => {
          // const businessUnitName = (info.getValue() as iBusinessUnit).name
          const customerAssetNumber = info.getValue() as string
          return customerAssetNumber ? (
            <Tooltip
              id={`service-faults-customer-asset-number-${customerAssetNumber}`}
              showOnlyWhenTextIsTruncate
              tooltipText={customerAssetNumber}
              toolTipClass='tooltip '
              className='font-normal text-2xs leading-5  text-ellipsis '
            >
              {customerAssetNumber}
            </Tooltip>
          ) : (
            <div className='font-normal text-2xs leading-5  text-ellipsis '>-</div>
          )
        },
        meta: {},
        filterFn: 'includesString',
      },
      {
        id: ServiceFaultsColumnIds.faultDateTime,
        header: 'Date and time',
        accessorKey: 'timeStamp',
        cell: (info) => {
          const value: number = info.getValue() as number
          const date = fromUnixTime(value)
          return (
            <div className='font-poppins text-2xs leading-4 font-normal text-c-dark-blue-1'>
              <span className='mr-2'>
                <FormattedDate value={date} />
              </span>
              <span>
                <FormattedTime value={date} />
              </span>
              {/* <div>{date.toISOString()}</div> */}
            </div>
          )
        },
        meta: {},
        filterFn: DateRangeColumnFilterUnixTimeFn<iFaultData>,
      },

      {
        id: ServiceFaultsColumnIds.deviceType,
        header: 'Device',
        accessorKey: 'deviceType',
        cell: (info) => {
          const value: string = info.getValue() as string
          return <div className=' py-3 text-2xs font-normal leading-5  text-ellipsis '>{value}</div>
        },
        meta: {},
        filterFn: 'includesString',
      },
      {
        id: ServiceFaultsColumnIds.businessUnit,
        header: 'Business Unit',
        accessorKey: 'businessUnitName',
        cell: (info) => {
          // const businessUnitName = (info.getValue() as iBusinessUnit).name
          const businessUnitName = info.getValue() as string
          return businessUnitName ? (
            <Tooltip
              id={`service-faults-business-unit-${businessUnitName}`}
              showOnlyWhenTextIsTruncate
              tooltipText={businessUnitName}
              toolTipClass='tooltip '
              className='font-normal text-2xs leading-5  text-ellipsis '
            >
              {businessUnitName}
            </Tooltip>
          ) : (
            <div className='w-40 mr-2 h-5 bg-c-light-blue-1 rounded-lg animate-pulse' />
          )
        },
        meta: {},
        filterFn: 'includesString',
      },
      {
        id: ServiceFaultsColumnIds.deviceUserName,
        header: 'Assigned To',
        accessorKey: 'deviceUserName',
        cell: (info) => {
          // const businessUnitName = (info.getValue() as iBusinessUnit).name
          const deviceUserName = info.getValue() as string
          return deviceUserName ? (
            <Tooltip
              id={`service-faults-device-user-name-${deviceUserName}`}
              showOnlyWhenTextIsTruncate
              tooltipText={deviceUserName}
              toolTipClass='tooltip '
              className='font-normal text-2xs leading-5  text-ellipsis '
            >
              {deviceUserName}
            </Tooltip>
          ) : (
            <div className='font-normal text-2xs leading-5  text-ellipsis '>-</div>
          )
        },
        meta: {},
        filterFn: 'includesString',
      },

      {
        id: ServiceFaultsColumnIds.faultType,
        header: 'Fault Type',
        accessorKey: 'eventType',
        cell: (info) => {
          const value: string = info.getValue() as string
          return (
            <Tooltip
              id={`service-faults-fault-type-${value}`}
              showOnlyWhenTextIsTruncate
              tooltipText={value}
              toolTipClass='tooltip'
              className='font-poppins text-2xs leading-4  text-ellipsis font-normal  text-c-dark-blue-1 '
            >
              {value}
            </Tooltip>
          )
        },
        meta: {},
        filterFn: FaultTypeFilter<iFaultData>,
      },
      {
        id: ServiceFaultsColumnIds.faultTypeUntranslated,
        header: 'Fault Remark',
        accessorKey: 'eventTypeUntranslated',
        cell: (info) => {
          const value: string = info.getValue() as string
          return (
            <Tooltip
              id={`service-faults-fault-type-untranslated-${value}`}
              showOnlyWhenTextIsTruncate
              tooltipText={value}
              toolTipClass='tooltip'
              className='font-poppins text-2xs leading-4  text-ellipsis font-normal  text-c-dark-blue-1 '
            >
              {value}
            </Tooltip>
          )
        },
        meta: {
          forcedHidden: true,
        },
        filterFn: FaultTypeFilter<iFaultData>,
      },

      {
        id: ServiceFaultsColumnIds.faultDescription,
        header: 'Description',
        accessorKey: 'eventDescription',
        cell: (info) => {
          const value: string = info.getValue() as string
          return (
            <Tooltip
              id={`service-faults-fault-description-${value}`}
              showOnlyWhenTextIsTruncate
              tooltipText={value}
              toolTipClass='tooltip'
              className='font-poppins text-2xs leading-4  text-ellipsis font-normal text-c-dark-blue-1 '
            >
              {value}
            </Tooltip>
          )
        },
        meta: {},
        filterFn: 'includesString',
      },
    ],
    [],
  )
  const renderFilterSummaryComponent = () => <> </>

  // const selectedFilterFormValues: ServiceFaultsFilterFormType = {
  //   // businessUnit: 'All',
  //   serialNumber:
  //     filterValues.find((filter) => filter.columnId === ServiceFaultsColumnIds.serialNumber)
  //       ?.value ?? '',
  //   deviceType: '',
  //   faultType: '',
  //   faultDescription: '',
  //   dateRangeFrom: '',
  //   dateRangeTo: '',
  // }

  const columnVisibility: VisibilityState = {
    [ServiceFaultsColumnIds.faultTypeUntranslated]: false,
  }

  return (
    <TsTable<iFaultData>
      columns={tableColumns}
      data={tableData ?? []}
      dataIsLoading={isLoading}
      showGlobalActionButton={false}
      showGlobalFilter
      onExport={() => {}}
      onPrint={() => {}}
      getRowCanExpand={() => true}
      // renderSubComponent={renderSubComponent}
      updateFilteredData={(data) => updateFilteredData(data)}
      renderFilterSummary={renderFilterSummaryComponent}
      resetFilter={() => onFilterFormReset()}
      onShowFilter={() => onShowFilter()}
      filterValues={filterValues}
      setShowExportDialog={(val) => setShowExportDialog(val)}
      columnVisibility={columnVisibility}
      defaultSortedColumKey={ServiceFaultsColumnIds.deviceSerialNumber}
      minDisplayRows={10}
      // setFilterValues={setFilterValues}
      // dateRangeSelectorType={DateRangeSelectorTypes.DateRange}
      // dateRangeOptions={dateRangeOptions}
      // dateRangeFilterFn={DateRangeColumnFilterFn<iFaultData>}
    />
  )
}

export default withErrorBoundary(ServiceFaultstable, {
  FallbackComponent: ErrorPage,
})

// {/* <div className='-mt-64 z-50'>
//         <ServiceFaultsFilterForm
//           selectedFilterValues={convertISelectOptionPropsToServiceFaultsFormType()}
//           serialNumberOptions={serialNumberOptions}
//           deviceTypeOptions={deviceTypeOptions}
//           faultTypeOptions={faultTypeOptions}
//           faultDescriptionOptions={faultDescriptionOptions}
//           onFilterSubmit={onFilterFormSubmit}
//           onFilterReset={onFilterFormReset}
//           onFilterFormClose={() => {}}
//         />
//       </div> */}
