import GenericWidget from 'components/viewComponents/GenericWidget'
import { iOperatorInfo } from 'data/Interfaces'
import React from 'react'
import { IFilterValues } from 'forms/FormModelInterface'
import { OperatorsPageColumnIds } from '../view/OperatorsPageUtils'

type ActiveInActiveOperatorProps = {
  data?: iOperatorInfo[]
  statusFilteredParam: string
  onFilter: (value: IFilterValues[]) => void
}

export default function ActiveInActiveOperator({
  data,
  statusFilteredParam,
  onFilter,
}: ActiveInActiveOperatorProps) {
  const widgetData = data?.reduce(
    (
      acc: {
        [key: string]: number
      },
      cur,
    ) => ({
      ...acc,
      [cur.status]: (acc?.[cur.status] ?? 0) + 1,
    }),
    {},
  )

  return (
    <GenericWidget
      widgetProps={{
        title: 'Operator Status',
        widgetData: Object.entries(widgetData ?? {}).map(([key, values]) => ({
          originalData: key,
          displayText: key,
          value: values,
          isFiltered: key === statusFilteredParam,
          columnId: OperatorsPageColumnIds.status,
        })),
        widgetLabelCount: 4,
        columnId: OperatorsPageColumnIds.status,
        gridContainerClassName: 'grid-cols-1 gap-1',
        onFilter: (filterRequestValues: IFilterValues[]) => onFilter?.(filterRequestValues),
      }}
    />
  )
}
