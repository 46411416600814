import React from 'react'
import ReactDOM from 'react-dom/client'
import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { UserAccountProvider } from 'contexts/UserAccountContext'
import { adb2cClientID, adb2cTenant } from 'utils/AuthConfig'
import { SeverityLevel, appInsights } from 'utils/AppInsightConfig'
import { SnackBarContextProvider } from 'contexts/SnackBarContext'
import reportWebVitals from './reportWebVitals'
import App from './App'
import './react-datepicker-overrides.css'
import './index.css'
// import 'react-datepicker/dist/react-datepicker.css'

appInsights.loadAppInsights()

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5, // 5 minutes
      // refetchOnWindowFocus: false,
      // refetchOnMount: false,
      // refetchOnReconnect: true,
    },
  },
})

const msalConfig = {
  auth: {
    clientId: adb2cClientID as string,
    // authority:
    //   // 'https://connectv2trial1.b2clogin.com/connectv2trial1.onmicrosoft.com/b2c_1A_SMART_HRD_SUSI',
    //   'https://connectv2trial1.b2clogin.com/connectv2trial1.onmicrosoft.com/b2c_1A_SIGNIN_UI_1',
    // // authority: 'https://connectv2trial1.b2clogin.com/connectv2trial1.onmicrosoft.com/b2c_1a_Smart_HRD_SUP_ONLY',
    knownAuthorities: [`${adb2cTenant}.b2clogin.com`],
    redirectUri: '/',
    navigateToLoginRequestUrl: true,
    postLogoutRedirectUri: '/',
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    navigateFrameWait: 500,
    loggerOptions: {
      loggerCallback: () => {
        // if (containsPii) {
        //   return
        // }
        // switch (level) {
        //   case LogLevel.Error:
        //     console.error(message)
        //     break
        //   case LogLevel.Info:
        //     console.info(message)
        //     break
        //   case LogLevel.Verbose:
        //     console.debug(message)
        //     break
        //   case LogLevel.Warning:
        //     console.warn(message)
        //     break
        //   default:
        //     break
        // }
      },
    },
  },
}
const msalInstance = new PublicClientApplication(msalConfig)

msalInstance
  .initialize()
  .then(() => {
    msalInstance.enableAccountStorageEvents()
    const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
    root.render(
      <React.StrictMode>
        <SnackBarContextProvider>
          <UserAccountProvider>
            <MsalProvider instance={msalInstance}>
              <QueryClientProvider client={queryClient}>
                <App />
                <ReactQueryDevtools initialIsOpen={false} />
              </QueryClientProvider>
            </MsalProvider>
          </UserAccountProvider>
        </SnackBarContextProvider>
      </React.StrictMode>,
    )
  })
  .catch((error) => {
    appInsights.trackException(
      { error, severityLevel: SeverityLevel.Critical },
      { page: 'index.tsx' },
    )
    console.error(error)
  })
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

// msalInstance
//   .handleRedirectPromise()
//   .then((r) => {
//     // This is called after login redirect is completed and user is
//     // redirected to your app with tokens
//     // the result is entire auth response, which includes
//     // id token, access token, account info etc

//     console.log(r)
//   })
//   .catch((error) => {
//     console.error(error)
//   })
