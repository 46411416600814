import React from 'react'
import { iDeviceUser, iBusinessUnit } from 'data/DeviceListHook'
import { ExportColumnType } from 'exportReports/DataExportInterfaces'
import {
  FormatToShortDate,
  FormatBusinessUnit,
  FormatDeviceUser,
} from 'exportReports/ExportDataUtils'

const CalibrationReportColumns: ExportColumnType[] = [
  {
    key: 'serialNumber',
    label: 'Serial #',
    format: (val: string) => val,
    cellWidth: 100,
  },
  {
    key: 'deviceType',
    label: 'Device',
    format: (val: string) => val,
    cellWidth: 55,
  },
  {
    key: 'businessUnit',
    label: 'Business Unit',
    format: (val: iBusinessUnit) => FormatBusinessUnit(val),
    cellWidth: 80,
  },
  {
    key: 'calDueStatus',
    label: 'Status',
    format: (val: string) => val,
    cellWidth: 50,
  },
  {
    key: 'lastCalibratedDate',
    label: 'Last calibrated',
    format: (val?: Date) => FormatToShortDate(val),
    cellWidth: 75,
  },
  {
    key: 'calibrationDueDate',
    label: 'Calibration due',
    format: (val?: Date) => FormatToShortDate(val),
    cellWidth: 75,
  },
  {
    key: 'deviceUser',
    label: 'Assigned to',
    format: (val?: iDeviceUser) => FormatDeviceUser(val),
    cellWidth: 90,
  },
]

export { CalibrationReportColumns }
