import { QueryClient, useQuery } from '@tanstack/react-query'
import { useMsal } from '@azure/msal-react'
import { IPublicClientApplication, InteractionStatus } from '@azure/msal-browser'
import { LoaderFunction, createSearchParams } from 'react-router-dom'

import ConnectAPIClient from 'services/ConnectAPIClient'
import { APIResponseTypes, OperatorStatusTypes, PortableTypes } from 'utils/CommonEnums'
import { selectAllOption } from 'forms/FormUtils'
import { iBusinessUnitDetails } from 'components/commonComponents/BusinessUnitDataUtils'
import { getAccessToken, getAccessTokenFromPCA, getAccessTokenProps } from 'services/MSALService'
import { getOrFetchUserInfo } from 'data/UserInfoHook'
import { IsUserGlobalScope } from 'utils/UserDataUtils'
import { allCustomerOption, getOrFetchCustomerList } from 'data/CustomerListHook'
import { getOrFetchBusinessUnitsByCustomer } from 'data/BusinessUnitsListByCustomerHook'
import { getOrFetchBusinessUnitsForUser } from 'data/BusinessUnitsListByUserHook'
import { parseAPIResponse } from 'services/APIErrorParser'

import { AxiosError } from 'axios'
import { allBusinessUnitOption } from './BusinessUnitsListHookWrapper'
import { iDataLoaderParams } from './Interfaces'

function getTopLevelBusinessUnitId(businessUnitsList: iBusinessUnitDetails[]) {
  const topLevelBusinessUnit = businessUnitsList.find(
    (businessUnit) => businessUnit.parentId === null,
  )
  if (!topLevelBusinessUnit) return ''
  return topLevelBusinessUnit?.id
}

export const operatorListDataQueryKey = (businessUnitId?: string, customerId?: string) => [
  'operators',
  'list',
  'businessUnit',
  businessUnitId,
]

export const operatorListDataURL = (businessUnitId?: string, customerId?: string) => {
  if (
    businessUnitId &&
    businessUnitId.toLowerCase() !== selectAllOption.value?.toString().toLowerCase()
    // || (customerId === '' || customerId === allCustomerOption.id)
  ) {
    return `users/api/operator?ExpandBusinessUnit=true&BusinessUnitId=${businessUnitId}`
  }
  return `users/api/operator`
}

export interface iBusinessUnit {
  id: string
  name: string
}

export interface iDeviceAssigned {
  serialNumber: string
  deviceType: PortableTypes
  assetNumber: string
  usingSince: Date | string
  status: OperatorStatusTypes
}

export interface DeviceChannelInfoDto {
  channelNumber: number
  channelName: string
}

export interface iOperatorInfo {
  id: string
  customerId: string
  firstName: string
  lastName: string
  uniqueId: string
  status: string
  operatorType: string
  businessUnit: iBusinessUnit
  currentDevice: Array<iDeviceAssigned>
}

interface iOperatorsPageDataLoaderParams {
  queryClient: QueryClient
  msalInstance: IPublicClientApplication
}

export interface iAPIBusinessUnitParams {
  businessUnitId?: string
  customerId?: string
}

interface iFetchOperatorListParams {
  token: string
  businessUnitId?: string
  customerId?: string
}

interface iGetOrFetchOperatorListProps {
  queryClient: QueryClient
  token: string
  businessUnitId?: string
  customerId?: string
}

interface iOperatorListAPIParams extends iAPIBusinessUnitParams, getAccessTokenProps {}

export async function FetchOperatorsList(
  params: iFetchOperatorListParams,
): Promise<iOperatorInfo[]> {
  try {
    const { token, businessUnitId, customerId } = params
    const url = operatorListDataURL(businessUnitId, customerId)
    const resp = await ConnectAPIClient.get<iOperatorInfo[]>(url, token)
    const parsedAPIResponse = parseAPIResponse<iOperatorInfo[]>(resp)
    if (parsedAPIResponse === APIResponseTypes.NoContent) {
      return []
    }
    return parsedAPIResponse
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function getOperatorListData(operatorListAPIParams: iOperatorListAPIParams) {
  const { msalContext, redirectPageURL, businessUnitId, customerId } = operatorListAPIParams
  const token = await getAccessToken({ msalContext, redirectPageURL })
  const operatorListData = await FetchOperatorsList({ token, businessUnitId })
  return operatorListData
}

export function useOperatorList(
  redirectPageURL: string,
  businessUnitId: string,
  customerId?: string,
  businessUnitsList?: iBusinessUnitDetails[] | undefined,
  enabled?: boolean,
) {
  const msalContext = useMsal()
  const apiURL = operatorListDataURL(businessUnitId)

  let buId = ''
  if (
    businessUnitId === allBusinessUnitOption.id &&
    customerId !== allCustomerOption.id &&
    businessUnitsList
  ) {
    buId = getTopLevelBusinessUnitId(businessUnitsList)
  } else {
    buId = businessUnitId
  }

  return useQuery<iOperatorInfo[], AxiosError>(
    operatorListDataQueryKey(buId),
    () =>
      getOperatorListData({
        msalContext,
        redirectPageURL,
        businessUnitId: buId,
      }),
    {
      select: (data) => {
        // Sort operator names alphabetically by first name
        const sortedData = [...data].sort((a, b) => a.firstName.localeCompare(b.firstName))
        return sortedData
      },
      enabled,
      onError: (error: AxiosError) => {
        console.log(error)
      },
    },
  )
}

export async function getOrFetchOperatorsList(props: iGetOrFetchOperatorListProps) {
  const { queryClient, token, businessUnitId, customerId } = props

  const operatorsListCached = queryClient.getQueryData<iOperatorInfo[]>(
    operatorListDataQueryKey(businessUnitId, customerId),
  )

  if (operatorsListCached) {
    return operatorsListCached
  }

  const operatorsListFetched = await queryClient.fetchQuery(
    operatorListDataQueryKey(businessUnitId, customerId),
    async () =>
      FetchOperatorsList({
        token,
        businessUnitId,
        customerId,
      }),
  )

  return operatorsListFetched
}

export const OperatorsPageDataLoader =
  (props: iDataLoaderParams): LoaderFunction =>
  async ({ request, params }) => {
    const { queryClient, msalInstance, msalAccounts, msalInProgress } = props
    if (!msalInstance) throw new Error('No MSAL Context')
    if (!queryClient) throw new Error('No query client')

    try {
      if (msalInProgress !== InteractionStatus.None) {
        return {}
      }
      if (msalAccounts.length === 0) {
        return {}
      }
      const token = await getAccessTokenFromPCA({ msalInstance })

      const userInfo = await getOrFetchUserInfo({ queryClient, token })

      const isUserGlobal = IsUserGlobalScope(userInfo)

      const searchParams = createSearchParams(new URLSearchParams(request.url.split('?')[1]))

      const customerId = isUserGlobal
        ? searchParams.get('c') ?? (allCustomerOption.id as string)
        : ''
      const businessUnitId = searchParams.get('mainbu') ?? allBusinessUnitOption.id

      const customerList = !isUserGlobal ? [] : await getOrFetchCustomerList({ queryClient, token })

      const businessUnitsList =
        isUserGlobal && customerId !== '' && customerId !== allCustomerOption.id
          ? await getOrFetchBusinessUnitsByCustomer({ queryClient, token, customerId })
          : await getOrFetchBusinessUnitsForUser({ queryClient, token })

      let buId = ''
      if (
        businessUnitId === allBusinessUnitOption.id &&
        customerId !== '' &&
        customerId !== allCustomerOption.id &&
        businessUnitsList
      ) {
        buId = getTopLevelBusinessUnitId(businessUnitsList)
      } else {
        buId = businessUnitId
      }

      const operatorsList = await getOrFetchOperatorsList({
        queryClient,
        token,
        businessUnitId: buId,
        customerId,
      })

      return {
        operatorPageData: operatorsList,
        businessUnitsList,
        customerList,
      }
    } catch (error) {
      console.error(error)
      throw error
    }
  }
