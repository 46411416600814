import React, { useContext, useEffect } from 'react'
import { Outlet, useLocation, useOutletContext, useParams } from 'react-router-dom'
import { useGenericEventHandler } from 'data/GenericEventHandler'
import { withErrorBoundary } from 'react-error-boundary'
import ErrorPage from 'pages/common/ErrorPage'
import { useGetDeviceDetails } from '../data/DeviceDetailsData'
import DeviceDetailsHeader from './DeviceDetailsHeader'
import { iDeviceDetails } from '../data/interface'
import DeviceDetailsAdditionalInfo from './DeviceDetailsAdditionalInfo'
import {
  DeviceDetailContextActions,
  DeviceDetailsContext,
  DeviceDetailsProvider,
} from './DeviceDetailsContext'
import DeviceDetailsFilterForm from './DeviceDetailsFilterForm'

type deviceDetailReportContextType = {
  deviceDetails: iDeviceDetails | null | undefined
}

function DeviceDetailViewComponent() {
  const deviceDetailsContext = useContext(DeviceDetailsContext)
  const { state, dispatch } = deviceDetailsContext
  const { genericEventHandler } = useGenericEventHandler()
  const location = useLocation()
  const redirectPageURL = `${location.pathname}${location.search}`
  const { deviceSerialNumber } = useParams<{ deviceSerialNumber: string }>()
  if (deviceSerialNumber === undefined) {
    throw new Error('deviceSerialNumber is undefined')
  }

  const {
    data: deviceDetails,
    // isLoading,
    isError: isDeviceDetailsError,
    error: deviceDetailsError,
  } = useGetDeviceDetails(redirectPageURL, deviceSerialNumber, true)

  useEffect(() => {
    function hideFilterFormHandler(e: KeyboardEvent) {
      if (e.key === 'Escape' && state.filterFormShown) {
        dispatch({ type: DeviceDetailContextActions.HideFilterForm })
      }
    }
    window.addEventListener('keyup', hideFilterFormHandler)

    return () => {
      window.removeEventListener('keyup', hideFilterFormHandler)
    }
  }, [state.filterFormShown])

  useEffect(() => {
    if (isDeviceDetailsError) {
      genericEventHandler({
        onlyTrack: true,
        severity: 'error',
        message: deviceDetailsError?.message || 'Error getting device details',
        error: deviceDetailsError,
        extraData: {
          component: 'DeviceDetailsRoot',
          action: 'get device details',
        },
      })
    }
  }, [isDeviceDetailsError, deviceDetailsError])

  return (
    <>
      <div
        className='flex flex-col h-full'
        aria-hidden='true'
        onClick={() =>
          state.filterFormShown && dispatch({ type: DeviceDetailContextActions.HideFilterForm })
        }
      >
        <DeviceDetailsHeader deviceDetails={deviceDetails} />

        <div className=''>
          <DeviceDetailsAdditionalInfo deviceDetails={deviceDetails} />
        </div>

        {deviceDetails && (
          <Outlet
            context={
              {
                deviceDetails,
              } satisfies deviceDetailReportContextType
            }
          />
        )}
      </div>
      <div className='z-50 '>
        <DeviceDetailsFilterForm />
      </div>
    </>
  )
}
const DeviceDetailView = withErrorBoundary(DeviceDetailViewComponent, {
  FallbackComponent: ErrorPage,
})
function DeviceDetailsRoot() {
  return (
    <DeviceDetailsProvider>
      <DeviceDetailView />
    </DeviceDetailsProvider>
  )
}

export function useDeviceDetails() {
  return useOutletContext<deviceDetailReportContextType>()
}

export default DeviceDetailsRoot
