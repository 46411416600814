import React, { useEffect, useState, memo } from 'react'
import { useForm, SubmitHandler, Controller, FieldErrors } from 'react-hook-form'
import { DateRange, IFilterValues, IselectOptionProps } from 'forms/FormModelInterface'
// eslint-disable-next-line import/no-extraneous-dependencies
import { DevTool } from '@hookform/devtools'
import { sub } from 'date-fns'

import { DateRangeSelectorTypes, SelectOptionTypes } from 'utils/CommonEnums'
import { ChevronRightIcon, CloseIcon } from 'assets/icons'
import { DatePickerControl, SelectControl, CreatableSelectControl } from 'components/formComponents'

import FilterFormTitle from 'components/formComponents/FilterFormTitle'
import { iDevice } from 'data/DeviceListHook'
import { dateRangeOptions, selectAllOption, selectBlankOption } from 'forms/FormUtils'
import TextInputControl from 'components/formComponents/TextInputControl'
import { withErrorBoundary } from 'react-error-boundary'
import ErrorPage from 'pages/common/ErrorPage'
import { SafetyPageColumnIds, SafetyPageFilterFormType } from './SafetyPageUtils'
import { iSafetyData } from '../data/iSafetyList'
// import { PrepareDeviceTypeOptionsForSelect } from '../data/CalibrationDataUtils'

// const [serialNumberOptions, setSerialNumberOptions] = useState<IselectOptionProps[]>([])
// const [assetNumberOptions, setAssetNumberOptions] = useState<IselectOptionProps[]>([])
// const [gasNameOptions, setGasNameOptions] = useState<IselectOptionProps[]>([])
// const [alarmTypeOptions, setAlarmTypeOptions] = useState<IselectOptionProps[]>([])
// const [alarmLevelOptions, setAlarmLevelOptions] = useState<IselectOptionProps[]>([])
// const [alarmSetPointOptions, setAlarmSetPointOptions] = useState<IselectOptionProps[]>([])
// const [businessUnitOptions, setBusinessUnitOptions] = useState<IselectOptionProps[]>([])
// const [deviceUserOptions, setDeviceUserOptions] = useState<IselectOptionProps[]>([])

interface SafetyPageFilterFormProps {
  selectedFilterValues?: SafetyPageFilterFormType
  serialNumberOptions?: IselectOptionProps[]
  assetNumberOptions?: IselectOptionProps[]
  gasNameOptions?: IselectOptionProps[]
  alarmTypeOptions?: IselectOptionProps[]
  alarmLevelOptions?: IselectOptionProps[]
  alarmSetPointOptions?: IselectOptionProps[]
  businessUnitOptions?: IselectOptionProps[]
  deviceUserOptions?: IselectOptionProps[]

  onFilterSubmit: (filterValues: IFilterValues[]) => void
  onFilterReset: () => void
  onFilterFormClose: () => void
}

// businessUnit: string

// alarmDateSelector: IselectOptionProps
// alarmDate: DateRange

// deviceUser: string

// gasName: string
// alarmType: string
// alarmLevel: string
// alarmSetPoint: string

function SafetyPageFilterForm(props: SafetyPageFilterFormProps): JSX.Element {
  const {
    selectedFilterValues,
    onFilterSubmit,
    onFilterReset,
    onFilterFormClose,
    serialNumberOptions,
    assetNumberOptions,
    gasNameOptions,
    alarmTypeOptions,
    alarmLevelOptions,
    alarmSetPointOptions,
    businessUnitOptions,
    deviceUserOptions,
  } = props

  // props
  const dtTo = new Date()
  const dtFrom = sub(new Date(), { months: 6 })
  const defValues: SafetyPageFilterFormType = {
    serialNumber: selectBlankOption,
    assetNumber: selectBlankOption,
    deviceUser: selectBlankOption,
    businessUnit: selectAllOption,
    gasName: selectBlankOption,
    alarmType: selectBlankOption,
    alarmLevel: selectBlankOption,
    alarmSetPoint: selectBlankOption,

    alarmDateSelector: dateRangeOptions.find(
      (option) => option.label === DateRangeSelectorTypes.Custom,
    ) ?? {
      label: '',
      value: '',
    },
    alarmDate: {
      startDate: dtFrom,
      endDate: dtTo,
    },
  }
  // const defaultFilterValues = ()  => {
  //   // const dtTo = new Date()
  //   // const dtFrom = sub(new Date(), { months: 1 })

  //   const defValues: SafetyPageFilterFormType = {
  //     serialNumber: '',
  //     assetNumber: '',

  //     deviceUser: '',
  //     businessUnit: '',
  //     gasName: '',
  //     alarmType: '',
  //     alarmLevel: '',
  //     alarmSetPoint: '',

  //     alarmDateSelector: dateRangeOptions.find(
  //       (option) => option.label === DateRangeSelectorTypes.All,
  //     ) ?? {
  //       label: '',
  //       value: '',
  //     },
  //     alarmDate: {
  //       startDate: sub(new Date(), { months: 1 }),
  //       endDate: new Date()
  //     },
  //   }
  //   return defValues
  // }

  // const defaultFormValues = defaultFilterValues()

  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    getValues,
    formState: {
      errors,
      isDirty,
      isSubmitting,
      isValid,
      isValidating,
      touchedFields,
      isSubmitSuccessful,
      isSubmitted,
      dirtyFields,
    },
  } = useForm<SafetyPageFilterFormType>({
    defaultValues: defValues, // defaultFormValues,
    values: selectedFilterValues,
    // values: defValues
    // values: defaultFormValues // selectedFilterValues,
  })

  const watchalarmDateSelector = watch('alarmDateSelector')
  const [showCustomAlarmDate, setShowCustomAlarmDate] = React.useState(false)

  // const [serialNumberOptions, setSerialNumberOptions] = useState<IselectOptionProps[]>([])
  // const [businessUnitOptions, setBusinessUnitOptions] = useState<IselectOptionProps[]>([])
  // const [deviceUsersOptions, setDeviceUsersOptions] = useState<IselectOptionProps[]>([])

  useEffect(() => {
    if (watchalarmDateSelector?.value === DateRangeSelectorTypes.Custom) {
      setShowCustomAlarmDate(true)
    } else {
      setShowCustomAlarmDate(false)
    }
  }, [watchalarmDateSelector])

  const onSubmit: SubmitHandler<SafetyPageFilterFormType> = (data) => {
    const filterValues: IFilterValues[] = []

    filterValues.push({
      columnId: SafetyPageColumnIds.serialNumber,
      value: data.serialNumber ? data.serialNumber.value : '',
    })

    filterValues.push({
      columnId: SafetyPageColumnIds.assetNumber,
      value: data.assetNumber ? data.assetNumber.value : '',
    })

    filterValues.push({
      columnId: SafetyPageColumnIds.gasName,
      value: data.gasName ? data.gasName.value : '',
    })

    filterValues.push({
      columnId: SafetyPageColumnIds.alarmType,
      value: data.alarmType ? data.alarmType.value : '',
    })

    filterValues.push({
      columnId: SafetyPageColumnIds.alarmLevel,
      value: data.alarmLevel ? data.alarmLevel.value : '',
    })

    filterValues.push({
      columnId: SafetyPageColumnIds.alarmSetPoint,
      value: data.alarmSetPoint ? data.alarmSetPoint.value : '',
    })

    filterValues.push({
      columnId: SafetyPageColumnIds.businessUnit,
      value: data.businessUnit
        ? data.businessUnit.value === SelectOptionTypes.All
          ? ''
          : (data.businessUnit.label as string)
        : '',
    })

    filterValues.push({
      columnId: SafetyPageColumnIds.deviceUser,
      value: data.deviceUser ? data.deviceUser.value : '',
    })

    filterValues.push({
      columnId: SafetyPageColumnIds.alarmDate,
      value: {
        label: data.alarmDateSelector.label as string,
        value: data.alarmDate,
      },
    })

    onFilterSubmit(filterValues)
  }

  const onError = (formErrors: FieldErrors<SafetyPageFilterFormType>) => {
    console.log(formErrors)
  }

  const onReset = () => {
    // reset(defaultFilterValues)
    reset(defValues)
    onFilterReset()
  }

  return (
    <div className='filter-form-container'>
      <div className='filter-form-header'>
        <FilterFormTitle />

        <div
          title='Close Filter Form'
          role='button'
          tabIndex={0}
          onClick={() => onFilterFormClose()}
          onKeyDown={() => onFilterFormClose()}
          className='filter-form-close-button'
        >
          <CloseIcon toggleClick={() => onFilterFormClose()} className='w-5 h-5 ' />
        </div>
      </div>

      <form
        onSubmit={handleSubmit(onSubmit, onError)}
        onReset={onFilterReset}
        noValidate
        className=''
      >
        <div className='filter-form-fields-container'>
          <div className='flex flex-col gap-0.5 items-start self-stretch'>
            <label
              htmlFor='faultsrFilter'
              className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1 '
            >
              Serial Number
            </label>
            <Controller
              name='serialNumber'
              control={control}
              render={({ field }) => (
                <CreatableSelectControl
                  className='w-full h-[41px]'
                  id='serialNumber'
                  selectControlProps={{
                    options: serialNumberOptions,
                    value: field.value,
                    defaultValue: field.value,
                    isMulti: false,
                    isSearchable: true,
                    isDropDownSelectable: false,
                    openMenuOnClick: true,
                    placeholder: '-',
                    isClearable: true,
                    onChange: (selectedOption: IselectOptionProps) => {
                      field.onChange(selectedOption)
                    },
                  }}
                />
              )}
            />
            <p className='text-xs text-red-500'>{errors.serialNumber?.message}</p>
          </div>

          {/* // asset number */}
          <div className='flex flex-col gap-0.5 items-start self-stretch'>
            <label
              htmlFor='assetNumber'
              className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1 '
            >
              Asset Number
            </label>
            <Controller
              name='assetNumber'
              control={control}
              render={({ field }) => (
                <CreatableSelectControl
                  className='w-full h-[41px]'
                  id='assetNumber'
                  selectControlProps={{
                    options: assetNumberOptions,
                    value: field.value,
                    defaultValue: field.value,
                    isMulti: false,
                    isSearchable: true,
                    isDropDownSelectable: false,
                    openMenuOnClick: true,
                    placeholder: '-',
                    isClearable: true,
                    onChange: (selectedOption: IselectOptionProps) => {
                      field.onChange(selectedOption)
                    },
                  }}
                />
              )}
            />
            <p className='text-xs text-red-500'>{errors.assetNumber?.message}</p>
          </div>
          {/* business unit */}

          <div className='flex flex-col gap-0.5 items-start self-stretch'>
            <label
              htmlFor='businessUnitFilter'
              className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1 '
            >
              Business Unit
            </label>
            <Controller
              name='businessUnit'
              control={control}
              render={({ field }) => (
                <CreatableSelectControl
                  className='w-full h-[41px]'
                  id='businessUnit'
                  selectControlProps={{
                    options: businessUnitOptions,
                    value: field.value,
                    defaultValue: field.value,
                    isMulti: false,
                    isSearchable: true,
                    isDropDownSelectable: false,
                    openMenuOnClick: true,
                    placeholder: '-',
                    isClearable: false,
                    onChange: (selectedOption: IselectOptionProps) => {
                      field.onChange(selectedOption)
                    },
                  }}
                />
              )}
            />
            <p className='text-xs text-red-500'>{errors.businessUnit?.message}</p>
          </div>
          {/* // gas name */}

          <div className='flex flex-col gap-0.5 items-start self-stretch'>
            <label
              htmlFor='gasName'
              className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1 '
            >
              Gas Name
            </label>
            <Controller
              name='gasName'
              control={control}
              render={({ field }) => (
                <CreatableSelectControl
                  className='w-full h-[41px]'
                  id='gasName'
                  selectControlProps={{
                    options: gasNameOptions,
                    value: field.value,
                    defaultValue: field.value,
                    isMulti: false,
                    isSearchable: true,
                    isDropDownSelectable: false,
                    openMenuOnClick: true,
                    placeholder: '-',
                    isClearable: true,
                    onChange: (selectedOption: IselectOptionProps) => {
                      field.onChange(selectedOption)
                    },
                  }}
                />
              )}
            />
            <p className='text-xs text-red-500'>{errors.gasName?.message}</p>
          </div>

          {/* // alarm type */}

          <div className='flex flex-col gap-0.5 items-start self-stretch'>
            <label
              htmlFor='alarmType'
              className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1 '
            >
              Alarm Type
            </label>
            <Controller
              name='alarmType'
              control={control}
              render={({ field }) => (
                <CreatableSelectControl
                  className='w-full h-[41px]'
                  id='alarmType'
                  selectControlProps={{
                    options: alarmTypeOptions,
                    value: field.value,
                    defaultValue: field.value,
                    isMulti: false,
                    isSearchable: true,
                    isDropDownSelectable: false,
                    openMenuOnClick: true,
                    placeholder: '-',
                    isClearable: true,
                    onChange: (selectedOption: IselectOptionProps) => {
                      field.onChange(selectedOption)
                    },
                  }}
                />
              )}
            />
            <p className='text-xs text-red-500'>{errors.alarmType?.message}</p>
          </div>

          {/* // alarm level */}

          <div className='flex flex-col gap-0.5 items-start self-stretch'>
            <label
              htmlFor='alarmLevel'
              className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1 '
            >
              % Level
            </label>
            <Controller
              name='alarmLevel'
              control={control}
              render={({ field }) => (
                <CreatableSelectControl
                  className='w-full h-[41px]'
                  id='alarmLevel'
                  selectControlProps={{
                    options: alarmLevelOptions,
                    value: field.value,
                    defaultValue: field.value,
                    isMulti: false,
                    isSearchable: true,
                    isDropDownSelectable: false,
                    openMenuOnClick: true,
                    placeholder: '-',
                    isClearable: true,
                    onChange: (selectedOption: IselectOptionProps) => {
                      field.onChange(selectedOption)
                    },
                  }}
                />
              )}
            />
            <p className='text-xs text-red-500'>{errors.alarmLevel?.message}</p>
          </div>

          {/* // alarm set point */}
          <div className='flex flex-col gap-0.5 items-start self-stretch'>
            <label
              htmlFor='alarmSetPoint'
              className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1 '
            >
              Peak value
            </label>
            <Controller
              name='alarmSetPoint'
              control={control}
              render={({ field }) => (
                <CreatableSelectControl
                  className='w-full h-[41px]'
                  id='alarmSetPoint'
                  selectControlProps={{
                    options: alarmSetPointOptions,
                    value: field.value,
                    defaultValue: field.value,
                    isMulti: false,
                    isSearchable: true,
                    isDropDownSelectable: false,
                    openMenuOnClick: true,
                    placeholder: '-',
                    isClearable: true,
                    onChange: (selectedOption: IselectOptionProps) => {
                      field.onChange(selectedOption)
                    },
                  }}
                />
              )}
            />
            <p className='text-xs text-red-500'>{errors.alarmSetPoint?.message}</p>
          </div>

          {/* // assigned to */}

          <div className='flex flex-col gap-0.5 items-start self-stretch'>
            <label
              htmlFor='devicesassetnumfilter'
              className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1 '
            >
              Assigned To
            </label>
            <Controller
              name='deviceUser'
              control={control}
              render={({ field }) => (
                <CreatableSelectControl
                  className='w-full h-[41px]'
                  id='deviceUser'
                  selectControlProps={{
                    options: deviceUserOptions,
                    value: field.value,
                    defaultValue: field.value,
                    isMulti: false,
                    isSearchable: true,
                    isDropDownSelectable: false,
                    openMenuOnClick: true,
                    placeholder: '-',
                    isClearable: true,
                    onChange: (selectedOption: IselectOptionProps) => {
                      field.onChange(selectedOption)
                    },
                  }}
                />
              )}
            />
            <p className='text-xs text-red-500'>{errors.deviceUser?.message}</p>
          </div>

          {/* Alarm date selector */}
          <div className='flex flex-col gap-0.5 items-start self-stretch'>
            <label
              htmlFor='alarmDateSelector'
              className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1 '
            >
              Date
            </label>

            <Controller
              name='alarmDateSelector'
              control={control}
              render={({ field }) => (
                <SelectControl
                  className='w-full h-[42px]'
                  selectControlProps={{
                    options: dateRangeOptions,
                    defaultValue: field.value,
                    isMulti: false,
                    isSearchable: false,
                    isDropDownSelectable: true,
                    openMenuOnClick: true,
                    placeholder: '',
                    isClearable: false,
                    // value: field.value,
                    onChange: (selectedOption: IselectOptionProps) => {
                      const selectedValue = selectedOption?.value
                      field.onChange(selectedOption)
                      if (selectedValue !== DateRangeSelectorTypes.Custom) {
                        setValue('alarmDate', selectedValue as DateRange, {
                          shouldValidate: true,
                        })
                      } else {
                        setValue('alarmDate', {
                          startDate: new Date(),
                          endDate: new Date(),
                        })
                      }
                    },
                  }}
                />
              )}
            />
          </div>

          {showCustomAlarmDate && (
            <div className='flex flex-row justify-between  items-end '>
              <div className='w-[110px]'>
                <div className='flex flex-col gap-0.5 items-start '>
                  <div>
                    <label
                      htmlFor='faultDateFrom'
                      className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1 '
                    >
                      From
                    </label>
                  </div>
                  <div className=''>
                    <Controller
                      name='alarmDate.startDate'
                      control={control}
                      render={({ field }) => (
                        <DatePickerControl
                          className=''
                          datePickerProps={{
                            selected: field.value,

                            onChange: (date: Date | null) => field.onChange(date as Date),
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className='mx-0.5 w-4 h-4 my-3'>
                <ChevronRightIcon className='' />
              </div>

              <div className='w-[110px]'>
                <div className='flex flex-col gap-0.5 items-start'>
                  <label
                    htmlFor='faultDateFilterTo'
                    className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1 '
                  >
                    To
                  </label>
                  <div className=''>
                    <Controller
                      name='alarmDate.endDate'
                      control={control}
                      render={({ field }) => (
                        <DatePickerControl
                          className=''
                          datePickerProps={{
                            selected: field.value,
                            maxDate: new Date(),
                            onChange: (date: Date | null) => field.onChange(date as Date),
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          {errors.alarmDate && (
            <>
              <p className='text-xs text-red-500'>{errors.alarmDate?.startDate?.message}</p>
              <p className='text-xs text-red-500'>{errors.alarmDate?.endDate?.message}</p>
            </>
          )}
        </div>
        {/* Buttons */}
        <div className='filter-form-footer'>
          <input
            className='filter-form-reset-button'
            type='reset'
            disabled={isSubmitting}
            onClick={() => {
              onReset()
            }}
          />
          <input
            className='filter-form-apply-button'
            type='submit'
            value='Apply'
            // disabled={!isDirty || !isValid || isSubmitting}
          />
        </div>
      </form>

      <DevTool control={control} />
    </div> // opening div
  )
}

export default withErrorBoundary(memo(SafetyPageFilterForm), {
  FallbackComponent: ErrorPage,
})
