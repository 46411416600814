import { useQuery } from '@tanstack/react-query'
import { useMsal } from '@azure/msal-react'
import { sub } from 'date-fns'
import { LoaderFunction, createSearchParams } from 'react-router-dom'
import { UploadClientType } from 'utils/CommonEnums'
import ConnectAPIClient from 'services/ConnectAPIClient'
import { getAccessToken, getAccessTokenFromPCA } from 'services/MSALService'
import { getOrFetchDeviceDetails } from 'data/DeviceDetailsHook'
import {
  iDeviceLogsAPIParams,
  iDeviceLogsDataLoaderParams,
  iGetDeviceLogsParams,
  iGetOrFetchDeviceLogDataProps,
} from 'pages/deviceDetails/commonUtils/interfaces'

import { AxiosError } from 'axios'
import { iUploadHistoryDetails, iUploadHistory } from './interface'

export const deviceSyncHistoryQueryKeyIdDates = ({
  deviceId,
  startDate,
  endDate,
}: {
  deviceId: string
  startDate?: string
  endDate?: string
}) => ['device', 'syncHistory', 'id', deviceId, 'startDate', startDate, 'endDate', endDate]

async function fetchDeviceSyncHistory(
  deviceEventLogParams: iDeviceLogsAPIParams,
): Promise<iUploadHistory[]> {
  try {
    const { deviceId, startDate, endDate, token } = deviceEventLogParams
    const urlSearchParams = new URLSearchParams()

    if (startDate) {
      urlSearchParams.set('FromDate', startDate)
    }
    if (endDate) {
      urlSearchParams.set('ToDate', endDate)
    }

    const url = `devicelogs/api/uploadhistory/${deviceId}?${urlSearchParams.toString()}`

    const resp = await ConnectAPIClient.get<iUploadHistoryDetails[]>(url, token)

    if (!resp.data) return []
    if (resp.data.length === 0) return []
    if (resp.data[0].uploadHistory.length === 0) return []
    const response = resp.data as iUploadHistoryDetails[]

    const { uploadHistory } = response[0]
    const uploadHistoryFiltered = uploadHistory.filter(
      (item) => item.clientType !== UploadClientType.PortableService,
    )
    return uploadHistoryFiltered
  } catch (error) {
    console.error(error)
    throw error
  }
}

async function getOrFetchDeviceSyncHistoryData(props: iGetOrFetchDeviceLogDataProps) {
  const { queryClient, token, startDate, endDate, deviceId } = props

  const deviceSyncHistoryCached = queryClient.getQueryData<iUploadHistory[]>(
    deviceSyncHistoryQueryKeyIdDates({ startDate, endDate, deviceId }),
  )

  if (deviceSyncHistoryCached) return deviceSyncHistoryCached

  const deviceSyncHistoryFetched = await queryClient.fetchQuery(
    deviceSyncHistoryQueryKeyIdDates({ startDate, endDate, deviceId }),
    () => fetchDeviceSyncHistory({ deviceId, startDate, endDate, token }),
  )

  return deviceSyncHistoryFetched
}

async function getDeviceSyncHistoryData(deviceSyncDataParams: iGetDeviceLogsParams) {
  const { startDate, endDate, msalContext, redirectPageURL, deviceId } = deviceSyncDataParams
  const token = await getAccessToken({ msalContext, redirectPageURL })
  const eventLogsData = await fetchDeviceSyncHistory({ startDate, endDate, token, deviceId })
  return eventLogsData
}

export const DeviceSyncHistoryLoader =
  (props: iDeviceLogsDataLoaderParams): LoaderFunction =>
  async ({ request, params }) => {
    try {
      const { queryClient, msalInstance } = props
      if (!params?.deviceSerialNumber) throw new Error('No device serial number')
      if (!msalInstance) throw new Error('No MSAL Context')
      if (!queryClient) throw new Error('No query client')

      const token = await getAccessTokenFromPCA({ msalInstance })

      const searchParams = createSearchParams(new URLSearchParams(request.url.split('?')[1]))

      const endDate = searchParams.get('to') ?? new Date().toISOString().split('T')[0]
      const startDate =
        searchParams.get('from') ??
        sub(new Date(endDate), { months: 6 }).toISOString().split('T')[0]

      const deviceDetails = await getOrFetchDeviceDetails({
        queryClient,
        token,
        deviceSerialNumber: params?.deviceSerialNumber,
      })

      const deviceId = deviceDetails?.id ?? ''

      const deviceSyncData = getOrFetchDeviceSyncHistoryData({
        queryClient,
        token,
        startDate,
        endDate,
        deviceId,
      })

      return deviceSyncData
    } catch (error) {
      console.error(error)
      throw error
      // return []
    }
  }

export function useDeviceSyncHistoryData(
  deviceId: string,
  startDate: string,
  endDate: string,
  redirectPageURL: string,
  enabled?: boolean,
) {
  const msalContext = useMsal()

  return useQuery(
    deviceSyncHistoryQueryKeyIdDates({ deviceId, startDate, endDate }),
    () =>
      getDeviceSyncHistoryData({
        msalContext,
        redirectPageURL,
        deviceId,
        startDate,
        endDate,
      }),
    {
      enabled,
      onError: (error: AxiosError) => {
        console.log(error)
      },
    },
  )
}
