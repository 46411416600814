import React, { useEffect, useState } from 'react'

import { iDevice } from 'data/DeviceListHook'
import GenericWidget from 'components/viewComponents/GenericWidget'
import { iWidgetData } from 'components/viewComponents/interfaces'
import { groupDeviceDataByCalDueDays } from 'pages/calibration/data/CalibrationDataUtils'
import { DeviceCalDueTypeTexts } from 'utils/Constants'
import { DeviceCalDueTypes } from 'utils/CommonEnums'
import { IFilterValues } from 'forms/FormModelInterface'
import { calDueDateRangeOptions } from 'forms/FormUtils'
import { withErrorBoundary } from 'react-error-boundary'
import ErrorPage from 'pages/common/ErrorPage'
import { CalibrationPageColumnIds } from '../CalibrationsPageUtils'

function CalibrationCountByDaysWidget({
  deviceListData,
  onFilter,
  calDueDatesFilteredParam,
}: {
  deviceListData: iDevice[]
  onFilter: (filterRequestValues: IFilterValues[]) => void
  calDueDatesFilteredParam: DeviceCalDueTypes
}): JSX.Element {
  const [widgetData, setWidgetData] = useState<iWidgetData[]>()

  const onFilterData = (filterRequestValues: IFilterValues[]) => {
    const filterRequestValueToSend: IFilterValues[] = []
    filterRequestValues.forEach((item) => {
      if (item.value === '') {
        filterRequestValueToSend.push({
          columnId: item.columnId,
          value: '',
        })
      } else {
        switch (item.value) {
          case DeviceCalDueTypes.DueIn7Days:
            filterRequestValueToSend.push({
              columnId: item.columnId,
              value: {
                label: DeviceCalDueTypes.DueIn7Days,
                value:
                  calDueDateRangeOptions.find((x) => x.label === DeviceCalDueTypes.DueIn7Days)
                    ?.value ?? '',
              },
            })
            break
          case DeviceCalDueTypes.DueIn30Days:
            filterRequestValueToSend.push({
              columnId: item.columnId,
              value: {
                label: DeviceCalDueTypes.DueIn30Days,
                value:
                  calDueDateRangeOptions.find((x) => x.label === DeviceCalDueTypes.DueIn30Days)
                    ?.value ?? '',
              },
            })
            break
          case DeviceCalDueTypes.DueIn60Days:
            filterRequestValueToSend.push({
              columnId: item.columnId,
              value: {
                label: DeviceCalDueTypes.DueIn60Days,
                value:
                  calDueDateRangeOptions.find((x) => x.label === DeviceCalDueTypes.DueIn60Days)
                    ?.value ?? '',
              },
            })
            break
          case DeviceCalDueTypes.DueIn90Days:
            filterRequestValueToSend.push({
              columnId: item.columnId,
              value: {
                label: DeviceCalDueTypes.DueIn90Days,
                value:
                  calDueDateRangeOptions.find((x) => x.label === DeviceCalDueTypes.DueIn90Days)
                    ?.value ?? '',
              },
            })
            break

          default:
            filterRequestValueToSend.push({
              columnId: item.columnId,
              value: '',
            })
            break
        }
      }
    })

    if (onFilter) {
      onFilter(filterRequestValueToSend)
    }
  }

  useEffect(() => {
    if (deviceListData) {
      const deviceCalDueDaysCount = groupDeviceDataByCalDueDays(deviceListData)

      const wDataToDisplay: iWidgetData[] = [
        {
          originalData: DeviceCalDueTypes.DueIn7Days,
          displayText:
            DeviceCalDueTypeTexts.find((x) => x.label === DeviceCalDueTypes.DueIn7Days)?.value ||
            DeviceCalDueTypes.DueIn7Days,
          value:
            deviceCalDueDaysCount.find((x) => x.calDueParam === DeviceCalDueTypes.DueIn7Days)
              ?.count || 0,
          gridItemClassName: 'col-span-1',
          isFiltered: calDueDatesFilteredParam === DeviceCalDueTypes.DueIn7Days,
          columnId: CalibrationPageColumnIds.calDue,
        },
        {
          originalData: DeviceCalDueTypes.DueIn30Days,
          displayText:
            DeviceCalDueTypeTexts.find((x) => x.label === DeviceCalDueTypes.DueIn30Days)?.value ||
            DeviceCalDueTypes.DueIn30Days,
          value:
            deviceCalDueDaysCount.find((x) => x.calDueParam === DeviceCalDueTypes.DueIn30Days)
              ?.count || 0,
          gridItemClassName: 'col-span-1',
          isFiltered: calDueDatesFilteredParam === DeviceCalDueTypes.DueIn30Days,
          columnId: CalibrationPageColumnIds.calDue,
        },
        {
          originalData: DeviceCalDueTypes.DueIn60Days,
          displayText:
            DeviceCalDueTypeTexts.find((x) => x.label === DeviceCalDueTypes.DueIn60Days)?.value ||
            DeviceCalDueTypes.DueIn60Days,
          value:
            deviceCalDueDaysCount.find((x) => x.calDueParam === DeviceCalDueTypes.DueIn60Days)
              ?.count || 0,
          gridItemClassName: 'col-span-1',
          isFiltered: calDueDatesFilteredParam === DeviceCalDueTypes.DueIn60Days,
          columnId: CalibrationPageColumnIds.calDue,
        },
        {
          originalData: DeviceCalDueTypes.DueIn90Days,
          displayText:
            DeviceCalDueTypeTexts.find((x) => x.label === DeviceCalDueTypes.DueIn90Days)?.value ||
            DeviceCalDueTypes.DueIn90Days,
          value:
            deviceCalDueDaysCount.find((x) => x.calDueParam === DeviceCalDueTypes.DueIn90Days)
              ?.count || 0,
          gridItemClassName: 'col-span-1',
          isFiltered: calDueDatesFilteredParam === DeviceCalDueTypes.DueIn90Days,
          columnId: CalibrationPageColumnIds.calDue,
        },
      ]

      setWidgetData(wDataToDisplay)
    }
  }, [deviceListData, calDueDatesFilteredParam])

  return (
    <GenericWidget
      widgetProps={{
        title: 'Due',
        widgetData,
        widgetLabelCount: 4,
        columnId: CalibrationPageColumnIds.calDue,
        gridContainerClassName: 'grid-cols-1 gap-1',

        onFilter: (filterRequestValues: IFilterValues[]) => onFilterData(filterRequestValues),
      }}
    />
  )
}

export default withErrorBoundary(CalibrationCountByDaysWidget, {
  FallbackComponent: ErrorPage,
})
