import React, { useEffect } from 'react'
import { useForm, SubmitHandler, Controller, FieldErrors } from 'react-hook-form'
import { DateRange, IFilterValues, IselectOptionProps } from 'forms/FormModelInterface'
import { sub } from 'date-fns'
// eslint-disable-next-line import/no-extraneous-dependencies
import { DevTool } from '@hookform/devtools'

import { DateRangeSelectorTypes, SelectOptionTypes } from 'utils/CommonEnums'
import { ChevronRightIcon, CloseIcon } from 'assets/icons'
import { DatePickerControl, SelectControl, CreatableSelectControl } from 'components/formComponents'
import FilterFormTitle from 'components/formComponents/FilterFormTitle'

import { dateRangeOptions, selectAllOption, selectBlankOption } from 'forms/FormUtils'
import { withErrorBoundary } from 'react-error-boundary'
import ErrorPage from 'pages/common/ErrorPage'
import { DeviceNearMissFilterFormType, columnIDs } from './DeviceNearMiss'

interface DeviceNearMissFilterFormProps {
  // Define any props that this component will receive
  selectedValues?: DeviceNearMissFilterFormType
  deviceUserNames?: IselectOptionProps[]
  gasTypes?: IselectOptionProps[]
  percentValues?: IselectOptionProps[]
  alarmLevels?: IselectOptionProps[]

  // Define any functions that this component will call
  onFilterSubmit: (values: IFilterValues[]) => void
  onFilterReset: () => void
  onFilterFormClose: () => void
}

const defaultValues = (): DeviceNearMissFilterFormType => {
  const dtTo = new Date()
  const dtFrom = sub(new Date(), { months: 6 })
  const defValues: DeviceNearMissFilterFormType = {
    nearMissStartDateSelector: dateRangeOptions.find(
      (dateRangeOption) => dateRangeOption.value === DateRangeSelectorTypes.Custom,
    ) as IselectOptionProps,

    nearMissStartDate: {
      startDate: dtFrom,
      endDate: dtTo,
    },
    gasName: selectAllOption,
    percentValue: selectBlankOption,
    alarmLevel: selectBlankOption,
    duration: selectBlankOption,
    deviceUserName: selectBlankOption,
  }
  return defValues
}

function DeviceNearMissFilterForm(props: DeviceNearMissFilterFormProps): JSX.Element {
  const {
    selectedValues,
    deviceUserNames,
    alarmLevels,
    gasTypes,
    percentValues,
    onFilterSubmit,
    onFilterReset,
    onFilterFormClose,
  } = props

  const defaultFormValues = defaultValues()
  const { register, control, handleSubmit, setValue, reset, formState, watch } =
    useForm<DeviceNearMissFilterFormType>({
      defaultValues: defaultFormValues,
      values: selectedValues,
    })

  const watchDateSelector = watch('nearMissStartDateSelector')

  const [showCustomDate, setShowCustomDate] = React.useState<boolean>(false)

  useEffect(() => {
    if (watchDateSelector?.value === DateRangeSelectorTypes.Custom) {
      setShowCustomDate(true)
    } else {
      setShowCustomDate(false)
    }
  }, [watchDateSelector])

  const { errors, isDirty, isValid, isSubmitting } = formState

  const onSubmit: SubmitHandler<DeviceNearMissFilterFormType> = (data) => {
    const filterValues: IFilterValues[] = []

    filterValues.push({
      columnId: columnIDs.dateTime,
      value:
        data.nearMissStartDateSelector.label === DateRangeSelectorTypes.All
          ? ''
          : data.nearMissStartDate,
    })

    // filterValues.push({
    //   columnId: columnIDs.assignedTo,
    //   value: data.deviceUserName?.value?.toString().trim() ?
    //   data.deviceUserName?.value?.toString().trim() === SelectOptionTypes.All ?
    //   '' : data.deviceUserName?.value?.toString().trim() : ''
    // }),

    filterValues.push({
      columnId: columnIDs.assignedTo,
      value: data.deviceUserName?.value?.toString().trim()
        ? data.deviceUserName?.value?.toString().trim() === SelectOptionTypes.All
          ? ''
          : data.deviceUserName?.value?.toString().trim()
        : '',
    })

    filterValues.push({
      columnId: columnIDs.alarmLevel,
      value: data.alarmLevel?.value?.toString().trim()
        ? data.alarmLevel?.value?.toString().trim() === SelectOptionTypes.All
          ? ''
          : data.alarmLevel?.value?.toString().trim()
        : '',
    })

    filterValues.push({
      columnId: columnIDs.gasName,
      value: data.gasName?.value?.toString().trim()
        ? data.gasName?.value?.toString().trim() === SelectOptionTypes.All
          ? ''
          : data.gasName?.value?.toString().trim()
        : '',
    })

    filterValues.push({
      columnId: columnIDs.percentValue,
      value: data.percentValue?.value?.toString().trim()
        ? data.percentValue?.value?.toString().trim() === SelectOptionTypes.All
          ? ''
          : data.percentValue?.value?.toString().trim()
        : '',
    })

    onFilterSubmit(filterValues)
  }

  const onError = (formErrors: FieldErrors<DeviceNearMissFilterFormType>) => {
    console.log(formErrors)
  }

  const onReset = () => {
    reset(defaultFormValues)
    onFilterReset()
  }

  return (
    <div className='filter-form-container'>
      <div className='filter-form-header'>
        <FilterFormTitle />

        <div
          title='Close Filter Form'
          role='button'
          tabIndex={0}
          onClick={() => onFilterFormClose()}
          onKeyDown={() => onFilterFormClose()}
          className='filter-form-close-button '
        >
          <CloseIcon toggleClick={() => onFilterFormClose()} className='w-5 h-5 ' />
        </div>
      </div>

      <form
        onSubmit={handleSubmit(onSubmit, onError)}
        onReset={onFilterReset}
        noValidate
        className=''
      >
        <div className='filter-form-fields-container'>
          {/* // Assigned Date */}
          <div className='flex flex-col gap-0.5 items-start self-stretch'>
            <label
              htmlFor='nearMissStartDateSelector'
              className='pl-4 pr-3 text-xs font-bold leading-5 text-c-dark-blue-1 '
            >
              Date & Time
            </label>

            <Controller
              name='nearMissStartDateSelector'
              control={control}
              render={({ field }) => (
                <SelectControl
                  className='w-full h-[42px]'
                  selectControlProps={{
                    options: dateRangeOptions,
                    defaultValue: field.value,
                    isMulti: false,

                    onChange: (selectedOption: IselectOptionProps) => {
                      const selectedValue = selectedOption?.value
                      field.onChange(selectedOption)
                      if (selectedValue !== DateRangeSelectorTypes.Custom) {
                        setValue('nearMissStartDate', selectedValue as DateRange, {
                          shouldValidate: true,
                        })
                      } else {
                        setValue('nearMissStartDate', {
                          startDate: selectedValues?.nearMissStartDate.startDate || new Date(),
                          endDate: selectedValues?.nearMissStartDate.endDate || new Date(),
                        })
                      }
                    },
                  }}
                />
              )}
            />
          </div>

          {showCustomDate && (
            <>
              <div className='flex flex-row self-stretch justify-between w-full items-end'>
                <div className='w-[110px]'>
                  <div className='flex flex-col gap-0.5 items-start '>
                    <label
                      htmlFor='customDateFrom'
                      className='pl-4  text-xs font-bold leading-5 text-c-dark-blue-1 '
                    >
                      From
                    </label>
                    <div className=''>
                      <Controller
                        name='nearMissStartDate.startDate'
                        control={control}
                        render={({ field }) => (
                          <DatePickerControl
                            className=''
                            datePickerProps={{
                              selected: field.value,
                              onChange: (date: Date | null) => field.onChange(date as Date),
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>

                <div className='mx-0.5 w-4 h-4 my-3'>
                  <ChevronRightIcon className='self-center' />
                </div>

                <div className='w-[110px]'>
                  <div className='flex flex-col gap-0.5 items-start'>
                    <label
                      htmlFor='customDateFilterTo'
                      className='pl-4 text-xs font-bold leading-5 text-c-dark-blue-1 '
                    >
                      To
                    </label>
                    <div className='self-stretch'>
                      <Controller
                        name='nearMissStartDate.endDate'
                        control={control}
                        render={({ field }) => (
                          <DatePickerControl
                            className=''
                            datePickerProps={{
                              selected: field.value,
                              maxDate: new Date(),
                              onChange: (date: Date | null) => field.onChange(date as Date),
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {errors.nearMissStartDate && (
                <>
                  <p className='text-xs text-red-500'>
                    {errors.nearMissStartDate?.startDate?.message}
                  </p>
                  <p className='text-xs text-red-500'>
                    {errors.nearMissStartDate?.endDate?.message}
                  </p>
                </>
              )}
            </>
          )}

          {/* Gas type */}
          <div className='flex flex-col gap-0.5 items-start self-stretch'>
            <label
              htmlFor='gasType'
              className='pl-4 px-3 text-xs font-bold leading-5 text-c-dark-blue-1 '
            >
              Gas Type
            </label>
            <Controller
              name='gasName'
              control={control}
              render={({ field }) => (
                <SelectControl
                  className='w-full  h-[42px]'
                  selectControlProps={{
                    options: gasTypes,
                    value: field.value,
                    defaultValue: field.value,
                    isMulti: false,
                    isSearchable: true,
                    openMenuOnClick: true,
                    isDropDownSelectable: true,
                    placeholder: '-',
                    isClearable: false,
                    onChange: (selectedOption: IselectOptionProps) => {
                      field.onChange(selectedOption)
                    },
                  }}
                />
              )}
            />
            <p className='text-xs text-red-500'>{errors.gasName?.message}</p>
          </div>

          {/* percent val */}
          <div className='flex flex-col gap-0.5 items-start self-stretch'>
            <label
              htmlFor='percentVal'
              className='pl-4 px-3 text-xs font-bold leading-5 text-c-dark-blue-1 '
            >
              Near-Miss Percentage
            </label>
            <Controller
              name='percentValue'
              control={control}
              render={({ field }) => (
                <SelectControl
                  className='w-full h-[42px]'
                  selectControlProps={{
                    options: percentValues,
                    value: field.value,
                    defaultValue: field.value,
                    isMulti: false,
                    isSearchable: true,
                    openMenuOnClick: false,
                    isDropDownSelectable: true,
                    placeholder: '-',
                    isClearable: true,
                    onChange: (selectedOption: IselectOptionProps) => {
                      field.onChange(selectedOption)
                    },
                  }}
                />
              )}
            />
            <p className='text-xs text-red-500'>{errors.percentValue?.message}</p>
          </div>

          {/* Alarm level */}
          <div className='flex flex-col gap-0.5 items-start self-stretch'>
            <label
              htmlFor='alarmLevel'
              className='pl-4 px-3 text-xs font-bold leading-5 text-c-dark-blue-1 '
            >
              Alarm Level
            </label>
            <Controller
              name='alarmLevel'
              control={control}
              render={({ field }) => (
                <CreatableSelectControl
                  className='w-full h-[42px]'
                  selectControlProps={{
                    options: alarmLevels,
                    value: field.value,
                    defaultValue: field.value,
                    isMulti: false,
                    isSearchable: true,
                    openMenuOnClick: true,
                    placeholder: '-',
                    isClearable: true,
                    onChange: (selectedOption: IselectOptionProps) => {
                      field.onChange(selectedOption)
                    },
                  }}
                />
              )}
            />
            <p className='text-xs text-red-500'>{errors.alarmLevel?.message}</p>
          </div>

          {/* Duration */}

          {/* <div className='flex flex-col gap-0.5 items-start self-stretch'>
                <label
                  htmlFor='duration'
                  className='pl-4 px-3 text-xs font-bold leading-5 text-c-dark-blue-1 '
                >
                  Duration (HH:MM:SS)
                </label>
                <Controller
                  name='duration'
                  control={control}
                  render={({ field }) => (
                    <CreatableSelectControl
                      className='w-full'
                      selectControlProps={{
                        // options: alarmLevels,
                        value: field.value,
                        defaultValue: field.value,
                        isMulti: false,
                        isSearchable: false,
                        openMenuOnClick: false,
                        placeholder: '',
                        isClearable: true,
                        onChange: (selectedOption: IselectOptionProps) => {
                          field.onChange(selectedOption)
                        },
                      }}
                    />
                  )}
                />
                <p className='text-xs text-red-500'>{errors.alarmLevel?.message}</p>
              </div> */}

          {/* Assigned to */}
          <div className='flex flex-col gap-0.5 items-start self-stretch'>
            <label
              htmlFor='assignedToUserNamesFilter'
              className='pl-4 px-3 text-xs font-bold leading-5 text-c-dark-blue-1 '
            >
              Assigned To
            </label>
            <Controller
              name='deviceUserName'
              control={control}
              render={({ field }) => (
                <CreatableSelectControl
                  className='w-full h-[42px]'
                  selectControlProps={{
                    options: deviceUserNames,
                    value: field.value,
                    defaultValue: field.value,
                    isMulti: false,
                    isSearchable: true,
                    openMenuOnClick: true,
                    placeholder: '-',
                    isClearable: true,
                    onChange: (selectedOption: IselectOptionProps) => {
                      field.onChange(selectedOption)
                    },
                  }}
                />
              )}
            />
            <p className='text-xs text-red-500'>{errors.deviceUserName?.message}</p>
          </div>
        </div>
        {/* Buttons */}
        <div className='filter-form-footer'>
          <input
            className='filter-form-reset-button'
            type='reset'
            disabled={isSubmitting}
            onClick={() => {
              onReset()
            }}
          />
          <input
            className='filter-form-apply-button'
            type='submit'
            value='Apply'
            // disabled={!isDirty || !isValid || isSubmitting}
          />
        </div>
      </form>

      <DevTool control={control} />
    </div> // opening div
  )
}

export default withErrorBoundary(DeviceNearMissFilterForm, {
  FallbackComponent: ErrorPage,
})
