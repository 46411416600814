import React, { useContext, useEffect } from 'react'
import { useMsal } from '@azure/msal-react'
import { CrowconFormButton } from 'components/miscComponents/CrowconFormButton'
import { useForm, Controller, SubmitHandler, FieldErrors } from 'react-hook-form'
import TextInputControl from 'components/formComponents/TextInputControl'
import { SelectControl } from 'components/formComponents'
import { IselectOptionProps } from 'forms/FormModelInterface'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { UserAccountContext } from 'contexts/UserAccountContext'
import { UserAccountContextActions, UserRolesConstants } from 'utils/Constants'
import { getAccessTokenProps } from 'services/MSALService'
import { useLocation } from 'react-router-dom'
import { withErrorBoundary } from 'react-error-boundary'
import ErrorPage from 'pages/common/ErrorPage'
import { UserSettingsFormType } from './UserSettingsFormInterface'
import { updateUserSettings, iUpdateUserSettingsDTO } from '../data/UserSettingsPageData'

interface EditUserSettingsFormProps {
  initialValue: UserSettingsFormType
  locales: IselectOptionProps[]
}

function EditUserSettingsForm(props: EditUserSettingsFormProps) {
  const { dispatch } = useContext(UserAccountContext)
  const { initialValue, locales } = props
  const msalContext = useMsal()
  const location = useLocation()
  const queryClient = useQueryClient()
  const redirectPageURL = `${location.pathname}`
  const userSettingsMutationKey = () => ['usersettings', 'update']

  const updateUserSettingsMutation = useMutation({
    mutationKey: userSettingsMutationKey(),
    mutationFn: async (userInfo: UserSettingsFormType) => {
      const userToUpdate: iUpdateUserSettingsDTO = {
        id: userInfo.Id,
        firstName: userInfo.FirstName,
        lastName: userInfo.LastName,
        localeId: userInfo.Language?.value?.toString() || '',
        timeZone: userInfo.TimeZone,
      }
      const accessTokenProps: getAccessTokenProps = {
        msalContext,
        redirectPageURL,
      }
      const mutationResult = await updateUserSettings({ userToUpdate, accessTokenProps })
      return mutationResult
    },
    onSuccess: async (userInfo: iUpdateUserSettingsDTO) => {
      queryClient.invalidateQueries(['userInfo'])

      dispatch({
        type: UserAccountContextActions.UpdateBasicProfile,
        payload: {
          firstName: userInfo.firstName,
          lastName: userInfo.lastName,
          locale: locales.find((l) => l.value === userInfo.localeId)?.label || 'en-GB',
        },
      })
    },
    onError: () => {
      console.log('Error..')
    },
  })
  const {
    // register,
    handleSubmit,
    //  watch,
    //  reset,
    control,
    // setValue,
    // getValues,
    formState: {
      isDirty,
      isSubmitting,
      // isValid,
      // isValidating,
      // touchedFields,
      // isSubmitSuccessful,
      // isSubmitted,
      // dirtyFields,
    },
  } = useForm<UserSettingsFormType>({
    defaultValues: initialValue ?? {},
    values: initialValue,
    criteriaMode: 'all',
    shouldFocusError: true,
  })

  const onSubmit: SubmitHandler<UserSettingsFormType> = async (data: UserSettingsFormType) => {
    console.log(data)
    updateUserSettingsMutation.mutate(data)
  }
  const onError = (formErrors: FieldErrors<UserSettingsFormType>) => {
    console.log('Form errors: ', formErrors)
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit, onError)}
      className='w-full pt-8 sm:pt-3 md:pt-2 lg:pt-12'
    >
      <div className='h-1/2 w-full px-[10px] md:px-5'>
        <div className='flex flex-row h-[76px] w-full gap-4'>
          <div className='w-1/2'>
            <label
              htmlFor='firstNameControl'
              className='pl-[14px] text-3xs font-bold  text-c-dark-blue-1 '
            >
              First name
            </label>
            <Controller
              name='FirstName'
              control={control}
              render={({ field }) => (
                <TextInputControl
                  className='w-full h-[44px]  pl-[14px]'
                  id='firstName'
                  elementProps={{
                    onChange: (e: string) => field.onChange(e),
                    defaultValue: field.value,
                  }}
                />
              )}
            />
          </div>
          <div className='w-1/2'>
            <label
              htmlFor='lastNameControl'
              className='pl-[14px] text-3xs font-bold  text-c-dark-blue-1 '
            >
              Last name
            </label>
            <Controller
              name='LastName'
              control={control}
              render={({ field }) => (
                <TextInputControl
                  className='w-full h-[44px] pl-[14px]'
                  id='lastName'
                  elementProps={{
                    onChange: (e: string) => field.onChange(e),
                    defaultValue: field.value,
                  }}
                />
              )}
            />
          </div>
        </div>
        <div className='flex flex-col-reverse md:flex-row md:h-[76px] w-full gap-2  md:gap-4'>
          <div className='w-full md:w-1/2'>
            <label
              htmlFor='userEmailControl'
              className='pl-[14px] text-3xs font-bold  text-c-dark-blue-1 '
            >
              User email
            </label>
            <Controller
              name='UserEmail'
              control={control}
              render={({ field }) => (
                <TextInputControl
                  className='w-full h-[44px] pl-[14px]'
                  id='UserEmail'
                  elementProps={{
                    readOnly: true,
                    defaultValue: field.value,
                  }}
                />
              )}
            />
          </div>
          <div className='w-full md:w-1/2'>
            <label
              htmlFor='languageControl'
              className='pl-[14px] text-3xs font-bold  text-c-dark-blue-1 '
            >
              Language
            </label>
            <Controller
              name='Language'
              control={control}
              render={({ field }) => (
                <SelectControl
                  className='w-full h-[44px]'
                  selectControlProps={{
                    options: locales,
                    defaultValue: field.value,
                    isMulti: false,
                    isSearchable: true,
                    isDropDownSelectable: true,
                    openMenuOnClick: true,
                    placeholder: 'Select',
                    isClearable: false,
                    onChange: (selectedOption: IselectOptionProps) => {
                      field.onChange(selectedOption)
                    },
                  }}
                />
              )}
            />
          </div>
        </div>
        <div className='flex flex-row h-[76px] w-full  gap-4 pt-2'>
          <div className='w-1/2'>
            <label
              htmlFor='businessUnitControl'
              className='pl-[14px] text-3xs font-bold  text-c-dark-blue-1 '
            >
              Business unit
            </label>
            <Controller
              name='BusinessUnit'
              control={control}
              render={({ field }) => (
                <TextInputControl
                  className='w-full h-[44px] pl-[14px]'
                  id='businessUnit'
                  elementProps={{
                    readOnly: true,
                    defaultValue: field.value,
                  }}
                />
              )}
            />
          </div>
          <div className='w-1/2'>
            <label
              htmlFor='roleNameControl'
              className='pl-[14px] text-3xs font-bold  text-c-dark-blue-1 '
            >
              Your role
            </label>
            <Controller
              name='RoleName'
              control={control}
              render={({ field }) => (
                <TextInputControl
                  className='w-full h-[44px] pl-[14px]'
                  id='roleName'
                  elementProps={{
                    readOnly: true,
                    defaultValue: UserRolesConstants[field.value as string],
                  }}
                />
              )}
            />
          </div>
        </div>
        <div className='pt-8 pr-1 flex flex-row'>
          <CrowconFormButton
            disabled={isSubmitting || !isDirty}
            className='h-52 w-full sm:w-[99px] rounded-[26px]'
            value='Apply'
          />
        </div>
      </div>
      <div className='p-5'>
        {updateUserSettingsMutation.isSuccess && (
          <p className='text-green-700 text-base font-semibold'>
            User informaton updated successfully..
          </p>
        )}
        {updateUserSettingsMutation.isError && (
          <p className='text-red-700 text-base font-semibold'>User informaton updation failed..</p>
        )}
      </div>
      {updateUserSettingsMutation.isLoading && (
        <div className='animate-pulse absolute right-1/2 bottom-1/2  transform translate-x-1/2 translate-y-1/2 '>
          <div className='h-8 w-8 animate-spin rounded-full border-4 border-solid border-c-orange border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]' />
        </div>
      )}
    </form>
  )
}

export default withErrorBoundary(EditUserSettingsForm, {
  FallbackComponent: ErrorPage,
})
