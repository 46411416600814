import React, { useState } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import CrowconButton from 'components/miscComponents/CrowconButton'
import { useMsal } from '@azure/msal-react'
import { Spinner, TickIcon, AlertIcon, CloseIcon } from 'assets/icons'
import TextInputControl from 'components/formComponents/TextInputControl'
import { deleteOperator } from '../data/DeleteOperatorAPI'

interface ConfirmDeleteOperatorProps {
  OperatorId: string
  OperatorName: string
  UniqueId: string
  setShowConfirmDeleteModal: (val: boolean) => void
}

function ConfirmDeleteOperator(props: ConfirmDeleteOperatorProps) {
  const { OperatorId, UniqueId, OperatorName, setShowConfirmDeleteModal } = props
  const queryClient = useQueryClient()
  const operatorDeleteMutationKey = () => ['operator', 'delete']
  const msalContext = useMsal()
  const [deleteOperationCompleted, SetDeleteOperationCompleted] = useState(false)

  const deleteOperatorMutation = useMutation(deleteOperator, {
    mutationKey: operatorDeleteMutationKey(),
    onSuccess: (data) => {
      console.log('Operator deleted successfully')

      setTimeout(() => {
        setShowConfirmDeleteModal(false)
        SetDeleteOperationCompleted(true)
      }, 500)
    },
    onError: (error: Error) => {
      console.log('Error in deleting operator', error)
    },
  })

  const deleteCurrentOperator = async () => {
    deleteOperatorMutation.mutate({
      operatorsToDelete: [OperatorId],
      msalContext,
    })
  }

  const inValidateOperatorQuery = () => {
    queryClient.invalidateQueries({
      queryKey: ['operators'],
    })
  }

  const {
    isLoading,
    isSuccess: isDeleteOperatorMutationSuccess,
    isError: isDeleteOperatorMutationError,
    error: deleteOperatorMutationError,
  } = deleteOperatorMutation

  return (
    <div>
      {deleteOperationCompleted ? (
        <div className='w-[280px] h-48 pt-9'>
          <CloseIcon
            toggleClick={() => inValidateOperatorQuery()}
            className='rounded-full w-20 h-20 p-4  bg-c-red-1  hover:bg-c-red-2 active:bg-c-red-3 mx-28 cursor-pointer'
          />
          <div className='text-base pt-3 pl-20'>Operator Deleted</div>
        </div>
      ) : (
        <div>
          <div className='flex flex-row gap-2'>
            <div className='h-11 w-full font-bold text-base pt-2 leading-5  text-c-dark-blue-1'>
              Are you sure want to delete this operator?
            </div>
            <div>
              <CloseIcon
                toggleClick={() => setShowConfirmDeleteModal(false)}
                className='bg-c-dark-blue-1 rounded-full w-10 h-10 p-2.5 hover:bg-c-dark-blue-2  active:bg-c-dark-blue-3'
              />
            </div>
          </div>
          <div className='flex flex-row gap-2 pt-5 px-1'>
            <div className='w-1/2'>
              <label className='px-3' htmlFor='editOperatorName'>
                Operator Name
                <div className='py-1'>
                  <TextInputControl
                    id='editOperatorName'
                    className='h-11 w-full border-3 '
                    elementProps={{
                      defaultValue: OperatorName,
                      readOnly: true,
                    }}
                  />
                </div>
              </label>
            </div>
            <div className='w-1/2'>
              <label className='px-3' htmlFor='editOperatorID'>
                ID
                <div className='py-1'>
                  <TextInputControl
                    id='editOperatorID'
                    className='h-11 w-full border-3 '
                    elementProps={{
                      defaultValue: UniqueId,
                      readOnly: true,
                    }}
                  />
                </div>
              </label>
            </div>
          </div>
          <div className='flex flex-row gap-6 px-1'>
            <CrowconButton
              useDefaultTextColour={false}
              additionalClassName='text-sm text-c-dark-blue-1  font-bold mt-1 bg-c-light-blue-2 hover:text-white h-12  w-[110px] rounded-3xl'
              buttonChildren={<span>Cancel</span>}
              buttonAction={() => setShowConfirmDeleteModal(false)}
            />
            <button
              className='text-c-white text-sm w-[150px] h-[48px]  mt-1 rounded-3xl cursor-pointer bg-c-red-1  hover:bg-c-red-2 active:bg-c-red-3  outline-0  disabled:bg-c-light-blue-2'
              type='button'
              onClick={() => deleteCurrentOperator()}
            >
              <div className='flex flex-row gap-5 px-2'>
                <div className='w-6'>
                  {isLoading && <Spinner className='w-5 h-5' />}
                  {isDeleteOperatorMutationSuccess && <TickIcon className='w-5 h-5' />}
                  {isDeleteOperatorMutationError && (
                    <AlertIcon fill='#ff0000' className='w-5 h-5 ' />
                  )}
                </div>
                Delete
              </div>
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default ConfirmDeleteOperator
