import GenericWidget from 'components/viewComponents/GenericWidget'
import { iOperatorInfo } from 'data/Interfaces'
import { IFilterValues } from 'forms/FormModelInterface'
import React from 'react'
import { OperatorDeviceCountTypes } from 'utils/CommonEnums'
import { OperatorsPageColumnIds } from '../view/OperatorsPageUtils'

type DevicesOperatorHasProps = {
  data?: iOperatorInfo[]
  statusFilteredParam: string
  onFilter: (value: IFilterValues[]) => void
}

function getDeviceCountText(count: number): string {
  switch (count) {
    case 0:
      return OperatorDeviceCountTypes.NoDevice
    case 1:
      return OperatorDeviceCountTypes.OneDevice
    default:
      return OperatorDeviceCountTypes.MultipleDevices
  }
}

export default function DevicesInUse({
  data,
  statusFilteredParam,
  onFilter,
}: DevicesOperatorHasProps) {
  const widgetData = data?.reduce(
    (
      acc: {
        [key: string]: iOperatorInfo[]
      },
      cur,
    ) => ({
      ...acc,
      [getDeviceCountText(cur.currentDevice.length)]: [
        ...(acc[getDeviceCountText(cur.currentDevice.length)] ?? []),
        cur,
      ],
    }),
    {},
  )

  return (
    <GenericWidget
      widgetProps={{
        title: 'Operator Device Usage Overview',
        widgetData: Object.entries(widgetData ?? {}).map(([key, values]) => ({
          originalData: key,
          displayText: key,
          isFiltered: statusFilteredParam === key,
          columnId: OperatorsPageColumnIds.devicesInUse,
          value: values.length,
        })),
        columnId: OperatorsPageColumnIds.devicesInUse,
        widgetLabelCount: 4,
        gridContainerClassName: 'grid-cols-1 gap-1',
        onFilter: (filterRequestValues: IFilterValues[]) => onFilter?.(filterRequestValues),
      }}
    />
  )
}
