import React, { useState, useEffect, useContext, Fragment } from 'react'
import { useMsal } from '@azure/msal-react'
import { useLocation } from 'react-router-dom'
import { useForm, Controller, SubmitHandler, FieldErrors } from 'react-hook-form'
import { Dialog, Transition } from '@headlessui/react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import clsx from 'clsx'
import { IselectOptionGroupProps, IselectOptionProps } from 'forms/FormModelInterface'
import { CloseIcon, TickIcon, Spinner, DeleteIcon, AlertIcon } from 'assets/icons'
import CrowconButton from 'components/miscComponents/CrowconButton'
import { DoesUserHavePermission, IsUserGlobalScope } from 'utils/UserDataUtils'
import { UserPermissionTypes, UserRoleTypes } from 'utils/CommonEnums'
import { StatusValues } from 'utils/Constants'
import TextInputControl from 'components/formComponents/TextInputControl'
import { CreatableSelectControl, SelectControl } from 'components/formComponents'
import { iBusinessUnitDetails, iOperatorInfo } from 'data/Interfaces'
import { UserAccountContext } from 'contexts/UserAccountContext'
import { useBusinessUnitsListWrapper } from 'data/BusinessUnitsListHookWrapper'
import { useOperatorList } from 'data/OperatorListHook'
import { DeviceDetailsQueryKeyBySerial } from 'pages/deviceDetails/rootPage/data/DeviceDetailsQueryKeys'
import { useDeviceAssignmentHistoryData } from 'pages/deviceDetails/childPages/assignmentHistory/data/DeviceAssignmentHistoryData'

import { useGenericEventHandler } from 'data/GenericEventHandler'
import withRoleChecking from 'hoc/withRoleChecking'
import { EditDeviceFormType, EditDeviceFormProps } from './EditDeviceFormInterfaces'
import { updateDevice, iUpdateDeviceDTO, ideviceAssignmentUpdateDTO } from '../data/EditDeviceAPI'
import { AllDeviceTypesOptionsForSelect } from '../data/DevicesDataUtils'
import { useDoesDeviceHaveAnyLogs } from '../data/DeleteDeviceAPI'
import ConfirmDeleteDevice from './ConfirmDeleteDevice'

function EditDevice(props: EditDeviceFormProps): JSX.Element {
  const { isOpen, setIsOpened, rowData } = props

  const [canDeviceBeDeleted, setCanDeviceBeDeleted] = useState<boolean>(false)
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState<boolean>(false)

  const msalContext = useMsal()

  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState<IselectOptionProps>({
    label: rowData?.businessUnit?.name ?? '',
    value: rowData?.businessUnit?.id ?? '',
  })

  const [operatorList, setOperatorList] = useState<IselectOptionGroupProps[]>([])
  const location = useLocation()
  const redirectPageURL = `${location.pathname}${location.search}`
  const { genericEventHandler } = useGenericEventHandler()
  if (rowData === undefined) return <>No data</>

  const deviceId = rowData.id

  const defaultValues: EditDeviceFormType = {
    deviceId: rowData.id,

    SerialNumber: rowData.serialNumber,

    DeviceType: {
      label: rowData.deviceType,
      value: rowData.deviceType,
    },

    AssetNumber: rowData.customerAssetNumber,

    DeviceStatus: StatusValues.find(
      (val) => (val.label as string).toLowerCase() === rowData.status.toLowerCase(),
    ) ?? {
      label: rowData.status,
      value: rowData.status,
    },

    BusinessUnit: selectedBusinessUnit,

    DeviceUser: rowData.deviceUser
      ? {
          label: `${rowData.deviceUser.fullName}`,
          value: rowData.deviceUser.id,
        }
      : null,
  }

  const userAccountContext = useContext(UserAccountContext)
  const { state: userInfo } = userAccountContext

  const isGlobalUser = IsUserGlobalScope(userInfo)
  const canUserEditDeviceStatus = DoesUserHavePermission(
    userInfo,
    UserPermissionTypes.StatusActiveReadWrite,
  )

  const customerId: string = isGlobalUser ? (rowData.customerId as string) : ''

  const [businessUnitsListConverted, setBusinessUnitsListConverted] = useState<
    IselectOptionProps[]
  >([])
  const [operatorListConverted, setOperatorListConverted] = useState<IselectOptionProps[]>([])

  const {
    data: businessUnitsList,
    isLoading: isBusinessUnitsListLoading,
    isError: isBusinessUnitsListError,
    error: businessUnitsListError,
  } = useBusinessUnitsListWrapper(
    customerId as string,
    redirectPageURL,
    isGlobalUser,
    userInfo?.user?.id !== '',
  )

  useEffect(() => {
    if (
      businessUnitsList &&
      businessUnitsList.length > 0 &&
      !isBusinessUnitsListError &&
      !isBusinessUnitsListLoading
    ) {
      const buListConverted: IselectOptionProps[] = businessUnitsList.map(
        (bu: iBusinessUnitDetails) => ({
          label: bu.name,
          value: bu.id,
        }),
      )
      setBusinessUnitsListConverted(buListConverted)
    }
  }, [businessUnitsList, isBusinessUnitsListError, isBusinessUnitsListLoading])

  const {
    data: operatorListData,
    isLoading: isOperatorListDataLoading,
    isError: isOperatorListDataError,
    error: operatorListDataError,
  } = useOperatorList(
    redirectPageURL,
    selectedBusinessUnit?.value as string,
    customerId,
    businessUnitsList,
    businessUnitsList !== undefined,
  )

  useEffect(() => {
    if (
      operatorListData &&
      operatorListData.length > 0 &&
      !isOperatorListDataError &&
      !isOperatorListDataLoading
    ) {
      const opListConverted: IselectOptionProps[] = operatorListData.map(
        (operator: iOperatorInfo) => ({
          label: `${operator.firstName} ${operator.lastName}`,
          value: operator.id,
        }),
      )
      setOperatorListConverted(opListConverted)
    }
  }, [operatorListData, isOperatorListDataError, isOperatorListDataLoading])

  /* Delete device related code */
  const {
    data: doesDeviceHaveAnyLogs,
    isLoading: isDoesDeviceHaveAnyLogsLoading,
    isError: isDoesDeviceHaveAnyLogsError,
    error: doesDeviceHaveAnyLogsError,
  } = useDoesDeviceHaveAnyLogs(deviceId, redirectPageURL)

  const {
    data: deviceAssignmentHistory,
    isLoading: isDeviceAssignmentHistoryLoading,
    isError: isDeviceAssignmentHistoryError,
    error: deviceAssignmentHistoryError,
  } = useDeviceAssignmentHistoryData(deviceId, redirectPageURL, undefined, undefined, true)

  useEffect(() => {
    if (
      doesDeviceHaveAnyLogs !== undefined &&
      !isDoesDeviceHaveAnyLogsLoading &&
      !isDoesDeviceHaveAnyLogsError &&
      !isDeviceAssignmentHistoryLoading &&
      !isDeviceAssignmentHistoryError &&
      deviceAssignmentHistory !== undefined &&
      deviceAssignmentHistory.length === 0
    ) {
      setCanDeviceBeDeleted(!doesDeviceHaveAnyLogs)
    }
  }, [
    doesDeviceHaveAnyLogs,
    isDoesDeviceHaveAnyLogsLoading,
    isDoesDeviceHaveAnyLogsError,
    deviceAssignmentHistory,
    isDeviceAssignmentHistoryLoading,
    isDeviceAssignmentHistoryError,
  ])

  /* End Delete device related code */

  const deviceTypes: IselectOptionProps[] = AllDeviceTypesOptionsForSelect()

  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    getValues,
    formState: {
      errors,
      isDirty,
      isSubmitting,
      isValid,
      isValidating,
      touchedFields,
      isSubmitSuccessful,
      isSubmitted,
      dirtyFields,
    },
  } = useForm<EditDeviceFormType>({
    // mode: 'onChange',
    defaultValues: defaultValues ?? {},
    criteriaMode: 'all',
    shouldFocusError: true,
    // values: defaultValues,
  })

  const queryClient = useQueryClient()

  const updateDeviceMutation = useMutation(updateDevice, {
    onSuccess: (data) => {
      console.log('Device updated successfully')

      queryClient.invalidateQueries({
        queryKey: ['devices'],
      })
      queryClient.invalidateQueries({
        queryKey: DeviceDetailsQueryKeyBySerial(rowData.serialNumber),
      })
      queryClient.invalidateQueries({
        queryKey: ['operators'],
      })

      setTimeout(() => {
        setIsOpened(false)
        setShowConfirmDeleteModal(false)
      }, 500)
    },
    onError: (error: Error) => {
      console.log('Error in updating device', error)
    },
    // mutationKey:
  })

  const {
    isLoading,
    isSuccess: isUpdateDeviceMutationSuccess,
    isError: isUpdateDeviceMutationError,
    error: updateDeviceMutationError,
  } = updateDeviceMutation

  const handleClose = () => {
    setIsOpened(false)
    setShowConfirmDeleteModal(false)
    updateDeviceMutation.reset()
  }

  const onSubmit: SubmitHandler<EditDeviceFormType> = async (data: EditDeviceFormType) => {
    console.log(data)
    const updateDTO: iUpdateDeviceDTO = {
      deviceId: data.deviceId,
      businessUnitId: data.BusinessUnit.value as string,
    }
    if (data.AssetNumber !== rowData.customerAssetNumber)
      updateDTO.customerAssetNumber = data.AssetNumber

    if (
      canUserEditDeviceStatus &&
      (data.DeviceStatus.label as string).toLowerCase() !== rowData.status.toLowerCase()
    )
      updateDTO.Status = StatusValues.find(
        (val) =>
          (val.label as string).toLowerCase() === (data.DeviceStatus.label as string).toLowerCase(),
      )?.value as number

    if (data.DeviceUser?.value && rowData.deviceUser) {
      // changed user assignment
      if (data.DeviceUser.value !== rowData.deviceUser.id) {
        const deviceAssignmentDTO: ideviceAssignmentUpdateDTO = {
          deviceUserId: data.DeviceUser.value as string,
          assignedOn: new Date().toISOString(),
        }
        updateDTO.deviceAssignment = deviceAssignmentDTO
      }
    } else if (data.DeviceUser?.value && !rowData.deviceUser) {
      // assigned user
      const deviceAssignmentDTO: ideviceAssignmentUpdateDTO = {
        deviceUserId: data.DeviceUser.value as string,
        assignedOn: new Date().toISOString(),
      }
      updateDTO.deviceAssignment = deviceAssignmentDTO
    } else if (!data.DeviceUser && rowData.deviceUser) {
      // unassigned user
      const deviceAssignmentDTO: ideviceAssignmentUpdateDTO = {
        deviceUserId: rowData.deviceUser.id,
        unassignedOn: new Date().toISOString(),
      }
      updateDTO.deviceAssignment = deviceAssignmentDTO
    }

    await updateDeviceMutation.mutate({
      msalContext,
      updateDeviceDTO: updateDTO,
    })
  }

  const onError = (formErrors: FieldErrors<EditDeviceFormType>) => {
    console.log('Form errors: ', formErrors)
  }

  useEffect(() => {
    switch (true) {
      case isBusinessUnitsListError:
        genericEventHandler({
          onlyTrack: true,
          severity: 'error',
          message: businessUnitsListError?.message || 'Error getting business units list',
          error: businessUnitsListError,
          extraData: {
            component: 'EditDevice',
            action: 'get business units list',
          },
        })
        break
      case isOperatorListDataError:
        genericEventHandler({
          onlyTrack: true,
          severity: 'error',
          message: operatorListDataError?.message || 'Error getting operator list',
          extraData: {
            component: 'EditDevice',
            action: 'get operator list',
          },
        })
        break
      case isDoesDeviceHaveAnyLogsError:
        genericEventHandler({
          onlyTrack: true,
          severity: 'error',
          message: doesDeviceHaveAnyLogsError?.message || 'Error checking device logs',
          extraData: {
            component: 'EditDevice',
            action: 'check device logs',
          },
        })
        break
      case isDeviceAssignmentHistoryError:
        genericEventHandler({
          onlyTrack: true,
          severity: 'error',
          message:
            deviceAssignmentHistoryError?.message || 'Error getting device assignment history',
          extraData: {
            component: 'EditDevice',
            action: 'get device assignment history',
          },
        })
        break
      case isUpdateDeviceMutationError:
        genericEventHandler({
          onlyTrack: true,
          severity: 'error',
          message: updateDeviceMutationError?.message || 'Error updating device',
          extraData: {
            component: 'EditDevice',
            action: 'update device',
          },
        })
        break
      default:
        break
    }
  }, [
    isBusinessUnitsListError,
    businessUnitsListError,
    isOperatorListDataError,
    operatorListDataError,
    isDoesDeviceHaveAnyLogsError,
    doesDeviceHaveAnyLogsError,
    isDeviceAssignmentHistoryError,
    deviceAssignmentHistoryError,
    isUpdateDeviceMutationError,
    updateDeviceMutationError,
  ])
  const DeleteDeviceButton = withRoleChecking(
    () => (
      <button
        className={clsx('rounded-full w-14 h-12 flex items-center justify-center ', {
          'cursor-not-allowed bg-c-light-gray-1 hover:bg-gray-500': !canDeviceBeDeleted,
          'cursor-pointer bg-c-red-1  hover:bg-c-red-2 active:bg-c-red-3': canDeviceBeDeleted,
        })}
        type='button'
        disabled={!canDeviceBeDeleted}
        onClick={() => {
          setShowConfirmDeleteModal(true)
        }}
      >
        {isDoesDeviceHaveAnyLogsLoading || isDeviceAssignmentHistoryLoading ? (
          <Spinner className='w-5 h-5' />
        ) : (
          <DeleteIcon />
        )}
      </button>
    ),
    [UserRoleTypes.Admin, UserRoleTypes.CustomerAdmin.split(' ').join('')],
  )
  return (
    <div>
      <div className='font-poppins w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all  h-full  text-c-dark-blue-1 font-bold text-3xs md:w-363 md:h-517 md:pt-6 md:px-6'>
        <div className='flex flex-col '>
          <div className='flex flex-row gap-2'>
            <div className='h-11 w-full font-bold text-base pt-2 leading-5  text-c-dark-blue-1'>
              Edit device
            </div>
            <div>
              <CloseIcon
                toggleClick={() => handleClose()}
                className='bg-c-dark-blue-1 rounded-full w-10 h-10 p-2.5 hover:bg-c-dark-blue-2  active:bg-c-dark-blue-3'
              />
            </div>
          </div>
          <div>
            <form onSubmit={handleSubmit(onSubmit, onError)} noValidate>
              <div className='flex flex-col w-72 pt-4 md:w-80 md:pt-4 gap-2'>
                <div className='flex flex-row w-full space-x-2  h-16'>
                  <div className='w-1/2'>
                    <label className='px-3' htmlFor='editDeviceSerialNumber'>
                      Serial Number
                      <div className='py-1'>
                        <Controller
                          name='SerialNumber'
                          control={control}
                          render={({ field }) => (
                            <TextInputControl
                              id='editDeviceSerialNumber'
                              className='h-11 w-full border-3 '
                              readOnly
                              elementProps={{
                                onChange: (e: string) => field.onChange(e),
                                readOnly: 'true',
                                defaultValue: field.value,
                              }}
                            />
                          )}
                        />
                      </div>
                    </label>
                  </div>

                  <div className='w-1/2'>
                    <label className='px-3' htmlFor='editDeviceFormDeviceType'>
                      Device Type
                      <div className='w-full py-1 h-10'>
                        <Controller
                          name='DeviceType'
                          control={control}
                          render={({ field }) => (
                            <SelectControl
                              id='editDeviceFormDeviceType'
                              className='h-11'
                              selectControlProps={{
                                options: deviceTypes,
                                isDisabled: true,
                                defaultValue: field.value,
                                isMulti: false,
                                onChange: (selectedOption: IselectOptionProps) => {
                                  field.onChange(selectedOption)
                                },
                              }}
                            />
                          )}
                        />
                      </div>
                    </label>
                  </div>
                </div>
                <div className='flex flex-row w-full space-x-2  h-16'>
                  <div className='w-1/2'>
                    <label className='px-3' htmlFor='editDeviceFormAssetNumber'>
                      Asset Number
                      <div className='py-1'>
                        <Controller
                          name='AssetNumber'
                          control={control}
                          render={({ field }) => (
                            <TextInputControl
                              id='editDeviceFormAssetNumber'
                              className='h-11 w-full border-3'
                              elementProps={{
                                onChange: (e: string) => field.onChange(e),
                                defaultValue: field.value,
                              }}
                            />
                          )}
                        />
                      </div>
                    </label>
                  </div>

                  <div className='w-1/2'>
                    <label className='px-3' htmlFor='editDeviceFormDeviceStatus'>
                      Status
                      <div className='py-1'>
                        <Controller
                          name='DeviceStatus'
                          control={control}
                          render={({ field }) => (
                            <SelectControl
                              id='editDeviceFormDeviceStatus'
                              className='h-11 rounded-3xl'
                              selectControlProps={{
                                options: StatusValues,
                                defaultValue: field.value,
                                isMulti: false,
                                isSearchable: true,
                                isDropDownSelectable: true,
                                openMenuOnClick: true,
                                placeholder: 'Select an option',
                                isClearable: false,
                                isDisabled: !canUserEditDeviceStatus,
                                onChange: (selectedOption: IselectOptionProps) => {
                                  field.onChange(selectedOption)
                                },
                              }}
                            />
                          )}
                        />
                      </div>
                    </label>
                  </div>
                </div>
                <div className='h-16'>
                  <label className='px-3' htmlFor='editDeviceFormBusinessunit'>
                    Business Unit
                    <div className='py-1'>
                      <Controller
                        name='BusinessUnit'
                        control={control}
                        render={({ field }) => (
                          <SelectControl
                            id='editDeviceFormBusinessunit'
                            className='h-11 rounded-3xl'
                            selectControlProps={{
                              isLoading: isBusinessUnitsListLoading,
                              options: businessUnitsListConverted,
                              // getOptionLabel: (option: iBusinessUnitDetails) => option.name,
                              // getOptionvalue: (option: iBusinessUnitDetails) => option.id,
                              defaultValue: field.value,
                              isMulti: false,
                              isSearchable: true,
                              isDropDownSelectable: true,
                              openMenuOnClick: true,
                              placeholder: 'Select Business unit',
                              isClearable: false,
                              onChange: (selectedOption: IselectOptionProps) => {
                                field.onChange(selectedOption)
                                setSelectedBusinessUnit(selectedOption)
                              },
                            }}
                          />
                        )}
                      />
                    </div>
                  </label>
                </div>

                <div className='h-16'>
                  <label className='px-3' htmlFor='editDeviceFormDeviceUser'>
                    Assigned To
                    <div className='py-1'>
                      <Controller
                        name='DeviceUser'
                        control={control}
                        render={({ field }) => (
                          <CreatableSelectControl
                            id='editDeviceFormDeviceUser'
                            className='h-11 w-full'
                            selectControlProps={{
                              options: operatorListConverted,
                              isLoading: isOperatorListDataLoading,
                              defaultValue: field.value,
                              isMulti: false,
                              isSearchable: true,
                              openMenuOnClick: true,
                              isDropDownSelectable: false,
                              placeholder: 'Search users',
                              isClearable: true,
                              menuPlacement: 'top',
                              maxMenuHeight: 120,
                              onChange: (selectedOption: IselectOptionProps) => {
                                field.onChange(selectedOption)
                              },
                            }}
                          />
                        )}
                      />
                    </div>
                  </label>
                </div>

                <div className='w-full gap-2 pt-3 md:pt-8 flex flex-row justify-center items-center'>
                  <DeleteDeviceButton />

                  <CrowconButton
                    useDefaultTextColour={false}
                    additionalClassName='text-base text-c-dark-blue-1  font-bold mt-1 bg-c-light-blue-2 hover:text-white h-12  w-28 rounded-3xl'
                    buttonChildren={<span>Cancel</span>}
                    buttonAction={() => handleClose()}
                  />

                  <button
                    type='submit'
                    className='text-base font-bold text-white m-1 p-1 rounded-3xl w-48 h-12 bg-c-dark-blue-1 outline-0 hover:bg-c-dark-blue-2  active:bg-c-dark-blue-3'
                    disabled={!isDirty || !isValid || isSubmitting || isLoading}
                  >
                    <div className='px-3 md:px-5 flex flex-row py-1.5 gap-1 justify-center '>
                      {isLoading && <Spinner className='w-5 h-5' />}
                      {isUpdateDeviceMutationSuccess && <TickIcon className='w-5 h-5' />}
                      {isUpdateDeviceMutationError && (
                        <AlertIcon fill='#ff0000' className='w-5 h-5 ' />
                      )}
                      <div className='flex-grow'> Apply </div>
                    </div>
                  </button>
                </div>
                {isUpdateDeviceMutationError && (
                  <div className='text-red-500 text-xs font-normal pt-2 pl-2'>
                    {updateDeviceMutationError?.message ?? ''}
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* Delete device modal */}
      <div className='z-50'>
        {/* {showConfirmDeleteModal && ( */}
        <Transition appear show={showConfirmDeleteModal} as={Fragment}>
          <Dialog as='div' className='z-50' onClose={() => setShowConfirmDeleteModal(false)}>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <div className='fixed inset-0 bg-black/25' />
            </Transition.Child>

            <div className='fixed inset-0 overflow-y-auto z-50'>
              <div className='flex min-h-full items-center justify-center p-4 text-center'>
                <Transition.Child
                  as={Fragment}
                  enter='ease-out duration-300'
                  enterFrom='opacity-0 scale-95'
                  enterTo='opacity-100 scale-100'
                  leave='ease-in duration-200'
                  leaveFrom='opacity-100 scale-100'
                  leaveTo='opacity-0 scale-95'
                >
                  <Dialog.Panel className='z-50'>
                    <ConfirmDeleteDevice
                      rowData={rowData}
                      onCloseEditDialog={() => handleClose()}
                      onCloseConfirmDeleteDialog={() => setShowConfirmDeleteModal(false)}
                    />
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
        {/* )} */}
      </div>
    </div>
  )
}

export default EditDevice
