import { useContext } from 'react'
import { QueryClient, useQuery } from '@tanstack/react-query'
import { useMsal } from '@azure/msal-react'

import ConnectAPIClient from 'services/ConnectAPIClient'
import { IselectOptionProps } from 'forms/FormModelInterface'
import { selectAllOption } from 'forms/FormUtils'
import { UserAccountContext } from 'contexts/UserAccountContext'
import { AxiosError } from 'axios'
import { getAPIData, iGenericAPIParams } from './GenericData'

export const customerListQueryKey = () => ['customers', 'list']
export const myCustomerInfoQueryKey = () => ['customers', 'detail', 'me']
export const billingPlanQueryKey = () => ['customers', 'subscriptions']
export const featureQueryKey = () => ['customers', 'feature']
export const customerDataURL = () => `customers/api/customer`
export const myCustomerInfo = () => `customers/api/customer/me`
export const billingPlanApiUrl = () => 'customers/api/Subscription/plan'
export const featureApiUrl = () => 'customers/api/feature'

export interface iBillingPlan {
  id?: string
  name: string
  description: string
  numberOfDevices: number
  numberOfOperators: number
  numberOfUsers: number
  numberOfApiKeys: number
  billingPlanType?: string
  status?: string
}

export interface UsedByCustomer {
  customerId: string
  name: string
}
export interface iFeature {
  id: string
  name: string
  description: string
  status: string
  usedByCustomers: UsedByCustomer[]
}

export interface iCustomerList {
  id: string
  name: string
  billingPlans: iBillingPlan
  subscriptionDurationInMonths: number
  subscriptionActivationDate: Date | string
  subscriptionExpirationDate: Date | string
  status: string
  statusDescription: string
  features: string[]
}

export const allCustomerOption: iCustomerList = {
  id: 'all',
  name: 'All',
  billingPlans: {
    id: 'all',
    name: 'All',
    description: 'All',
    numberOfDevices: 0,
    numberOfOperators: 0,
    numberOfUsers: 0,
    numberOfApiKeys: 0,
    billingPlanType: 'all',
  },
  subscriptionDurationInMonths: 0,
  subscriptionActivationDate: 'all',
  subscriptionExpirationDate: 'all',
  status: 'all',
  statusDescription: 'all',
  features: ['all'],
}

function convertCustomerListToIselect(resultData: iCustomerList[]) {
  const customerListConverted: IselectOptionProps[] = resultData.map((customer) => ({
    value: customer.id,
    label: customer.name,
  }))
  customerListConverted.unshift(selectAllOption)
  return customerListConverted
}

export function useCustomerList(redirectPageURL: string, isGlobalUser: boolean, enabled = true) {
  const msalContext = useMsal()
  const apiURL = customerDataURL()
  const queryKey = customerListQueryKey()
  const userContext = useContext(UserAccountContext)
  const adb2cPolicy = userContext?.state.adb2cPolicy

  return useQuery<iCustomerList[], AxiosError>(
    queryKey,
    () =>
      getAPIData<iGenericAPIParams, iCustomerList[]>({
        msalContext,
        redirectPageURL,
        apiURL,
        adb2cPolicy,
      }),
    {
      staleTime: 24 * 60 * 60 * 1000,
      enabled,
      select: (resultData) => {
        resultData.sort((a, b) => a.name.localeCompare(b.name))

        if (resultData?.find((customer) => customer.id === allCustomerOption.id)) {
          return resultData
        }
        resultData.unshift(allCustomerOption)
        return resultData
      },
      onError: (error) => {
        console.log(error)
      },
    },
  )
}
export function useCustomerMe(redirectPageURL: string, isGlobalUser: boolean, enabled: boolean) {
  const msalContext = useMsal()
  const apiURL = myCustomerInfo()
  const queryKey = myCustomerInfoQueryKey()
  const userContext = useContext(UserAccountContext)
  const adb2cPolicy = userContext?.state.adb2cPolicy

  return useQuery<iCustomerList, AxiosError>(
    queryKey,
    () =>
      getAPIData<iGenericAPIParams, iCustomerList>({
        msalContext,
        redirectPageURL,
        apiURL,
        adb2cPolicy,
      }),
    {
      enabled,
      onError: (error) => {
        console.log(error)
      },
    },
  )
}

interface iFetchCustomerListParams {
  token: string
}

interface iGetOrFetchCustomerListProps {
  queryClient: QueryClient
  token: string
}

export async function FetchCustomerList(
  params: iFetchCustomerListParams,
): Promise<iCustomerList[]> {
  try {
    const { token } = params
    const url = customerDataURL()
    const resp = await ConnectAPIClient.get<iCustomerList[]>(url, token)
    const response = resp.data
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function getOrFetchCustomerList(props: iGetOrFetchCustomerListProps) {
  const { queryClient, token } = props

  const customerListCached = queryClient.getQueryData<iCustomerList[]>(customerListQueryKey())

  if (customerListCached) {
    // const customerListConverted: IselectOptionProps[] =
    //   convertCustomerListToIselect(customerListCached)
    return customerListCached
  }

  const customerListFetched = await queryClient.fetchQuery(customerListQueryKey(), async () =>
    FetchCustomerList({
      token,
    }),
  )

  // const customerListConverted: IselectOptionProps[] =
  //   convertCustomerListToIselect(customerListFetched)
  return customerListFetched
}

export const useSubscription = (redirectPageURL: string, isGlobalUser: boolean, enabled = true) => {
  const msalContext = useMsal()
  const userContext = useContext(UserAccountContext)
  const adb2cPolicy = userContext?.state.adb2cPolicy
  const apiURL = billingPlanApiUrl()
  const queryKey = billingPlanQueryKey()

  return useQuery<iBillingPlan[], AxiosError>(
    queryKey,
    () =>
      getAPIData<iGenericAPIParams, iBillingPlan[]>({
        msalContext,
        redirectPageURL,
        apiURL,
        adb2cPolicy,
      }),
    {
      enabled,
      onError: (error: AxiosError) => {
        console.log(error)
      },
    },
  )
}

export const useFeatures = (redirectPageURL: string, isGlobalUser: boolean, enabled = true) => {
  const msalContext = useMsal()
  const userContext = useContext(UserAccountContext)
  const adb2cPolicy = userContext?.state.adb2cPolicy
  const apiURL = featureApiUrl()
  const queryKey = featureQueryKey()

  return useQuery<iFeature[], AxiosError>(
    queryKey,
    () =>
      getAPIData<iGenericAPIParams, iFeature[]>({
        msalContext,
        redirectPageURL,
        apiURL,
        adb2cPolicy,
      }),
    {
      enabled,
      onError: (error: AxiosError) => {
        console.log(error)
      },
    },
  )
}
