import React, { useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js'

import { useOperatorList } from 'data/OperatorListHook'
import BusinessAndCustomerSelection from 'components/modules/BusinessAndCustomerSelection'
import { BusinessContext } from 'contexts/BusinessContext'
import { withPageTracking } from 'utils/AppInsightConfig'
import { useGenericEventHandler } from 'data/GenericEventHandler'
import { withErrorBoundary } from 'react-error-boundary'
import ErrorPage from 'pages/common/ErrorPage'
import { Doughnut } from 'react-chartjs-2'
import clsx from 'clsx'
import { useFleetHealthData, useFleetInsightsData } from '../data/DashboardData'
import DashboardOverdueWidget from './widgets/DashboardOverdueWidget'
import DashboardOverdueDetailsWidget from './widgets/DashboardOverdueDetailsWidget'
import DashboardAlarmsChart from './widgets/DashboardAlarmsChart'
import DashboardFaultsChart from './widgets/DashboardFaultsChart'
import DashboardDeviceAssignmentChart from './widgets/DashboardDeviceAssignmentChart'
import DashboardOperatorAssignmentChart from './widgets/DashboardOperatorAssignmentChart'
import DashboardDueIn30DaysWidget from './widgets/DashboardDueIn30DaysWidget'
import DashboardDueIn90DaysWidget from './widgets/DashboardDueIn90DaysWidget'
import DashboardDueIn60DaysWidget from './widgets/DashboardDueIn60DaysWidget'
/**
 * ChatJS is a library for creating charts. It is used in this file to create the charts for the dashboard.
 * below down is the configuration for the chart-js
 */
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
)

ChartJS.defaults.font.family = 'Poppins'
ChartJS.defaults.font.size = 12
ChartJS.defaults.font.weight = 'normal'
ChartJS.defaults.plugins.title.color = '#1B4373'
ChartJS.defaults.plugins.title.display = true
ChartJS.defaults.plugins.title.position = 'top'
ChartJS.defaults.plugins.title.align = 'start'
ChartJS.defaults.plugins.legend.position = 'top'
ChartJS.defaults.plugins.legend.align = 'start'

ChartJS.defaults.plugins.legend.display = true
ChartJS.defaults.plugins.legend.labels.pointStyle = 'circle'
ChartJS.defaults.plugins.legend.labels.usePointStyle = true
ChartJS.defaults.plugins.legend.labels.borderRadius = 8
ChartJS.defaults.plugins.legend.labels.useBorderRadius = true

// ChartJS.defaults.layout.padding = 8
ChartJS.defaults.responsive = false
ChartJS.defaults.maintainAspectRatio = false

ChartJS.defaults.hover.mode = 'point'
ChartJS.defaults.hover.intersect = true
ChartJS.defaults.hover.includeInvisible = false

/**
 * This function is used to create the skeleton for the Dues of the devices
 * @returns JSX.Element as Skeleton for the Chip
 */
function ChipSkeleton() {
  const bgColor = 'bg-slate-300'
  return (
    <div className='animate-pulse p-[4px] '>
      <div className={`${bgColor} w-3/5 h-6 rounded-xl`} />

      <div className='mt-10 flex items-end'>
        <div className={`h-6 mb-2 w-1/6 ${bgColor} rounded-xl`} />
        <div className={`mx-2 h-3 mb-2 w-1/12 ${bgColor} rounded-xl`} />
        <div className={`h-6 mb-2 w-1/6 ${bgColor} rounded-xl`} />
      </div>
      <div className=' flex items-baseline justify-between '>
        <div className={`${bgColor} h-5 w-1/6 rounded-xl`} />
        <div className={`${bgColor} h-5 w-1/5 rounded-xl`} />
      </div>
    </div>
  )
}
/**
 * This function is used to create the skeleton for the Devices
 * @returns JSX.Element as Skeleton for the Device Calibration
 */
function DeviceCalibrationSkeleton() {
  const bgColor = 'bg-slate-300'
  return (
    <div className='h-full flex-grow px-2 py-2 w-full overflow-hidden  shadow-card rounded-2xl bg-white flex'>
      <div className='animate-pulse p-2 w-full   '>
        <div className={`${bgColor} w-full h-6 rounded-xl`} />

        <div className='mt-2 flex items-end'>
          <div className={`h-6 mb-2 w-1/6 ${bgColor} rounded-xl`} />
          <div className={`h-6 mb-2 ml-2 w-2/6 ${bgColor} rounded-xl`} />
        </div>
        {Array.from({ length: 5 }).map(() => (
          <div key={Math.random()}>
            <div className='flex justify-between my-5'>
              <div className='flex flex-grow items-center'>
                <div className={`${bgColor} w-8 h-10  rounded-xl`} />
                <div className={`${bgColor} w-1/5 h-2/5 mx-5 rounded-xl`} />
              </div>
              <div className='flex items-center'>
                <div className={`${bgColor} w-8 h-2/5 rounded-xl`} />
                <div className={`${bgColor} w-6 h-6 ml-3 rounded-xl`} />
              </div>
            </div>
            <div className={`${bgColor} mt9 mb-6  w-full h-2 rounded-xl`} />
          </div>
        ))}
      </div>
    </div>
  )
}
/**
 * This function is used to create the skeleton for the Bar Graph
 * @returns JSX.Element as Skeleton for the Bar Graph
 */
function BarGraphSkeleton() {
  const bgColor = 'bg-slate-300'
  return (
    <div className='flex-grow flex flex-col px-4 py-4  bg-c-white shadow-card rounded-2xl items-center'>
      <div className='animate-pulse flex h-full w-full flex-col pb-4 '>
        <div className={`h-6 mb-2 w-3/6 ${bgColor} rounded-xl my-2`} />
        <div className='flex-row flex items-center'>
          <div className={`h-2 mb-2 w-2 ${bgColor} rounded-xl`} />
          <div className={`h-3  mb-2 mx-2  w-2/6 ${bgColor} rounded-xl`} />
          <div className={`h-2 mb-2 w-2 ${bgColor} rounded-xl`} />
          <div className={`h-3 mb-2 ml-2 w-2/6 ${bgColor} rounded-xl`} />
        </div>
        <div className='flex items-end relative'>
          <div className='flex flex-col'>
            {Array.from({ length: 11 }).map(() => (
              <div key={Math.random()} className={`h-3 mb-2 w-5 ${bgColor} rounded-xl`} />
            ))}
            <div className={` mb-2 absolute bottom-[-29px] left-0 px-3  w-full`}>
              <div className={`h-[1px]  ${bgColor} rounded-xl `} />
              <div className='flex mt-2'>
                {Array.from({ length: 2 }).map(() => (
                  <div key={Math.random()} className={`h-4 w-10 ml-4 ${bgColor} rounded-xl `} />
                ))}
              </div>
            </div>
          </div>
          <div className={`h-full w-[1px] mx-1  ${bgColor} `} />
          <div className={`h-4/6 w-5  rounded-t-lg mb-1 ${bgColor} `} />
          <div className={`h-3/6 w-5  rounded-t-lg ml-[1px] mb-1 ${bgColor} `} />
          <div className={`h-5/6 w-5  rounded-t-lg mb-1 ml-3 ${bgColor} `} />
          <div className={`h-2/6 w-5  rounded-t-lg ml-[1px] mb-1 ${bgColor} `} />
        </div>
      </div>
    </div>
  )
}
/**
 * This function is used to create the skeleton for the Pie Graph
 * @returns JSX.Element as Skeleton for the Pie Graph
 */
function PieGraphSkeleton() {
  const bgColor = 'bg-slate-300'
  return (
    <div className='flex-grow flex flex-col px-4 py-4  bg-c-white shadow-card rounded-2xl items-center'>
      <div className='animate-pulse flex h-full w-full flex-col '>
        <div className={`h-6 mb-2 w-3/6 ${bgColor} rounded-xl my-2`} />
        <div className='flex-row flex items-center'>
          <div className={`h-2 mb-2 w-2 ${bgColor} rounded-xl`} />
          <div className={`h-3  mb-2 mx-2  w-2/6 ${bgColor} rounded-xl`} />
          <div className={`h-2 mb-2 w-2 ${bgColor} rounded-xl`} />
          <div className={`h-3 mb-2 ml-2 w-2/6 ${bgColor} rounded-xl`} />
        </div>
        <div className='mt-3 flex flex-grow justify-center items-center'>
          <div
            className={`${bgColor} w-[12rem] h-[12rem] flex lg:w-[14rem] lg:h-[14rem] rounded-full justify-center items-center`}
          >
            <div className='bg-white w-[6rem] h-[6rem] lg:w-[8rem] lg:h-[8rem]   rounded-full' />
          </div>
        </div>
      </div>
    </div>
  )
}

function Dashboard(): JSX.Element {
  /**
   * Getting the selectedCustomer, selectedBusinessUnit and businessUnitDataFromQuery from the BusinessContext
   * now which is handled in the BusinessContextProvider globally
   */
  const { selectedCustomer, selectedBusinessUnit, businessUnitDataFromQuery } =
    useContext(BusinessContext)
  const businessUnitsList = businessUnitDataFromQuery?.data
  const { genericEventHandler } = useGenericEventHandler()
  const location = useLocation()
  const redirectPageURL = `${location.pathname}${location.search}`

  useEffect(() => {
    document.title = 'Home - Crowcon Connect'
  }, [])

  const [dueIn30DaysData, setDueIn30DaysData] = React.useState<number>(0)
  const [dueIn60DaysData, setDueIn60DaysData] = React.useState<number>(0)
  const [dueIn90DaysData, setDueIn90DaysData] = React.useState<number>(0)

  const {
    data: fleetHealthData,
    isLoading: isFleetHealthDataLoading,
    isError: isFleetHealthDataError,
    error: fleetHealthDataError,
  } = useFleetHealthData(
    redirectPageURL,
    selectedBusinessUnit?.id ?? ('' as string),
    selectedCustomer?.id ?? ('' as string),
    businessUnitsList,
    businessUnitsList !== undefined,
  )

  const {
    data: fleetInsightsData,
    isLoading: isFleetInsightsDataLoading,
    isError: isFleetInsightsDataError,
    error: fleetInsightsDataError,
  } = useFleetInsightsData(
    redirectPageURL,
    selectedBusinessUnit?.id ?? ('' as string),
    selectedCustomer?.id ?? ('' as string),
    businessUnitsList,
    businessUnitsList !== undefined,
  )

  const {
    data: operatorListData,
    isLoading: isOperatorListDataLoading,
    isError: isOperatorListDataError,
    error: operatorListDataError,
  } = useOperatorList(
    redirectPageURL,
    selectedBusinessUnit?.id ?? ('' as string),
    selectedCustomer?.id ?? ('' as string),
    businessUnitsList,
    businessUnitsList !== undefined,
  )

  useEffect(() => {
    switch (true) {
      case isFleetHealthDataError:
        genericEventHandler({
          onlyTrack: true,
          severity: 'error',
          message: fleetHealthDataError?.message || 'Error getting fleet health data',
          error: fleetHealthDataError,
          extraData: {
            component: 'Dashboard',
            action: 'get fleet health data',
          },
        })
        break
      case isFleetInsightsDataError:
        genericEventHandler({
          onlyTrack: true,
          severity: 'error',
          message: fleetInsightsDataError?.message || 'Error getting fleet insights data',
          error: fleetInsightsDataError,
          extraData: {
            component: 'Dashboard',
            action: 'get fleet insights data',
          },
        })
        break
      case isOperatorListDataError:
        genericEventHandler({
          onlyTrack: true,
          severity: 'error',
          message: operatorListDataError?.message || 'Error getting operator list data',
          error: operatorListDataError,
          extraData: {
            component: 'Dashboard',
            action: 'get operator list data',
          },
        })
        break
      default:
        break
    }
  }, [
    isFleetHealthDataError,
    isFleetInsightsDataError,
    isOperatorListDataError,
    fleetHealthDataError,
    fleetInsightsDataError,
    operatorListDataError,
  ])

  useEffect(() => {
    if (fleetHealthData) {
      const due30d = fleetHealthData.upcomingCalibrations?.thirtyDaysDue || 0
      const due60d = fleetHealthData.upcomingCalibrations?.sixtyDaysDue || 0
      const due90d = fleetHealthData.upcomingCalibrations?.ninetyDaysDue || 0

      setDueIn30DaysData(due30d)
      setDueIn60DaysData(due30d + due60d)
      setDueIn90DaysData(due30d + due60d + due90d)
    }
  }, [fleetHealthData])

  return (
    <div className='flex-col h-screen '>
      <BusinessAndCustomerSelection pageTitle='Dashboard' />
      <div className='bg-c-light-blue-2 pb-4 '>
        {/* <div className='grid grid-cols-2 lg:grid-cols-4 px-4 pt-4 gap-4'>
          <div className='flex flex-row items-center overflow-hidden gap-2 rounded-2xl bg-c-white p-3 shadow-card  transform transition duration-[400ms] ease-in-out  '>
            <div className='p-3 bg-c-orange/20 rounded-full '>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 24 24'
                strokeWidth='1.5'
                className='h-6 w-6 stroke-c-orange fill-transparent'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M10.5 19.5h3m-6.75 2.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-15a2.25 2.25 0 0 0-2.25-2.25H6.75A2.25 2.25 0 0 0 4.5 4.5v15a2.25 2.25 0 0 0 2.25 2.25Z'
                />
              </svg>
            </div>
            <div className=''>
              <p className='font-bold text-xs text-c-dark-blue-2/70'>Total Devices</p>
              <p className='text-c-dark-blue-2 text-2xl font-extrabold'>
                {fleetHealthData?.totalDevices}
              </p>
            </div>
          </div>
          <div className='flex flex-row items-center overflow-hidden gap-2 rounded-2xl bg-c-white p-3 shadow-card  transform transition duration-[400ms] ease-in-out  '>
            <div className='p-3 bg-c-dark-blue-2/20 rounded-full '>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth='1.5'
                stroke='currentColor'
                className='w-6 h-6 stroke-c-dark-blue-2'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z'
                />
              </svg>
            </div>
            <div className=''>
              <p className='font-bold text-xs text-c-dark-blue-2/70'>Total Operators</p>
              <p className='text-c-dark-blue-2 text-bold text-2xl font-extrabold'>3210</p>
            </div>
          </div>
          <div className='relative flex flex-row items-center overflow-hidden gap-2 rounded-2xl bg-c-white p-3 shadow-card  transform transition duration-[400ms] ease-in-out  '>
            <div className='absolute -top-1/2 '>
              {fleetHealthData && (
                <Doughnut
                  height={100}
                  width={50}
                  data={{
                    // labels: ['Red', 'Blue', 'Yellow'],
                    datasets: [
                      {
                        data: [
                          fleetHealthData?.totalActiveDevices,
                          fleetHealthData?.totalInActiveDevices,
                        ],
                        backgroundColor: ['rgb(70, 250, 152)', 'rgb(255, 0, 0)'],
                        borderRadius: 100,
                        spacing: 0.5,
                        borderWidth: 0,
                      },
                    ],
                  }}
                  options={{
                    radius: 22,
                    plugins: {
                      tooltip: {
                        enabled: true,
                      },
                    },
                  }}
                />
              )}
            </div>

            <div className='pl-[62px]'>
              <p className='font-bold text-xs text-c-dark-blue-2/70'>Active/InActive Devices</p>
              <div className='flex flex-row justify-between items-center w-full'>
                <p className='text-c-green text-2xl font-extrabold'>
                  {fleetHealthData?.totalActiveDevices}
                </p>
                <p className='text-c-red-1 text-2xl font-extrabold'>
                  {fleetHealthData?.totalInActiveDevices}
                </p>
              </div>
            </div>
          </div>
          <div className='relative flex flex-row items-center overflow-hidden gap-2 rounded-2xl bg-c-white p-3 shadow-card  transform transition duration-[400ms] ease-in-out  '>
            <div className='absolute -top-1/2 '>
              {fleetHealthData && (
                <Doughnut
                  height={100}
                  width={50}
                  data={{
                    // labels: ['Red', 'Blue', 'Yellow'],
                    datasets: [
                      {
                        data: [
                          fleetHealthData?.devicesOverDue.gasProOverDue,
                          fleetHealthData?.devicesOverDue.t3OverDue,
                          fleetHealthData?.devicesOverDue.t4OverDue,
                          fleetHealthData?.devicesOverDue.gasmanOverDue,
                        ],
                        backgroundColor: [
                          'rgb(70, 250, 20)',
                          'rgb(255, 0, 0)',
                          'rgb(300,230,10)',
                          'rgb(0,100,250)',
                        ],
                        borderRadius: 100,
                        spacing: 0.5,
                        borderWidth: 0,
                      },
                    ],
                  }}
                  options={{
                    radius: 22,
                    plugins: {
                      tooltip: {
                        enabled: true,
                      },
                    },
                  }}
                />
              )}
            </div>

            <div className='pl-[62px]'>
              <p className='font-bold text-xs text-c-dark-blue-2/70'>Overdue Devices</p>
              <div className='grid grid-cols-4 gap-1 justify-between items-center w-full'>
                {[
                  {
                    deviceName: 'Gas Pro',
                    bg: 'rgb(70, 250, 20)',
                    deviceCount: fleetHealthData?.devicesOverDue.gasProOverDue,
                  },
                  {
                    deviceName: 'T3',
                    bg: 'rgb(130, 10,0)',
                    deviceCount: fleetHealthData?.devicesOverDue.t3OverDue,
                  },
                  {
                    deviceName: 'T4',
                    bg: 'rgb(255,200,1)',
                    deviceCount: fleetHealthData?.devicesOverDue.t4OverDue,
                  },
                  {
                    deviceName: 'GasMan',
                    bg: 'rgb(20,10,115)',
                    deviceCount: fleetHealthData?.devicesOverDue.gasmanOverDue,
                  },
                ].map((device) => (
                  <p
                    style={{
                      background: device.bg,
                    }}
                    key={device.deviceName}
                    className='text-c-white w-full text-4xs text-center mt-2 p-1 rounded-full font-extrabold'
                  >
                    {device.deviceName}
                  </p>
                ))}
              </div>
            </div>
          </div>
        </div> */}
        <dl className='py-4 px-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4  gap-5 divide-y divide-gray-200 overflow-hidden  bg-c-light-blue-2   md:divide-x md:divide-y-0'>
          <div className=' overflow-hidden rounded-2xl bg-c-white px-3 py-2 shadow-card sm:pt-4 transform transition duration-[400ms] ease-in-out  '>
            <dt>
              {!isFleetHealthDataLoading ? (
                <DashboardOverdueWidget
                  value={fleetHealthData?.devicesOverDue.totalOverdue || 0}
                  totalValue={fleetHealthData?.totalDevices || 0}
                  customerId={selectedCustomer?.id}
                  businessUnitId={selectedBusinessUnit?.id}
                />
              ) : (
                <ChipSkeleton />
              )}
            </dt>
          </div>
          <div className=' overflow-hidden rounded-2xl  bg-c-white px-3 py-2 shadow-card  sm:pt-4 transform transition duration-[400ms] ease-in-out  '>
            <dt>
              {!isFleetHealthDataLoading ? (
                <DashboardDueIn30DaysWidget
                  value={dueIn30DaysData}
                  totalValue={fleetHealthData?.totalDevices || 0}
                  customerId={selectedCustomer?.id}
                  businessUnitId={selectedBusinessUnit?.id}
                />
              ) : (
                <ChipSkeleton />
              )}
            </dt>
          </div>
          <div className=' overflow-hidden rounded-2xl  bg-c-white px-3 py-2 shadow-card  sm:pt-4 transform transition duration-[400ms] ease-in-out  '>
            <dt>
              {!isFleetHealthDataLoading ? (
                <DashboardDueIn60DaysWidget
                  value={dueIn60DaysData}
                  totalValue={fleetHealthData?.totalDevices || 0}
                  customerId={selectedCustomer?.id}
                  businessUnitId={selectedBusinessUnit?.id}
                />
              ) : (
                <ChipSkeleton />
              )}
            </dt>
          </div>
          <div className=' overflow-hidden rounded-2xl  bg-c-white px-3 py-2 shadow-card  sm:pt-4 transform transition duration-[400ms] ease-in-out  '>
            <dt>
              {!isFleetHealthDataLoading ? (
                <DashboardDueIn90DaysWidget
                  value={dueIn90DaysData}
                  totalValue={fleetHealthData?.totalDevices || 0}
                  customerId={selectedCustomer?.id}
                  businessUnitId={selectedBusinessUnit?.id}
                />
              ) : (
                <ChipSkeleton />
              )}
            </dt>
          </div>
        </dl>

        <div className='pt-1 pb-4 px-4 grid xs:grid-cols-1 sm:grid-cols-2  lg:grid-cols-4 xl:grid-cols-8    grid-rows-none  gap-5  bg-c-light-blue-2'>
          <div className='col-span-1 lg:col-span-2 xl:col-span-2 row-span-2   transform transition duration-[400ms] ease-in-out   '>
            {!isFleetHealthDataLoading && fleetHealthData?.devicesOverDue ? (
              <DashboardOverdueDetailsWidget
                deviceOverDueData={fleetHealthData?.devicesOverDue}
                customerId={selectedCustomer?.id}
                businessUnitId={selectedBusinessUnit?.id}
              />
            ) : (
              <DeviceCalibrationSkeleton />
            )}
          </div>
          {/* <div className='md:col-start-2 lg:col-span-3  grid grid-cols-1 lg:grid-cols-2 grid-rows-2 gap-5  h-full'> */}
          <div className='col-span-1 lg:col-span-2 xl:col-span-3 flex transform transition duration-[400ms] ease-in-out   '>
            {!isFleetInsightsDataLoading ? (
              <DashboardAlarmsChart
                alarmsHistoryData={fleetInsightsData?.alarms ?? []}
                customerId={selectedCustomer?.id ?? ''}
                businessUnitId={selectedBusinessUnit?.id ?? ''}
              />
            ) : (
              <BarGraphSkeleton />
            )}
          </div>

          <div className='col-span-1 lg:col-span-2 xl:col-span-3 flex transform transition duration-[400ms] ease-in-out  '>
            {!isFleetInsightsDataLoading ? (
              <DashboardFaultsChart
                faultsHistoryData={fleetInsightsData?.faults ?? []}
                customerId={selectedCustomer?.id}
                businessUnitId={selectedBusinessUnit?.id}
              />
            ) : (
              <BarGraphSkeleton />
            )}
          </div>

          <div className='col-span-1 lg:col-span-2 xl:col-span-3 xl:row-start-1 xl:col-start-6   transform transition duration-[400ms] ease-in-out  '>
            {!isFleetHealthDataLoading && fleetHealthData?.fleetAssignment ? (
              <DashboardDeviceAssignmentChart
                deviceAssignmentData={fleetHealthData?.fleetAssignment}
                customerId={selectedCustomer?.id ?? ''}
                businessUnitId={selectedBusinessUnit?.id ?? ''}
              />
            ) : (
              <PieGraphSkeleton />
            )}
          </div>

          <div className='col-span-1 lg:col-span-2 xl:col-span-3  transform transition duration-[400ms] ease-in-out  '>
            {!isOperatorListDataLoading ? (
              <DashboardOperatorAssignmentChart
                operatorData={operatorListData ?? []}
                customerId={selectedCustomer?.id ?? ''}
                businessUnitId={selectedBusinessUnit?.id ?? ''}
              />
            ) : (
              <PieGraphSkeleton />
            )}
          </div>
          {/* </div> */}
        </div>
      </div>
    </div>
  )
}

export default withErrorBoundary(withPageTracking(Dashboard), {
  FallbackComponent: ErrorPage,
})

/*
<div className='flex-grow pt-4 px-4 bg-c-light-blue-2 grid grid-cols-2 gap-4 h-full min-h-screen'>
<div className='px-4 py-4 h-full bg-c-white shadow-form rounded-2xl'>
  <Bar options={chartOptions[0]} data={data} />
</div>
<div className='px-4 py-4 h-full bg-c-white shadow-form rounded-2xl'>
  //  <Bar options={chartOptions[1]} data={data} /> 
  <Doughnut
    // height={400}
    options={{ ...chartOptions[1], maintainAspectRatio: false }}
    data={calDueData}
  />
</div>

<div className='px-4 py-4 h-full bg-c-white shadow-form rounded-2xl'>
  <Bar options={chartOptions[2]} data={data} />
</div>

<div className='px-4 py-4 h-full bg-c-white shadow-form rounded-2xl'>
  <Bar options={chartOptions[3]} data={data} />
</div>
</div>
<div className='flex-grow'>{}</div>
*/

// export const options = {
//   responsive: true,
//   font: {
//     size: 12,
//     // family: 'Poppins',
//   },
//   plugins: {
//     legend: {
//       position: 'top' as const,
//       font: {
//         size: 12,
//         // family: 'Poppins',
//       },
//     },
//     title: {
//       display: true,
//       text: 'Connect data',
//       color: '#1B4373',
//       font: {
//         size: 24,
//         // family: 'Poppins',
//       },
//     },
//   },
// }

// const object = {
//   prop1: 'value1',
//   prop2: 'value2',
//   prop3: 'value3',
// }

// const propertyNames = Object.keys(object)
// const propertyValues = Object.values(object)
// const entries = Object.entries(object)
// console.log(propertyNames) // Output: ['prop1', 'prop2', 'prop3']
// console.log(propertyValues) // Output: ['value1', 'value2', 'value3']
// console.log(entries) // Output: [['prop1', 'value1'], ['prop2', 'value2'], ['prop3', 'value3']]
// console.log(entries[0][0]) // Output: 'prop1'
// console.log(entries[0][1]) // Output: 'value1'

// const abc: iDevicesOverdueData = {
//   totalOverdue: 0,
//   gasmanOverDue: 0,
//   t3OverDue: 0,
//   t4OverDue: 0,
//   gasProOverDue: 0,
// }

// const xyz = Object.keys(fleetHealthData?.devicesOverDue || abc)
