import { IMsalContext, useMsal } from '@azure/msal-react'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { iCrowconProblemDetails, parseAPIError } from 'services/APIErrorParser'
import ConnectAPIClient from 'services/ConnectAPIClient'
import { getAccessToken } from 'services/MSALService'

export type AssignmentHistory = {
  deviceUserId: string
  name: string
  uniqueId: string
  device: any[]
}

export const doesOperatorHasAssignmentHistory = async (
  operatorId: string,
  msalContext: IMsalContext,
  redirectPageURL: string,
) => {
  const token = await getAccessToken({ msalContext, redirectPageURL })
  try {
    const resp = await ConnectAPIClient.get<AssignmentHistory>(
      `portables/api/Device/Assignment/deviceUser/${operatorId}`,
      token,
    )
    return resp.data
  } catch (error) {
    const axiosError = error as AxiosError
    const errorCode = axiosError?.response?.status || 0
    throw new Error(errorCode.toString())
  }
}

const checkOperatorHaveAssignmentHistoryQueryKey = (operatorId: string) => [
  'operator',
  'hasAssignmentHistory',
  'id',
  operatorId,
]

export function useDoesOperatorHaveAssignmentHistory(operatorId: string, redirectPageURL: string) {
  const msalContext = useMsal()
  const queryKey = checkOperatorHaveAssignmentHistoryQueryKey(operatorId)
  return useQuery(
    queryKey,
    () => doesOperatorHasAssignmentHistory(operatorId, msalContext, redirectPageURL),
    {
      onError: (error: AxiosError) => {
        console.log(error)
      },
      retry: false,
    },
  )
}

interface iDeleteOperatorProps {
  operatorsToDelete: string[]
  msalContext: IMsalContext
}

export const deleteOperator = async (deleteOperatorProps: iDeleteOperatorProps) => {
  const { operatorsToDelete, msalContext } = deleteOperatorProps

  const token = await getAccessToken({ msalContext, redirectPageURL: '' })
  try {
    const resp = await ConnectAPIClient.put(
      'users/api/Operator/update/delete',
      token,
      '',
      operatorsToDelete,
    )
    return resp.data
  } catch (error) {
    const axiosError = error as AxiosError
    const errorDescription = parseAPIError(axiosError?.response?.data as iCrowconProblemDetails)
    throw new Error(errorDescription)
  }
}
