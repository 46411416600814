import React from 'react'
import { iBusinessUnit } from 'data/DeviceListHook'
import { ExportColumnType } from 'exportReports/DataExportInterfaces'
import { FormatToShortDate, FormatBusinessUnit } from 'exportReports/ExportDataUtils'
import { iDeviceAssigned } from 'data/Interfaces'

const OperatorReportColumns: ExportColumnType[] = [
  {
    key: 'firstName',
    label: 'Name',
    format: (val: string) => val,
    cellWidth: 150,
  },
  {
    key: 'currentDevice',
    label: 'Device(s) in use',
    format: (val?: iDeviceAssigned[]) => val?.length.toString() || '',
    cellWidth: 100,
  },
  {
    key: 'businessUnit',
    label: 'Business Unit',
    format: (val: iBusinessUnit) => FormatBusinessUnit(val),
    cellWidth: 130,
  },
  {
    key: 'uniqueId',
    label: 'ID',
    format: (val: string) => val,
    cellWidth: 130,
  },
]

export { OperatorReportColumns }
