import React, { useEffect, useState } from 'react'
import { sub } from 'date-fns'
import GenericWidget from 'components/viewComponents/GenericWidget'
import { iWidgetData } from 'components/viewComponents/interfaces'
import { DateRangeSelectorTypes } from 'utils/CommonEnums'
import { DateRange, IFilterValues, IselectOptionProps } from 'forms/FormModelInterface'
import { countFaultsInLastXDays } from 'pages/service-faults/data/FaultsDataUtils'
import { iFaultData } from 'pages/service-faults/data/FaultsData'

import { withErrorBoundary } from 'react-error-boundary'
import ErrorPage from 'pages/common/ErrorPage'
import { ServiceFaultsColumnIds } from '../ServiceFaultsPageUtils'

const FaultCountMap = [
  {
    dateRange: DateRangeSelectorTypes.Last7Days,
    days: 7,
  },
  {
    dateRange: DateRangeSelectorTypes.Last30Days,
    days: 30,
  },
  {
    dateRange: DateRangeSelectorTypes.Last60Days,
    days: 60,
  },
  {
    dateRange: DateRangeSelectorTypes.Last90Days,
    days: 90,
  },
]

const faultDateRangeOptions: IselectOptionProps[] = [
  {
    label: DateRangeSelectorTypes.Last7Days,
    value: {
      startDate: sub(new Date(), { days: 7 }),
      endDate: new Date(),
    },
  },
  {
    label: DateRangeSelectorTypes.Last30Days,
    value: {
      startDate: sub(new Date(), { days: 30 }),
      endDate: new Date(),
    },
  },
  {
    label: DateRangeSelectorTypes.Last60Days,
    value: {
      startDate: sub(new Date(), { days: 60 }),
      endDate: new Date(),
    },
  },
  {
    label: DateRangeSelectorTypes.Last90Days,
    value: {
      startDate: sub(new Date(), { days: 90 }),
      endDate: new Date(),
    },
  },
]
function FaultsByNumberOfDaysWidget({
  faultsData,
  onFilter,
  faultsDateRangeFilteredParam,
  faultsDateRangeSelctorFilteredParam,
}: {
  faultsData: iFaultData[]
  onFilter: (filterRequestValues: IFilterValues[]) => void
  faultsDateRangeFilteredParam: DateRange
  faultsDateRangeSelctorFilteredParam: DateRangeSelectorTypes
}): JSX.Element {
  const [widgetData, setWidgetData] = useState<iWidgetData[]>()

  const onFilterData = (filterRequestValues: IFilterValues[]) => {
    const filterRequestValueToSend: IFilterValues[] = []
    filterRequestValues.forEach((item) => {
      if (item.value === '') {
        filterRequestValueToSend.push({
          columnId: item.columnId,
          value: '',
        })
      } else {
        filterRequestValueToSend.push({
          columnId: item.columnId,
          // value: item.value as DateRange,
          value: {
            label: item.value as DateRangeSelectorTypes,
            value: faultDateRangeOptions.find((x) => x.label === item.value)?.value ?? '',
          },
        })
      }
    })
    if (onFilter) {
      onFilter(filterRequestValueToSend)
    }
  }

  useEffect(() => {
    if (faultsData) {
      const wData: iWidgetData[] = []
      FaultCountMap.forEach((faultCount, index) => {
        const count = countFaultsInLastXDays(faultsData, faultCount.days)

        wData.push({
          originalData: faultCount.dateRange,
          // faultDateRangeOptions.find((x) => x.label === faultCount.dateRange)?.value ?? '',
          displayText: `${faultCount.days.toString()} days`,
          value: count,
          gridItemClassName: `col-span-1 ${index > 1 ? 'lg:col-start-2 ' : 'lg:col-start-1'}`,
          columnId: ServiceFaultsColumnIds.faultDateTime,
          isFiltered:
            faultsDateRangeFilteredParam ===
              faultDateRangeOptions.find((x) => x.label === faultCount.dateRange)?.value ||
            faultsDateRangeSelctorFilteredParam ===
              faultDateRangeOptions.find((x) => x.label === faultCount.dateRange)?.label,
        })
      })
      setWidgetData(wData)
    }
  }, [faultsData, faultsDateRangeFilteredParam, faultsDateRangeSelctorFilteredParam])

  return (
    <GenericWidget
      widgetProps={{
        title: 'Faults in last',
        widgetData,
        gridContainerClassName: 'lg:grid-flow-col-dense gap-1 ',
        widgetLabelCount: 4,
        columnId: ServiceFaultsColumnIds.faultDateTime,
        onFilter: (filterRequestValues: IFilterValues[]) => onFilterData(filterRequestValues),
      }}
    />
  )
}

export default withErrorBoundary(FaultsByNumberOfDaysWidget, {
  FallbackComponent: ErrorPage,
})
