import { QueryClient, useQuery } from '@tanstack/react-query'
import { useMsal } from '@azure/msal-react'

import ConnectAPIClient from 'services/ConnectAPIClient'
import { IselectOptionProps } from 'forms/FormModelInterface'
import { selectAllOption } from 'forms/FormUtils'
import { AxiosError } from 'axios'
import { getAPIData, iGenericAPIParams } from './GenericData'
import { iBusinessUnitDetails } from './Interfaces'
import { allBusinessUnitOption } from './BusinessUnitsListHookWrapper'

export const businessUnitsListForUserQueryKey = () => ['businessUnits', 'user']
export const businessUnitsListForUserURL = () => `customers/api/customer/bu/details`
export const businessUnitsListForUserURLAPIVersion = '0.2'

interface iFetchBUListForUserParams {
  token: string
}

interface iGetOrFetchBUListForUserProps {
  queryClient: QueryClient
  token: string
}

export function useBusinessUnitsListByUser(redirectPageURL: string) {
  const msalContext = useMsal()
  const apiURL = businessUnitsListForUserURL()
  const queryKey = businessUnitsListForUserQueryKey()
  return useQuery<iBusinessUnitDetails[], AxiosError>(
    queryKey,
    () =>
      getAPIData<iGenericAPIParams, iBusinessUnitDetails[]>({
        msalContext,
        redirectPageURL,
        apiURL,
        version: businessUnitsListForUserURLAPIVersion,
      }),
    {
      staleTime: 24 * 60 * 60 * 1000,
      select: (resultData) => {
        // resultData.sort((a, b) => a.name.localeCompare(b.name));

        if (resultData) {
          if (resultData?.find((bu) => bu.id === allBusinessUnitOption.id)) {
            return resultData
          }
          resultData.unshift(allBusinessUnitOption)
          return resultData
        }
        return []

        // const businessUnitsListConverted: IselectOptionProps[] = resultData.map((businessUnit) => ({
        //   value: businessUnit.id,
        //   label: businessUnit.name,
        // }))
        // businessUnitsListConverted.unshift(selectAllOption)
        // return businessUnitsListConverted
      },
      onError: (error) => {
        console.log(error)
      },
    },
  )
}

export async function FetchBusinessUnitsListForUser(
  params: iFetchBUListForUserParams,
): Promise<iBusinessUnitDetails[]> {
  try {
    const { token } = params
    const url = businessUnitsListForUserURL()
    const resp = await ConnectAPIClient.get<iBusinessUnitDetails[]>(
      url,
      token,
      businessUnitsListForUserURLAPIVersion,
    )
    const response = resp.data
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function getOrFetchBusinessUnitsForUser(props: iGetOrFetchBUListForUserProps) {
  const { queryClient, token } = props
  const queryKey = businessUnitsListForUserQueryKey()

  const businessUnitsListCached = queryClient.getQueryData<iBusinessUnitDetails[]>(queryKey)

  if (businessUnitsListCached) {
    // const customerListConverted: IselectOptionProps[] =
    //   convertCustomerListToIselect(customerListCached)
    return businessUnitsListCached
  }

  const businessUnitsListFetched = await queryClient.fetchQuery(queryKey, async () =>
    FetchBusinessUnitsListForUser({
      token,
    }),
  )
  return businessUnitsListFetched
}
