import React, { useEffect } from 'react'
import { sub } from 'date-fns'
import { useForm, SubmitHandler, Controller, FieldErrors } from 'react-hook-form'
import { DateRange, IFilterValues, IselectOptionProps } from 'forms/FormModelInterface'
// eslint-disable-next-line import/no-extraneous-dependencies
import { DevTool } from '@hookform/devtools'

import { DateRangeSelectorTypes, SelectOptionTypes } from 'utils/CommonEnums'
import { ChevronRightIcon, CloseIcon } from 'assets/icons'
import { DatePickerControl, SelectControl, CreatableSelectControl } from 'components/formComponents'
import FilterFormTitle from 'components/formComponents/FilterFormTitle'

import { dateRangeOptions, selectAllOption } from 'forms/FormUtils'
import { withErrorBoundary } from 'react-error-boundary'
import ErrorPage from 'pages/common/ErrorPage'
import { DeviceSyncHistoryFilterFormType, columnIDs } from './DeviceSyncHistory'

interface DeviceSyncHistoryFilterFormProps {
  // Define any props that this component will receive
  selectedValues?: DeviceSyncHistoryFilterFormType
  uploadDataTypes: readonly IselectOptionProps[]
  uploadClientTypes: readonly IselectOptionProps[]

  // Define any functions that this component will call
  onFilterSubmit: (values: IFilterValues[]) => void
  onFilterReset: () => void
  onFilterFormClose: () => void
}

const defaultValues = (): DeviceSyncHistoryFilterFormType => {
  const dtTo = new Date()
  const dtFrom = sub(new Date(), { months: 6 })
  const defValues: DeviceSyncHistoryFilterFormType = {
    uploadDateSelector: dateRangeOptions.find(
      (option) => option.value === DateRangeSelectorTypes.Custom,
    ) ?? {
      label: '',
      value: '',
    },
    uploadDate: {
      startDate: dtFrom,
      endDate: dtTo,
    },
    uploadDataType: selectAllOption,
    uploadClientType: selectAllOption,
  }
  return defValues
}

function DeviceSyncHistoryFilterForm(props: DeviceSyncHistoryFilterFormProps): JSX.Element {
  const {
    selectedValues,
    uploadDataTypes,
    uploadClientTypes,
    onFilterSubmit,
    onFilterReset,
    onFilterFormClose,
  } = props

  const defaultFormValues = defaultValues()
  const { register, control, handleSubmit, setValue, reset, formState, watch } =
    useForm<DeviceSyncHistoryFilterFormType>({
      defaultValues: defaultFormValues,
      values: selectedValues,
    })

  const watchUploadDateSelector = watch('uploadDateSelector')
  const [showCustomEventDate, setShowCustomEventDate] = React.useState<boolean>(false)

  useEffect(() => {
    if (watchUploadDateSelector?.value === DateRangeSelectorTypes.Custom) {
      setShowCustomEventDate(true)
    } else {
      setShowCustomEventDate(false)
    }
  }, [watchUploadDateSelector])

  const { errors, isDirty, isValid, isSubmitting } = formState

  const onSubmit: SubmitHandler<DeviceSyncHistoryFilterFormType> = (data) => {
    const filterValues: IFilterValues[] = []

    filterValues.push({
      columnId: columnIDs.uploadDate,
      value: data.uploadDateSelector.label === DateRangeSelectorTypes.All ? '' : data.uploadDate,
    })

    filterValues.push({
      columnId: columnIDs.uploadDataType,
      value:
        data.uploadDataType === null ||
        data.uploadDataType.value?.toString().toLowerCase() ===
          selectAllOption.value?.toString().toLowerCase()
          ? ''
          : data.uploadDataType.value,
    })

    filterValues.push({
      columnId: columnIDs.uploadClientType,
      value:
        data.uploadClientType === null ||
        data.uploadClientType.value?.toString().toLowerCase() ===
          selectAllOption.value?.toString().toLowerCase()
          ? ''
          : data.uploadClientType.value,
    })

    onFilterSubmit(filterValues)
  }

  const onError = (errors: FieldErrors<DeviceSyncHistoryFilterFormType>) => {
    console.log(errors)
  }

  const onReset = () => {
    reset(defaultFormValues)
    onFilterReset()
  }

  return (
    <div className='filter-form-container'>
      <div className='filter-form-header'>
        <FilterFormTitle />

        <div
          title='Close Filter Form'
          role='button'
          tabIndex={0}
          onClick={() => onFilterFormClose()}
          onKeyDown={() => onFilterFormClose()}
          className='filter-form-close-button'
        >
          <CloseIcon toggleClick={() => onFilterFormClose()} className='w-5 h-5 ' />
        </div>
      </div>

      <form onSubmit={handleSubmit(onSubmit, onError)} onReset={onReset} noValidate className=''>
        <div className='filter-form-fields-container'>
          {/* // Event Date */}
          <div className='flex flex-col gap-0.5 items-start self-stretch'>
            <label
              htmlFor='eventDateSelector'
              className='pl-4 pr-3 text-xs font-bold leading-5 text-c-dark-blue-1 '
            >
              Date
            </label>

            <Controller
              name='uploadDateSelector'
              control={control}
              render={({ field }) => (
                <SelectControl
                  className='w-full h-[42px]'
                  selectControlProps={{
                    options: dateRangeOptions,
                    defaultValue: field.value,
                    isMulti: false,

                    onChange: (selectedOption: IselectOptionProps) => {
                      const selectedValue = selectedOption?.value
                      field.onChange(selectedOption)
                      if (selectedValue !== DateRangeSelectorTypes.Custom) {
                        setValue('uploadDate', selectedValue as DateRange, {
                          shouldValidate: true,
                        })
                      } else {
                        setValue('uploadDate', {
                          startDate: selectedValues?.uploadDate.startDate || new Date(),
                          endDate: selectedValues?.uploadDate.endDate || new Date(),
                        })
                      }
                    },
                  }}
                />
              )}
            />
          </div>

          {showCustomEventDate && (
            <>
              <div className='flex flex-row self-stretch justify-between w-full items-end'>
                <div className='flex-grow '>
                  <div className='flex flex-col gap-0.5 items-start '>
                    <label
                      htmlFor='eventDateSelectorFrom'
                      className='pl-4  text-xs font-bold leading-5 text-c-dark-blue-1 '
                    >
                      From
                    </label>
                    <div className='self-stretch'>
                      <Controller
                        name='uploadDate.startDate'
                        control={control}
                        //   rules={{
                        //     validate: (value) => {
                        //       if (isAfter(new Date(value), new Date(eventDateStart))) {
                        //         return 'Start date must be less than end date'
                        //       }
                        //       return true
                        //     },
                        //   }}
                        render={({ field }) => (
                          <DatePickerControl
                            className='h-10 text-xs'
                            datePickerProps={{
                              selected: field.value,
                              onChange: (date: Date | null) => field.onChange(date as Date),
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>

                <div className='px-0.5 pb-3 '>
                  <ChevronRightIcon className='self-center' />
                </div>

                <div className='flex-grow '>
                  <div className='flex flex-col gap-0.5 items-start'>
                    <label
                      htmlFor='eventDateFilterTo'
                      className='pl-4 text-xs font-bold leading-5 text-c-dark-blue-1 '
                    >
                      To
                    </label>
                    <div className='self-stretch'>
                      <Controller
                        name='uploadDate.endDate'
                        control={control}
                        // rules={{ required: true }}
                        // defaultValue={(dateRangeOptions[0].value as DateRange).endDate}
                        //   rules={{
                        //     validate: {
                        //       isNotBeforeStart: (value) => {
                        //         if (isBefore(new Date(value), new Date(eventDateStart))) {
                        //           return 'End date must be greater than start date'
                        //         }
                        //         return true
                        //       },
                        //       isNotInFuture: (value) => {
                        //         if (isAfter(new Date(value), new Date())) {
                        //           return 'End date must not be in future'
                        //         }
                        //         return true
                        //       },
                        //     },
                        //   }}
                        render={({ field }) => (
                          <DatePickerControl
                            className='h-10 text-xs'
                            datePickerProps={{
                              // field,
                              selected: field.value,
                              maxDate: new Date(),
                              // minDate:lastSyncStart,
                              onChange: (date: Date | null) => field.onChange(date as Date),
                              // selectsStart: true,
                              // startDate: field.value,
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {errors.uploadDate && (
                <>
                  <p className='text-xs text-red-500'>{errors.uploadDate?.startDate?.message}</p>
                  <p className='text-xs text-red-500'>{errors.uploadDate?.endDate?.message}</p>
                </>
              )}
            </>
          )}

          <div className='flex flex-col gap-0.5 items-start self-stretch'>
            <label
              htmlFor='uploadDataTypeFilter'
              className='pl-4 px-3 text-xs font-bold leading-5 text-c-dark-blue-1 '
            >
              Upload Data Type
            </label>
            <Controller
              name='uploadDataType'
              control={control}
              render={({ field }) => (
                <SelectControl
                  className='w-full h-[42px]'
                  selectControlProps={{
                    options: uploadDataTypes,
                    defaultValue: field.value,
                    isMulti: false,

                    onChange: (selectedOption: IselectOptionProps) => {
                      field.onChange(selectedOption)
                    },
                  }}
                />
              )}
            />
            <p className='text-xs text-red-500'>{errors.uploadDataType?.message}</p>
          </div>

          <div className='flex flex-col gap-0.5 items-start self-stretch'>
            <label
              htmlFor='uploadClientTypeFilter'
              className='pl-4 px-3 text-xs font-bold leading-5 text-c-dark-blue-1 '
            >
              Upload Client Type
            </label>
            <Controller
              name='uploadClientType'
              control={control}
              render={({ field }) => (
                <SelectControl
                  className='w-full h-[42px]'
                  selectControlProps={{
                    options: uploadClientTypes,
                    defaultValue: field.value,
                    isMulti: false,

                    onChange: (selectedOption: IselectOptionProps) => {
                      field.onChange(selectedOption)
                    },
                  }}
                />
              )}
            />
            <p className='text-xs text-red-500'>{errors.uploadClientType?.message}</p>
          </div>
        </div>
        {/* Buttons */}
        <div className='filter-form-footer'>
          <input
            className='filter-form-reset-button'
            type='reset'
            disabled={isSubmitting}
            onClick={() => {
              onReset()
            }}
          />
          <input
            className='filter-form-apply-button'
            type='submit'
            value='Apply'
            // disabled={!isDirty || !isValid || isSubmitting}
          />
        </div>
      </form>

      <DevTool control={control} />
    </div> // opening div
  )
}

export default withErrorBoundary(DeviceSyncHistoryFilterForm, {
  FallbackComponent: ErrorPage,
})
