import { format } from 'date-fns'

import { iFleetInsightsSummary } from './DashboardData'

interface iAlarmsData {
  alarmsDataHistory: iFleetInsightsSummary['alarms']
}

interface iFaultsData {
  faultsDataHistory: iFleetInsightsSummary['faults']
}
const NumberOfMonths = 6
const monthsInNumber = {
  Jan: 1,
  Feb: 2,
  Mar: 3,
  Apr: 4,
  May: 5,
  Jun: 6,
  Jul: 7,
  Aug: 8,
  Sept: 9,
  Oct: 10,
  Nov: 11,
  Dec: 12,
}
function convertStringToDateFormat(month: string) {
  const [monthName, year] = month.split(' ')
  const monthDate = new Date()
  monthDate.setMonth(monthsInNumber[monthName as keyof typeof monthsInNumber] - 1)
  monthDate.setFullYear(Number(year))
  return monthDate
}
export function populateAlarmsDataArray(alarmsData: iAlarmsData): iFleetInsightsSummary['alarms'] {
  const alarmsDataArray: iFleetInsightsSummary['alarms'] = []
  const { alarmsDataHistory } = alarmsData
  // const monthsArray = []
  // for (let monthCounter = 1; monthCounter <= NumberOfMonths; monthCounter += 1) {
  //   const month = new Date()
  //   month.setMonth(month.getMonth() - monthCounter)
  //   const monthString = format(month, 'MMM yyyy')
  //   monthsArray.push(monthString)
  // }

  alarmsDataHistory.forEach((alarmData) => {
    const formattedMonth = format(convertStringToDateFormat(alarmData.month), 'MMM')
    alarmsDataArray.push({
      month: formattedMonth,
      alarm1: alarmData.alarm1,
      alarm2: alarmData.alarm2,
    })
  })

  return alarmsDataArray.reverse()
}

export function populateFaultsDataArray(faultsData: iFaultsData): iFleetInsightsSummary['faults'] {
  const faultsDataArray: iFleetInsightsSummary['faults'] = []
  const { faultsDataHistory } = faultsData
  // const monthsArray = []
  // for (let monthCounter = 1; monthCounter <= NumberOfMonths; monthCounter += 1) {
  //   const month = new Date()
  //   month.setMonth(month.getMonth() - monthCounter)
  //   const monthString = format(month, 'MMM yyyy')
  //   monthsArray.push(monthString)
  // }

  faultsDataHistory.forEach((faultData) => {
    const formattedMonth = format(convertStringToDateFormat(faultData.month), 'MMM')

    faultsDataArray.push({
      month: formattedMonth,
      faults: faultData.faults,
    })
  })

  return faultsDataArray.reverse()
}

export function populateStartAndEndDatesOfMonths() {
  const monthsArray = []
  for (let monthCounter = 1; monthCounter <= NumberOfMonths; monthCounter += 1) {
    const month = new Date()
    month.setMonth(month.getMonth() - monthCounter)
    const monthString = format(month, 'MMM yyyy')
    monthsArray.push(monthString)
  }

  const startAndEndDatesOfMonths = monthsArray.map((month) => {
    const monthDate = convertStringToDateFormat(month)
    const startDate = new Date(monthDate.getFullYear(), monthDate.getMonth(), 1)
    const endDate = new Date(monthDate.getFullYear(), monthDate.getMonth() + 1, 0)
    return {
      month,
      startDate: format(startDate, 'yyyy-MM-dd'),
      endDate: format(endDate, 'yyyy-MM-dd'),
    }
  })

  return startAndEndDatesOfMonths.reverse()
}
