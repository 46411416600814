// Import the React library
import React from 'react'
// Import the CalenderDateProps type
import { CalenderDateProps } from './CalenderType'
import { dayDateToDate } from './CalenderUtils'

// Define the CalenderDate component
function CalenderDate({
  day, // The day object
  onDateSelect, // Function to handle date selection
  shouldShowFilter, // Boolean to determine if the filter should be shown
  children, // Any child components
  className, // Additional CSS classes
  ...rest // Any other props
}: CalenderDateProps) {
  // Render the component
  return (
    // Button element
    <button
      {...rest} // Spread the rest of the props
      type='button' // Set the button type
      title='Select date or range of dates to filter' // Set the button title
      onClick={() => {
        // If the filter should be shown, call the onDateSelect function with the day object
        console.log('day', day.date)
        if (shouldShowFilter) {
          onDateSelect(day)
        }
      }}
      className={className} // Set the CSS classes
    >
      {/* // Time element */}
      <div
        className={
          day.isToday ? 'flex h-6 w-6  text-c-blue font-semibold z-10' : 'flex h-6 w-6  z-10'
        } // Set the CSS classes based on whether the day is today
      >
        {/* // Display the date of the day object, padded with zeros to 2 digits */}
        {dayDateToDate(`${day?.date}`).getDate()}
      </div>
      {/* // Render any child components */}
      {children}
    </button>
  )
}

// Set the display name of the component
CalenderDate.displayName = 'CalenderDate'
// Export the component as the default export
export default CalenderDate
