import ExpandIcon from 'assets/icons/ExpandIcon'
import clsx from 'clsx'
import { iDevice } from 'data/DeviceListHook'
import React, { useState } from 'react'
import ZoomedCalibrationCalendarWidget from './ZoomedCalibrationCalendarWidget'

/**
 * `CalibrationsCalenderHeaderProps` is a TypeScript type used for the props of the `CalibrationsCalenderHeader` component.
 *
 * @typedef {Object} CalibrationsCalenderHeaderProps
 *
 * @property {iDevice[]} data - An array of `iDevice` objects representing the devices to be displayed in the calendar.
 *
 * @property {Date} date - A JavaScript Date object representing the current date.
 *
 * The `CalibrationsCalenderHeader` component uses these props to render a list of devices and the current date.
 */
type CalibrationsCalenderHeaderProps = {
  data: iDevice[]
  date: Date
}

export default function CalibrationsCalenderHeader({
  data,
  date,
}: CalibrationsCalenderHeaderProps) {
  // Initialize state for zoom functionality
  const [zoom, setZoom] = useState(false)

  // Reduce the data array into an object where each key is a device type and each value is an array of devices of that type
  // that have a calibration due date in the same month and year as the current date
  const calDueDates = data.reduce((acc: { [key: string]: iDevice[] }, curr: iDevice) => {
    // Check if the current device has a type and a calibration due date
    if (curr.deviceType && curr.calibrationDueDate) {
      // If it does, add it to the accumulator object
      // The key is the device type and the value is an array of devices of that type
      return {
        ...acc,
        [`${curr.deviceType}`]: [
          // If there are already devices of this type, include them in the array
          ...(acc[`${curr.deviceType}`] ?? []),
          // Add the current device to the array if its calibration due date is in the current month and year
          // If not, add null to the array
          (new Date(curr.calibrationDueDate).getMonth() === date.getMonth() &&
          new Date(curr.calibrationDueDate).getFullYear() === date.getFullYear()
            ? curr
            : null) as iDevice,
          // Filter out any null values from the array
        ].filter((d) => d),
      }
    }
    // If the current device does not have a type or a calibration due date, return the accumulator as is
    return acc
  }, {})

  return (
    <div>
      {/* Container for displaying the calibration due dates */}
      <div className={clsx(' flex-row gap-3 mt-3 hidden lg:flex flex-wrap cursor-grab relative')}>
        {/* Map over the entries in the calDueDates object and render a div for each key-value pair */}
        {Object.entries(calDueDates).map(([key, value]) => (
          <div
            className='flex flex-row gap-5 hover:bg-c-light-blue-2 rounded-full bg-c-light-blue-1 px-3 py-1 select-none'
            key={key}
          >
            {/* Display the device type */}
            <div className='text-2xs font-bold leading-3  text-ellipsis self-center text-c-dark-blue-2'>
              {key}
            </div>
            {/* Display the number of devices of this type */}
            <div className='text-2xs font-bold leading-3  text-ellipsis self-center text-c-dark-blue-2'>
              {value.length}
            </div>
          </div>
        ))}
      </div>

      {/* Button for zooming the calendar */}
      <div className='absolute top-0 right-0 p-2'>
        <button
          type='button'
          title='Zoom calender'
          className='invisible group-hover/header:visible'
          onClick={() => setZoom(true)}
        >
          <ExpandIcon className='w-5 h-5 stroke-c-blue ' />
        </button>
      </div>
      {/* Zoomed view of the calendar */}
      <ZoomedCalibrationCalendarWidget open={zoom} onClose={() => setZoom(false)} data={data} />
    </div>
  )
}
