import { CellContext, ColumnDef } from '@tanstack/react-table'
import TsTable from 'components/table/TsTable'
import { IFilterValues } from 'forms/FormModelInterface'
import ErrorPage from 'pages/common/ErrorPage'
import React, { useMemo, useState } from 'react'
import { withErrorBoundary } from 'react-error-boundary'
import { EditIcon } from 'assets/icons'
import { CustomerPropertyFilter } from 'forms/FormUtils'
import Show from 'components/atom/Show'
import clsx from 'clsx'
import { FormattedDate } from 'react-intl'
import { BillingPlanEnums } from 'utils/CommonEnums'
import Tooltip from 'components/atom/Tooltip'
import { AddEntryInTableButton } from 'components/table/tsTableComponents/buttons'
import {
  CustomerStatusConstants,
  CustomerStatusEnums,
  CustomersPageColumnHeader,
  CustomersPageColumnIds,
} from '../view/CustomerPageUtils'
import CustomerCrudForm from './CustomerCrudForm'
import { Customer } from '../data/iCustomerList'

const AddCustomerButton = AddEntryInTableButton
interface CustomersPageTableProps {
  tableData?: Customer[]
  isLoading: boolean
  filterValues: IFilterValues[]
  updateFilteredData: (data: Customer[]) => void
  onShowFilter: () => void
  onFilterFormSubmit: (filterValues: IFilterValues[]) => void
  onFilterFormReset: () => void
  setShowExportDialog: (val: boolean) => void
}

function CustomersPageTable(tableDataProps: CustomersPageTableProps): JSX.Element {
  const {
    tableData,
    filterValues,
    isLoading,
    updateFilteredData,
    onShowFilter,
    onFilterFormReset,
    setShowExportDialog,
  } = tableDataProps

  const [typeOfCrud, setTypeOfCrud] = useState<string>()
  const [customer, setCustomer] = useState<Customer>()

  const tableColumns = useMemo(
    () =>
      (!isLoading
        ? [
            {
              id: CustomersPageColumnIds.subscriptionActivationDate,
              header: CustomersPageColumnHeader[CustomersPageColumnIds.subscriptionActivationDate],
              accessorKey: CustomersPageColumnIds.subscriptionActivationDate,
              cell: (info: CellContext<Customer, unknown>) => {
                const value: string = info.getValue() as string
                return (
                  <div className='text-center text-2xs leading-5  text-ellipsis px-2'>
                    <FormattedDate value={value} />
                  </div>
                )
              },
              meta: {},
              filterFn: 'includesString',
            },
            {
              id: CustomersPageColumnIds?.name,
              header: CustomersPageColumnHeader[CustomersPageColumnIds?.name],
              accessorKey: CustomersPageColumnIds?.name,
              cell: (info: CellContext<Customer, unknown>) => {
                const value: string = info.getValue() as string
                return (
                  <Tooltip
                    showOnlyWhenTextIsTruncate
                    id={`customer-${value}`}
                    className='text-center text-2xs leading-5  text-ellipsis pr-2 '
                    tooltipText={value}
                    toolTipClass='tooltip left-0 z-50 w-fit'
                  >
                    {value}
                  </Tooltip>
                )
              },
              meta: {},
              filterFn: 'includesString',
            },

            {
              id: CustomersPageColumnIds.subscriptionExpirationDate,
              header: CustomersPageColumnHeader[CustomersPageColumnIds.subscriptionExpirationDate],
              accessorKey: CustomersPageColumnIds.subscriptionExpirationDate,
              cell: (info: CellContext<Customer, unknown>) => {
                const value: string = info.getValue() as string
                return (
                  <div className='text-center text-2xs leading-5  text-ellipsis px-2'>
                    <FormattedDate value={value} />
                  </div>
                )
              },
              meta: {},
              filterFn: 'includesString',
            },
            {
              id: CustomersPageColumnIds.billingPlanName,
              header: CustomersPageColumnHeader[CustomersPageColumnIds.billingPlanName],
              accessorFn: (row: Customer) =>
                `${row?.billingPlans?.name ? row?.billingPlans?.name ?? '' : ''}`,
              cell: (info: CellContext<Customer, unknown>) => {
                const value = info.row.original?.billingPlans?.name

                return (
                  <Tooltip
                    tooltipText={value}
                    toolTipClass='tooltip left-0 z-50 w-fit'
                    showOnlyWhenTextIsTruncate
                    id={`customer-${info?.row?.index}`}
                    className='text-center text-2xs leading-5  text-ellipsis px-2'
                  >
                    {value}
                  </Tooltip>
                )
              },
              meta: {},
              filterFn: CustomerPropertyFilter,
            },
            {
              id: CustomersPageColumnIds.billingPlanType,
              header: CustomersPageColumnHeader[CustomersPageColumnIds.billingPlanType],
              accessorFn: (row: Customer) =>
                `${
                  row?.billingPlans?.billingPlanType ? row?.billingPlans?.billingPlanType ?? '' : ''
                }`,
              cell: (info: CellContext<Customer, unknown>) => {
                const value = info.row.original?.billingPlans?.billingPlanType

                return (
                  <div className='text-center text-2xs leading-5  text-ellipsis px-2'>
                    {BillingPlanEnums[value as string]}
                  </div>
                )
              },
              meta: {},
              filterFn: CustomerPropertyFilter,
            },
            {
              id: CustomersPageColumnIds.features,
              header: CustomersPageColumnHeader[CustomersPageColumnIds.features],
              accessorFn: (row: Customer) =>
                `${row.features?.[0]?.name ? row.features?.[0]?.name ?? '' : ''}`,
              cell: (info: CellContext<Customer, unknown>) => {
                const value = info.row.original?.features?.[0]?.name
                return (
                  <div className='text-center text-2xs leading-5  text-ellipsis px-2'>{value}</div>
                )
              },
              meta: {},
              filterFn: CustomerPropertyFilter,
            },
            {
              id: CustomersPageColumnIds.status,
              header: CustomersPageColumnHeader[CustomersPageColumnIds.status],
              accessorKey: CustomersPageColumnIds.status,
              cell: (info: CellContext<Customer, unknown>) => {
                const value: string = info.getValue() as string

                return (
                  <div
                    className='w-full h-full content-center cursor-pointer m-auto flex items-center justify-start'
                    title='Double click to view device details'
                  >
                    <div
                      className={clsx(
                        {
                          'bg-c-green': value === CustomerStatusEnums.Active,
                          'bg-c-red-light':
                            value === CustomerStatusEnums.Inactive ||
                            value === CustomerStatusEnums.CreationFailed,
                          'bg-c-blue': value === CustomerStatusEnums.Invited,
                        },
                        'font-semibold whitespace-nowrap rounded-full text-c-dark-blue-1 bg-opacity-20 text-xs leading-4 px-2.5 py-1  ',
                      )}
                    >
                      {CustomerStatusConstants[value as CustomerStatusEnums]}
                    </div>
                  </div>
                )
              },
              meta: {},
              filterFn: 'includesString',
            },
            {
              id: '999',
              cell: (info: CellContext<Customer, unknown>) => (
                <button
                  type='button'
                  title='Edit Customer'
                  className='bg-c-dark-blue-1 rounded-full p-1.5 '
                  onClick={() => {
                    setTypeOfCrud('edit')
                    setCustomer(info.row.original)
                  }}
                >
                  <EditIcon height={24} width={24} fill='white' />
                </button>
              ),
              meta: {
                cellClassName: 'overflow-visible flex justify-end w-full px-2.5',
                headerClassName: 'w-1 overflow-visible',
                shouldDraggable: false,
                resizable: false,
                sortable: false,
              },
            },
          ]
        : []) as ColumnDef<Customer>[],
    [isLoading],
  )
  const renderFilterSummaryComponent = () => <> </>

  return (
    <>
      <TsTable<Customer>
        columns={tableColumns}
        data={tableData ?? []}
        dataIsLoading={isLoading}
        showGlobalActionButton
        showGlobalFilter
        onExport={() => {}}
        onPrint={() => {}}
        getRowCanExpand={() => true}
        updateFilteredData={(data) => updateFilteredData(data)}
        renderFilterSummary={renderFilterSummaryComponent}
        resetFilter={() => onFilterFormReset()}
        onShowFilter={() => onShowFilter()}
        filterValues={filterValues}
        defaultSortedColumKey={CustomersPageColumnIds.subscriptionActivationDate}
        setShowExportDialog={(val) => setShowExportDialog(val)}
        showFilterForm={false}
        globalActionButton={
          <AddCustomerButton buttonText='Add Customer' buttonAction={() => setTypeOfCrud('add')} />
        }
        // setFilterValues={setFilterValues}
        // dateRangeSelectorType={DateRangeSelectorTypes.DateRange}
        // dateRangeOptions={dateRangeOptions}
        // dateRangeFilterFn={DateRangeColumnFilterFn<iFaultData>}
      />

      <Show>
        <Show.When isTrue={Boolean(typeOfCrud)}>
          <CustomerCrudForm
            close={() => {
              setTypeOfCrud('')
              setCustomer(undefined)
            }}
            type={typeOfCrud}
            data={typeOfCrud === 'edit' ? customer : undefined}
          />
        </Show.When>
      </Show>
    </>
  )
}

export default withErrorBoundary(CustomersPageTable, {
  FallbackComponent: ErrorPage,
})
