import { IMsalContext, useMsal } from '@azure/msal-react'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { iBusinessUnit, iDeviceUser, DeviceChannelInfoDto } from 'data/DeviceListHook'
import { iCrowconProblemDetails, parseAPIError } from 'services/APIErrorParser'

import ConnectAPIClient from 'services/ConnectAPIClient'
import { getAccessToken } from 'services/MSALService'
import { PortableTypes } from 'utils/CommonEnums'

export const doesDeviceHaveAnyLogs = async (
  deviceId: string,
  msalContext: IMsalContext,
  redirectPageURL: string,
) => {
  const token = await getAccessToken({ msalContext, redirectPageURL })
  try {
    // this API checks if the device has any event logs, gas logs, cal dates (due and last cal) history, near miss records
    // It does not check assignment check any assignment history

    const resp = await ConnectAPIClient.get<boolean>(
      `devicelogs/api/eventlogs/check/${deviceId}`,
      token,
    )

    return resp.data
  } catch (error) {
    const axiosError = error as AxiosError
    const errorDescription = parseAPIError(axiosError?.response?.data as iCrowconProblemDetails)
    throw new Error(errorDescription)
  }
}

const checkDeviceHasAnyLogsQueryKey = (deviceId: string) => ['device', 'hasAnyLogs', 'id', deviceId]

export function useDoesDeviceHaveAnyLogs(deviceId: string, redirectPageURL: string) {
  const msalContext = useMsal()
  const queryKey = checkDeviceHasAnyLogsQueryKey(deviceId)
  return useQuery(queryKey, () => doesDeviceHaveAnyLogs(deviceId, msalContext, redirectPageURL), {
    onError: (axiosError: AxiosError) => {
      const errorDescription = parseAPIError(axiosError?.response?.data as iCrowconProblemDetails)
      throw new Error(errorDescription)
    },
  })
}

interface iDeleteDeviceProps {
  devicesToDelete: string[]
  msalContext: IMsalContext
}

export const deleteDevice = async (deleteDeviceProps: iDeleteDeviceProps) => {
  const { devicesToDelete, msalContext } = deleteDeviceProps

  const token = await getAccessToken({ msalContext, redirectPageURL: '' })
  try {
    const resp = await ConnectAPIClient.put(
      'portables/api/device/update/delete',
      token,
      '',
      devicesToDelete,
    )
    return resp.data
  } catch (error) {
    const axiosError = error as AxiosError
    const errorDescription = parseAPIError(axiosError?.response?.data as iCrowconProblemDetails)
    throw new Error(errorDescription)
  }
}

// export const doesDeviceHaveAnyAssignments = async (deviceId: string, msalContext: IMsalContext) => {
//   const token = await getAccessToken({ msalContext, redirectPageURL: '' })
//   try {
//     const resp = await ConnectAPIClient.get(`portables/api/assignment/device/${deviceId}`, token)
//     return resp.data
//   } catch (error) {
//     const axiosError = error as AxiosError
//     const errorDescription = parseAPIError(axiosError?.response?.data as iCrowconProblemDetails)
//     throw new Error(errorDescription)
//   }
// }

// export const doesDeviceHaveAnyCalRecords = async (deviceId: string, msalContext: IMsalContext) => {
//   const token = await getAccessToken({ msalContext, redirectPageURL: '' })
//   try {
//     const resp = await ConnectAPIClient.get(`portables/api/device/${deviceId}/calibration`, token)
//     return resp.data
//   } catch (error) {
//     const axiosError = error as AxiosError
//     const errorDescription = parseAPIError(axiosError?.response?.data as iCrowconProblemDetails)
//     throw new Error(errorDescription)
//   }
// }

// export const updateDevice = async (updateDeviceProps: iUpdateDeviceProps) => {
//   const { updateDeviceDTO, msalContext } = updateDeviceProps

//   const token = await getAccessToken({ msalContext, redirectPageURL: '' })
//   try {
//     const resp = await ConnectAPIClient.put('portables/api/device', token, '0.2', updateDeviceDTO)
//     return resp.data
//   } catch (error) {
//     const axiosError = error as AxiosError
//     const errorDescription = parseAPIError(axiosError?.response?.data as iCrowconProblemDetails)
//     throw new Error(errorDescription)
//   }
// }
