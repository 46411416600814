import React, { useEffect } from 'react'
import { useForm, SubmitHandler, Controller, FieldErrors } from 'react-hook-form'
import { DateRange, IFilterValues, IselectOptionProps } from 'forms/FormModelInterface'
import { sub } from 'date-fns'
// eslint-disable-next-line import/no-extraneous-dependencies
import { DevTool } from '@hookform/devtools'

import { DateRangeSelectorTypes, SelectOptionTypes } from 'utils/CommonEnums'
import { ChevronRightIcon, CloseIcon } from 'assets/icons'
import { DatePickerControl, SelectControl, CreatableSelectControl } from 'components/formComponents'

import FilterFormTitle from 'components/formComponents/FilterFormTitle'
import { dateRangeOptions, selectAllOption, selectBlankOption } from 'forms/FormUtils'
import { withErrorBoundary } from 'react-error-boundary'
import ErrorPage from 'pages/common/ErrorPage'
import { ReportFilterFormType, columnIDs } from './DeviceFaults'

interface ReportFilterFormProps {
  // Define any props that this component will receive
  selectedValues?: ReportFilterFormType
  deviceUserNames?: IselectOptionProps[]
  faultDescriptions?: IselectOptionProps[]
  faultTypes?: IselectOptionProps[]

  // Define any functions that this component will call
  onFilterSubmit: (values: IFilterValues[]) => void
  onFilterReset: () => void
  onFilterFormClose: () => void
}

const defaultFilterValues = (): ReportFilterFormType => {
  const dtTo = new Date()
  const dtFrom = sub(new Date(), { months: 6 })
  const defValues = {
    faultDateSelector: dateRangeOptions.find(
      (option) => option.value === DateRangeSelectorTypes.Custom,
    ) ?? {
      label: '',
      value: '',
    },
    faultDate: {
      startDate: dtFrom,
      endDate: dtTo,
    },
    faultType: selectAllOption,
    faultDescription: selectBlankOption,
    deviceUserName: selectBlankOption,
  }

  return defValues
}
// Define the component's logic and UI here
//   const { initialValues, defaultValues, deviceUsers } = props

function DeviceFaultsFilterForm(props: ReportFilterFormProps): JSX.Element {
  const {
    selectedValues,
    deviceUserNames,
    faultDescriptions,
    faultTypes,
    onFilterSubmit,
    onFilterReset,
    onFilterFormClose,
  } = props

  const defaultFormValues = defaultFilterValues()
  const { register, control, handleSubmit, setValue, reset, formState, watch } =
    useForm<ReportFilterFormType>({
      defaultValues: defaultFormValues,
      values: selectedValues,
    })

  const faultDateSelector = watch('faultDateSelector')
  const [showCustomFaultDate, setShowShowCustomFaultDate] = React.useState<boolean>(false)

  useEffect(() => {
    if (faultDateSelector?.value === DateRangeSelectorTypes.Custom) {
      setShowShowCustomFaultDate(true)
    } else {
      setShowShowCustomFaultDate(false)
    }
  }, [faultDateSelector])

  const { errors, isDirty, isValid, isSubmitting } = formState

  const onSubmit: SubmitHandler<ReportFilterFormType> = (data) => {
    const filterValues: IFilterValues[] = []

    filterValues.push({
      columnId: columnIDs.faultDateTime,
      value: data.faultDateSelector.label === DateRangeSelectorTypes.All ? '' : data.faultDate,
    })

    filterValues.push({
      columnId: columnIDs.faultType,
      value: data.faultType?.value?.toString().trim()
        ? data.faultType?.value?.toString().trim() === SelectOptionTypes.All
          ? ''
          : data.faultType?.value?.toString().trim()
        : '',
    })

    filterValues.push({
      columnId: columnIDs.faultDescription,
      value: data.faultDescription?.value?.toString().trim()
        ? data.faultDescription?.value?.toString().trim() === SelectOptionTypes.All
          ? ''
          : data.faultDescription?.value?.toString().trim()
        : '',
    })

    filterValues.push({
      columnId: columnIDs.assignedTo,
      value: data.deviceUserName?.value?.toString().trim()
        ? data.deviceUserName?.value?.toString().trim() === SelectOptionTypes.All
          ? ''
          : data.deviceUserName?.value?.toString().trim()
        : '',
    })

    onFilterSubmit(filterValues)
  }

  const onError = (formErrors: FieldErrors<ReportFilterFormType>) => {
    console.log(formErrors)
  }

  const onReset = () => {
    reset(defaultFilterValues)
    onFilterReset()
  }

  return (
    <div className='filter-form-container'>
      <div className='filter-form-header'>
        <FilterFormTitle />

        <div
          title='Close Filter Form'
          role='button'
          tabIndex={0}
          onClick={() => onFilterFormClose()}
          onKeyDown={() => onFilterFormClose()}
          className='filter-form-close-button'
        >
          <CloseIcon toggleClick={() => onFilterFormClose()} className='w-5 h-5 ' />
        </div>
      </div>
      <form
        onSubmit={handleSubmit(onSubmit, onError)}
        onReset={onFilterReset}
        noValidate
        className=''
      >
        <div className='filter-form-fields-container'>
          {/* // Assigned Date */}
          <div className='flex flex-col gap-0.5 items-start self-stretch'>
            <label
              htmlFor='faultDateSelector'
              className='pl-4 pr-3 text-xs font-bold leading-5 text-c-dark-blue-1 '
            >
              Date & Time
            </label>

            <Controller
              name='faultDateSelector'
              control={control}
              render={({ field }) => (
                <SelectControl
                  className='w-full h-[42px]'
                  selectControlProps={{
                    options: dateRangeOptions,
                    defaultValue: field.value,
                    isMulti: false,

                    onChange: (selectedOption: IselectOptionProps) => {
                      const selectedValue = selectedOption?.value
                      field.onChange(selectedOption)
                      if (selectedValue !== DateRangeSelectorTypes.Custom) {
                        setValue('faultDate', selectedValue as DateRange, {
                          shouldValidate: true,
                        })
                      } else {
                        setValue('faultDate', {
                          startDate: selectedValues?.faultDate.startDate || new Date(),
                          endDate: selectedValues?.faultDate.endDate || new Date(),
                        })
                      }
                    },
                  }}
                />
              )}
            />
          </div>

          {showCustomFaultDate && (
            <>
              <div className='flex flex-row self-stretch justify-between w-full items-end'>
                <div className='flex-grow '>
                  <div className='flex flex-col gap-0.5 items-start '>
                    <label
                      htmlFor='faultDateFrom'
                      className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1'
                    >
                      From
                    </label>
                    <div className='w-[110px]'>
                      <Controller
                        name='faultDate.startDate'
                        control={control}
                        render={({ field }) => (
                          <DatePickerControl
                            className=''
                            datePickerProps={{
                              selected: field.value,
                              onChange: (date: Date | null) => field.onChange(date as Date),
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>

                <div className='mx-0.5 w-4 h-4 my-3'>
                  <ChevronRightIcon className='self-center' />
                </div>

                <div className='w-[110px]'>
                  <div className='flex flex-col gap-0.5 items-start'>
                    <label
                      htmlFor='faultDateFilterTo'
                      className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1'
                    >
                      To
                    </label>
                    <div className=''>
                      <Controller
                        name='faultDate.endDate'
                        control={control}
                        render={({ field }) => (
                          <DatePickerControl
                            className=''
                            datePickerProps={{
                              selected: field.value,
                              maxDate: new Date(),
                              onChange: (date: Date | null) => field.onChange(date as Date),
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {errors.faultDate && (
                <>
                  <p className='text-xs text-red-500'>{errors.faultDate?.startDate?.message}</p>
                  <p className='text-xs text-red-500'>{errors.faultDate?.endDate?.message}</p>
                </>
              )}
            </>
          )}

          <div className='flex flex-col gap-0.5 items-start self-stretch'>
            <label
              htmlFor='faultTypeFilter'
              className='pl-4 px-3 text-xs font-bold leading-5 text-c-dark-blue-1 '
            >
              Fault Type
            </label>
            <Controller
              name='faultType'
              control={control}
              render={({ field }) => (
                <SelectControl
                  className='w-full h-[42px]'
                  selectControlProps={{
                    options: faultTypes,
                    value: field.value,
                    defaultValue: field.value,
                    isMulti: false,
                    isSearchable: true,
                    isDropDownSelectable: true,
                    openMenuOnClick: true,
                    placeholder: 'Select fault types',
                    isClearable: false,
                    onChange: (selectedOption: IselectOptionProps) => {
                      field.onChange(selectedOption)
                    },
                  }}
                />
              )}
            />
            <p className='text-xs text-red-500'>{errors.faultType?.message}</p>
          </div>

          <div className='flex flex-col gap-0.5 items-start self-stretch'>
            <label
              htmlFor='faultDescFilter'
              className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1'
            >
              Description
            </label>
            <Controller
              name='faultDescription'
              control={control}
              render={({ field }) => (
                <CreatableSelectControl
                  className='w-full h-[42px]'
                  selectControlProps={{
                    options: faultDescriptions,
                    value: field.value,
                    defaultValue: field.value,
                    isMulti: false,
                    isSearchable: true,
                    openMenuOnClick: true,
                    placeholder: '-',
                    isClearable: true,
                    onChange: (selectedOption: IselectOptionProps) => {
                      field.onChange(selectedOption)
                    },
                  }}
                />
              )}
            />
            <p className='text-xs text-red-500'>{errors.faultDescription?.message}</p>
          </div>

          <div className='flex flex-col gap-0.5 items-start self-stretch'>
            <label
              htmlFor='faultUserNamesFilter'
              className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1'
            >
              Assigned To
            </label>
            <Controller
              name='deviceUserName'
              control={control}
              render={({ field }) => (
                <CreatableSelectControl
                  className='w-full h-[42px] '
                  selectControlProps={{
                    options: deviceUserNames,
                    value: field.value,
                    defaultValue: field.value,
                    isMulti: false,
                    isSearchable: true,
                    openMenuOnClick: true,
                    placeholder: '-',
                    isClearable: false,
                    onChange: (selectedOption: IselectOptionProps) => {
                      field.onChange(selectedOption)
                    },
                  }}
                />
              )}
            />
            <p className='text-xs text-red-500'>{errors.deviceUserName?.message}</p>
          </div>
        </div>
        {/* Buttons */}
        <div className='filter-form-footer'>
          <input
            className='filter-form-reset-button'
            type='reset'
            disabled={isSubmitting}
            onClick={() => {
              onReset()
            }}
          />
          <input
            className='filter-form-apply-button'
            type='submit'
            value='Apply'
            // disabled={!isDirty || !isValid || isSubmitting}
          />
        </div>
      </form>

      <DevTool control={control} />
    </div> // opening div
  )
}

export default withErrorBoundary(DeviceFaultsFilterForm, {
  FallbackComponent: ErrorPage,
})
