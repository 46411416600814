import ConnectAPIClient from 'services/ConnectAPIClient'
import { getAccessToken, getAccessTokenProps } from 'services/MSALService'
import { useMsal } from '@azure/msal-react'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { Customer, iCustomerParams } from './iCustomerList'

const customerListUrl = () => `customers/api/customer`

const customerListQueryKey = () => ['customers', 'list']

export async function FetchCustomersList(params: { token: string }): Promise<Customer[]> {
  try {
    const { token } = params
    const url = customerListUrl()
    const resp = await ConnectAPIClient.get<Customer[]>(url, token)
    const response = resp.data
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function getCustomersListData(customersListAPIParams: getAccessTokenProps) {
  const { msalContext, redirectPageURL } = customersListAPIParams
  const token = await getAccessToken({ msalContext, redirectPageURL })
  const customersListData = await FetchCustomersList({ token })
  return customersListData
}

export function useCustomersList(redirectPageURL: string, enabled?: boolean) {
  const msalContext = useMsal()

  return useQuery(
    customerListQueryKey(),
    () =>
      getCustomersListData({
        msalContext,
        redirectPageURL,
      }),
    {
      enabled,
      onError: (error: AxiosError) => {
        console.log(error)
      },
    },
  )
}

export async function updateCustomer(
  params: getAccessTokenProps & { payload: iCustomerParams },
): Promise<Customer> {
  try {
    const { msalContext, payload } = params
    const token = await getAccessToken({ msalContext, redirectPageURL: '' })
    const url = customerListUrl()
    const resp = await ConnectAPIClient.put<Customer>(`${url}/${payload.id}`, token, '', {
      ...payload,
    } as unknown as Customer)
    const response = resp.data
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}
export async function addCustomer(
  params: getAccessTokenProps & { payload: iCustomerParams },
): Promise<Customer> {
  try {
    const { msalContext, payload } = params
    const token = await getAccessToken({ msalContext, redirectPageURL: '' })
    const url = customerListUrl()
    const resp = await ConnectAPIClient.post<Customer>(`${url}`, token, '0.3', {
      ...payload,
    } as unknown as Customer)
    const response = resp.data
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}
