/*
TODO:
Refactoring required:
1. Column width for description - need to add max-width. Max-width needs to be calculated
based on available width and other columns width (also considering side nav status etc)



*/

import React, { useMemo } from 'react'
import { ColumnDef } from '@tanstack/react-table'
import { isValid } from 'date-fns'
import { FormattedDate } from 'react-intl'
import clsx from 'clsx'

import { IFilterValues } from 'forms/FormModelInterface'
import { CalDueDateFilterFn, DateRangeColumnFilterFn } from 'forms/FormUtils'
import { iDevice } from 'data/DeviceListHook'

import TsTable from 'components/table/TsTable'
import { DeviceCalDueTypes } from 'utils/CommonEnums'
import Tooltip from 'components/atom/Tooltip'
import { withErrorBoundary } from 'react-error-boundary'
import ErrorPage from 'pages/common/ErrorPage'
import { CalibrationPageColumnIds } from './CalibrationsPageUtils'

interface calibrationPageTableProps {
  tableData?: iDevice[]
  isLoading: boolean
  filterValues: IFilterValues[]
  updateFilteredData: (data: iDevice[]) => void
  onShowFilter: () => void
  onFilterFormSubmit: (filterValues: IFilterValues[]) => void
  onFilterFormReset: () => void
  setShowExportDialog: (val: boolean) => void
}

function CalibrationPageTable(tableDataProps: calibrationPageTableProps): JSX.Element {
  const {
    tableData,
    filterValues,
    isLoading,
    updateFilteredData,
    onShowFilter,
    onFilterFormReset,
    setShowExportDialog,
  } = tableDataProps

  const tableColumns = useMemo<ColumnDef<iDevice>[]>(
    () => [
      {
        id: CalibrationPageColumnIds.serialNumber,
        header: 'Serial  #',
        accessorKey: 'serialNumber',
        cell: (info) => {
          const value: string = info.getValue() as string
          return <div className='font-bold text-2xs leading-5  text-ellipsis'>{value}</div>
        },
        meta: {},
        filterFn: 'includesString',
      },
      {
        id: CalibrationPageColumnIds.deviceType,
        header: 'Device',
        accessorKey: 'deviceType',
        cell: (info) => {
          const value: string = info.getValue() as string
          return <div className='py-3 text-2xs font-normal leading-5  text-ellipsis '>{value}</div>
        },
        meta: {},
        filterFn: 'includesString',
      },
      {
        id: CalibrationPageColumnIds.businessUnit,
        header: 'Business Unit',
        accessorKey: 'businessUnit',
        accessorFn: (row) => `${row?.businessUnit?.name}`,
        cell: (info) => {
          const businessUnitName = info.getValue() as string
          return businessUnitName ? (
            <Tooltip
              showOnlyWhenTextIsTruncate
              tooltipText={businessUnitName}
              toolTipClass='tooltip'
              id={`calibration-business-unit-${businessUnitName}`}
              className={` font-normal text-2xs leading-5  text-ellipsis `}
            >
              {businessUnitName}
            </Tooltip>
          ) : (
            <div className='mr-2 h-5 bg-c-light-blue-1 rounded-lg animate-pulse' />
          )
        },
        meta: {},
        filterFn: 'includesString',
      },
      {
        id: CalibrationPageColumnIds.calibrationStatus,
        header: 'Status',
        accessorKey: 'calDueStatus',
        cell: (info) => {
          const value: string = info.getValue() as string
          return (
            <span
              className={clsx(
                'whitespace-nowrap rounded-full px-2.5 py-1 font-semibold text-xs leading-4',

                {
                  'bg-c-green-1 text-c-dark-blue-1': value === DeviceCalDueTypes.DueOver90Days,
                  'bg-c-green-2 text-c-dark-blue-1': value === DeviceCalDueTypes.DueIn90Days,
                  'bg-c-green-3 text-c-dark-blue-1': value === DeviceCalDueTypes.DueIn60Days,
                  'bg-c-blue-1 text-c-dark-blue-1': value === DeviceCalDueTypes.DueIn30Days,
                  'bg-c-red-light text-c-dark-blue-1': value === DeviceCalDueTypes.DueIn7Days,
                  'bg-c-red-1 text-c-white': value === DeviceCalDueTypes.Overdue,
                },
              )}
            >
              {value}
            </span>
          )
        },
        meta: {},
        filterFn: 'includesString',
      },
      {
        id: CalibrationPageColumnIds.lastCal,
        header: 'Last calibrated',
        accessorKey: 'lastCalibratedDate',
        cell: (info) => {
          const value = info.getValue() as string
          if (!value) {
            return (
              <div className='font-poppins text-2xs leading-4 font-medium text-c-dark-blue-1  '>
                Not available
              </div>
            )
          }
          const date = new Date(value)
          const isLastCalDateValid = isValid(date)
          return (
            <div className='font-poppins text-2xs leading-4 font-normal text-c-dark-blue-1 '>
              {isLastCalDateValid && <FormattedDate value={date} />}
            </div>
          )
        },
        meta: {},
        filterFn: DateRangeColumnFilterFn<iDevice>,
      },
      {
        id: CalibrationPageColumnIds.calDue,
        header: 'Calibration due',
        accessorKey: 'calibrationDueDate',
        cell: (info) => {
          const value = info.getValue() as string
          if (value) {
            const date = new Date(value)
            const isCalDueDateValid = isValid(date)
            return (
              <div className='font-poppins text-2xs leading-4 font-normal text-c-dark-blue-1   '>
                {isCalDueDateValid && <FormattedDate value={date} />}
              </div>
            )
          }
          return (
            <div className='font-poppins text-2xs leading-4 font-medium text-c-dark-blue-1  w-24'>
              Not available
            </div>
          )
        },
        meta: {},
        filterFn: CalDueDateFilterFn<iDevice>,
      },
      {
        id: CalibrationPageColumnIds.deviceUser,
        header: 'Assigned To',
        accessorFn: (row) => `${row.deviceUser ? row.deviceUser.fullName : ''}`,
        cell: (info) => {
          const value: string = info.getValue() as string
          return (
            <Tooltip
              id={`calibration-device-user-${value}`}
              tooltipText={value}
              showOnlyWhenTextIsTruncate
              toolTipClass='tooltip'
              className='text-2xs leading-4 font-normal text-c-dark-blue-1   text-ellipsis '
            >
              {value}
            </Tooltip>
          )
        },
        meta: {},
        filterFn: 'includesString',
      },
    ],
    [],
  )
  const renderFilterSummaryComponent = () => <> </>

  return (
    <TsTable<iDevice>
      columns={tableColumns}
      data={tableData ?? []}
      dataIsLoading={isLoading}
      showGlobalActionButton={false}
      showGlobalFilter
      onExport={() => {}}
      onPrint={() => {}}
      getRowCanExpand={() => true}
      // renderSubComponent={renderSubComponent}
      updateFilteredData={(data) => updateFilteredData(data)}
      renderFilterSummary={renderFilterSummaryComponent}
      resetFilter={() => onFilterFormReset()}
      onShowFilter={() => onShowFilter()}
      filterValues={filterValues}
      defaultSortedColumKey={CalibrationPageColumnIds.serialNumber}
      setShowExportDialog={(val) => setShowExportDialog(val)}
      minDisplayRows={10}
      // setFilterValues={setFilterValues}
      // dateRangeSelectorType={DateRangeSelectorTypes.DateRange}
      // dateRangeOptions={dateRangeOptions}
      // dateRangeFilterFn={DateRangeColumnFilterFn<iFaultData>}
    />
  )
}

export default withErrorBoundary(CalibrationPageTable, {
  FallbackComponent: ErrorPage,
})
