import { CellContext, ColumnDef } from '@tanstack/react-table'
import TsTable from 'components/table/TsTable'
import { IFilterValues } from 'forms/FormModelInterface'
import ErrorPage from 'pages/common/ErrorPage'
import React, { useMemo, useState } from 'react'
import { withErrorBoundary } from 'react-error-boundary'
import { EditIcon } from 'assets/icons'
import { UserPropertyFilter } from 'forms/FormUtils'
import Show from 'components/atom/Show'
import Tooltip from 'components/atom/Tooltip'
import { UserRolesConstants } from 'utils/Constants'
import clsx from 'clsx'
import { CustomerStatusConstants, CustomerStatusEnums } from 'pages/customer/view/CustomerPageUtils'
import { AddEntryInTableButton } from 'components/table/tsTableComponents/buttons'
import { User } from '../data/iUsersList'
import { UsersPageColumnHeader, UsersPageColumnIds } from '../view/UsersPageUtils'
import UserCrudForm from './UserCrudForm'

const AddUserButton = AddEntryInTableButton
interface UsersPageTableProps {
  tableData?: User[]
  isLoading: boolean
  filterValues: IFilterValues[]
  updateFilteredData: (data: User[]) => void
  onShowFilter: () => void
  onFilterFormSubmit: (filterValues: IFilterValues[]) => void
  onFilterFormReset: () => void
  setShowExportDialog: (val: boolean) => void
  isGlobalUser: boolean
}

function UsersPageTable(tableDataProps: UsersPageTableProps): JSX.Element {
  const {
    tableData,
    filterValues,
    isLoading,
    updateFilteredData,
    onShowFilter,
    onFilterFormReset,
    setShowExportDialog,
    isGlobalUser,
  } = tableDataProps

  const [typeOfCrud, setTypeOfCrud] = useState<string>()
  const [user, setUser] = useState<User>()

  const tableColumns = useMemo(
    () =>
      (!isLoading
        ? [
            {
              id: UsersPageColumnIds.email,
              header: UsersPageColumnHeader[UsersPageColumnIds.email],
              accessorKey: UsersPageColumnIds.email,
              cell: (info: CellContext<User, unknown>) => {
                const value: string = info.getValue() as string
                return (
                  <Tooltip
                    toolTipClass='tooltip left-0 z-50 w-fit'
                    tooltipText={value}
                    showOnlyWhenTextIsTruncate
                    id={`user-${value}`}
                    className='text-center text-2xs leading-5  text-ellipsis pr-2'
                  >
                    {value}
                  </Tooltip>
                )
              },
              meta: {},
              filterFn: 'includesString',
            },
            {
              id: UsersPageColumnIds.firstName,
              header: UsersPageColumnHeader[UsersPageColumnIds.firstName],
              accessorKey: UsersPageColumnIds.firstName,
              cell: (info: CellContext<User, unknown>) => {
                const value: string = info.getValue() as string
                return (
                  <div className='text-center text-2xs leading-5  text-ellipsis px-2'>{value}</div>
                )
              },
              meta: {},
              filterFn: 'includesString',
            },
            {
              id: UsersPageColumnIds.lastName,
              header: UsersPageColumnHeader[UsersPageColumnIds.lastName],
              accessorKey: UsersPageColumnIds.lastName,
              cell: (info: CellContext<User, unknown>) => {
                const value: string = info.getValue() as string
                return (
                  <div className='text-center text-2xs leading-5  text-ellipsis px-2'>{value}</div>
                )
              },
              meta: {},
              filterFn: 'includesString',
            },
            {
              id: UsersPageColumnIds.businessUnitName,
              header: UsersPageColumnHeader[UsersPageColumnIds.businessUnitName],
              accessorFn: (row: User) =>
                `${row.businessUnit?.name ? row.businessUnit?.name ?? '' : ''}`,
              cell: (info: CellContext<User, unknown>) => {
                const value = info.row.original.businessUnit?.name

                return (
                  <Tooltip
                    toolTipClass='tooltip left-0 z-50 w-fit'
                    tooltipText={value}
                    showOnlyWhenTextIsTruncate
                    id={`user-${info.row.index}-business-unit`}
                    className='text-center text-2xs leading-5  text-ellipsis px-2'
                  >
                    {value}
                  </Tooltip>
                )
              },
              meta: {},
              filterFn: UserPropertyFilter,
            },
            {
              id: UsersPageColumnIds.customerName,
              header: UsersPageColumnHeader[UsersPageColumnIds.customerName],
              accessorFn: (row: User) => `${row.customer?.name ? row.customer?.name ?? '' : ''}`,
              cell: (info: CellContext<User, unknown>) => {
                const value = info.row.original.customer?.name

                return (
                  <Tooltip
                    toolTipClass='tooltip left-0 z-50 w-fit'
                    tooltipText={value}
                    showOnlyWhenTextIsTruncate
                    id={`user-${info.row.index}-customer`}
                    className='text-center text-2xs leading-5  text-ellipsis px-2'
                  >
                    {value}
                  </Tooltip>
                )
              },
              meta: {},
              filterFn: UserPropertyFilter,
            },
            {
              id: UsersPageColumnIds.roleName,
              header: UsersPageColumnHeader[UsersPageColumnIds.roleName],
              accessorFn: (row: User) =>
                `${row.role?.[0]?.roleName ? row.role?.[0]?.roleName ?? '' : ''}`,
              cell: (info: CellContext<User, unknown>) => {
                const value = info.row.original?.role?.[0]?.roleName
                return (
                  <div className='text-center text-2xs leading-5  text-ellipsis px-2'>
                    {UserRolesConstants[value as string]}
                  </div>
                )
              },
              meta: {},
              filterFn: UserPropertyFilter,
            },
            {
              id: UsersPageColumnIds.status,
              header: UsersPageColumnHeader[UsersPageColumnIds.status],
              accessorKey: UsersPageColumnIds.status,
              cell: (info: CellContext<User, unknown>) => {
                const value: string = info.getValue() as string

                return (
                  <div
                    className='w-full h-full content-center cursor-pointer m-auto flex items-center justify-start'
                    title='Double click to view device details'
                  >
                    <div
                      className={clsx(
                        {
                          'bg-c-green': value === CustomerStatusEnums.Active,
                          'bg-c-red-light':
                            value === CustomerStatusEnums.Inactive ||
                            value === CustomerStatusEnums.CreationFailed,
                          'bg-c-blue': value === CustomerStatusEnums.Invited,
                        },
                        `font-semibold whitespace-nowrap rounded-full text-c-dark-blue-1 bg-opacity-20 text-xs leading-4 px-2.5 py-1 `,
                      )}
                    >
                      {CustomerStatusConstants[value as CustomerStatusEnums]}
                    </div>
                  </div>
                )
              },
              meta: {},
              filterFn: 'includesString',
            },
            {
              id: '999',
              cell: (info: CellContext<User, unknown>) => (
                <button
                  type='button'
                  title='Edit User'
                  className='bg-c-dark-blue-1 rounded-full p-1.5 '
                  onClick={() => {
                    setTypeOfCrud('edit')
                    setUser(info.row.original)
                  }}
                >
                  <EditIcon height={24} width={24} fill='white' />
                </button>
              ),
              meta: {
                cellClassName: 'overflow-visible flex justify-end w-full px-2.5',
                headerClassName: 'w-1 overflow-visible',
                shouldDraggable: false,
                resizable: false,
                sortable: false,
              },
            },
          ].filter((column) =>
            !isGlobalUser ? column.id !== UsersPageColumnIds.customerName : true,
          )
        : []) as ColumnDef<User>[],
    [isGlobalUser, isLoading],
  )
  const renderFilterSummaryComponent = () => <> </>

  return (
    <>
      <TsTable<User>
        columns={tableColumns}
        data={tableData ?? []}
        dataIsLoading={isLoading}
        showGlobalActionButton
        showGlobalFilter
        onExport={() => {}}
        onPrint={() => {}}
        getRowCanExpand={() => true}
        updateFilteredData={(data) => updateFilteredData(data)}
        renderFilterSummary={renderFilterSummaryComponent}
        resetFilter={() => onFilterFormReset()}
        onShowFilter={() => onShowFilter()}
        filterValues={filterValues}
        defaultSortedColumKey={UsersPageColumnIds.email}
        setShowExportDialog={(val) => setShowExportDialog(val)}
        globalActionButton={
          <AddUserButton buttonText='Add User' buttonAction={() => setTypeOfCrud('add')} />
        }
        showFilterForm={false}
        // setFilterValues={setFilterValues}
        // dateRangeSelectorType={DateRangeSelectorTypes.DateRange}
        // dateRangeOptions={dateRangeOptions}
        // dateRangeFilterFn={DateRangeColumnFilterFn<iFaultData>}
      />

      <Show>
        <Show.When isTrue={Boolean(typeOfCrud)}>
          <UserCrudForm
            close={() => {
              setTypeOfCrud('')
              setUser(undefined)
            }}
            type={typeOfCrud}
            data={typeOfCrud === 'edit' ? user : undefined}
          />
        </Show.When>
      </Show>
    </>
  )
}

export default withErrorBoundary(UsersPageTable, {
  FallbackComponent: ErrorPage,
})
