import { differenceInCalendarDays, sub } from 'date-fns'
import { iDevice } from 'data/DeviceListHook'
import { DeviceCalDueTypes } from 'utils/CommonEnums'

export function getDeviceCalDueStatus(device: iDevice) {
  if (!device.calibrationDueDate) return DeviceCalDueTypes.NotAvailable
  const dueDate = new Date(device.calibrationDueDate)
  const today = new Date()

  //   differenceInCalendarDays(dateLeft, dateRight)
  // Arguments
  // Name	Type	Description
  // dateLeft	Date | Number
  // the later date

  // dateRight	Date | Number
  // the earlier date

  const timeDiff = differenceInCalendarDays(dueDate, today)

  if (timeDiff < 0) return DeviceCalDueTypes.Overdue
  if (timeDiff <= 7) return DeviceCalDueTypes.DueIn7Days
  if (timeDiff <= 30) return DeviceCalDueTypes.DueIn30Days
  if (timeDiff <= 60) return DeviceCalDueTypes.DueIn60Days
  if (timeDiff <= 90) return DeviceCalDueTypes.DueIn90Days
  return DeviceCalDueTypes.DueOver90Days

}
