import { QueryClient, useQuery } from '@tanstack/react-query'
import { IMsalContext, useMsal } from '@azure/msal-react'
import { LoaderFunction, createSearchParams, redirect } from 'react-router-dom'

import { GasUnitOfMeasurement } from 'utils/CommonEnums'
import { getAccessToken } from 'services/MSALService'
import ConnectAPIClient from 'services/ConnectAPIClient'
import { AxiosError } from 'axios'

const deviceGasUoMListQueryKey = (deviceId: string) => ['device', 'gasUoMList', 'id', deviceId]

const deviceGasUoMListURL = (deviecId: string) => `devicelogs/api/gaslogs/${deviecId}/gasuomlist`

interface iGasUoMListAPIResponse {
  gasTypes: string[]
  uoMs: string[]
}

async function fetchDeviceGasUoMList({
  token,
  deviceId,
}: {
  token: string
  deviceId: string
}): Promise<iGasUoMListAPIResponse> {
  try {
    const url = deviceGasUoMListURL(deviceId)
    const resp = await ConnectAPIClient.get<iGasUoMListAPIResponse>(url, token)
    return resp.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function getDeviceGasUoMList({
  msalContext,
  redirectPageURL,
  deviceId,
}: {
  msalContext: IMsalContext
  redirectPageURL: string
  deviceId: string
}) {
  const token = await getAccessToken({ msalContext, redirectPageURL })
  const gasUoMList = await fetchDeviceGasUoMList({ token, deviceId })
  return gasUoMList
}

export function useDeviceGasUoMList(redirectPageURL: string, deviceId: string, enabled?: boolean) {
  const msalContext = useMsal()
  const queryKey = deviceGasUoMListQueryKey(deviceId)

  return useQuery(queryKey, () => getDeviceGasUoMList({ msalContext, redirectPageURL, deviceId }), {
    enabled,
    onError: (error: AxiosError) => {
      console.log(error)
    },
  })
}
