import { QueryClient, useQuery } from '@tanstack/react-query'
import { useMsal } from '@azure/msal-react'

import ConnectAPIClient from 'services/ConnectAPIClient'
import { IselectOptionProps } from 'forms/FormModelInterface'
import { selectAllOption } from 'forms/FormUtils'
import { AxiosError } from 'axios'
import { getAPIData, iGenericAPIParams } from './GenericData'
import { iBusinessUnitDetails } from './Interfaces'
import { allBusinessUnitOption } from './BusinessUnitsListHookWrapper'

const businessUnitsListByCustomerIdQueryKey = (customerId: string) => [
  'businessUnits',
  'customer',
  customerId,
]
const businessUnitsListByCustomerURL = (customerId: string) =>
  `customers/api/customer/bu/${customerId}`

const businessUnitsListByCustomerURLAPIVersion = '0.2'

interface iFetchBUListByCustomerIdParams {
  token: string
  customerId: string
}

interface iGetOrFetchBUListByCustomerIdProps {
  queryClient: QueryClient
  token: string
  customerId: string
}

export function useBusinessUnitsListByCustomerId(
  customerId: string,
  redirectPageURL: string,
  enabled: boolean,
) {
  const msalContext = useMsal()
  const apiURL = businessUnitsListByCustomerURL(customerId)
  const queryKey = businessUnitsListByCustomerIdQueryKey(customerId)
  return useQuery<iBusinessUnitDetails[], AxiosError>(
    queryKey,
    () =>
      getAPIData<iGenericAPIParams, iBusinessUnitDetails[]>({
        msalContext,
        redirectPageURL,
        apiURL,
        version: businessUnitsListByCustomerURLAPIVersion,
      }),
    {
      enabled,
      staleTime: 24 * 60 * 60 * 1000,
      select: (resultData) => {
        // const businessUnitsListConverted: IselectOptionProps[] = resultData.map((businessUnit) => ({
        //   value: businessUnit.id,
        //   label: businessUnit.name,
        // }))
        // resultData.sort((a, b) => a.name.localeCompare(b.name));

        if (resultData?.find((bu) => bu.id === allBusinessUnitOption.id)) {
          return resultData
        }
        resultData.unshift(allBusinessUnitOption)
        return resultData
        // resultData.find((businessUnit) => businessUnit.parentId === 'null')
        // businessUnitsListConverted.unshift({...selectAllOption, isDisabled: true})
        // businessUnitsListConverted.unshift(selectAllOption)
        // return businessUnitsListConverted
      },
      onError: (error) => {
        console.log(error)
      },
    },
  )
}

export async function FetchBusinessUnitsListByCustomer(
  params: iFetchBUListByCustomerIdParams,
): Promise<iBusinessUnitDetails[]> {
  try {
    const { token, customerId } = params
    const url = businessUnitsListByCustomerURL(customerId)
    const resp = await ConnectAPIClient.get<iBusinessUnitDetails[]>(
      url,
      token,
      businessUnitsListByCustomerURLAPIVersion,
    )
    const response = resp.data
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function getOrFetchBusinessUnitsByCustomer(props: iGetOrFetchBUListByCustomerIdProps) {
  const { queryClient, token, customerId } = props

  const businessUnitsListCached = queryClient.getQueryData<iBusinessUnitDetails[]>(
    businessUnitsListByCustomerIdQueryKey(customerId),
  )

  if (businessUnitsListCached) {
    // const customerListConverted: IselectOptionProps[] =
    //   convertCustomerListToIselect(customerListCached)
    return businessUnitsListCached
  }

  const businessUnitsListFetched = await queryClient.fetchQuery(
    businessUnitsListByCustomerIdQueryKey(customerId),
    async () =>
      FetchBusinessUnitsListByCustomer({
        token,
        customerId,
      }),
  )
  return businessUnitsListFetched

  // const customerListConverted: IselectOptionProps[] =
  //   convertCustomerListToIselect(customerListFetched)
  // return customerListConverted

  // const businessUnitsListFetched = await queryClient.fetchQuery<iBusinessUnitDetails[]>(
  //   queryKey,
  //   async () => {
  //     const businessUnitsList = await getBusinessUnitsByCustomer({ token })
  //     return businessUnitsList
  //   },
  // )

  // return businessUnitsListFetched
}
