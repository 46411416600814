import { Dialog } from '@headlessui/react'
import { CloseIcon } from 'assets/icons'
import ErrorIcon from 'assets/icons/ErrorIcon'
import InfoIcon from 'assets/icons/InfoIcon'
import SuccessIcon from 'assets/icons/SuccessIcon'
import WarningIcon from 'assets/icons/WarningIcon'
import clsx from 'clsx'
import React, { createContext, useContext, useEffect, useMemo } from 'react'

/**
 * The severity types for the snack bar.
 */
export type SnackBarSeverityType = 'success' | 'error' | 'warning' | 'info'

/**
 * The types of snack bar notifications.
 */
export type SnackBarType = 'Successful' | 'Error' | 'Warning' | 'Information'

/**
 * The data structure for a snack bar notification.
 */
type SnackBarDataType = {
  message: string
  type: SnackBarType
  severity: SnackBarSeverityType
}

/**
 * The context type for the SnackBarContext.
 */
type SnackBarContextType = {
  open: () => void
  close: () => void
  setSnackBar: React.Dispatch<React.SetStateAction<SnackBarDataType>>
  snackbar: SnackBarDataType
}

/**
 * The icons for different severity types of snack bar notifications.
 */
const SnackbarIcon = {
  success: <SuccessIcon className='h-5 w-5 stroke-green-400  fill-transparent' />,
  error: <ErrorIcon className='h-5 w-5 stroke-red-400 fill-transparent' />,
  warning: <WarningIcon className='h-5 w-5 stroke-yellow-400  fill-transparent' />,
  info: <InfoIcon className='h-5 w-5 stroke-blue-400  fill-transparent' />,
}

/**
 * The background colors for different severity types of snack bar notifications.
 */
const SnackbarBg = {
  success: 'bg-green-50',
  error: 'bg-red-50',
  warning: 'bg-yellow-50',
  info: 'bg-blue-50',
}

/**
 * The context for managing and displaying snack bar notifications.
 */
export const SnackBarContext = createContext({
  // snackbar: {
  //   type: 'Error',
  //   message: 'Error',
  //   severity: 'error',
  // },
} as SnackBarContextType)

/**
 * The provider component for the SnackBarContext.
 * @param children - The child components to be wrapped by the SnackBarContextProvider.
 */
export function SnackBarContextProvider({ children }: { children: React.ReactNode }) {
  const { snackbar } = useContext(SnackBarContext)
  const [openSnackBar, setOpenSnackBar] = React.useState(true)
  const [state, setState] = React.useState<SnackBarDataType>(snackbar)

  /**
   * Closes the snack bar.
   */
  const close = () => setOpenSnackBar(false)

  /**
   * Opens the snack bar.
   */
  const open = () => setOpenSnackBar(true)

  /**
   * The context value for the SnackBarContext.
   */
  const contextValue = useMemo(() => ({ open, close, snackbar: state, setSnackBar: setState }), [])

  // This effect runs whenever 'close' or 'openSnackBar' changes. If 'openSnackBar'
  // is true, it sets up a timeout to call the 'close' function after 5000 milliseconds
  // (5 seconds). This means that if the snack bar is open, it will automatically close
  // after 5 seconds.
  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null
    if (openSnackBar) {
      timeoutId = setTimeout(() => {
        close()
      }, 5000)
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [close, openSnackBar])

  return (
    <SnackBarContext.Provider value={contextValue}>
      <Dialog
        open={openSnackBar}
        onClose={close}
        className={clsx(
          `fixed flex right-5 lg:top-5 flex-col items-center space-y-4 sm:items-end transition `,
          {
            ' z-[999] transform ease-out duration-150  translate-y-0 opacity-100 sm:translate-x-0':
              openSnackBar,
            'z-[-999] transition ease-in duration-150 opacity-0 translate-y-2 sm:translate-y-0 sm:translate-x-2':
              !openSnackBar,
          },
        )}
      >
        {state && (
          <Dialog.Panel
            className={`w-[300px]  shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden rounded-lg flex items-start p-4 ${
              SnackbarBg[state.severity]
            }`}
          >
            <div className='flex-shrink-0'>{SnackbarIcon[state.severity]}</div>
            <div className='ml-3 w-0 flex-1 pt-0.5'>
              <p className='text-sm font-medium text-gray-900'>{state.type}</p>
              <p className='mt-1 text-sm text-gray-500'>{state.message}</p>
            </div>
            <div className='ml-4 flex flex-shrink-0'>
              <button
                type='button'
                onClick={close}
                className={`inline-flex rounded-md  text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:${
                  SnackbarBg[state.severity]
                } focus:ring-offset-2`}
              >
                <span className='sr-only'>Close</span>
                <CloseIcon className='h-5 w-5' stroke='black' fill='black' />
              </button>
            </div>
          </Dialog.Panel>
        )}
      </Dialog>

      {children}
    </SnackBarContext.Provider>
  )
}
