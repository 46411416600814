import { FilterIcon } from 'assets/icons'
import Tooltip from 'components/atom/Tooltip'
import CrowconButton from 'components/miscComponents/CrowconButton'
import React from 'react'

export function FilterTableDataButton({
  toggleFilterFormVisibility,
}: {
  toggleFilterFormVisibility: () => void
}) {
  return (
    <div className=' relative'>
      <Tooltip
        tooltipText='Filter'
        toolTipClass='tooltip px-3 -top-8 left-1/2 -translate-x-1/2 w-fit m-auto text-center w-auto'
      >
        <CrowconButton
          // additionalClassName='flex flex-row justify-around items-center rounded-full md:rounded-4xl w-10 md:w-32 h-10 md:h-52 '
          additionalClassName='p-[10px]  rounded-full'
          buttonAction={toggleFilterFormVisibility}
          buttonChildren={
            <>
              {/* <span className='hidden md:inline pl-5 font-bold text-base leading-5 '>Filter</span> */}
              {/* <span className='md:pr-4 md:pl-3'> */}
              <FilterIcon width={18} height={18} />
              {/* </span> */}
            </>
          }
        />
      </Tooltip>
    </div>
  )
}
