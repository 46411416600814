import { UserAccountContext } from 'contexts/UserAccountContext'
import NotFound from 'pages/common/NotFound'
import React, { useContext } from 'react' // Adjust the import path to where your UserContext is defined
import { IsUserGlobalScope } from 'utils/UserDataUtils'

export function useUserHasRequiredRoles(requiredRoles?: string[]) {
  const { state: userInfo } = useContext(UserAccountContext) // Adjust this line if you're getting userInfo from somewhere else
  const isGlobalUser = IsUserGlobalScope(userInfo)
  const customerIsAdmin = requiredRoles?.includes(userInfo.authority[0].roleName)

  const userHasRequiredRoles = isGlobalUser || customerIsAdmin
  return userHasRequiredRoles
}

// The functional component version of the HOC
function withRoleChecking<T>(
  WrappedComponent: React.ComponentType<T>,
  requiredRoles?: string[],
  shouldShowNoAccess = false,
) {
  // The functional component that checks user roles and decides what to render
  function RoleCheckingComponent(props: React.PropsWithChildren<T>) {
    const { state: userInfo } = useContext(UserAccountContext)
    const userHasRequiredRoles = useUserHasRequiredRoles(requiredRoles)
    if (!userHasRequiredRoles) {
      // If the user does not have the required roles, redirect or show an error message
      return shouldShowNoAccess ? (
        <div className='bg-c-white'>
          <NotFound />
        </div>
      ) : (
        <div />
      )
    }

    // If the user has the required roles, render the wrapped component
    return <WrappedComponent {...(props as T)} userInfo={userInfo} />
  }

  return RoleCheckingComponent
}

export default withRoleChecking
