import React, { Fragment, useContext, useEffect, useMemo } from 'react'
import { Transition, Dialog } from '@headlessui/react'
import { Controller, DeepPartial, FieldErrors, useForm } from 'react-hook-form'
import { CreatableSelectControl, DatePickerControl, SelectControl } from 'components/formComponents'
import { IselectOptionProps } from 'forms/FormModelInterface'
import TextInputControl from 'components/formComponents/TextInputControl'
import { CloseIcon } from 'assets/icons'
import { customerListQueryKey, useFeatures, useSubscription } from 'data/CustomerListHook'
import { useLocation } from 'react-router-dom'
import { UserAccountContext } from 'contexts/UserAccountContext'
import { IsUserGlobalScope } from 'utils/UserDataUtils'
import Show from 'components/atom/Show'

import { userExists } from 'pages/susi/data/SignupData'
import { debounce } from 'components/modules/Calender/CalenderUtils'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useMsal } from '@azure/msal-react'
import { BillingPlanEnums } from 'utils/CommonEnums'
import { useGenericEventHandler } from 'data/GenericEventHandler'
import { AxiosError } from 'axios'
import { Customer } from '../data/iCustomerList'
import { addCustomer, updateCustomer } from '../data/CustomerListData'
import { CustomerStatusConstants, CustomerStatusEnums } from '../view/CustomerPageUtils'

export type CustomersCrudFormProps = {
  id?: string
  name?: string
  planDuration?: string
  subscriptionPlanType?: IselectOptionProps
  subscription?: IselectOptionProps
  features?: IselectOptionProps
  adminFirstName?: string
  adminLastName?: string
  adminEmail?: string
  status?: IselectOptionProps
  subscriptionExpirationDate?: Date | number | string
}

export default function CustomersCrudForm({
  type,
  data: customer,
  close,
}: {
  type?: string
  data?: Customer
  close: () => void
}) {
  const location = useLocation()
  const isOpened = Boolean(type)
  const { state: userInfo } = useContext(UserAccountContext)
  const isGlobalCustomers = IsUserGlobalScope(userInfo)

  const formValues = useMemo(
    (): CustomersCrudFormProps | undefined =>
      customer
        ? {
            id: customer?.id as string,
            name: customer?.name as string,
            subscriptionPlanType: customer?.billingPlans?.billingPlanType
              ? {
                  value: customer?.billingPlans?.billingPlanType as string,
                  label: BillingPlanEnums[
                    customer?.billingPlans?.billingPlanType as string
                  ] as string,
                }
              : undefined,
            subscription: customer?.billingPlans
              ? {
                  value: customer?.billingPlans?.id as string,
                  label: customer?.billingPlans?.name as string,
                }
              : undefined,
            subscriptionExpirationDate: customer?.subscriptionExpirationDate
              ? new Date(customer?.subscriptionExpirationDate as string)
              : '',
            status: {
              label: CustomerStatusConstants[customer?.status as CustomerStatusEnums],
              value: customer?.status,
            },

            features: customer?.features?.[0]
              ? {
                  value: customer?.features?.[0]?.featureId as string,
                  label: customer?.features?.[0]?.name as string,
                }
              : undefined,
          }
        : undefined,
    [customer],
  )
  const {
    handleSubmit,
    control,
    reset,
    watch,
    setError,
    clearErrors,
    formState: { errors, isValid, isDirty, isSubmitting, isSubmitted },
  } = useForm<CustomersCrudFormProps>({
    defaultValues: formValues as DeepPartial<CustomersCrudFormProps>,
    values: formValues,
    criteriaMode: 'all',
    shouldUnregister: true,
    mode: 'onTouched',
  })

  const { genericEventHandler } = useGenericEventHandler()
  // useQueryClient is a hook provided by react-query that returns the query client instance.
  // The query client is used to interact with and control the state of queries.
  const queryClient = useQueryClient()

  // useMsal is a hook provided by the MSAL (Microsoft Authentication Library) React library that returns the MSAL context.
  // The MSAL context contains various properties and methods related to authentication.
  const msalContext = useMsal()

  const formValuesWatch = watch()

  const onError = (formErrors: FieldErrors<CustomersCrudFormProps>) => {
    console.log(formErrors)
  }

  const {
    data: subscriptionData,
    isLoading: isSubscriptionLoading,
    isError: isSubscriptionHasError,
    error: subscriptionError,
  } = useSubscription(location.pathname, isGlobalCustomers, isGlobalCustomers === true)
  const {
    data: featureData,
    isLoading: isFeatureLoading,
    isError: isFeatureHasError,
    error: featureError,
  } = useFeatures(location.pathname, isGlobalCustomers, isGlobalCustomers === true)

  useEffect(() => {
    if (isSubscriptionHasError) {
      genericEventHandler({
        error: subscriptionError, // The error object
        severity: 'error', // The severity of the event
        onlyTrack: true, // Whether to only track the event, not display it
        message: subscriptionError?.message || 'Error fetching subscription', // The error message
        extraData: {
          component: 'CustomerCrudFrom.tsx', // The component where the event occurred
          action: 'fetching subscription', // The action that was being performed when the event occurred
        },
      })
    }
    if (isFeatureHasError) {
      genericEventHandler({
        error: featureError, // The error object
        severity: 'error', // The severity of the event
        onlyTrack: true, // Whether to only track the event, not display it
        message: featureError?.message || 'Error fetching features', // The error message
        extraData: {
          component: 'CustomerCrudFrom.tsx', // The component where the event occurred
          action: 'fetching features', // The action that was being performed when the event occurred
        },
      })
    }
  }, [subscriptionError, isSubscriptionHasError, isFeatureHasError, featureError])

  const onReset = () => {
    reset()
  }

  const subscriptionOptions = useMemo(
    () =>
      subscriptionData?.filter?.(
        (subscription) =>
          subscription.billingPlanType === formValuesWatch?.subscriptionPlanType?.value,
      ),
    [formValuesWatch?.subscriptionPlanType?.value],
  )

  async function checkWeatherUserExists(email: string) {
    if (email) {
      try {
        const userStatus = await userExists(email as string)
        if (userStatus.isUserActive) {
          setError('adminEmail', {
            message: 'User already exists',
          })
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  const debouncedOnEmail = debounce(checkWeatherUserExists, 100)

  const updateCustomerMutation = useMutation(updateCustomer, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: customerListQueryKey(),
      })
      close()
    },
    onError: (error: AxiosError) => {
      console.log(error)
      genericEventHandler({
        error,
        severity: 'error',
        onlyTrack: false,
        message:
          (
            (error as unknown as AxiosError).response?.data as {
              detail: string
            }
          )?.detail ||
          error?.message ||
          'Error while updating customer',
        extraData: {
          component: 'CustomerCrudFrom.tsx',
          action: 'customer updating failed',
        },
      })
    },
  })
  const addCustomerMutation = useMutation(addCustomer, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: customerListQueryKey(),
      })
      close()
    },
    onError: (error: AxiosError) => {
      console.log(error)
      genericEventHandler({
        error,
        severity: 'error',
        onlyTrack: false,
        message:
          (
            (error as unknown as AxiosError).response?.data as {
              detail: string
            }
          )?.detail ||
          error?.message ||
          'Error while creating customer',
        extraData: {
          component: 'CustomerCrudFrom.tsx',
          action: 'customer creation failed',
        },
      })
    },
  })

  const formSubmitHandler = (formData: CustomersCrudFormProps) => {
    if (type === 'add') {
      const createCustomerParams = {
        billingPlanId: formData.subscription?.value as string,
        name: formData.name,
        ownerFirstName: formData.adminFirstName,
        ownerLastName: formData.adminLastName,
        ownerEmail: formData.adminEmail,
        address: 'None',
        adminUser: {
          firstName: formData.adminFirstName,
          lastName: formData.adminLastName,
          email: formData.adminEmail,
        },
        subscriptionDurationInMonths: Number(formData.planDuration),
        features:
          featureData && featureData?.length > 0
            ? [
                {
                  featureId: formData.features?.value as string,
                  status: featureData?.filter?.(
                    (feature) => feature.id === formData.features?.value,
                  )?.[0]?.status,
                },
              ]
            : [],
        PortalURL: process.env.REACT_APP_SITE_BASE_URL,
      }
      addCustomerMutation.mutate({
        msalContext,
        redirectPageURL: location.pathname,
        payload: createCustomerParams,
      })
    } else {
      const updateCustomerParams = {
        id: customer?.id,
        billingPlanId: formData.subscription?.value as string,
        name: formData.name,
        subscriptionExpirationDate: new Date(
          formData.subscriptionExpirationDate as string,
        ).toISOString(),
        features:
          featureData && featureData?.length > 0
            ? [
                {
                  featureId: formData.features?.value as string,
                  status: featureData?.filter?.(
                    (feature) => feature.id === formData.features?.value,
                  )?.[0]?.status,
                },
              ]
            : [],
        status: formData?.status?.value,
      }

      updateCustomerMutation.mutate({
        msalContext,
        redirectPageURL: location.pathname,
        payload: updateCustomerParams,
      })
    }
  }

  return (
    <Transition
      show={isOpened}
      enter='transition duration-100 ease-out'
      enterFrom='transform scale-95 opacity-0'
      enterTo='transform scale-100 opacity-100'
      leave='transition duration-75 ease-out'
      leaveFrom='transform scale-100 opacity-100'
      leaveTo='transform scale-95 opacity-0'
      as={Fragment}
    >
      <Dialog open={isOpened} onClose={close} className='relative z-50 rounded-2xl'>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-black/30' />
        </Transition.Child>
        <form
          className='fixed inset-0 w-screen overflow-y-auto'
          onSubmit={handleSubmit(formSubmitHandler, onError)}
          onReset={onReset}
          noValidate
        >
          <div className='flex min-h-full items-center justify-center p-4'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Panel className='mx-auto w-530 rounded-2xl bg-white'>
                <div className='font-poppins px-6 pt-18 pb-10 w-full '>
                  <div className='flex flex-col '>
                    <div
                      id='add-device-form-header'
                      className='flex flex-row justify-center items-center mb-6'
                    >
                      <div className='w-full font-bold text-base leading-5'>
                        {type === 'edit' ? 'Edit' : 'Add'} Customer
                      </div>
                      <CloseIcon
                        toggleClick={() => close()}
                        className='bg-c-dark-blue-1 rounded-full w-10 h-10 p-2.5 hover:bg-c-dark-blue-2  active:bg-c-dark-blue-3'
                      />
                    </div>
                    <div className='grid grid-cols-2 gap-2'>
                      {type === 'add' && (
                        <p className='col-span-full l font-bold text-sm leading-5'>
                          Basic Customer Details
                        </p>
                      )}
                      <div className=''>
                        <label
                          htmlFor='Name'
                          className='pl-[14px] text-xs font-bold leading-5 text-c-dark-blue-1 '
                        >
                          Name
                        </label>
                        <Controller
                          name='name'
                          control={control}
                          rules={{
                            required: 'Name is required',
                          }}
                          render={({ field }) => (
                            <TextInputControl
                              className='w-full h-[42px]'
                              id='name'
                              elementProps={{
                                value: field.value,
                                defaultValue: field.value,
                                placeholder: 'Enter Name',
                                onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                                  field.onChange(event.target.value)
                                },
                                onBlur: () => {
                                  field.onBlur()
                                },
                              }}
                            />
                          )}
                        />
                        <p className='text-xs text-red-500'>{errors.name?.message}</p>
                      </div>

                      {type === 'add' && (
                        <div className='col-span-1'>
                          <label
                            htmlFor='planDuration'
                            className='pl-[14px] text-xs font-bold leading-5 text-c-dark-blue-1 '
                          >
                            Plan Duration
                            <span className='text-4xs mx-1 text-c-light-gray-1'>( months )</span>
                          </label>
                          <Controller
                            name='planDuration'
                            control={control}
                            rules={{
                              required: 'Plan Duration is required',
                            }}
                            render={({ field }) => (
                              <TextInputControl
                                className='w-full h-[42px]'
                                id='planDuration'
                                elementProps={{
                                  type: 'number',
                                  value: field.value,
                                  defaultValue: field.value,
                                  placeholder: 'Enter Plan Duration',
                                  onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                                    field.onChange(event.target.value)
                                  },
                                  onBlur: () => {
                                    field.onBlur()
                                  },
                                }}
                              />
                            )}
                          />
                          <p className='text-xs text-red-500'>{errors.planDuration?.message}</p>
                        </div>
                      )}

                      <div className=''>
                        <label
                          htmlFor='subscriptionPlanType'
                          className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1 '
                        >
                          Subscription Type
                        </label>
                        <Controller
                          name='subscriptionPlanType'
                          rules={{
                            required: 'Subscription Plan is required',
                          }}
                          control={control}
                          render={({ field }) => (
                            <CreatableSelectControl
                              className='w-full h-[41px]'
                              key={(field.value?.value as string) || ''}
                              selectControlProps={{
                                options: [
                                  {
                                    label: 'Demo Plan',
                                    value: 'DemoPlan',
                                  },
                                  {
                                    label: 'Live Plan',
                                    value: 'LivePlan',
                                  },
                                ],
                                isLoading: isSubscriptionLoading,
                                value: field.value,
                                defaultValue: field.value,
                                isMulti: false,
                                isSearchable: true,
                                isDropDownSelectable: false,
                                openMenuOnClick: true,
                                placeholder: 'Select Subscription Type',
                                isClearable: true,
                                // maxMenuHeight: 130,
                                onChange: (selectedOption: IselectOptionProps) => {
                                  field.onChange(selectedOption)
                                  clearErrors('subscription')
                                },
                              }}
                            />
                          )}
                        />
                        <p className='text-xs text-red-500'>
                          {errors.subscriptionPlanType?.message}
                        </p>
                      </div>
                      <div className=''>
                        <label
                          htmlFor='subscription'
                          className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1 '
                        >
                          Subscription
                        </label>
                        <Controller
                          name='subscription'
                          rules={{
                            required: 'Subscription is required',
                          }}
                          control={control}
                          render={({ field }) => (
                            <CreatableSelectControl
                              className='w-full h-[41px]'
                              key={(field.value?.value as string) || ''}
                              selectControlProps={{
                                options:
                                  subscriptionOptions && subscriptionOptions.length > 0
                                    ? subscriptionOptions?.map((subscription) => ({
                                        label: subscription.name,
                                        value: subscription.id,
                                      }))
                                    : [],
                                isLoading: isSubscriptionLoading,
                                value: field.value,
                                defaultValue: field.value,
                                isMulti: false,
                                isSearchable: true,
                                isDropDownSelectable: false,
                                openMenuOnClick: true,
                                placeholder: 'Select Subscription',
                                isClearable: true,
                                // maxMenuHeight: 130,
                                onChange: (selectedOption: IselectOptionProps) => {
                                  field.onChange(selectedOption)
                                },

                                onFocus: () => {
                                  if (!formValuesWatch?.subscriptionPlanType?.value) {
                                    setError('subscription', {
                                      message: 'Select Subscription Type First',
                                    })
                                  }
                                },
                              }}
                            />
                          )}
                        />
                        <p className='text-xs text-red-500'>{errors.subscription?.message}</p>
                      </div>

                      {type === 'edit' && (
                        <div className=''>
                          <div>
                            <label
                              htmlFor='subscriptionExpirationDate'
                              className='pl-[14px] text-xs font-bold leading-5 text-c-dark-blue-1 '
                            >
                              Expiration Date
                            </label>
                          </div>
                          <div className='h-[42px]'>
                            <Controller
                              name='subscriptionExpirationDate'
                              control={control}
                              rules={{
                                validate: {
                                  lastCalNotInFuture: (lastCalDate) =>
                                    (lastCalDate && lastCalDate >= new Date()) ||
                                    lastCalDate === null ||
                                    'Expiration date must in the future than last Expiration date',
                                },
                              }}
                              render={({ field }) => (
                                <DatePickerControl
                                  className=''
                                  datePickerProps={{
                                    minDate: field.value,
                                    selected: field.value,
                                    onChange: (date: Date | string | number) =>
                                      field.onChange(date),
                                    onBlur: () => {
                                      field.onBlur()
                                    },
                                  }}
                                />
                              )}
                            />
                          </div>
                          <p className='text-xs text-red-500'>
                            {errors.subscriptionExpirationDate?.message}
                          </p>
                        </div>
                      )}
                      <Show>
                        <Show.When isTrue={type === 'add'}>
                          <hr className='col-span-full my-2' />
                          <p className='col-span-full l font-bold text-sm leading-5'>
                            Customer Admin Details
                          </p>
                          <div className=''>
                            <label
                              htmlFor='adminFirstName'
                              className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1 '
                            >
                              Admin First Name
                            </label>
                            <Controller
                              name='adminFirstName'
                              control={control}
                              rules={{
                                required: 'Admin First Name is required',
                              }}
                              render={({ field }) => (
                                <TextInputControl
                                  className='w-full h-[42px]'
                                  id='adminFirstName'
                                  elementProps={{
                                    disabled: type === 'edit',
                                    value: field.value,
                                    defaultValue: field.value,
                                    placeholder: 'Enter Admin First Name',
                                    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                                      field.onChange(event.target.value)
                                    },
                                    onBlur: () => {
                                      field.onBlur()
                                    },
                                  }}
                                />
                              )}
                            />
                            <p className='text-xs text-red-500'>{errors.adminFirstName?.message}</p>
                          </div>
                          <div className=''>
                            <label
                              htmlFor='adminLastName'
                              className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1 '
                            >
                              Admin Last Name
                            </label>
                            <Controller
                              name='adminLastName'
                              control={control}
                              rules={{
                                required: 'Admin Last Name is required',
                              }}
                              render={({ field }) => (
                                <TextInputControl
                                  className='w-full h-[42px]'
                                  id='adminLastName'
                                  elementProps={{
                                    disabled: type === 'edit',
                                    value: field.value,
                                    defaultValue: field.value,
                                    placeholder: 'Enter Admin Last Name',
                                    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                                      field.onChange(event.target.value)
                                    },
                                    onBlur: () => {
                                      field.onBlur()
                                    },
                                  }}
                                />
                              )}
                            />
                            <p className='text-xs text-red-500'>{errors.adminLastName?.message}</p>
                          </div>
                          <div className=''>
                            <label
                              htmlFor='adminEmail'
                              className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1 '
                            >
                              Admin Email
                            </label>
                            <Controller
                              name='adminEmail'
                              control={control}
                              rules={{
                                required: 'Admin Email is required',
                              }}
                              render={({ field }) => (
                                <TextInputControl
                                  className='w-full h-[42px]'
                                  id='adminEmail'
                                  elementProps={{
                                    disabled: type === 'edit',
                                    value: field.value,
                                    defaultValue: field.value,
                                    placeholder: 'Enter Email Name',
                                    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                                      field.onChange(event.target.value)
                                      debouncedOnEmail(event.target.value)
                                    },
                                    onBlur: () => {
                                      field.onBlur()
                                    },
                                  }}
                                />
                              )}
                            />
                            <p className='text-xs text-red-500'>{errors.adminEmail?.message}</p>
                          </div>
                        </Show.When>
                      </Show>
                      <div className=''>
                        <label
                          htmlFor='features'
                          className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1 '
                        >
                          Features
                        </label>
                        <Controller
                          name='features'
                          control={control}
                          render={({ field }) => (
                            <CreatableSelectControl
                              key={(field.value?.value as string) || ''}
                              className='w-full h-[41px]'
                              selectControlProps={{
                                options:
                                  featureData && featureData.length > 0
                                    ? featureData?.map((feature) => ({
                                        label: feature.name,
                                        value: feature.id,
                                      }))
                                    : [],
                                isLoading: isFeatureLoading,
                                value: field.value,
                                defaultValue: field.value,
                                isMulti: false,
                                isSearchable: true,
                                isDropDownSelectable: false,
                                openMenuOnClick: true,
                                placeholder: 'Select Features',
                                isClearable: true,
                                // maxMenuHeight: 130,
                                onChange: (selectedOption: IselectOptionProps) => {
                                  field.onChange(selectedOption)
                                },
                              }}
                            />
                          )}
                        />
                        <p className='text-xs text-red-500'>{errors.features?.message}</p>
                      </div>
                      {type === 'edit' &&
                        (formValues?.status?.value === CustomerStatusEnums.Active ||
                          formValues?.status?.value === CustomerStatusEnums.Inactive) && (
                          <div className=''>
                            <label
                              htmlFor='status'
                              className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1 '
                            >
                              Status
                            </label>
                            <Controller
                              name='status'
                              control={control}
                              render={({ field }) => (
                                <SelectControl
                                  id='editDeviceFormDeviceStatus'
                                  className='h-11 rounded-3xl'
                                  selectControlProps={{
                                    options: [
                                      {
                                        label: CustomerStatusConstants[CustomerStatusEnums.Active],
                                        value: CustomerStatusEnums.Active,
                                      },
                                      {
                                        label:
                                          CustomerStatusConstants[CustomerStatusEnums.Inactive],
                                        value: CustomerStatusEnums.Inactive,
                                      },
                                    ],
                                    defaultValue: field.value,
                                    isMulti: false,
                                    isSearchable: true,
                                    isDropDownSelectable: true,
                                    openMenuOnClick: true,
                                    placeholder: 'Select an option',
                                    isClearable: false,
                                    onChange: field.onChange,
                                  }}
                                />
                              )}
                            />

                            <p className='text-xs text-red-500'>{errors.status?.message}</p>
                          </div>
                        )}
                      <div className='col-span-2'>
                        <button
                          type='submit'
                          disabled={!isValid || !isDirty || isSubmitting || isSubmitted}
                          className='font-extrabold  active:bg-c-dark-blue-3  hover:bg-c-dark-blue-2 disabled:bg-c-light-blue-3 disabled:text-c-dark-blue-1 text-base mt-2 bg-c-dark-blue-1 text-white h-12 w-full rounded-3xl'
                        >
                          {type === 'edit' ? 'Update' : 'Create'} Customer
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </form>
      </Dialog>
    </Transition>
  )
}

CustomersCrudForm.defaultProps = {
  data: undefined,
  type: 'add',
}
