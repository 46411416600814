import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { PortableTypes } from 'utils/CommonEnums'
import deviceBlur from 'assets/images/deviceBlur.png'
import gasman from 'assets/images/crowcon-small-gasman.webp'
import gasPro from 'assets/images/crowcon-small-gaspro.webp'
import t3 from 'assets/images/crowcon-small-t3.webp'
import t4 from 'assets/images/crowcon-small-t4.webp'

interface DeviceImageProps {
  deviceType: PortableTypes
}

const deviceImageMap = {
  [PortableTypes.Gasman]: gasman,
  [PortableTypes.GasPro]: gasPro,
  [PortableTypes.T3]: t3,
  [PortableTypes.T4]: t4,
}

function SmallDeviceImage(props: DeviceImageProps): JSX.Element {
  const { deviceType } = props
  return (
    <img
      className='w-5'
      // decoding='async'
      // loading='lazy'
      src={deviceImageMap[deviceType]}
      alt='Device'
      // height='25px'
      // width='100%'
    />
  )
}

export default SmallDeviceImage
