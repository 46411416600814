import {
  GasType,
  PortableTypes,
  ComplianceType,
  DeviceUserAssignedFilterValues,
  DeviceSyncFilterValues,
} from 'utils/CommonEnums'
import { IselectOptionProps } from 'forms/FormModelInterface'
import { iDevice } from 'data/DeviceListHook'
import { DevicePageColumnIds } from 'pages/devices-2/view/DevicesPageUtils'
import { GetProductNameText } from 'utils/CommonUtils'
import { iDeviceListDataWithStats } from 'pages/devices-2/data/DevicePageData'

import {
  iCombinedDeviceData,
  iGasTypeCount,
  iDeviceTypeCount,
  iComplianceCount,
} from './iDevicesList'

export const groupDeviceDataByGasType = (deviceData: iDeviceListDataWithStats[]) => {
  const gasTypeCounts = new Array<iGasTypeCount>(
    { columnId: DevicePageColumnIds.gasType, gasType: GasType.O2, count: 0 },
    { columnId: DevicePageColumnIds.gasType, gasType: GasType.CO, count: 0 },
    { columnId: DevicePageColumnIds.gasType, gasType: GasType.H2S, count: 0 },
    { columnId: DevicePageColumnIds.gasType, gasType: GasType.FLAM, count: 0 },
    { columnId: DevicePageColumnIds.gasType, gasType: GasType.CO2, count: 0 },
    { columnId: DevicePageColumnIds.gasType, gasType: GasType.Other, count: 0 },
  )

  deviceData.forEach((device) => {
    if (device.channels && device.channels.length > 0) {
      device.channels.forEach((channel) => {
        if (channel.channelName === GasType.O2) {
          gasTypeCounts[0].count += 1
        } else if (channel.channelName === GasType.CO) {
          gasTypeCounts[1].count += 1
        } else if (channel.channelName === GasType.H2S) {
          gasTypeCounts[2].count += 1
        } else if (channel.channelName === GasType.FLAM) {
          gasTypeCounts[3].count += 1
        } else if (channel.channelName === GasType.CO2) {
          gasTypeCounts[4].count += 1
        } else {
          gasTypeCounts[5].count += 1
        }
      })
    } else {
      gasTypeCounts[5].count += 1
    }
  })

  return gasTypeCounts
}

export const groupDeviceDataByDeviceType = (deviceData: iDeviceListDataWithStats[] | iDevice[]) => {
  const deviceTypeCounts = new Array<iDeviceTypeCount>(
    { deviceType: GetProductNameText(PortableTypes.T4), count: 0 },
    { deviceType: GetProductNameText(PortableTypes.Gasman), count: 0 },
    { deviceType: GetProductNameText(PortableTypes.T3), count: 0 },
    { deviceType: GetProductNameText(PortableTypes.GasPro), count: 0 },
  )

  deviceData.forEach((device) => {
    // const deviceTypeText = GetProductNameText(device.deviceType)
    if (device.deviceType === GetProductNameText(PortableTypes.T4)) {
      deviceTypeCounts[0].count += 1
    } else if (device.deviceType === GetProductNameText(PortableTypes.Gasman)) {
      deviceTypeCounts[1].count += 1
    } else if (device.deviceType === GetProductNameText(PortableTypes.T3)) {
      deviceTypeCounts[2].count += 1
    } else if (device.deviceType === GetProductNameText(PortableTypes.GasPro)) {
      deviceTypeCounts[3].count += 1
    }
  })

  return deviceTypeCounts
}

export const groupDeviceDataByComplianceType = (deviceData: iDeviceListDataWithStats[]) => {
  const complianceCounts = new Array<iComplianceCount>(
    {
      complianceParam: ComplianceType.Assigned,
      complianceFilter: DeviceUserAssignedFilterValues.AllAssignedUsers,
      count: 0,
      columnId: DevicePageColumnIds.deviceUser,
    },
    {
      complianceParam: ComplianceType.UnAssigned,
      complianceFilter: DeviceUserAssignedFilterValues.NoAssignedUsers,
      count: 0,
      columnId: DevicePageColumnIds.deviceUser,
    },
    {
      complianceParam: ComplianceType.Synced,
      complianceFilter: DeviceSyncFilterValues.AllSynced,
      count: 0,
      columnId: DevicePageColumnIds.lastSync,
    },
    {
      complianceParam: ComplianceType.NotSynced,
      complianceFilter: DeviceSyncFilterValues.NotSynced,
      count: 0,
      columnId: DevicePageColumnIds.lastSync,
    },
  )

  deviceData.forEach((device) => {
    if (device.deviceUser && device.deviceUser.id && device.deviceUser.fullName !== null) {
      complianceCounts[0].count += 1
    } else {
      complianceCounts[1].count += 1
    }

    if (device.lastUploadDate) {
      complianceCounts[2].count += 1
    } else {
      complianceCounts[3].count += 1
    }
  })

  return complianceCounts
}

export const AllDeviceTypesOptionsForSelect = (): IselectOptionProps[] => {
  const portableTypes: IselectOptionProps[] = []

  Object.keys(PortableTypes).forEach((portableType) => {
    portableTypes.push({
      value: portableType.toString(),
      label: portableType.toString(),
    })
  })

  return portableTypes
}
