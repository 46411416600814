import { ControllerRenderProps } from 'react-hook-form'
import { add } from 'date-fns'
import { PortableTypes } from './CommonEnums'
import { returnDateWithoutTimeOffset } from './Constants'

export const GetProductNameText = (product: PortableTypes) => {
  switch (product) {
    case PortableTypes.Gasman:
      return 'Gasman'
    case PortableTypes.GasPro:
      return 'Gas-Pro'
    case PortableTypes.T3:
      return 'T3'
    case PortableTypes.T4:
      return 'T4'
    default:
      return ''
  }
}

/**
 * Move an array item to a different position. Returns a new array with the item moved to the new position.
 */
export function arrayMove<T>(array: T[], from: number, to: number): T[] {
  const newArray = array.slice()
  newArray.splice(to < 0 ? newArray.length + to : to, 0, newArray.splice(from, 1)[0])

  return newArray
}
