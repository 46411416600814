import React, { Fragment, useContext, useMemo } from 'react'
import { Transition, Dialog } from '@headlessui/react'
import { Controller, DeepPartial, FieldErrors, useForm } from 'react-hook-form'
import { CreatableSelectControl, SelectControl } from 'components/formComponents'
import { IselectOptionProps } from 'forms/FormModelInterface'
import TextInputControl from 'components/formComponents/TextInputControl'
import { CloseIcon } from 'assets/icons'
import { BusinessContext } from 'contexts/BusinessContext'
import { UserAccountContext } from 'contexts/UserAccountContext'
import { IsUserGlobalScope } from 'utils/UserDataUtils'
import { useCustomerMe } from 'data/CustomerListHook'
import { useLocation } from 'react-router-dom'
import {
  CrowconGlobalId,
  CrowconGlobalName,
  CrowconScope,
  CrowconScopeValue,
  CustomerScopeValue,
  UserRoleTypes,
  UserStatusTypes,
} from 'utils/CommonEnums'
import { useMsal } from '@azure/msal-react'
import { useQueryClient, useMutation } from '@tanstack/react-query'
import { debounce } from 'components/modules/Calender/CalenderUtils'
import { userExists } from 'pages/susi/data/SignupData'
import { AxiosError } from 'axios'
import { useGenericEventHandler } from 'data/GenericEventHandler'
import { UserRolesConstants, UserStatusInNumber } from 'utils/Constants'
import { CustomerStatusConstants, CustomerStatusEnums } from 'pages/customer/view/CustomerPageUtils'
import { useBusinessUnitsListWrapper } from 'data/BusinessUnitsListHookWrapper'
import Show from 'components/atom/Show'
import { addUser, updateUser, useRolesList, userListQueryKey } from '../data/UsersListData'
import { User, iUserPutParams, iUserV3Params } from '../data/iUsersList'

export type UserCrudFormProps = {
  firstName: string
  lastName: string
  email: string
  status?: IselectOptionProps
  businessUnit: IselectOptionProps | null
  customer: IselectOptionProps
  role: IselectOptionProps | null
}

function getCustomerId(operationType?: string, formCustomerId?: string, userCustomerId?: string) {
  return formCustomerId === CrowconGlobalId || userCustomerId === CrowconGlobalId
    ? ''
    : operationType !== 'edit'
    ? formCustomerId
    : formCustomerId || userCustomerId
}

export default function UserCrudForm({
  type,
  data: user,
  close,
}: {
  type?: string
  data?: User
  close: () => void
}) {
  const location = useLocation()
  const isOpened = Boolean(type)
  const { state: userInfo } = useContext(UserAccountContext)
  const isGlobalUser = IsUserGlobalScope(userInfo)
  // useQueryClient is a hook provided by react-query that returns the query client instance.
  // The query client is used to interact with and control the state of queries.
  const queryClient = useQueryClient()

  // useMsal is a hook provided by the MSAL (Microsoft Authentication Library) React library that returns the MSAL context.
  // The MSAL context contains various properties and methods related to authentication.
  const msalContext = useMsal()
  const { genericEventHandler } = useGenericEventHandler()
  const { data: myCustomerDetail } = useCustomerMe(
    location.pathname,
    isGlobalUser,
    Boolean(
      userInfo?.authority?.[0]?.businessUnitId &&
        !userInfo?.authority?.[0]?.businessUnitId?.startsWith?.('0000'),
    ),
  )
  const { customerDataFromQuery } = useContext(BusinessContext)

  const isCustomerDataLoading = customerDataFromQuery?.isLoading

  const { data: roleData, isLoading: isRoleDataLoading } = useRolesList(location.pathname, true)
  const formDefaultValue = {
    ...user,
    businessUnit: {
      value: user?.businessUnit?.id as string,
      label: user?.businessUnit?.name as string,
    },
    role: {
      value: user?.role?.[0].roleId as string,
      label: user?.role?.[0].roleName as string,
    },
    customer: {
      value: user?.customer?.id as string,
      label: user?.customer?.name as string,
    },
    status: {
      value: UserStatusInNumber[user?.status as string] as number,
      label: CustomerStatusConstants[user?.status as CustomerStatusEnums],
    },
  } as unknown as UserCrudFormProps

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    resetField,
    setError,
    watch,
    formState: { errors, isValid, isSubmitting, isDirty, isSubmitted },
  } = useForm<UserCrudFormProps>({
    defaultValues: formDefaultValue as DeepPartial<UserCrudFormProps>,
    // values: data,
    criteriaMode: 'all',
    shouldUnregister: true,
    mode: 'onTouched',
  })
  const formValuesWatch = watch()
  const onError = (formErrors: FieldErrors<UserCrudFormProps>) => {
    console.log(formErrors)
  }

  const onReset = () => {
    reset()
  }

  const customerOptions = useMemo(() => {
    if (!isGlobalUser) {
      setValue('customer', {
        value: myCustomerDetail?.id as string,
        label: myCustomerDetail?.name as string,
      })
    }

    return [
      isGlobalUser
        ? {
            value: CrowconGlobalId,
            label: CrowconGlobalName,
          }
        : {
            value: myCustomerDetail?.id as string,
            label: myCustomerDetail?.name as string,
          },
      ...(customerDataFromQuery?.data ?? [])
        .map((customer) => ({
          value: customer.id,
          label: customer.name,
        }))
        .filter((customer) => customer.label !== 'All'),
    ]
  }, [isGlobalUser, customerDataFromQuery?.data, myCustomerDetail])

  const businessUnitDataFromQuery = useBusinessUnitsListWrapper(
    getCustomerId(type, formValuesWatch?.customer?.value as string, user?.customer?.id) as string,
    location.pathname,
    isGlobalUser,
    Boolean(
      userInfo?.user?.id !== '' &&
        getCustomerId(type, formValuesWatch?.customer?.value as string, user?.customer?.id),
    ),
  )
  const isBusinessUnitDataLoading = businessUnitDataFromQuery?.isLoading
  const businessUnitOptions = businessUnitDataFromQuery?.data
    ?.filter((businessUnit) => businessUnit.id !== 'all')
    ?.map((businessUnit) => ({
      value: businessUnit.id,
      label: businessUnit.name,
      labelText: businessUnit.customerId,
    }))

  const updateUserMutation = useMutation(updateUser, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: userListQueryKey(),
      })
      close()
    },
    onError: (error: AxiosError) => {
      console.log(error)
      genericEventHandler({
        error,
        severity: 'error',
        onlyTrack: false,
        message:
          (
            (error as unknown as AxiosError).response?.data as {
              detail: string
            }
          )?.detail ||
          error.message ||
          'Error while updating user',
        extraData: {
          component: 'UserCrudFrom.tsx',
          action: 'user updating failed',
        },
      })
    },
  })
  const addUserMutation = useMutation(addUser, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: userListQueryKey(),
      })
      close()
    },
    onError: (error: AxiosError) => {
      console.log(error)
      genericEventHandler({
        error,
        severity: 'error',
        onlyTrack: false,
        message:
          (
            (error as unknown as AxiosError).response?.data as {
              detail: string
            }
          )?.detail ||
          error?.message ||
          'Error while creating user',
        extraData: {
          component: 'UserCrudFrom.tsx',
          action: 'user creation failed',
        },
      })
    },
  })
  const onSubmit = (formData: UserCrudFormProps) => {
    if (type === 'edit') {
      const payload: iUserPutParams = {
        status: formData.status?.value as number,
        userRoleId: user?.role?.[0]?.userRoleId as string,
        userId: user?.id as string,
        businessUnitId: formData.businessUnit?.value as string,
        roleId: formData?.role?.value as string,
      }
      updateUserMutation.mutate({
        redirectPageURL: location.pathname,
        msalContext,
        payload,
      })
    } else {
      const payload: iUserV3Params = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        emailId: formData.email,
        mobileNumber: '',
        locale: CrowconGlobalId,
        customerId: (formData.customer.value === CrowconGlobalId
          ? CrowconGlobalId
          : formData.customer.value) as string,
        UserScope:
          formData.customer.value === CrowconGlobalId ? CrowconScopeValue : CustomerScopeValue,
        role: {
          roleId: formData?.role?.value as string,
          businessUnitId: (formData.customer.value === CrowconGlobalId
            ? CrowconGlobalId
            : formData.businessUnit?.value) as string,
        },
        PortalURL: process.env.REACT_APP_SITE_BASE_URL as string,
      }
      addUserMutation.mutate({
        redirectPageURL: location.pathname,
        msalContext,
        payload,
      })
    }
  }

  async function checkWeatherUserExists(email: string) {
    if (email) {
      try {
        const userStatus = await userExists(email as string)
        if (userStatus.isUserActive) {
          setError('email', {
            message: 'User already exists',
          })
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  const debouncedOnEmail = debounce(checkWeatherUserExists, 100)

  return (
    <Transition
      show={isOpened}
      enter='transition duration-100 ease-out'
      enterFrom='transform scale-95 opacity-0'
      enterTo='transform scale-100 opacity-100'
      leave='transition duration-75 ease-out'
      leaveFrom='transform scale-100 opacity-100'
      leaveTo='transform scale-95 opacity-0'
      as={Fragment}
    >
      <Dialog open={isOpened} onClose={close} className='relative z-50 rounded-2xl'>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-black/30' />
        </Transition.Child>
        <form
          className='fixed inset-0 w-screen overflow-y-auto'
          onSubmit={handleSubmit(onSubmit, onError)}
          onReset={onReset}
          noValidate
        >
          <div className='flex min-h-full items-center justify-center p-4'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Panel className='mx-auto w-530 rounded-2xl bg-white'>
                <div className='font-poppins px-6 pt-18 pb-10 w-full '>
                  <div className='flex flex-col '>
                    <div
                      id='add-device-form-header'
                      className='flex flex-row justify-center items-center mb-6'
                    >
                      <div className='w-full font-bold text-base leading-5'>
                        {type === 'edit' ? 'Update' : 'Add'} User
                      </div>
                      <CloseIcon
                        toggleClick={() => close()}
                        className='bg-c-dark-blue-1 rounded-full w-10 h-10  p-2.5 hover:bg-c-dark-blue-2  active:bg-c-dark-blue-3'
                      />
                    </div>
                    <div className='grid grid-cols-2 gap-2'>
                      <div className=''>
                        <label
                          htmlFor='firstName'
                          className='pl-[14px] text-xs font-bold leading-5 text-c-dark-blue-1 '
                        >
                          First Name
                        </label>
                        <Controller
                          name='firstName'
                          control={control}
                          rules={{
                            required: 'First name is required',
                          }}
                          render={({ field }) => (
                            <TextInputControl
                              className='w-full h-[42px]'
                              id='firstName'
                              elementProps={{
                                disabled: type === 'edit',
                                value: field.value,
                                defaultValue: field.value,
                                placeholder: 'Enter first name',
                                onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                                  field.onChange(event.target.value)
                                },
                                onBlur: () => {
                                  field.onBlur()
                                },
                              }}
                            />
                          )}
                        />
                        <p className='text-xs text-red-500'>{errors.firstName?.message}</p>
                      </div>
                      <div className=''>
                        <label
                          htmlFor='lastName'
                          className='pl-[14px] text-xs font-bold leading-5 text-c-dark-blue-1 '
                        >
                          Last Name
                        </label>
                        <Controller
                          name='lastName'
                          control={control}
                          rules={{
                            required: 'Last name is required',
                          }}
                          render={({ field }) => (
                            <TextInputControl
                              className='w-full h-[42px]'
                              id='lastName'
                              elementProps={{
                                disabled: type === 'edit',
                                value: field.value,
                                defaultValue: field.value,
                                placeholder: 'Enter last name',
                                onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                                  field.onChange(event.target.value)
                                },
                                onBlur: () => {
                                  field.onBlur()
                                },
                              }}
                            />
                          )}
                        />
                        <p className='text-xs text-red-500'>{errors.lastName?.message}</p>
                      </div>
                      <div className='col-span-2'>
                        <label
                          htmlFor='email'
                          className='pl-[14px] text-xs font-bold leading-5 text-c-dark-blue-1 '
                        >
                          Email
                        </label>
                        <Controller
                          name='email'
                          control={control}
                          rules={{
                            required: 'Email is required',
                          }}
                          render={({ field }) => (
                            <TextInputControl
                              className='w-full h-[42px]'
                              id='email'
                              elementProps={{
                                disabled: type === 'edit',
                                value: field.value,
                                defaultValue: field.value,
                                placeholder: 'Enter email',
                                onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                                  field.onChange(event.target.value)
                                  debouncedOnEmail(event.target.value)
                                },
                                onBlur: () => {
                                  field.onBlur()
                                },
                              }}
                            />
                          )}
                        />
                        <p className='text-xs text-red-500'>{errors.email?.message}</p>
                      </div>

                      <Show>
                        <Show.When isTrue={Boolean(type !== 'edit' && isGlobalUser)}>
                          <div className=''>
                            <label
                              htmlFor='customer'
                              className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1 '
                            >
                              Customer
                            </label>
                            <Controller
                              name='customer'
                              control={control}
                              rules={{
                                required: 'Customer Unit is required',
                              }}
                              render={({ field }) => (
                                <CreatableSelectControl
                                  className='w-full h-[41px]'
                                  key={(field.value?.value as string) || ''}
                                  selectControlProps={{
                                    options:
                                      customerOptions && customerOptions.length > 0
                                        ? customerOptions
                                        : [],
                                    defaultValue: field.value,
                                    isMulti: false,
                                    isLoading: isGlobalUser ? isCustomerDataLoading : false,
                                    isSearchable: true,
                                    isDropDownSelectable: false,
                                    openMenuOnClick: true,
                                    placeholder: 'Select customer unit',
                                    isClearable: true,
                                    // maxMenuHeight: 130,
                                    onChange: (selectedOption: IselectOptionProps) => {
                                      field.onChange(selectedOption)
                                      setValue('businessUnit', null)
                                      resetField('businessUnit', {
                                        defaultValue: null,
                                        keepDirty: false,
                                        keepTouched: false,
                                        keepError: false,
                                      })
                                      setValue('role', null)
                                      resetField('role', {
                                        defaultValue: null,
                                        keepDirty: false,
                                        keepTouched: false,
                                        keepError: false,
                                      })
                                    },
                                  }}
                                />
                              )}
                            />
                            <p className='text-xs text-red-500'>{errors.customer?.message}</p>
                          </div>
                        </Show.When>
                      </Show>

                      <Show>
                        <Show.When
                          isTrue={
                            formValuesWatch?.customer?.value !== CrowconGlobalId &&
                            user?.customer?.id !== CrowconGlobalId
                          }
                        >
                          <div className=''>
                            <label
                              htmlFor='businessUnit'
                              className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1 '
                            >
                              Business Unit
                            </label>
                            <Controller
                              name='businessUnit'
                              control={control}
                              rules={{
                                required: 'Business Unit is required',
                              }}
                              render={({ field }) => (
                                <CreatableSelectControl
                                  className='w-full h-[41px]'
                                  selectControlProps={{
                                    options:
                                      businessUnitOptions && businessUnitOptions.length > 0
                                        ? businessUnitOptions
                                        : [],
                                    value: field.value,
                                    isLoading: isBusinessUnitDataLoading,
                                    defaultValue: field.value,
                                    isMulti: false,
                                    key: formValuesWatch.customer?.value as string,
                                    isSearchable: true,
                                    isDropDownSelectable: false,
                                    openMenuOnClick: true,
                                    placeholder: 'Select business unit',
                                    isClearable: true,
                                    // maxMenuHeight: 130,
                                    onChange: (selectedOption: IselectOptionProps) => {
                                      field.onChange(selectedOption)
                                      setValue('role', null)
                                      resetField('role', {
                                        defaultValue: null,
                                        keepDirty: false,
                                        keepTouched: false,
                                        keepError: false,
                                      })
                                    },
                                  }}
                                />
                              )}
                            />
                            <p className='text-xs text-red-500'>{errors.businessUnit?.message}</p>
                          </div>
                        </Show.When>
                      </Show>

                      <div className=''>
                        <label
                          htmlFor='role'
                          className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1 '
                        >
                          Role
                        </label>
                        <Controller
                          name='role'
                          rules={{
                            required: 'Role is required',
                          }}
                          control={control}
                          render={({ field }) => (
                            <CreatableSelectControl
                              key={(field.value?.value as string) || ''}
                              className='w-full h-[41px]'
                              selectControlProps={{
                                options:
                                  roleData && roleData.length > 0
                                    ? roleData
                                        ?.filter((role) =>
                                          formValuesWatch.customer?.value === CrowconGlobalId ||
                                          user?.customer?.id === CrowconGlobalId
                                            ? role.scope === CrowconScope
                                            : role.scope !== CrowconScope,
                                        )
                                        ?.map((role) => ({
                                          label: UserRolesConstants[role.name as UserRoleTypes],
                                          value: role.id,
                                        }))
                                    : [],
                                isLoading: isRoleDataLoading,
                                value: field.value,
                                defaultValue: field.value,
                                isMulti: false,
                                isSearchable: true,
                                isDropDownSelectable: false,
                                openMenuOnClick: true,
                                placeholder: 'Select Role',
                                isClearable: true,
                                // maxMenuHeight: 130,
                                onChange: (selectedOption: IselectOptionProps) => {
                                  field.onChange(selectedOption)
                                },
                              }}
                            />
                          )}
                        />
                        <p className='text-xs text-red-500'>{errors.role?.message}</p>
                      </div>
                      {type === 'edit' &&
                        (CustomerStatusEnums.Active === user?.status ||
                          user?.status === CustomerStatusEnums.Inactive) && (
                          <div className='flex flex-col mt-2'>
                            <label
                              htmlFor='status'
                              className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1 '
                            >
                              Status
                            </label>
                            <Controller
                              name='status'
                              control={control}
                              render={({ field }) => (
                                <SelectControl
                                  id='editDeviceFormDeviceStatus'
                                  className='h-11 rounded-3xl'
                                  selectControlProps={{
                                    options: [
                                      {
                                        value: UserStatusInNumber[UserStatusTypes.Active],
                                        label: UserStatusTypes.Active,
                                      },
                                      {
                                        value: UserStatusInNumber[UserStatusTypes.Inactive],
                                        label: UserStatusTypes.Inactive,
                                      },
                                    ],
                                    defaultValue: field.value,
                                    isMulti: false,
                                    isSearchable: true,
                                    isDropDownSelectable: true,
                                    openMenuOnClick: true,
                                    placeholder: 'Select an option',
                                    isClearable: false,
                                    onChange: field.onChange,
                                  }}
                                />
                              )}
                            />

                            <p className='text-xs text-red-500'>{errors.status?.message}</p>
                          </div>
                        )}
                      <div className='col-span-2'>
                        <button
                          type='submit'
                          disabled={!isValid || !isDirty || isSubmitting || isSubmitted}
                          className='text-base font-extrabold active:bg-c-dark-blue-3  hover:bg-c-dark-blue-2 disabled:bg-c-light-blue-3 disabled:text-c-dark-blue-1 mt-2 bg-c-dark-blue-1 text-white h-12 w-full rounded-3xl'
                        >
                          {type === 'edit' ? 'Update' : 'Create'} User
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </form>
      </Dialog>
    </Transition>
  )
}

UserCrudForm.defaultProps = {
  data: undefined,
  type: 'add',
}
