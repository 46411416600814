import { useMsal } from '@azure/msal-react'
import { InteractionStatus } from '@azure/msal-browser'
import { useQuery } from '@tanstack/react-query'
import { sub } from 'date-fns'
import { LoaderFunction, createSearchParams } from 'react-router-dom'
import {
  iDeviceLogsAPIParams,
  iDeviceLogsDataLoaderParams,
  iGetDeviceLogsParams,
  iGetOrFetchDeviceLogDataProps,
} from 'pages/deviceDetails/commonUtils/interfaces'
import ConnectAPIClient from 'services/ConnectAPIClient'

import { iDataLoaderParams } from 'data/Interfaces'
import { getAccessToken, getAccessTokenFromPCA } from 'services/MSALService'
import { getOrFetchDeviceDetails } from 'data/DeviceDetailsHook'
import { AxiosError } from 'axios'
import { iAssignmentHistory, iAssignmentHistoryAPIResponse } from './interface'

export const deviceAssignmentHistoryQueryKeyIdDates = ({
  deviceId,
  startDate,
  endDate,
}: {
  deviceId: string
  startDate?: string
  endDate?: string
}) => ['device', 'assignmentHistory', 'id', deviceId, 'startDate', startDate, 'endDate', endDate]

async function fetchDeviceAssignmentHistory(
  deviceAssignmentHistoryParams: iDeviceLogsAPIParams,
): Promise<iAssignmentHistory[]> {
  try {
    const { deviceId, startDate, endDate, token } = deviceAssignmentHistoryParams
    // FROM and TO are optional here.
    // Without FROM and TO there is NO FILTER in the API
    // So if you call this API without FROM and TO, it will search ENTIRE Assignment table

    // const FromDate = new Date(startDate).toISOString().split('T')[0]
    // const ToDate = new Date(endDate).toISOString().split('T')[0]

    const urlSearchParams = new URLSearchParams()

    if (startDate) {
      urlSearchParams.set('FromDate', startDate)
    }
    if (endDate) {
      urlSearchParams.set('ToDate', endDate)
    }

    const url = `portables/api/device/assignment/device/${deviceId}?${urlSearchParams.toString()}`

    // let url = `portables/api/device/assignment/device/${deviceId}`

    // if (FromDate && ToDate) {
    //   url += `?FromDate=${FromDate}&ToDate=${ToDate}`
    // }

    const resp = await ConnectAPIClient.get<iAssignmentHistoryAPIResponse>(url, token)

    const response = resp.data as iAssignmentHistoryAPIResponse

    const { deviceUser } = response

    return deviceUser
  } catch (error) {
    console.error(error)
    throw error
  }
}

async function getOrFetchAssignmentHistoryData(props: iGetOrFetchDeviceLogDataProps) {
  const { queryClient, token, startDate, endDate, deviceId } = props

  const assginmentHistoryCached = queryClient.getQueryData<iAssignmentHistory>(
    deviceAssignmentHistoryQueryKeyIdDates({ deviceId, startDate, endDate }),
  )

  if (assginmentHistoryCached) return assginmentHistoryCached

  const assginmentHistoryFetched = await queryClient.fetchQuery(
    deviceAssignmentHistoryQueryKeyIdDates({ deviceId, startDate, endDate }),
    async () =>
      fetchDeviceAssignmentHistory({
        startDate,
        endDate,
        token,
        deviceId,
      }),
  )

  return assginmentHistoryFetched
}

export async function getAssignmentHistoryData(eventLogsDataParams: iGetDeviceLogsParams) {
  const { startDate, endDate, msalContext, redirectPageURL, deviceId } = eventLogsDataParams
  const token = await getAccessToken({ msalContext, redirectPageURL })
  const assignmentHistoryData = await fetchDeviceAssignmentHistory({
    startDate,
    endDate,
    token,
    deviceId,
  })
  return assignmentHistoryData
}

export const DeviceAssignmentHistoryLoader =
  (props: iDataLoaderParams): LoaderFunction =>
  async ({ request, params }) => {
    const { queryClient, msalInstance, msalAccounts, msalInProgress } = props
    if (!msalInstance) throw new Error('No MSAL Context')
    if (!queryClient) throw new Error('No query client')

    try {
      if (msalInProgress !== InteractionStatus.None) {
        return {}
      }
      if (msalAccounts.length === 0) {
        return {}
      }
      if (!params?.deviceSerialNumber) throw new Error('No device serial number')
      const token = await getAccessTokenFromPCA({ msalInstance })

      const searchParams = createSearchParams(new URLSearchParams(request.url.split('?')[1]))

      const endDate = searchParams.get('to') ?? new Date().toISOString().split('T')[0]
      const startDate =
        searchParams.get('from') ??
        sub(new Date(endDate), { months: 6 }).toISOString().split('T')[0]

      const deviceDetails = await getOrFetchDeviceDetails({
        queryClient,
        token,
        deviceSerialNumber: params?.deviceSerialNumber,
      })

      const deviceId = deviceDetails?.id ?? ''

      const logsData = getOrFetchAssignmentHistoryData({
        queryClient,
        token,
        startDate,
        endDate,
        deviceId,
      })

      return logsData
    } catch (error) {
      console.error(error)
      throw error
      // return []
    }
  }

export function useDeviceAssignmentHistoryData(
  deviceId: string,
  redirectPageURL: string,
  startDate?: string,
  endDate?: string,
  enabled?: boolean,
) {
  const msalContext = useMsal()

  return useQuery(
    deviceAssignmentHistoryQueryKeyIdDates({ deviceId, startDate, endDate }),
    () =>
      getAssignmentHistoryData({
        msalContext,
        redirectPageURL,
        deviceId,
        startDate,
        endDate,
      }),
    {
      enabled,
      onError: (error: AxiosError) => {
        console.log(error)
      },
    },
  )
}
