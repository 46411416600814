import React, { useEffect } from 'react'
import { useForm, SubmitHandler, Controller, FieldErrors } from 'react-hook-form'
import { DateRange, IFilterValues, IselectOptionProps } from 'forms/FormModelInterface'
// eslint-disable-next-line import/no-extraneous-dependencies
import { DevTool } from '@hookform/devtools'
/* eslint-disable import/no-duplicates */
import { isAfter, isBefore, sub } from 'date-fns'

import { DateRangeSelectorTypes, SelectOptionTypes } from 'utils/CommonEnums'
import { ChevronRightIcon, CloseIcon } from 'assets/icons'
import { DatePickerControl, SelectControl, CreatableSelectControl } from 'components/formComponents'
import FilterFormTitle from 'components/formComponents/FilterFormTitle'
import TextInputControl from 'components/formComponents/TextInputControl'
import { dateRangeOptions, selectAllOption, selectBlankOption } from 'forms/FormUtils'

import { withErrorBoundary } from 'react-error-boundary'
import ErrorPage from 'pages/common/ErrorPage'
import { DeviceGasLogsFilterFormType, columnIDs } from './DeviceGasLogs'

interface iDeviceGaslogFilterFormProps {
  // Define any props that this component will receive
  selectedValues?: DeviceGasLogsFilterFormType
  channelNumbers?: IselectOptionProps[]
  gasNames?: IselectOptionProps[]
  gasValues?: IselectOptionProps[]
  gasUnits?: IselectOptionProps[]

  // Define any functions that this component will call
  onFilterSubmit: (values: IFilterValues[]) => void
  onFilterReset: () => void
  onFilterFormClose: () => void
}

const defaultFilterValues = () => {
  const dtTo = new Date()
  const dtFrom = sub(new Date(), { months: 6 })
  const defValues: DeviceGasLogsFilterFormType = {
    channelNumber: selectAllOption,
    gasName: selectAllOption,
    gasUnit: selectAllOption,
    gasValue: '-',
    dateRangeSelector: dateRangeOptions.find(
      (option) => option.value === DateRangeSelectorTypes.Custom,
    ) ?? {
      label: '',
      value: '',
    },
    dateRange: {
      startDate: dtFrom,
      endDate: dtTo,
    },
  }
  return defValues
}

function DeviceGasLogsFilterForm(props: iDeviceGaslogFilterFormProps): JSX.Element {
  const {
    selectedValues = defaultFilterValues(),
    channelNumbers = [],
    gasNames = [],
    gasValues = [],
    gasUnits = [],
    onFilterSubmit,
    onFilterReset,
    onFilterFormClose,
  } = props

  const defaultFormValues = defaultFilterValues()

  const { register, control, handleSubmit, setValue, reset, formState, watch } =
    useForm<DeviceGasLogsFilterFormType>({
      defaultValues: selectedValues,
      values: selectedValues,
    })

  const watchDateRangeSelector = watch('dateRangeSelector')

  const [showCustomDate, setShowCustomDate] = React.useState<boolean>(false)

  useEffect(() => {
    if (watchDateRangeSelector?.value === DateRangeSelectorTypes.Custom) {
      setShowCustomDate(true)
    } else {
      setShowCustomDate(false)
    }
  }, [watchDateRangeSelector])

  const { errors, isDirty, isValid, isSubmitting } = formState

  const onSubmit: SubmitHandler<DeviceGasLogsFilterFormType> = (data) => {
    const filterValues: IFilterValues[] = []

    filterValues.push({
      columnId: columnIDs.channelNumber,
      value:
        data.channelNumber?.label?.toString().toLowerCase() ===
        selectAllOption.label.toString().toLowerCase()
          ? ''
          : data.channelNumber?.value?.toString().trim() ?? '',
    })

    filterValues.push({
      columnId: columnIDs.gasName,
      value:
        data.gasName?.label?.toString().toLowerCase() ===
        selectAllOption.label.toString().toLowerCase()
          ? ''
          : data.gasName?.value?.toString().trim() ?? '',
    })

    filterValues.push({
      columnId: columnIDs.gasUnit,
      value: data.gasUnit?.value?.toString().trim() ?? '',
    })

    filterValues.push({
      columnId: columnIDs.gasValue,
      value: data.gasValue?.toString().trim() ?? '',
    })

    filterValues.push({
      columnId: columnIDs.timeStamp,
      value: data.dateRange,
    })

    onFilterSubmit(filterValues)
  }

  const onError = (formErrors: FieldErrors<DeviceGasLogsFilterFormType>) => {
    console.log(formErrors)
  }

  const onReset = () => {
    reset(defaultFormValues)
    onFilterReset()
  }

  return (
    <div className='filter-form-container'>
      <div className='filter-form-header'>
        <FilterFormTitle />

        <div
          title='Close Filter Form'
          role='button'
          tabIndex={0}
          onClick={() => onFilterFormClose()}
          onKeyDown={() => onFilterFormClose()}
          className='filter-form-close-button '
        >
          <CloseIcon toggleClick={() => onFilterFormClose()} className='w-5 h-5 ' />
        </div>
      </div>

      <form onSubmit={handleSubmit(onSubmit, onError)} onReset={onReset} noValidate className=''>
        <div className='filter-form-fields-container'>
          {/* // Date time */}
          <div className='flex flex-col gap-0.5 items-start self-stretch'>
            <label
              htmlFor='dateRangeSelector'
              className='pl-4 pr-3 text-xs font-bold leading-5 text-c-dark-blue-1 '
            >
              Date
            </label>

            <Controller
              name='dateRangeSelector'
              control={control}
              render={({ field }) => (
                <SelectControl
                  className='w-full h-[41px]'
                  selectControlProps={{
                    options: dateRangeOptions,
                    defaultValue: field.value,
                    isMulti: false,

                    onChange: (selectedOption: IselectOptionProps) => {
                      const selectedValue = selectedOption?.value
                      field.onChange(selectedOption)
                      if (selectedValue !== DateRangeSelectorTypes.Custom) {
                        setValue('dateRange', selectedValue as DateRange, {
                          shouldValidate: true,
                        })
                      } else {
                        setValue('dateRange', {
                          startDate: selectedValues?.dateRange.startDate || new Date(),
                          endDate: selectedValues?.dateRange.endDate || new Date(),
                        })
                      }
                    },
                  }}
                />
              )}
            />
          </div>

          {showCustomDate && (
            <>
              <div className='flex flex-row justify-between items-end'>
                <div className='w-[110px]'>
                  <div className='flex flex-col gap-0.5 items-start '>
                    <label
                      htmlFor='dateRangeFrom'
                      className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1'
                    >
                      From
                    </label>
                    <div className='self-stretch'>
                      <Controller
                        name='dateRange.startDate'
                        control={control}
                        render={({ field }) => (
                          <DatePickerControl
                            className=''
                            datePickerProps={{
                              selected: field.value,
                              onChange: (date: Date | null) => field.onChange(date as Date),
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>

                <div className='mx-0.5 w-4 h-4 my-3'>
                  <ChevronRightIcon className='self-center' />
                </div>

                <div className='w-[110px]'>
                  <div className='flex flex-col gap-0.5 items-start'>
                    <label
                      htmlFor='dateRangeFilterTo'
                      className='pl-[13px] text-xs font-bold leading-5 text-c-dark-blue-1'
                    >
                      To
                    </label>
                    <div className='self-stretch'>
                      <Controller
                        name='dateRange.endDate'
                        control={control}
                        render={({ field }) => (
                          <DatePickerControl
                            className=''
                            datePickerProps={{
                              selected: field.value,
                              maxDate: new Date(),
                              onChange: (date: Date | null) => field.onChange(date as Date),
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {errors.dateRange && (
                <>
                  <p className='text-xs text-red-500'>{errors.dateRange?.startDate?.message}</p>
                  <p className='text-xs text-red-500'>{errors.dateRange?.endDate?.message}</p>
                </>
              )}
            </>
          )}

          {/* // Channel Number */}
          <div className='flex flex-col gap-0.5 items-start self-stretch'>
            <label
              htmlFor='channelNumberFilter'
              className='pl-4 px-3 text-xs font-bold leading-5 text-c-dark-blue-1 '
            >
              Channel Number
            </label>
            <Controller
              name='channelNumber'
              control={control}
              render={({ field }) => (
                <CreatableSelectControl
                  className='w-full h-[41px]'
                  selectControlProps={{
                    options: channelNumbers,
                    value: field.value,
                    defaultValue: field.value,
                    isMulti: false,
                    isSearchable: true,
                    openMenuOnClick: true,
                    placeholder: 'Search channel number',
                    isClearable: false,
                    onChange: (selectedOption: IselectOptionProps) => {
                      field.onChange(selectedOption)
                    },
                  }}
                />
              )}
            />
            <p className='text-xs text-red-500'>{errors.channelNumber?.message}</p>
          </div>

          {/* // Gas Name */}
          <div className='flex flex-col gap-0.5 items-start self-stretch'>
            <label
              htmlFor='gasNameFilter'
              className='pl-4 px-3 text-xs font-bold leading-5 text-c-dark-blue-1 '
            >
              Gas Name
            </label>
            <Controller
              name='gasName'
              control={control}
              render={({ field }) => (
                <CreatableSelectControl
                  className='w-full h-[41px]'
                  selectControlProps={{
                    options: gasNames,
                    value: field.value,
                    defaultValue: field.value,
                    isMulti: false,
                    isSearchable: true,
                    openMenuOnClick: true,
                    placeholder: 'Search gas name',
                    isClearable: false,
                    onChange: (selectedOption: IselectOptionProps) => {
                      field.onChange(selectedOption)
                    },
                  }}
                />
              )}
            />
            <p className='text-xs text-red-500'>{errors.gasName?.message}</p>
          </div>

          {/* // Gas Value */}
          {/* <div className='flex flex-col gap-0.5 items-start self-stretch'>
              <label
                htmlFor='gasValueFilter'
                className='pl-4 px-3 text-xs font-bold leading-5 text-c-dark-blue-1 '
              >
                Gas Value
              </label>
              <Controller
                name='gasValue'
                control={control}
                render={({ field }) => (
                  <TextInputControl
                    className='w-full'
                    elementProps={{
                      options: gasValues,
                      value: field.value,
                      defaultValue: field.value,
                      placeholder: '-',
                      onChange: (selectedOption: string) => {
                        field.onChange(selectedOption)
                      },
                    }}
                  />
                )}
              />
              <p className='text-xs text-red-500'>{errors.gasName?.message}</p>
            </div> */}

          {/* // Gas Unit */}
          <div className='flex flex-col gap-0.5 items-start self-stretch'>
            <label
              htmlFor='gasUnitFilter'
              className='pl-4 px-3 text-xs font-bold leading-5 text-c-dark-blue-1 '
            >
              Gas Unit
            </label>
            <Controller
              name='gasUnit'
              control={control}
              render={({ field }) => (
                <CreatableSelectControl
                  className='w-full h-[41px]'
                  selectControlProps={{
                    options: gasUnits,
                    value: field.value,
                    defaultValue: field.value,
                    isMulti: false,
                    isSearchable: true,
                    openMenuOnClick: true,
                    placeholder: 'Search gas unit',
                    isClearable: false,
                    onChange: (selectedOption: IselectOptionProps) => {
                      field.onChange(selectedOption)
                    },
                  }}
                />
              )}
            />
            <p className='text-xs text-red-500'>{errors.gasUnit?.message}</p>
          </div>
        </div>
        {/* Buttons */}
        <div className='filter-form-footer'>
          <input
            className='filter-form-reset-button'
            type='reset'
            disabled={isSubmitting}
            onClick={() => {
              onReset()
            }}
          />
          <input
            className='filter-form-apply-button'
            type='submit'
            value='Apply'
            // disabled={!isDirty || !isValid || isSubmitting}
          />
        </div>
      </form>

      <DevTool control={control} />
    </div> // opening div
  )
}

export default withErrorBoundary(DeviceGasLogsFilterForm, {
  FallbackComponent: ErrorPage,
})

// function DeviceGasLogsFilterForm(
//   props: DeviceAssignmentHistoryFilterFormProps,
// ): JSX.Element {
//   const {
//     selectedValues,
//     deviceUserNames,
//     deviceUserUniqueIds,
//     onFilterSubmit,
//     onFilterReset,
//     onFilterFormClose,
//   } = props

//   const { register, control, handleSubmit, setValue, reset, formState, watch } =
//     useForm<DeviceAssignmentHistoryFilterFormType>({
//       defaultValues: selectedValues,
//       values: selectedValues,
//     })

//   const watchAssignmentDateSelector = watch('assignedDateSelector')
//   const watchReturnDateSelector = watch('returnedDateSelector')

//   const [showCustomAssignmentDate, setShowCustomAssignmentDate] = React.useState<boolean>(false)
//   const [showCustomReturnDate, setShowCustomReturnDate] = React.useState<boolean>(false)

//   useEffect(() => {
//     if (watchAssignmentDateSelector?.value === DateRangeSelectorTypes.Custom) {
//       setShowCustomAssignmentDate(true)
//     } else {
//       setShowCustomAssignmentDate(false)
//     }
//     if (watchReturnDateSelector?.value === DateRangeSelectorTypes.Custom) {
//       setShowCustomReturnDate(true)
//     } else {
//       setShowCustomReturnDate(false)
//     }
//   }, [watchAssignmentDateSelector, watchReturnDateSelector])

//   const { errors, isDirty, isValid, isSubmitting } = formState

//   const onSubmit: SubmitHandler<DeviceAssignmentHistoryFilterFormType> = (data) => {
//     const filterValues: IFilterValues[] = []

//     filterValues.push({
//       columnId: columnIDs.deviceUserAssignedOn,
//       value: data.assignedDate,
//     })

//     filterValues.push({
//       columnId: columnIDs.deviceUserReturnedOn,
//       value: data.returnedDate,
//     })

//     filterValues.push({
//       columnId: columnIDs.deviceUserName,
//       value: data.deviceUserName?.value?.toString().trim() ?? ''
//     })

//     filterValues.push({
//       columnId: columnIDs.deviceUserUniqueID,
//       value: data.deviceUserUniqueID?.value?.toString().trim() ?? ''
//     })

//     onFilterSubmit(filterValues)
//   }

//   const onError = (formErrors: FieldErrors<DeviceAssignmentHistoryFilterFormType>) => {
//     console.log(formErrors)
//   }

//   return (
//     <>
//     Filter form
//     </>
//   )
//   // return (
//   //   <div className='pt-6 pr-9 pb-5 pl-10 bg-c-white w-80 z-50 shadow-form rounded-l-2xl h-full '>
//   //     <div className='h-11 w-full 2xl:hidden'>
//   //       <CloseIcon
//   //         toggleClick={() => onFilterFormClose()}
//   //         className='bg-c-dark-blue-1 w-10 h-10 p-2 rounded-full float-right'
//   //       />
//   //     </div>
//   //     <form
//   //       onSubmit={handleSubmit(onSubmit, onError)}
//   //       onReset={onFilterReset}
//   //       noValidate
//   //       className='h-full'
//   //     >
//   //       {/* // Assigned Date */}
//   //       <div className='flex flex-col gap-0.5 items-start self-stretch'>
//   //         <label
//   //           htmlFor='assignedDateSelector'
//   //           className='pl-4 pr-3 text-xs font-bold leading-5 text-c-dark-blue-1 '
//   //         >
//   //           Date
//   //         </label>

//   //         <Controller
//   //           name='assignedDateSelector'
//   //           control={control}
//   //           render={({ field }) => (
//   //             <SelectControl
//   //               className='w-full'
//   //               selectControlProps={{
//   //                 options: dateRangeOptions,
//   //                 defaultValue: field.value,
//   //                 isMulti: false,

//   //                 onChange: (selectedOption: IselectOptionProps) => {
//   //                   const selectedValue = selectedOption?.value
//   //                   field.onChange(selectedOption)
//   //                   if (selectedValue !== DateRangeSelectorTypes.Custom) {
//   //                     setValue('assignedDate', selectedValue as DateRange, { shouldValidate: true })
//   //                   } else {
//   //                     setValue('assignedDate', {
//   //                       startDate: selectedValues?.assignedDate.startDate || new Date(),
//   //                       endDate: selectedValues?.assignedDate.endDate || new Date(),
//   //                     })
//   //                   }
//   //                 },
//   //               }}
//   //             />
//   //           )}
//   //         />
//   //       </div>

//   //       {showCustomAssignmentDate && (
//   //         <>
//   //           <div className='flex flex-row self-stretch justify-between w-full items-end'>
//   //             <div className='flex-grow '>
//   //               <div className='flex flex-col gap-0.5 items-start '>
//   //                 <label
//   //                   htmlFor='assignedDateFrom'
//   //                   className='pl-4  text-xs font-bold leading-5 text-c-dark-blue-1 '
//   //                 >
//   //                   From
//   //                 </label>
//   //                 <div className='self-stretch'>
//   //                   <Controller
//   //                     name='assignedDate.startDate'
//   //                     control={control}
//   //                     render={({ field }) => (
//   //                       <DatePickerControl
//   //                         className='h-10 text-xs'
//   //                         datePickerProps={{
//   //                           selected: field.value,
//   //                           onChange: (date: Date | null) => field.onChange(date as Date),
//   //                         }}
//   //                       />
//   //                     )}
//   //                   />
//   //                 </div>
//   //               </div>
//   //             </div>

//   //             <div className='px-0.5 pb-3 '>
//   //               <ChevronRightIcon className='self-center' />
//   //             </div>

//   //             <div className='flex-grow '>
//   //               <div className='flex flex-col gap-0.5 items-start'>
//   //                 <label
//   //                   htmlFor='assignedDateFilterTo'
//   //                   className='pl-4 text-xs font-bold leading-5 text-c-dark-blue-1 '
//   //                 >
//   //                   To
//   //                 </label>
//   //                 <div className='self-stretch'>
//   //                   <Controller
//   //                     name='assignedDate.endDate'
//   //                     control={control}
//   //                     render={({ field }) => (
//   //                       <DatePickerControl
//   //                         className='h-10 text-xs'
//   //                         datePickerProps={{
//   //                           selected: field.value,
//   //                           maxDate: new Date(),
//   //                           onChange: (date: Date | null) => field.onChange(date as Date),
//   //                         }}
//   //                       />
//   //                     )}
//   //                   />
//   //                 </div>
//   //               </div>
//   //             </div>
//   //           </div>

//   //           {errors.assignedDate && (
//   //             <>
//   //               <p className='text-xs text-red-500'>{errors.assignedDate?.startDate?.message}</p>
//   //               <p className='text-xs text-red-500'>{errors.assignedDate?.endDate?.message}</p>
//   //             </>
//   //           )}
//   //         </>
//   //       )}

//   //       {/* // Returned Date */}
//   //       <div className='flex flex-col gap-0.5 items-start self-stretch'>
//   //         <label
//   //           htmlFor='returnedDateSelectorFilter'
//   //           className='pl-4 pr-3 text-xs font-bold leading-5 text-c-dark-blue-1 '
//   //         >
//   //           Date
//   //         </label>

//   //         <Controller
//   //           name='returnedDateSelector'
//   //           control={control}
//   //           render={({ field }) => (
//   //             <SelectControl
//   //               className='w-full'
//   //               selectControlProps={{
//   //                 options: dateRangeOptions,
//   //                 defaultValue: field.value,
//   //                 isMulti: false,

//   //                 onChange: (selectedOption: IselectOptionProps) => {
//   //                   const selectedValue = selectedOption?.value
//   //                   field.onChange(selectedOption)
//   //                   if (selectedValue !== DateRangeSelectorTypes.Custom) {
//   //                     setValue('returnedDate', selectedValue as DateRange, { shouldValidate: true })
//   //                   } else {
//   //                     setValue('returnedDate', {
//   //                       startDate: selectedValues?.returnedDate.startDate || new Date(),
//   //                       endDate: selectedValues?.returnedDate.endDate || new Date(),
//   //                     })
//   //                   }
//   //                 },
//   //               }}
//   //             />
//   //           )}
//   //         />
//   //       </div>

//   //       {showCustomReturnDate && (
//   //         <>
//   //           <div className='flex flex-row self-stretch justify-between w-full items-end'>
//   //             <div className='flex-grow '>
//   //               <div className='flex flex-col gap-0.5 items-start '>
//   //                 <label
//   //                   htmlFor='returnedDateSelectorFrom'
//   //                   className='pl-4  text-xs font-bold leading-5 text-c-dark-blue-1 '
//   //                 >
//   //                   From
//   //                 </label>
//   //                 <div className='self-stretch'>
//   //                   <Controller
//   //                     name='returnedDate.startDate'
//   //                     control={control}
//   //                     render={({ field }) => (
//   //                       <DatePickerControl
//   //                         className='h-10 text-xs'
//   //                         datePickerProps={{
//   //                           selected: field.value,
//   //                           onChange: (date: Date | null) => field.onChange(date as Date),
//   //                         }}
//   //                       />
//   //                     )}
//   //                   />
//   //                 </div>
//   //               </div>
//   //             </div>

//   //             <div className='px-0.5 pb-3 '>
//   //               <ChevronRightIcon className='self-center' />
//   //             </div>

//   //             <div className='flex-grow '>
//   //               <div className='flex flex-col gap-0.5 items-start'>
//   //                 <label
//   //                   htmlFor='returnedDateFilterTo'
//   //                   className='pl-4 text-xs font-bold leading-5 text-c-dark-blue-1 '
//   //                 >
//   //                   To
//   //                 </label>
//   //                 <div className='self-stretch'>
//   //                   <Controller
//   //                     name='returnedDate.endDate'
//   //                     control={control}
//   //                     render={({ field }) => (
//   //                       <DatePickerControl
//   //                         className='h-10 text-xs'
//   //                         datePickerProps={{
//   //                           selected: field.value,
//   //                           maxDate: new Date(),
//   //                           onChange: (date: Date | null) => field.onChange(date as Date),
//   //                         }}
//   //                       />
//   //                     )}
//   //                   />
//   //                 </div>
//   //               </div>
//   //             </div>
//   //           </div>

//   //           {errors.returnedDate && (
//   //             <>
//   //               <p className='text-xs text-red-500'>{errors.returnedDate?.startDate?.message}</p>
//   //               <p className='text-xs text-red-500'>{errors.returnedDate?.endDate?.message}</p>
//   //             </>
//   //           )}
//   //         </>
//   //       )}

//   //       <div className='flex flex-col gap-0.5 items-start self-stretch'>
//   //         <label
//   //           htmlFor='assignedToUserNamesFilter'
//   //           className='pl-4 px-3 text-xs font-bold leading-5 text-c-dark-blue-1 '
//   //         >
//   //           Assigned To
//   //         </label>
//   //         <Controller
//   //           name='deviceUserName'
//   //           control={control}
//   //           render={({ field }) => (
//   //             <CreatableSelectControl
//   //               className='w-full'
//   //               selectControlProps={{
//   //                 options: deviceUserNames,
//   //                 value: field.value,
//   //                 defaultValue: field.value,
//   //                 isMulti: false,
//   //                 isSearchable: true,
//   //                 openMenuOnClick: false,
//   //                 placeholder: 'Search users',
//   //                 isClearable: true,
//   //                 onChange: (selectedOption: IselectOptionProps) => {
//   //                   field.onChange(selectedOption)
//   //                 },
//   //               }}
//   //             />
//   //           )}
//   //         />
//   //         <p className='text-xs text-red-500'>{errors.deviceUserName?.message}</p>
//   //       </div>

//   //       <div className='flex flex-col gap-0.5 items-start self-stretch'>
//   //         <label
//   //           htmlFor='uploadDataTypeFilter'
//   //           className='pl-4 px-3 text-xs font-bold leading-5 text-c-dark-blue-1 '
//   //         >
//   //           Assigned To
//   //         </label>
//   //         <Controller
//   //           name='deviceUserUniqueID'
//   //           control={control}
//   //           render={({ field }) => (
//   //             <CreatableSelectControl
//   //               className='w-full'
//   //               selectControlProps={{
//   //                 options: deviceUserUniqueIds,
//   //                 value: field.value,
//   //                 defaultValue: field.value,
//   //                 isMulti: false,
//   //                 isSearchable: true,
//   //                 openMenuOnClick: false,
//   //                 placeholder: 'Search unique IDs',
//   //                 isClearable: true,
//   //                 onChange: (selectedOption: IselectOptionProps) => {
//   //                   field.onChange(selectedOption)
//   //                 },
//   //               }}
//   //             />
//   //           )}
//   //         />
//   //         <p className='text-xs text-red-500'>{errors.deviceUserUniqueID?.message}</p>
//   //       </div>

//   //       {/* Buttons */}
//   //       <div className='w-full flex flex-row gap-1 pt-8'>
//   //         <input
//   //           className='text-xl font-bold text-c-dark-blue-1 m-1 p-1 rounded-3xl w-28 h-12 bg-c-light-blue-2'
//   //           type='reset'
//   //           disabled={!isDirty || isSubmitting}
//   //           onClick={() => {
//   //             reset()
//   //           }}
//   //         />
//   //         <input
//   //           className='text-xl font-bold text-white m-1 p-1 rounded-3xl w-36 h-12 bg-c-dark-blue-1 cursor-pointer'
//   //           type='submit'
//   //           value='Apply'
//   //           // disabled={!isDirty || !isValid || isSubmitting}
//   //         />
//   //       </div>
//   //     </form>

//   //     <DevTool control={control} />
//   //   </div> // opening div
//   // )
// }

// export default DeviceGasLogsFilterForm
