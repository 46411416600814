import React, { useEffect, useState } from 'react'
import { useMsal } from '@azure/msal-react'
import { InteractionStatus } from '@azure/msal-browser'

import { SignUpAuthority } from 'utils/AuthConfig'
import { TickIconWithCircle } from 'assets/icons'
import Timer from 'components/atom/Timer'
import { withPageTracking } from 'utils/AppInsightConfig'
import { UserStatusTypes } from 'utils/CommonEnums'
import { AxiosResponse } from 'axios'
import { useMutation } from '@tanstack/react-query'
import { useLocation } from 'react-router-dom'
import { activateUser, iActivateUserProps, iUserDataToActivate } from '../data/SignupData'

/**
 * Generates a unique key for the activateUser mutation based on the invite ID.
 *
 * @param inviteId - The ID of the invite.
 * @returns The unique key for the activateUser mutation.
 */
const activateUserMutationKey = (inviteId: string) => ['activateUser', inviteId]
/**
 * SignUpWelcomePageCardWrapper is a wrapper component for the SignUpWelcomePage.
 * The card that wraps the SignUpWelcomePage child component.
 */
function SignUpWelcomePageCardWrapper({ children }: { children: React.ReactNode }): JSX.Element {
  return (
    <div className='font-poppins  bg-c-white h-auto w-screen md:w-max   md:rounded-3xl   mt-[50px] '>
      <div className='px-4 py-8 lg:px-6'>
        <div className='mx-auto text-center text-c-dark-blue-1'>{children}</div>
      </div>
    </div>
  )
}

/**
 *
 * SignUpWelcomePage is the component that is displayed after the user has signed up.
 * It activates the user and redirects them to the app.
 */

function SignUpWelcomePage(): JSX.Element {
  // Get the dispatch function from the UserAccountContext for storing the user data into the context.
  // const { dispatch } = useContext(UserAccountContext)
  // Get the navigate function from the useNavigate hook for redirecting the user to the app.
  // const navigate = useNavigate()
  /**
   * instance is the MSAL instance that is used to get the data of the MSAL regarding accounts and active account and also provide the functions
   * to set the active account and get the active account or to redirect the user to the login page / sign up page.
   */
  const msalContext = useMsal()
  const { instance, inProgress } = msalContext
  // redirectPageURL is the URL of the page where the user is redirected after the login.
  const redirectPageURL = useLocation().pathname
  // activeAccount is the account that is currently active.
  // Removed usage of activateAccount as it is observed it takes some time for the active account to get updated after coming to this page
  // Usually the instance takes time to update its state like accounts and therefore we are using the instance.getActiveAccount() to get the active account.
  // const activeAccount = instance.getActiveAccount()
  // this is the state that is used to start the timer after the user has been activated.
  const [shouldStartTimer, setShouldStartTimer] = React.useState(false)
  // userDataToActive is the state that is used to store the user data that is to be activated.
  // No longer activating the user from UI, this is now done from signup policy
  const [userDataToActive, setUserDataToActive] = useState<iUserDataToActivate>()

  // Needed state for this because we need to update this from the instance.getActiveAccount() which needs some time to update
  const [userName, setUserName] = useState<string>()

  // redirectToApp is the function that is used to redirect the user to the app after the user has been activated.
  const redirectToApp = React.useCallback(() => {
    // sign up token still does not return datakey and hence we are logging out and logging in again to get the datakey
    // it is safer approach anyway
    instance.logoutRedirect({
      postLogoutRedirectUri: '/',
      account: instance.getActiveAccount(),
      authority: SignUpAuthority,
    })

    // // collect the user data from the all accounts that has been present in the instance.
    // const allAccounts: AccountInfo[] = instance.getAllAccounts()
    // // check if the all accounts are present and the length of the accounts is greater than 0.
    // if (allAccounts.length > 0) {
    //   // find the current account from the all accounts with the stored user while creating account.
    //   const currentAccount = allAccounts.find(
    //     (account) =>
    //       (account.idTokenClaims?.email as string) === (userDataToActive?.EmailId as string),
    //   )
    //   // check if the current account is present.
    //   if (currentAccount) {
    //     // set the active account to the current account.
    //     instance.setActiveAccount(currentAccount)
    //     // update the context and redirect the user to the app.
    //     dispatch({
    //       type: UserAccountContextActions.SetADB2CPolicy,
    //       payload: { adb2cPolicy: SignUpAuthority },
    //     })

    //     // Sign up JWT is not returning DataKey extension and hence causing APIs to return erronous data
    //     // Hence, we are logging out and logging in again to get the DataKey extension attribute from AD B2C
    //     // This is a temporary fix until the AD B2C is fixed

    //     // navigate('/')
    //     instance.logoutRedirect({
    //       postLogoutRedirectUri: '/',
    //       account: currentAccount,
    //       authority: SignUpAuthority,
    //     })
    //   }
    // }
  }, [instance])

  useEffect(() => {
    if (instance.getActiveAccount() && inProgress === InteractionStatus.None) {
      if (instance.getActiveAccount()?.idTokenClaims === undefined) {
        instance.logoutRedirect({
          postLogoutRedirectUri: '/',
          account: instance.getActiveAccount(),
          authority: SignUpAuthority,
        })
      }

      const username =
        `${instance.getActiveAccount()?.idTokenClaims?.given_name} ${
          instance.getActiveAccount()?.idTokenClaims?.family_name
        }` ??
        instance.getActiveAccount()?.username ??
        instance.getActiveAccount()?.idTokenClaims?.name ??
        'User'
      setUserName(username)
      setShouldStartTimer(true)
    }
  }, [inProgress, instance])

  // Added this useEffect to handle the case where the user is not logged and navigating to /web/signup/done
  useEffect(() => {
    if (instance.getAllAccounts().length === 0 && inProgress === InteractionStatus.None) {
      instance.logoutRedirect({
        postLogoutRedirectUri: '/',
        account: instance.getActiveAccount(),
        authority: SignUpAuthority,
      })
    }
  }, [inProgress, instance])

  // getting the username form active account.
  const username =
    `${instance.getActiveAccount()?.idTokenClaims?.given_name} ${
      instance.getActiveAccount()?.idTokenClaims?.family_name
    }` ??
    instance.getActiveAccount()?.username ??
    instance.getActiveAccount()?.idTokenClaims?.name ??
    'User'

  // invitationId is the id of the invitation that is sent to the user for useMutation Hook.
  const invitationId = instance.getActiveAccount()?.idTokenClaims?.invitationId as string

  // activateUserMutation is the useMutation hook that is used to activate the user.
  const activateUserMutation = useMutation({
    mutationKey: activateUserMutationKey(invitationId),
    mutationFn: (userData: iUserDataToActivate) => {
      // getAccessTokenProps is the props that is used to get the access token for the user.
      const getAccessTokenProps = {
        msalContext,
        redirectPageURL,
        adb2cPolicy: SignUpAuthority,
      }

      // activateUserPayLoad is the payload that is used to activate the user.
      const activateUserPayLoad: iActivateUserProps = {
        userData,
        accessTokenProps: getAccessTokenProps,
      }

      return activateUser(activateUserPayLoad)
    },
    onSuccess: (
      data: AxiosResponse<iUserDataToActivate, unknown>,
      variables: iUserDataToActivate,
    ) => {
      // set the user data to the state and start the timer.
      setUserDataToActive(variables)
      setShouldStartTimer(true)
    },
    onError: (error) => {
      console.log('activateUser - onError')
      console.log(error)
    },
  })

  /**
   * useEffect is used to activate the user when the active account is present and
   * the activateUserMutation is not loading,
   * not success, not error and not paused.
   */

  useEffect(() => {
    const activeAccount = instance.getActiveAccount()
    if (
      activeAccount &&
      inProgress === InteractionStatus.None &&
      !activateUserMutation.isLoading &&
      !activateUserMutation.isSuccess &&
      !activateUserMutation.isError &&
      !activateUserMutation.isPaused
    ) {
      const userData: iUserDataToActivate = {
        InvitationId: activeAccount?.idTokenClaims?.invitationId as string,
        UserOID: activeAccount?.idTokenClaims?.oid as string,
        FirstName: activeAccount?.idTokenClaims?.given_name as string,
        LastName: activeAccount?.idTokenClaims?.family_name as string,
        Status: UserStatusTypes.Active,
        EmailId: activeAccount?.idTokenClaims?.email as string,
      }
      activateUserMutation.mutate(userData)
    }
  }, [instance, activateUserMutation, inProgress])

  // if the user is activated then redirect the user to the app.
  if (instance.getActiveAccount()) {
    return (
      <SignUpWelcomePageCardWrapper>
        <div className='text-xl text-center md:text-left font-bold font-poppins  tracking-tight text-c-dark-blue-1'>
          Hello {userName}
        </div>
        <div className='text-sm font-medium leading-5 text-center md:text-left text-c-dark-blue-1 '>
          Your account has been activated
        </div>

        <TickIconWithCircle fill='green' className='h-10 w-full flex justify-center my-4' />

        <p className='mx-auto  max-w-xl text-base leading-6 text-c-dark-blue-1 font-bold text-center'>
          Redirecting to Crowcon Connect App in{' '}
          <Timer
            countdown={3}
            shouldStartAutomatically={shouldStartTimer}
            callback={redirectToApp}
          />{' '}
          seconds
        </p>

        {/* <p className='mx-auto mt-1 max-w-xl text-xs leading-4 text-c-light-gray-1 text-center'>
          Or click here to login and start using Crowcon Connect
        </p> */}
      </SignUpWelcomePageCardWrapper>
    )
  }
  // if the user is not activated then show the error message.
  // if (activateUserMutation.isError) {
  //   return (
  //     <SignUpWelcomePageCardWrapper>
  //       <div className='flex flex-col items-center justify-around gap-x-10 '>
  //         <AlertIcon fill='red' className='w-10 h-10 my-4' />
  //         <div className='max-w-xl text-red-500 text-sm font-medium leading-5 text-left'>
  //           Error activating your account. Please contact support.
  //         </div>
  //       </div>
  //     </SignUpWelcomePageCardWrapper>
  //   )
  // }
  // if the user is not activated then show the loading spinner.
  return (
    <SignUpWelcomePageCardWrapper>
      <div className='flex flex-col justify-center items-center gap-x-10'>
        <div className='max-w-xl text-lg font-medium leading-5 text-c-dark-blue-1 text-left'>
          Please wait while we activate your account
        </div>
        <div className='p-8 mt-6 animate-spin rounded-full border-4 border-solid border-c-dark-blue-1 border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]' />
      </div>
    </SignUpWelcomePageCardWrapper>
  )
}

export default withPageTracking(SignUpWelcomePage)
