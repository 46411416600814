import GenericWidget from 'components/viewComponents/GenericWidget'
import { iOperatorInfo } from 'data/Interfaces'
import { IFilterValues } from 'forms/FormModelInterface'
import React from 'react'
import { OperatorsPageColumnIds } from '../view/OperatorsPageUtils'

type HighestOperatorProps = {
  data?: iOperatorInfo[]
  operatorFilteredParam: string
  onFilter: (value: IFilterValues[]) => void
}

export default function HighestOperator({
  data,
  operatorFilteredParam,
  onFilter,
}: HighestOperatorProps) {
  const widgetData = data?.reduce(
    (
      acc: {
        [key: string]: number
      },
      cur,
    ) => ({
      ...acc,
      [`${cur.firstName} ${cur.lastName}`]: cur.currentDevice.length ?? 0,
    }),
    {},
  )

  return (
    <GenericWidget
      widgetProps={{
        title: 'Top 5 Operator',
        widgetData: Object.entries(widgetData ?? {})
          .sort((a, b) => b[1] - a[1])
          .map(([key, values]) => ({
            originalData: key,
            displayText: key,
            value: values,
            isFiltered: key === operatorFilteredParam,
            columnId: OperatorsPageColumnIds.fullName,
          }))
          .slice(0, 5),
        widgetLabelCount: 4,
        columnId: OperatorsPageColumnIds.fullName,
        gridContainerClassName: 'grid-cols-1 md:grid-cols-2 gap-1',
        onFilter: (filterRequestValues: IFilterValues[]) => onFilter?.(filterRequestValues),
      }}
    />
  )
}
